import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { RenewalObject } from "../../../interfaces/NJPORT/renewal.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { formatDateStringForTextField } from "../../utilities/basics";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: RenewalObject | null;
}

const RenewalInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<RenewalObject>({});
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [reasonDD, setReasonDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultStatus, setDefaultStatus] = React.useState<string>('');
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>('');
  const [defaultpacCard, setDefaultpacCard] = React.useState<string>('');
  const [defaultReason, setDefaultReason] = React.useState<any>('');

  React.useEffect(() => {
    jobTitleDropdown();
    pacCardDropdown();
    reasonDropdown();
    if (editReport) setReport(editReport);
    // if (editReport) setDefaultReason(editReport?.Reason)

    defaultStatusDD(reasonDD);
    defaultjobTilteDD(jobTitleDD);
    defaultpacCardDD(pacCardDD);
    defaultReasonDD(reasonDD);

  }, []);


  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.Reason)?.Value! : ''
    setDefaultStatus(v);
  }

  const defaultjobTilteDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.Job_Code)?.Value! : ''
    setDefaultjobTilte(v);
  }


  const defaultpacCardDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.PAC_Card_Issued)?.Value! : ''
    setDefaultpacCard(v);
  }

  const defaultReasonDD = (d: DropDown[]) => {
    console.log(editReport?.Reason);
    let v: string = d ? d.find(d => d.Value == editReport?.Reason)?.Value! : ''
    setDefaultReason(v);
  }

  const handleSubmit = async () => {
    let data = report;
    // console.log(data); return false;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data)
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    console.log(report);
    let temp = { ...report };
    if (temp) {
      if (!temp.CaseNo || temp.CaseNo.trim().length === 0) {
        flag = true;
        text = "CaseNo";
      } else if (!temp.Date_Renewal) {
        flag = true;
        text = "Date Renewal";
      } else if (!temp.FirstName || temp.FirstName.length === 0) {
        flag = true;
        text = "First Name";
      } else if (!temp.LastName || temp.LastName.length === 0) {
        flag = true;
        text = "Last Name";
      } else if (!temp.DOB) {
        flag = true;
        text = "Date of Birth";
      } else if (!temp.Job_Code || temp.Job_Code.length === 0) {
        flag = true;
        text = "Job Title";
      }

      if (flag) {
        setAlertText("Please complete Mandatory fields " + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE"
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const reasonDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_APPLICANTREASON"
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setReasonDD(response);
    defaultStatusDD(response);
    defaultReasonDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
  };

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "CaseNo":
        temp.CaseNo = val;
        break;
      case "Date_Renewal":
        temp.Date_Renewal = val;
        break;
      case "FirstName":
        temp.FirstName = val;
        break;
      case "MiddleName":
        temp.MiddleName = val;
        break;
      case "LastName":
        temp.LastName = val;
        break;
      case "Job_Title":
        temp.Job_Code = val;
        setDefaultjobTilte(jobTitleDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "ssn":
        temp.SSN = val;
        break;
      case "DOB":
        temp.DOB = val;
        break;

      case "email":
        temp.Email = val;
        break;
      case "pac_Card_Issued":
        temp.PAC_Card_Issued = val;
        setDefaultpacCard(pacCardDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "PAC_Card_Number":
        temp.PAC_Card_Number = val;
        break;
      case "Date_PAC_Card_Issued":
        temp.Date_PAC_Card_Issued = val;
        break;
      case "reason":
        temp.Reason = val;
        setDefaultReason(reasonDD.find(d => d.Key === val)?.Value ?? '');
        break;

    }
    if(!temp.Date_Renewal){
      temp.Date_Renewal=convertDate(report.Date_Renewal)
    }
    if(!temp.DOB){
      temp.DOB=convertDate(report.DOB)
    }
    if(!temp.Date_PAC_Card_Issued){
      temp.Date_PAC_Card_Issued=convertDate(report.Date_PAC_Card_Issued)
    }
    setReport(temp);
  };
  const convertDate = (dateInput: Date | string | null | undefined) => {
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is a string, try to parse it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  
    if (date instanceof Date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is invalid, fallback to today's date
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  };
  

  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Case No"
                value={report.CaseNo}
                onChange={(e) => handleValueChange("CaseNo", e.target.value)}
                error={report.CaseNo ? false : true}
                helperText={report.CaseNo ? "" : "Required"}
              />
            </Grid>
            {/* <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("InvestigatorName", e.target.value)
                }
              />
            </Grid> */}
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Renewal"
                value={convertDate(report.Date_Renewal)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Date_Renewal", e.target.value)
                }
                error={convertDate(report.Date_Renewal) ? false : true}
                helperText={report.Date_Renewal ? "" : "Required"}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Person
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="First Name"
                value={report.FirstName}
                onChange={(e) => handleValueChange("FirstName", e.target.value)}
                error={report.FirstName ? false : true}
                helperText={report.FirstName ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Middle Name"
                value={report.MiddleName}
                onChange={(e) =>
                  handleValueChange("MiddleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Last Name"
                value={report.LastName}
                onChange={(e) => handleValueChange("LastName", e.target.value)}
                error={report.LastName ? false : true}
                helperText={report.LastName ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="DOB"
                value={convertDate(report.DOB)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleValueChange("DOB", e.target.value)}
                error={convertDate(report.DOB) ? false : true}
                helperText={report.DOB ? "" : "Required"}
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="SSN"
                value={report.SSN}
                onChange={(e) => handleValueChange("SSN", e.target.value)}
              // error={report.SSN ? false : true}
              // helperText={report.SSN ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="PhoneNo"
                value={report.PhoneNumber}
                onChange={(e) =>
                  handleValueChange("PhoneNumber", e.target.value)
                }
              // error={report.PhoneNo ? false : true}
              // helperText={report.PhoneNo ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Email"
                value={report.Email}
                onChange={(e) => handleValueChange("Email", e.target.value)}
                error={report.Email ? false : true}
              />
            </Grid>
          </Grid> */}
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Job
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  label="JobTitle"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("Job_Title", e.target.value)
                  }
                  error={report.Job_Title ? false : true}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          PAC
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Pac Card Number"
                value={report.PAC_Card_Number}
                onChange={(e) =>
                  handleValueChange("PAC_Card_Number", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Pac Card Issued"
                value={convertDate(report.Date_PAC_Card_Issued)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Date_PAC_Card_Issued", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="reason-Label">Reason</InputLabel>
                <Select
                  labelId="reason-Label"
                  id="reason-select"
                  value={defaultReason}
                  label="JobTitle"
                  renderValue={() => defaultReason}
                  onChange={(e: any) =>
                    handleValueChange("reason", e.target.value)
                  }
                >
                  {reasonDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RenewalInputForm;

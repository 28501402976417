import React, {useState} from "react";
import SearchResults from "../../components/tools/scor/_searchResultsSCOR";
import {GetDarInfo_Query, PersonInfo, getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import SCORSearch from "../../components/tools/scor/_scorSearch";
import {handleSearchTime} from "../../services/formatDate.service";

function SCOR() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [hideJail, setHideJail] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any, top: number) => {    
        let search_query: GetDarInfo_Query = {
            "QueryType": "H",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 1 : 0,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": params.CrumbScore,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": params.CrumbPercentile ? [params.CrumbPercentile] : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setDarTbl([])
        setDarTbl(qRes.AnyTable)
        if (qRes.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    
    const HandleHideJail = (value:any) => {
        setHideJail(value)
    }

    return (
        <div>
            {isUserPermission('Arrest')?
                <>
                    <SCORSearch SubmitSearch={SubmitSearch} HandleHideJail={HandleHideJail}/>
                    {!noData ? <SearchResults Persons={darTbl} hideJail={hideJail} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default SCOR;
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import {AxiosResponse} from "axios";
import AuthenticatedDashboardReqService from "./AuthenticatedDashboardReq.service";
import AuthenticatedDashboardAPIReq from "./AuthenticatedDashboardReq.service";
import {GetDefaultStateForSite} from "../interfaces/config.interface";
import {SiteName} from "./config.service";
import saveAs from "file-saver";


export async function RunGetDailyStats(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/Dashboard/GetDailyStats', params)
    return (res.data)
}

export async function GetStolenVehicleDashboard(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/Dashboard/GetStolenVehicleStats', params)
    return (res.data)
}

export async function RunGetDefaultCity(): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetDefaultCity')
    return (res.data)
}

export async function RunGetDashboardToken(): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/Dashboard/GetDashboardToken')
    return (res.data)
}

export async function RunGetDashboardRefreshToken(): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetDashboardRefreshToken')
    return (res.data)
}

export async function RunGetCityList(): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetCityList')
    return (res.data)
}

export async function RunGetStolenVehicleCountyCityList(state : string | null): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetStolenVehicleCountyCityList?State='+ (state ? state : GetDefaultStateForSite(SiteName)))
    return (res.data)
}


export async function GetStolenVehicleAgencyORI(state : string | null): Promise<any[]> {
    let res: AxiosResponse<any[]> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetStolenVehicleAgencyORI?State='+ (state ? state : GetDefaultStateForSite(SiteName)))
    return (res.data)
}

export async function GetDailyShooting(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/Dashboard/GetDailyShooting', params)
    return (res.data)
}

export async function GetETraceDashboard(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/Dashboard/GetETraceStats', params)
    return (res.data)
}

export async function GetETraceStatDetails(params: any): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.post('/api/Dashboard/GetETraceStatDetails', params , {responseType:'blob'})
    if (res != null) {
        try{
            if (res != null) {
                const outputFilename = "ETraceData.xlsx";
                let url = window.URL.createObjectURL(res.data);     
                saveAs(url,outputFilename)
            }
        }catch(e){
            console.log(e)
        }    
    }
    return null; 
}

export async function RunGeteTraceCountyCityList(): Promise<any>
{
    let res: AxiosResponse<any> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetStolenVehicleCountyCityList')
    return (res.data)
}

export async function GeteTraceAgencyORI(County:string | null, City:string | null): Promise<any[]> {
    let res: AxiosResponse<any[]> = await AuthenticatedDashboardAPIReq.get('/api/Dashboard/GetETraceAgencyORI?County=' + County + '&City=' + City)
    return (res.data)
}

export function isPointInPolygon (latitude:number, longitude:number, polygon:any) {
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
        throw new TypeError('Invalid latitude or longitude. Numbers are expected')
    } else if (!polygon || !Array.isArray(polygon)) {
        throw new TypeError('Invalid polygon. Array with locations expected')
    } else if (polygon.length === 0) {
        throw new TypeError('Invalid polygon. Non-empty Array expected')
    }

    const x = latitude; const y = longitude

    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0]; const yi = polygon[i][1]
        const xj = polygon[j][0]; const yj = polygon[j][1]

        const intersect = ((yi > y) !== (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
        if (intersect) inside = !inside
    }

    return inside
};
import React, { useState, useEffect } from "react";

import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    ButtonGroup,
    Tooltip,
    FormControlLabel
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatInputDate } from "../../../services/formatDate.service";
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface ScorVariationSearchProps {
    SubmitSearch: any
    HandleHideJail: any
}

const ScorVariationSearch: React.FC<ScorVariationSearchProps> = ({ SubmitSearch, HandleHideJail }) => {
    var toDate = new Date();
    var prevDate = new Date();
    let today: Date = toDate
    var fromday: Date = new Date();
    fromday.setMonth(fromday.getMonth() - 6); //Last 6 Months

    const [params, setParams] = React.useState({ header: "MONTH", detail: "PersonID", group: "CRUMBSCORE", fromDate: formatInputDate(fromday), toDate: formatInputDate(today), showDate: true, systemCall: false, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", Variation: (SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? "100" : "20" })
    const [dateType, setDateType] = React.useState<string>("SCOR Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [hideJail, setHideJail] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleHeaderChange = (e: any) => {
        setParams({ ...params, header: e.target.value })
    }

    const handleDetailChange = (e: any) => {
        setParams({ ...params, detail: e.target.value })
    }

    const handleGroupChange = (e: any) => {
        setParams({ ...params, group: e.target.value })
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, header: "MONTH", detail: "PersonID", group: "CRUMBSCORE", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true, systemCall: false, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", Variation: (SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? "100" : "20" })
        setDateType("SCOR Date")
        setAndOrType("AND")
        setHideJail(false)
        setClear(clear + 1)
    }

    const handleHideJail = (value: any) => {
        setHideJail(value)
        HandleHideJail(value)
    }

    const handleSearch = () => {
        let dropDownParam = { ...dropDownParams };
        dropDownParam.Statute = Object?.keys(dropDownParam.Statute)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.AgencyArrested = Object?.keys(dropDownParam.AgencyArrested)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                >
                    <ToggleButton value="SCOR Date" sx={{ pr: 2, pl: 2 }} >{GetStringNameForSite(SiteName, "SCOR")} Date</ToggleButton>
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                </ToggleButtonGroup>
                { /* Header Dropdown */}
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="header">Header</InputLabel>
                        <Select
                            labelId="header"
                            value={params.header}
                            size="small"
                            label="Header"
                            onChange={handleHeaderChange}
                        >
                            {/*<MenuItem value={"DAY"}>DAY</MenuItem>*/}
                            {/*<MenuItem value={"WEEK"}>WEEK</MenuItem>*/}
                            <MenuItem value={"MONTH"}>MONTH</MenuItem>
                            {/*<MenuItem value={"YEAR"}>YEAR</MenuItem>*/}
                        </Select>
                    </FormControl>
                </div>
                < >
                    <div >
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                            size={"small"} label="From Date"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            value={params.fromDate}
                            onChange={(e: any) => {
                                setParams({ ...params, fromDate: e.target.value })
                            }}
                        />
                    </div>
                    <div >
                        <TextField variant="outlined" margin='none' type={"date"} fullWidth
                            size={"small"} label="To Date"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            value={params.toDate}
                            onChange={(e: any) => {
                                setParams({ ...params, toDate: e.target.value })
                            }}
                        />
                    </div>
                </>
                { /* Detail Dropdown */}
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="detail">Detail</InputLabel>
                        <Select
                            labelId="detail"
                            value={params.detail}
                            size="small"
                            label="Detail"
                            onChange={handleDetailChange}
                        >
                            <MenuItem value={"PersonID"}>OFFENDER</MenuItem>
                            {/*<MenuItem value={"STATUTE"}>STATUTE</MenuItem>*/}
                            <MenuItem value={"ARRESTAGENCY"}>ARRESTED AGENCY</MenuItem>
                            <MenuItem value={"ARRESTCOUNTY"}>ARRESTED {GetStringNameForSite(SiteName, "COUNTY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"RESIDENTCITY"}>RESIDENT {GetStringNameForSite(SiteName, "CITY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"RESIDENTCOUNTY"}>RESIDENT {GetStringNameForSite(SiteName, "COUNTY")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"INCIDENTCITY"}>INCIDENT {GetStringNameForSite(SiteName, "CITY")?.toUpperCase()}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                { /* GROUP Dropdown */}
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="group">Group</InputLabel>
                        <Select
                            labelId="group"
                            value={params.group}
                            size="small"
                            label="Group"
                            onChange={handleGroupChange}
                        >
                            <MenuItem value={"CRUMBSCORE"}>{GetStringNameForSite(SiteName, "SCOR_DETAILS_TBL")?.toUpperCase()}</MenuItem>
                            <MenuItem value={"DENSERANK"}>{GetStringNameForSite(SiteName, "SCOR_PIVOT")?.toUpperCase()}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div><TextField margin='none' size={"small"} fullWidth label="Variation" type="number" value={params.Variation} onChange={(e: any) => setParams({ ...params, Variation: e.target.value })} /></div>
            </div>
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Age Lower Range" type="number" value={params.AgeRangeStart} onChange={(e: any) => setParams({ ...params, AgeRangeStart: e.target.value })} title="Age of offender at the time of Arrest. (Lower Limit)" /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Age Upper Range" type="number" value={params.AgeRangeEnd} onChange={(e: any) => setParams({ ...params, AgeRangeEnd: e.target.value })} title="Age of offender at the time of Arrest. (Upper Limit)" /></div>
            </div>
            <div style={{ "marginTop": "15px" }}>
                <ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"crumb"} />
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={hideJail}
                                    checked={hideJail}
                                    onChange={(e: any, value: any) => handleHideJail(value)}
                                />}
                            label="Hide Jail"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20, } }} />
                    </div>
                <div>
                    <ButtonGroup size="large" color="inherit" >
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
}

export default ScorVariationSearch;
import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup } from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatInputDate } from "../../../services/formatDate.service";
import { GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface AnkleBraceletSearchProps {
    SubmitSearch: any
}

const AnkleBraceletSearch: React.FC<AnkleBraceletSearchProps> = ({ SubmitSearch }) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else
        fromday = toDate;
    const [params, setParams] = React.useState({ CaseNumber:"", FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, CaseNumber:"", FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall: false })
        setDateType("Entry Date")
        setAndOrType("AND")
        setClear(clear + 1)
    }

    const handleSearch = () => {
        SubmitSearch(params, dateType, andOrType, dropDownParams)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={handleDateType}
                    sx={{ mr: 2 }}
                >
                    <ToggleButton value="Report Date" sx={{ pr: 2, pl: 2 }} >Report Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                        size={"small"} label="From Date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={params.fromDate}
                        onChange={(e: any) => {
                            setParams({ ...params, fromDate: e.target.value })
                        }}
                    />
                </div>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                        size={"small"} label="To Date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={params.toDate}
                        onChange={(e: any) => {
                            setParams({ ...params, toDate: e.target.value })
                        }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><TextField margin='none' size={"small"} fullWidth label="Case #" value={params.CaseNumber} onChange={(e: any) => setParams({ ...params, CaseNumber: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} /></div>
                {/*<div><TextField margin='none' size={"small"} fullWidth label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} /></div>*/}
                {/*<div><TextField margin='none' size={"small"} fullWidth label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} /></div>*/}
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"AnkleBracelet"} /></div>
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default AnkleBraceletSearch;
import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { StevedoreObject } from "../../../interfaces/NJPORT/Stevedore.interface";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: StevedoreObject | null;
}

const SteveDoreInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<StevedoreObject>({});
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultstatus, setDefaultStatus] = React.useState<string>('');
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>('');
  const [defaultpacCard, setDefaultpacCard] = React.useState<string>('');

  React.useEffect(() => {
    invStatusDropdown();
    jobTitleDropdown();
    pacCardDropdown();
    if (editReport) setReport(editReport);

    defaultStatusDD(invStatusDD);
    defaultjobTilteDD(jobTitleDD);

  }, []);

  const defaultStatusDD = (d : DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.Investigation_Status)?.Value!:''
    setDefaultStatus(v);
  }

  const defaultjobTilteDD = (d : DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.BusinessType)?.Value!:''
    setDefaultjobTilte(v);
  }



  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "investigatorName":
        temp.InvestigatorName = val;
        break;
      case "date_Received":
        temp.Date_Received = val;
        break;
      case "date_Status_Change":
        temp.Date_Status_Change = val;
        break;
      case "investigation_Status":
        temp.Investigation_Status = val;
        setDefaultStatus(invStatusDD.find(d => d.Key === val)?.Value ?? '');
        break;
        case "email":
          temp.Email = val;
          break;
          case "address":
          temp.Address = val;
          break;
          case "city":
          temp.City = val;
          break;
          case "percentOwned":
          temp.PercentOwned = val;
          break;
          case "stevedore":
          temp.Stevedore = val;
          break;
          case "businessType":
          temp.BusinessType = val;
          setDefaultjobTilte(jobTitleDD.find(d => d.Key === val)?.Value ?? '');
          break;
          case "principles":
          temp.Principles = val;
          break;
          case "notes":
          temp.Notes = val;
          break;
      case "createUser":
        temp.CreateUser = val;
        break;
      case "createDept":
        temp.CreateDept = val;
        break;
      case "createDate":
        temp.CreateDate = val;
        break;
      case "updateUser":
        temp.UpdateUser = val;
        break;
      case "updateDept":
        temp.UpdateDept = val;
        break;
      case "updateDate":
        temp.UpdateDate = val;
        break;
      case "deleted":
        temp.Deleted = val;
        break;
    }
    setReport(temp);
  };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.CaseNo || temp.CaseNo.trim().length === 0) {
        flag = true;
        text = "Case No";
      } else if (!temp.InvestigatorName || temp.InvestigatorName.trim().length === 0) {
        flag = true;
        text = "Investigator Name";
      }
      else if (!temp.Date_Received) {
        flag = true;
        text = "Date Received";
      } else if (!temp.Date_Status_Change) {
        flag = true;
        text = "Date Status Change";
      }
      else if(!temp.Date_Status_Change){
        flag = true;
        text = "Date Status Change";
      }
      else if (
        !temp.Investigation_Status ||
        temp.Investigation_Status.length === 0
      ) {
        flag = true;
        text = "Investigation Status";
      } else if (!temp.Email || temp.Email.trim().length === 0) {
        flag = true;
        text = "Email";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
    defaultStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_BUSINESSTYPE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);

  };

  return (
    <Box className="pt-1" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Case No"
                value={report.CaseNo}
                onChange={(e) =>
                  handleValueChange("caseNo", e.target.value)
                }
                error={report.CaseNo ? false : true}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("investigatorName", e.target.value)
                }
                error={report.InvestigatorName ? false : true}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Received"
                value={report.Date_Received}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Received", e.target.value)
                }
                error={report.Date_Received ? false : true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={5} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Inv Status</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="invStatus-select"
                  value={defaultstatus}
                  label="InvStatus"
                  renderValue={() => defaultstatus}
                  onChange={(e: any) =>
                    handleValueChange("investigation_Status", e.target.value)
                  }
                  error={report.Investigation_Status ? false : true}
                >
                  {invStatusDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Status Change"
                value={report.Date_Status_Change}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Status_Change", e.target.value)
                }
                error={report.Date_Status_Change ? false : true}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Person
        </div>
        <div className="my-3">
          

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Email"
                value={report.Email}
                onChange={(e) => handleValueChange("email", e.target.value)}
                error={report.Email ? false : true}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
              type="number"
                fullWidth
                margin="none"
                label="%Owned"
                value={report.PercentOwned}
                onChange={(e) => handleValueChange("percentOwned", e.target.value)}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Address"
                value={report.Address}
                onChange={(e) => handleValueChange("address", e.target.value)}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="City"
                value={report.City}
                onChange={(e) => handleValueChange("city", e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Business
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Stevedore"
                value={report.Stevedore}
                onChange={(e) => handleValueChange("stevedore", e.target.value)}
                // error={report.Job_Code ? false : true}
                
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Type of Business</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  label="JobTitle"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("businessType", e.target.value)
                  }
                  // error={report.Job_Title ? false : true}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Current Principles"
                value={report.Principles}
                onChange={(e) =>
                  handleValueChange("sponsorName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Notes"
                value={report.Notes}
                onChange={(e) =>
                  handleValueChange("notes", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>


      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SteveDoreInputForm;

import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Login from "./components/authentication/login/login";
import ForgotPassword from "./components/authentication/forgot-password/forgot-password";
import {MainLayout} from "./layouts/MainLayout";
import {Error404} from "./errors/404";
import MainDashboard from "./tools/main_dashboard/main_dashboard";
import Arrest from "./tools/arrest/arrest";
import FrequentOffender from "./tools/frequent_offender/frequent_offender";
import PersonNearBy from "./tools/person_nearby/person_nearby";
import ALPRMultipleOccurrence from "./tools/alpr_multiple_occurrence/alpr_multiple_occurrence";
import PhoneSearch from "./tools/phone_search/phone_search";
import TowedVehicle from "./tools/towed_vehicle/towed_vehicle";
import ShootingNotification from "./tools/shooting_notification/shooting_notification";
import CarjackingNotification from "./tools/carjacking_notification/carjacking_notification";
import OpenCaseIncidents from "./tools/open_case_incident/open_case_incident";
import CodisNotification from "./tools/codis_notification/codis_notification";
import NJPOP from "./tools/njpop/njpop";
import ALPRVINSearch from "./tools/alpr_vin_search/alpr_vin_search";
import NJTrace from "./tools/njtrace/njtrace";
import HumanTraffic from "./tools/human_traffic/human_traffic";
import ArrestAnalysis from "./tools/arrest_analysis/arrest_analysis";
import Parole from "./tools/parole/parole";
import PrisonerInformation from "./tools/prisoner_information/prisoner_information";
import ProfessionalLicense from "./tools/professional_license/professional_license";
import StatuteMapping from "./tools/statue_mapping/statue_mapping";
import ViolentOffender from "./tools/violent_offender/violent_offender";
import ECrawl from "./tools/ecrawl/ecrawl";
import LogOff from "./components/authentication/logoff/logoff";
import ArrestDetail from './Views/Detail/ArrestDetail';
import ETicketDetail from './Views/Detail/ETicketDetail';
import InfocopDetail from './Views/Detail/InfocopDetail';
/*import InfocopImageDetail from './Views/Detail/InfocopImageDetail';*/
import ALPRDetail from './Views/Detail/ALPRDetail';
import CadRmsDetail from './Views/Detail/CadRmsDetail';
import CodisDNADetail from './Views/Detail/CodisDNADetail';
import ETraceDetail from './Views/Detail/ETraceDetail';
import NJTraceDetail from './Views/Detail/NJTraceDetail';
import ParkMobileDetail from './Views/Detail/ParkMobileDetail';
import PrisonParoleDetail from './Views/Detail/PrisonParoleDetail';
import PrisonReleaseDetail from './Views/Detail/PrisonReleaseDetail';
import ProfessionDetail from './Views/Detail/ProfessionDetail';
import ViolentDetail from './Views/Detail/ViolentDetail';

import ManageUser from './Views/Admin/ManageUser'
import ManagePOI from "./Views/Admin/ManagePOI";
import NCIC from "./components/Admin/NCIC";
import ManageNCIC from "./Views/Admin/ManageNCIC";
import ManagePersonMainRecord from "./Views/Admin/ManagePersonMainRecord";
import ModifyUser from "./Views/Admin/ModifyUser";
import AddPOI from "./Views/Admin/AddPOI";
import PersonSummaryPDF from "./tools/pdf/BTPersonSummaryPDF";
import ManageDept from "./Views/Admin/ManageDept";
import NJPOPMultipleOccurrence from "./tools/njpop/njpopMultipleOccurrence";
import DashboardLayout from "./components/Dashboard/NJPOP/_dashboardLayout";
import Dashboard from "./tools/dashboard/NJPOP/dashboard";
import ShotSpotter from './tools/shot_spotter/shot_spotter';
import SCOR from './tools/scor/scor';
import ScorVariation from './tools/scor_variation/scor_variation';
import LinkAnalysis from "./tools/link_analysis/link_analysis";
import ManageUnitLevelGroup from './Views/Admin/UnitLevelGroup';
import AuditLog from './Views/Admin/AuditLog';
import ManagePassword from './Views/Admin/ManagePassword';
import ProfileReport from './tools/profile_report/profile_report';
import StolenVehicle from "./tools/stolen_vehicle/stolen_vehicle";
import NJPOPPerson from "./tools/njpop/njpopPerson";
import DuplicatePerson from "./tools/duplicate_person/duplicate_person";
import GVRTF from "./tools/grail/GVRTF";
import GRAILReferral from "./tools/grail/GRAILReferral";
import PersonAnalysis from "./tools/link_analysis/PersonAnalysis";
import SecuredView from './components/SharedPerson/SharedPerson';
import GVRTFReferralDetail from "./Views/Detail/GVRTFReferralDetail";
import UserManual from "./Views/Support/UserManual";
import FAQs from "./Views/Support/FAQs";
import TRAINING from "./Views/Support/TRAINING";
import LPRDetail from './Views/Detail/LPRDetail';
import NCICMultipleSearch from './tools/ncic_multiple_occurrence/ncic_multiple_occurrence';
import SecuredNJTraceView from './components/SharedPerson/SharedNJTrace';
import Jail from "./tools/jail/jail";
import SVDashboard from "./tools/dashboard/StolenVehicle/svDashboard";
import ETDashboard from "./tools/dashboard/eTrace/etDashboard";
import PersonTimeLine from "./tools/link_analysis/PersonTimeLine";
import NIBINTimeLine from "./tools/link_analysis/NIBINTimeLine";
import JailDetail from "./Views/Detail/JailDetail";
import ALPRPDFReportDetail from './Views/Detail/ALPRPDFReportDetail';
import LoginPage from "./pages/authentication/login";
import SAML_LOGIN from "./pages/authentication/SAML_LOGIN";
import GVRTFEmailOutputForm from './components/GRAIL/GVRTF_EmailOutputForm';
import MissingPerson from "./tools/missing_person/missing_person";
import FieldContact from "./tools/field_contact/field_contact";
import SupplementalReport from "./tools/supplemental_report/supplemental_report";
import TrafficCrash from "./tools/traffic_crash/traffic_crash";
import Investigation from './tools/njport/investigation';
import Stevedore from './tools/njport/stevedore';
import Tempcard from './tools/njport/tempcard';
import Renewal from './tools/njport/renewal';
import MoneyTrackingSheet from './tools/njport/money';
import AnalystsReport from './tools/njport/analysts';
import Shipentry from './tools/njport/shipentry';
import PDOHire from './tools/njport/pdohire';
import VolunteerHire from './tools/njport/volunteerhire';
import ArrestForm from './tools/njport/arrest';
import PreliminaryReport from './tools/preliminary_report/preliminary_report';
import SARSForm from './tools/sars/sars';
import TerminalHire from './tools/njport/terminalhire';
import NJDashboard from './tools/dashboard/NJPORT/njDashboard';
import InDashboard from './tools/dashboard/INVESTIGATION/inDashboard';
import AnkleBracelet from './tools/ankleBracelet/ankleBracelet';
import GunOffenderDetail from "./Views/Detail/GunOffenderDetail";
import BOLODetail from "./Views/Detail/BOLODetail";
// import VHDashboard from './tools/dashboard/VolunteerHire/vhDashboard';
// import PDDashboard from './tools/dashboard/PDODashboard/pdoDashboard';



function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <Route exact path='/logoff'><LogOff /></Route>
                {/*<Route exact path='/login'><Login /></Route>*/}
                <Route exact path='/sso/saml'><SAML_LOGIN /></Route>
                <Route exact path='/login'><LoginPage /></Route>
                <Route exact path='/forgot-password'><ForgotPassword /></Route>
                <Route exact path='/'><MainLayout><MainDashboard /></MainLayout></Route>
                <Route path='/change_password'><MainLayout><ManagePassword /></MainLayout></Route>
                <Route path='/tools/arrest'><MainLayout><Arrest /></MainLayout></Route>
                <Route path='/tools/frequent_offender'><MainLayout><FrequentOffender /></MainLayout></Route>
                <Route path='/tools/person_nearby'><MainLayout><PersonNearBy /></MainLayout></Route>
                <Route path='/tools/alpr_multiple_occurrence'><MainLayout><ALPRMultipleOccurrence /></MainLayout></Route>
                <Route path='/tools/ncic_multiple_occurrence'><MainLayout><NCICMultipleSearch /></MainLayout></Route>
               {/* <Route path='/tools/phone_search'><MainLayout><PhoneSearch /></MainLayout></Route>*/}
                <Route path='/tools/towed_vehicle'><MainLayout><TowedVehicle /></MainLayout></Route>
                <Route path='/tools/shooting_notification'><MainLayout><ShootingNotification /></MainLayout></Route>
                <Route path='/tools/open_case_incident'><MainLayout><OpenCaseIncidents /></MainLayout></Route>
                <Route path='/tools/codis_notification'><MainLayout><CodisNotification /></MainLayout></Route>
                <Route path='/tools/njpop'><MainLayout><NJPOP /></MainLayout></Route>
                <Route path='/tools/alpr_vin_search'><MainLayout><ALPRVINSearch /></MainLayout></Route>
                <Route path='/tools/human_traffic'><MainLayout><HumanTraffic /></MainLayout></Route>
                <Route path='/tools/arrest_analysis'><MainLayout><ArrestAnalysis /></MainLayout></Route>
                <Route path='/tools/parole'><MainLayout><Parole /></MainLayout></Route>
                <Route path='/tools/jail'><MainLayout><Jail /></MainLayout></Route>
                <Route path='/tools/prisoner_information'><MainLayout><PrisonerInformation /></MainLayout></Route>
                <Route path='/tools/professional_license'><MainLayout><ProfessionalLicense /></MainLayout></Route>
                <Route path='/tools/statute_mapping'><MainLayout><StatuteMapping /></MainLayout></Route>
                <Route path='/tools/violent_offender'><MainLayout><ViolentOffender /></MainLayout></Route>
                <Route path='/tools/scor'><MainLayout><SCOR /></MainLayout></Route>
                <Route path='/tools/scor_variation'><MainLayout><ScorVariation/></MainLayout></Route>
                <Route path='/tools/shot_spotter'><MainLayout><ShotSpotter /></MainLayout></Route>
                <Route path='/tools/stolen_vehicle'><MainLayout><StolenVehicle /></MainLayout></Route>
                <Route path='/tools/profile_report'><MainLayout><ProfileReport /></MainLayout></Route>
                <Route path='/tools/duplicate_person'><MainLayout><DuplicatePerson /></MainLayout></Route>
                <Route path='/tools/GVRTF_Referral'><MainLayout><GRAILReferral /></MainLayout></Route>
                <Route path='/tools/carjacking_notification'><MainLayout><CarjackingNotification /></MainLayout></Route>
                <Route path='/tools/trace'><MainLayout><NJTrace /></MainLayout></Route>
                <Route path='/tools/ecrawl'><MainLayout><ECrawl /></MainLayout></Route>
                <Route path='/tools/preliminary_report'><MainLayout><PreliminaryReport /></MainLayout></Route>
                <Route path='/tools/sars'><MainLayout><SARSForm /></MainLayout></Route>
                <Route path='/tools/missing_person'><MainLayout><MissingPerson /></MainLayout></Route>
                <Route path='/tools/field_contact'><MainLayout><FieldContact /></MainLayout></Route>
                <Route path='/tools/supplemental_report'><MainLayout><SupplementalReport /></MainLayout></Route>
                <Route path='/tools/traffic_crash'><MainLayout><TrafficCrash /></MainLayout></Route>

                <Route path='/njport/investigation'><MainLayout><Investigation /></MainLayout></Route>
                <Route path='/njport/stevedore'><MainLayout><Stevedore /></MainLayout></Route>
                <Route path='/njport/tempcard'><MainLayout><Tempcard /></MainLayout></Route>
                <Route path='/njport/renewal'><MainLayout><Renewal/></MainLayout></Route>
                <Route path='/njport/money'><MainLayout><MoneyTrackingSheet/></MainLayout></Route>
                <Route path='/njport/analysts'><MainLayout><AnalystsReport/></MainLayout></Route>
                <Route path='/njport/shipentry'><MainLayout><Shipentry/></MainLayout></Route>
                <Route path='/njport/pdohire'><MainLayout><PDOHire/></MainLayout></Route>
                <Route path='/njport/volunteerhire'><MainLayout><VolunteerHire/></MainLayout></Route>
                <Route path='/njport/terminalhire'><MainLayout><TerminalHire/></MainLayout></Route>
                <Route path="/njport/arrest"><MainLayout><ArrestForm /></MainLayout></Route>
                
                <Route path='/poi'><MainLayout><ManagePOI/></MainLayout></Route>
                <Route path='/users'><MainLayout><ManageUser/></MainLayout></Route>
                <Route path='/BTAM/user/profile'><ManageUser/></Route>
                <Route path='/departments'><MainLayout><ManageDept /></MainLayout></Route>
                <Route path='/deptUnit'><MainLayout><ManageUnitLevelGroup /></MainLayout></Route>
                <Route path='/SearchHistory'><MainLayout><AuditLog /></MainLayout></Route>
                <Route path='/ncic'><MainLayout><ManageNCIC /></MainLayout></Route>
                <Route path='/PersonMainRecord'><MainLayout><ManagePersonMainRecord/></MainLayout></Route>
                <Route path='/addPOI'><MainLayout><AddPOI/></MainLayout></Route>
                <Route path='/btPDF'><PersonSummaryPDF /></Route>
                <Route path='/SharedPerson'><SecuredView /></Route>
                <Route path='/Admin/User'><ModifyUser/></Route>
                <Route path='/detail/arrest'><MainLayout><ArrestDetail /></MainLayout></Route>
                <Route path='/detail/ETicket'><MainLayout><ETicketDetail /></MainLayout></Route>
                <Route path='/detail/Infocop'><MainLayout><InfocopDetail /></MainLayout></Route>
                {/*<Route path='/detail/Infocop_Image'><MainLayout><InfocopImageDetail /></MainLayout></Route>*/}
                <Route path='/detail/ALPR'><MainLayout><ALPRDetail /></MainLayout></Route>
                <Route path='/detail/ALPRPDFReport'><MainLayout><ALPRPDFReportDetail /></MainLayout></Route>
                <Route path='/detail/LPR'><MainLayout><LPRDetail /></MainLayout></Route>
                <Route path='/detail/CodisDNA'><MainLayout><CodisDNADetail /></MainLayout></Route>
                <Route path='/detail/CadRms'><MainLayout><CadRmsDetail /></MainLayout></Route>
                <Route path='/detail/ETrace'><MainLayout><ETraceDetail /></MainLayout></Route>
                <Route path='/detail/trace'><MainLayout><NJTraceDetail /></MainLayout></Route>
                <Route path='/detail/ParkMobile'><MainLayout><ParkMobileDetail /></MainLayout></Route>
                <Route path='/detail/PrisonParole'><MainLayout><PrisonParoleDetail /></MainLayout></Route>
                <Route path='/detail/PrisonRelease'><MainLayout><PrisonReleaseDetail /></MainLayout></Route>
                <Route path='/detail/Profession'><MainLayout><ProfessionDetail /></MainLayout></Route>
                <Route path='/detail/ViolentDetail'><MainLayout><ViolentDetail /></MainLayout></Route>
                <Route path='/detail/ReferralDetail'><MainLayout><GVRTFReferralDetail /></MainLayout></Route>
                <Route path='/detail/Jail'><MainLayout><JailDetail /></MainLayout></Route>
                <Route path='/detail/GunOffender'><MainLayout><GunOffenderDetail /></MainLayout></Route>
                <Route path='/detail/BOLO'><MainLayout><BOLODetail /></MainLayout></Route>
                {/*<Route path='/admin/UpdateUser'><MainLayout><ModifyUser /></MainLayout></Route>*/}
                <Route path='/support/FAQ'><MainLayout><FAQs /></MainLayout></Route>
                <Route path='/support/Training'><MainLayout><TRAINING /></MainLayout></Route>
                <Route path='/support/UserManual'><MainLayout><UserManual /></MainLayout></Route>
                {/*<Route path='/support/Contact'><MainLayout><GVRTFReferralDetail /></MainLayout></Route>*/}
                <Route path='/njpop/mo_connection'><NJPOPMultipleOccurrence /></Route>
                <Route path='/njpop/person'><NJPOPPerson /></Route>
                <Route path='/dashboard/StolenVehicle'><SVDashboard /></Route>
                <Route path='/dashboard/eTrace'><ETDashboard /></Route>
                <Route path='/dashboard/NJPort'><NJDashboard /></Route>
                <Route path='/dashboard/INVESTIGATION'><InDashboard /></Route>                
                <Route path='/dashboard'><Dashboard /></Route>
                <Route path='/link_analysis'><LinkAnalysis /></Route>
                <Route path='/nibin_report_timeline'><NIBINTimeLine /></Route>
                <Route path='/GRAIL'><MainLayout><GVRTF /></MainLayout></Route>
                <Route path='/GVRTFEmailOutputForm'><GVRTFEmailOutputForm/></Route>
                <Route path='/PersonAnalysis'><PersonAnalysis /></Route>
                <Route path='/PersonTimeLine'><MainLayout><PersonTimeLine /></MainLayout></Route>
                <Route path='/SharedNJTrace'><SecuredNJTraceView /></Route>  
                <Route path='/SharedTrace'><SecuredNJTraceView /></Route> 
                <Route path='/AddNJTrace'><MainLayout><SecuredNJTraceView /></MainLayout></Route> 
                <Route path='/tools/anklebracelet'><MainLayout><AnkleBracelet /></MainLayout></Route>
                <Route path='/'><Error404></Error404></Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface VolunteerRequestProps {}

const VolunteerHirePieChart: React.FC<VolunteerRequestProps> = () => {
  const [hiredData, setHiredData] = useState<any>([]);

  useEffect(() => {
    const payload = {
      queryType: "VOLUNTEERHIRE_MONTH_START_TIME",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      setHiredData(qRes?.AnyTable);
    });
  }, []);

   const handleSeries = () => {
        const countMapping = new Map<string, number>();

        hiredData.forEach((item: any) => {
            const hiringTimeDesc = item.HiringTimeDesc;
            if (!countMapping.has(hiringTimeDesc)) {
                countMapping.set(hiringTimeDesc, 0);
            }
            let count = countMapping.get(hiringTimeDesc);
            if (count === undefined) {
                count = 0;
            }

            count += item.Requested;
            countMapping.set(hiringTimeDesc, count);
        });

        const pieData = Array.from(countMapping, ([name, value]) => ({ name, value }));
        const filteredPieData = pieData.filter((item) => item.value > 0)    

    return [
      {
        name: "Volunteer Hire",
        type: "pie",
        radius: "50%",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
            },
            data: filteredPieData,
      },
    ];
  };

  var option = {
    tooltip: {
      trigger: "item",
    },
    height: "180",
    width: "95%",
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: handleSeries(),
  };

  return (
    <>
      {hiredData && hiredData?.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "160px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-base xl:text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default VolunteerHirePieChart;

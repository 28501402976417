import React from "react";
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { Column, 
    ColumnDirective, 
    ColumnMenu, 
    ColumnsDirective,
    DetailRow, 
    ExcelExport,
    ExcelExportProperties, 
    Filter,
    Grid, 
    GridComponent, 
    Inject, 
    Page, 
    PdfExport,
    Reorder,
    Resize, 
    Search, 
    Sort, 
    SortSettingsModel, 
    Toolbar 
} from "@syncfusion/ej2-react-grids";
import NJTraceWeaponIcon from "../../../assets/Images/NjGunTrace.png";
import NJTraceATFDisplay from "./_searchResultsTraceATF";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import NJTraceWeaponATFStatus from './_searchResultsTraceATF'

interface SearchResultsProps {
    NJTraceWeapon: WeaponTraceInfo[]
    NJTraceWeaponStatus: WeaponTraceInfo[]
}


const SearchResultsWeapon: React.FC<SearchResultsProps> = ({ NJTraceWeapon , NJTraceWeaponStatus}) => {
    const [typedWeapon, setTypedWeapon] = React.useState<any>({})
    const [weaponStatus, setweaponStatus] = React.useState<any>({})


    let grid: Grid | null;

    const handleDataTyping = () => {
        if (NJTraceWeapon) {
            let tempP: any = [...NJTraceWeapon]
            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }        
                if(p.CreateDate){
                    p.CreateDate = new Date(p.CreateDate)
                }
                if(p.OffenseDate){
                    p.OffenseDate = new Date (p.OffenseDate)
                }
                if(p.ATF_trace_SentDate){
                    p.ATF_trace_SentDate = new Date (p.ATF_trace_SentDate)
                }
                if(p.RECOVERY_DATE){
                    p.RECOVERY_DATE = new Date (p.RECOVERY_DATE)
                }
                
            }
            setTypedWeapon(tempP)
        }
        if(NJTraceWeaponStatus){
            setweaponStatus(NJTraceWeaponStatus)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [NJTraceWeapon])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'NJTraceWeapon.xlsx';
            (grid.getColumnByField("CONVERSION_DEVICE") as Column).visible = true;
            grid.excelExport();
        } 
    }


    const excelExportComplete = () => {
        if(grid){
            (grid.getColumnByField("CONVERSION_DEVICE") as Column).visible = false;
        }
    }


    // const expandRow = () => {
    //     if (grid) {
    //         const selectedrowindex: number[] = grid.getSelectedRowIndexes()
    //         for (let idx of selectedrowindex) {
    //             grid.detailRowModule.expand(idx)
    //         }
    //     }
    // }

    const getWeaponSummary = (w: any) => {
        let tempWeaponStatus:any=[]
        if(NJTraceWeaponStatus && NJTraceWeaponStatus.length > 0){
          tempWeaponStatus =  NJTraceWeaponStatus?.filter((x :any)=> x.NJTraceWeaponID === w.NJTraceWeaponID)
        }
        return (<div style={{maxWidth:"90%"}}><NJTraceWeaponATFStatus NJTraceATF={tempWeaponStatus} /></div>)
    }


    let sortSettings: SortSettingsModel = {
        columns: [
            { field: 'RECOVERY_DATE', direction: 'Descending' },
        ]
    };

    const parseATFNotes =(njtrace: any) :any =>{
        let rejectedNotes = ""
        if(njtrace){
            try{
                if(njtrace?.ATFLatestNotes != null && njtrace?.ATFLatestNotes?.length > 0){
                    let tempE = JSON.parse(njtrace?.ATFLatestNotes)
                    if(tempE?.errors){
                        for(let err of tempE?.errors){
                            if(err?.description){
                                rejectedNotes+= err?.description + "\n"
                            }
                        }
    
                    }
                    else if (tempE?.warnings){
                        for(let warning of tempE?.warnings){
                            if(warning?.description){
                                rejectedNotes+= warning?.description + "\n"
                            }
                        }
                    }

                }           
            }
            catch(e){
                console.log(e)
                rejectedNotes=""
            }

        }
       
        return(
            <>
            {rejectedNotes}
            </>
        )    
    }

    
    return(
    <div className={"pl-10 pr-10 border-2 border-gray-100"}>
        <span style={{display:"inline-block", width:"full"}}>
                NJTrace Weapon Information <img className={"inline ml-2"} src={NJTraceWeaponIcon} alt="ai" />
                {(NJTraceWeapon && NJTraceWeapon.length > 0) ? <h4 className={"font-bold pl-5"}> {NJTraceWeapon.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
        </span>
        {NJTraceWeapon && NJTraceWeapon?.length > 0 ? <>
            <GridComponent
                dataSource={typedWeapon}
                allowPaging={true}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                detailTemplate={getWeaponSummary}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                excelExportComplete={excelExportComplete}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={35}

            >
                <ColumnsDirective>
                    {/* <ColumnDirective field='NJTraceWeaponID' headerText='Weapon ID' isPrimaryKey={true} width={140} minWidth={140} maxWidth={140} visible={false} />                                        */}
                    <ColumnDirective field='RECOVERY_DATE'  maxWidth={120} headerText='Recovery Date' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='AgencyCaseNo' headerText='Case #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='MANUFACTURER_NAME' headerText='Weapon Make' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='W_TYPE_DESC' headerText='Weapon Type' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='CALIBER' headerText='Weapon Caliber' width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='MODEL' headerText='Weapon Model' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SERIAL_NUMBER' headerText='Weapon Serial #' width={140} minWidth={140} maxWidth={140}  />
                    <ColumnDirective field='CONVERSION_DEVICE' headerText='CONVERSION_DEVICE' width={140} minWidth={140} maxWidth={140} visible={false} />                                       
                    <ColumnDirective field='ATF_Trace_ID_Single' headerText='ATF ID' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_LatestStatus' headerText='Current ATF Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATFLatestNotes' headerText='Latest ATF Received Notes' template={parseATFNotes} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                 
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}


    </div>);

}

export default SearchResultsWeapon


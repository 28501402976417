import {
    Alert,
    Autocomplete,
    //Box,
    Button,
    ButtonGroup,
    FormControl,
    IconButton,
    Grid,
    InputLabel,
    //MenuItem,
    //Select,
    Snackbar,
    TextField,
    Card,
    CardContent,
    //CardMedia,
    //CardActions,
} from "@mui/material";
import React, { useState } from "react";
import {
    DropDown,
    InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { ShipEntyObject } from "../../../interfaces/NJPORT/ShipEntry.interface";
// import { formatInTimeZone } from "date-fns-tz";
import FilePreview from "./filePreview";
import DeleteIcon from "@mui/icons-material/Delete";
import WeaponAddIcon from '@mui/icons-material/AddCircle';  //"../../../assets/Images/WeaponTrace.png";

interface InputFormProps {
    handleReportSubmit: any;
    editReport: InvestigationObject | null;
}

interface ShipsNVArray {
    key: string;
    value: ShipEntyObject;
}

var idx: number = 0;
var editRecord: ShipEntyObject | undefined;

const ShipEntryInputForm: React.FC<InputFormProps> = ({
    handleReportSubmit,
    editReport,
}) => {
    const [report, setReport] = React.useState<ShipEntyObject>(
        editReport ? editReport : {},
    );
    const [ships, setShips] = React.useState<ShipsNVArray[]>([]);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertText, setAlertText] = React.useState<String>("");
    const [alertType, setAlertType] = React.useState<any>("success");
    const [portDD, setPortDD] = React.useState<DropDown[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isError, setError] = useState<boolean>(false);
    const [fileBase64, setFileBase64] = useState<string>("");
    const [terminalArrayList, setTerminalArrayList] = useState<{ [key: string]: string }[][]>([]);
    const [editRecordTerminalSet, setEditRecordTerminalSet] = useState<boolean>(false);
    const [allPorts, setAllPorts] = useState<DropDown[]>([]);
    const [clear, setClear] = useState<boolean>(false);

    React.useEffect(() => {
        let terminals = [...terminalArrayList]
        if (editRecord && terminals && terminals.length <= 1) {
            setTerminalListForEditRecord()
        }
    }, [allPorts]);

    React.useEffect(() => {
        if (clear) {
            let tmp = [...ships];
            tmp = [];
            if (editRecord) {
                let tmpEditRecord = JSON.parse(JSON.stringify(editRecord)) as ShipEntyObject; // make a copy on the list
                tmpEditRecord.Deleted = false;  // if it was marked soft deleted brin it back not deleted
                tmp.push(
                    {
                        key: String(idx++),
                        value: tmpEditRecord, // save the copy on the list
                    }
                )
                setTerminalListForEditRecord()
            }
            else {
                tmp.push(
                    {
                        key: String(idx++),
                        value:
                        {
                            ID: null,
                            CaseNo: null,
                            ShipName: null,
                            Port: null,
                            ShipTerminal: null,
                            Berth: null,
                            ArrivalDateTime: convertDate(null),
                            CreateUser: null,
                            CreateDept: null,
                            CreateDate: null,
                            UpdateUser: null,
                            UpdateDept: null,
                            UpdateDate: null,
                            Deleted: false,
                            EditStatus: null,
                            FileContent: null,
                            errorMessages: null,
                        }
                    }
                )
            }
            setShips(tmp);
            setFileBase64("");
            setClear(false)
        }
    }, [clear]);

    React.useEffect(() => {
        editRecord = undefined
        portLocationDropDown();
        if (editReport) {
            editRecord = JSON.parse(JSON.stringify(editReport)) as ShipEntyObject; // save this as original record
            editRecord.ArrivalDateTime = convertDate(editRecord.ArrivalDateTime) as string

            let tmpEditReport = JSON.parse(JSON.stringify(editReport)) as ShipEntyObject; // put a copy on the list

            const tmp = [...ships]
            try {
                tmp.push({ key: String(idx++), value: (tmpEditReport) });
            }
            catch (e) {
                console.log(e);
            }
            if (tmp && tmp.length > 0 && tmp[0]) {
                if (tmp[0].value.FileContent) {
                    setFileBase64(tmp[0].value.FileContent);
                }
                else {
                    setFileBase64('');
                }
            }
            setShips(tmp);
        } else {
            // const timeZoneDate = toZonedTime(new Date(), "Asia/Kolkata");
            // report.ArrivalDateTime = formatInTimeZone(
            //   new Date(),
            //   "Asia/Kolkata",
            //   "yyyy-MM-dd'T'HH:mm:ss",
            // );
            handleAddShip()
        }
    }, [editReport]);

    const setTerminalListForEditRecord = async () => {
        let ports =  [...allPorts]
        if (ports) {
            let terminals = [...terminalArrayList]
            if (editRecord && editRecord !== undefined) {
                let tmpPort = allPorts.find((d) => d.Key === editRecord?.Port && (d.Key !== undefined) && (d.Value !== undefined)) // port saved as key but displayed as description
                editRecord.Port = tmpPort?.Value
                let tmp = await terminalDropDown(tmpPort?.Key);
                let l1: DropDown[] = tmp.filter((p) => (p.Value !== undefined))
                const strList: { [key: string]: string }[] = [];
                l1.forEach((p) => { strList.push({ key: p.Key as string, value: p.Value as string }); });
                terminals[0] = strList;
                terminals.splice(1, 0)
                setTerminalArrayList(terminals);
                let flag = true

                if (editRecord != null) {  //update the edit record  show the terminal desc instead of the saved terminal key
                    let terminalDesc = strList.find((d) => d.key === editReport?.ShipTerminal)
                    if (terminalDesc !== undefined) {
                        editRecord.ShipTerminal = terminalDesc.value; // terminal saved as key but displayed as description

                        let tmp = [...ships];
                        tmp = [];
                        let tmpEditRecord = JSON.parse(JSON.stringify(editRecord)) as ShipEntyObject; // make a copy on the list
                        tmp.push(
                            {
                                key: String(idx++),
                                value: tmpEditRecord, // save the copy on the list
                            }
                        )
                        if (editRecord.FileContent && editRecord.FileContent.trim().length > 0) { 
                            setFileBase64(editRecord.FileContent);
                        }
                        setShips(tmp);
                    }
                }
                setEditRecordTerminalSet(flag)
            }
        }
    }

    const getFileTypeFromBase64 = (base64: string) => {
        const mimeType = base64.match(/data:([^;]+);base64,/);
        return mimeType ? mimeType[1] : "";
    };

    const portLocationDropDown = async () => {
        let data = {
            params: {
                DropDownName: "PORT_LOCATION",
                State: "NJ",
            },
        };
        let response: DropDown[] = await InvestigationDropDown(data);
        setAllPorts(response);
        setPortDD(response);  // ports menu the same for all record entries
    };

    const terminalDropDown = async (port: any) => {
        let data = {
            params: {
                DropDownName: "PORT_SHIPTERMINAL" + "_" + port,
                // State: "NJ",
            },
        };
        let response: DropDown[] = await InvestigationDropDown(data);
        return response;
    };

    const handleSubmit = () => {
        if (handleValidation()) {
            let idx: number = 0;
            let shipArray = [...ships];
            let tmpContent = { fileBase64 }
            let terminalArrays = [...terminalArrayList]
            shipArray.forEach((ship) => {
                let ddKey = portDD.find((d) => d.Value === ship.value.Port)?.Key
                ship.value.Port = ddKey

                let terminalKey = terminalArrays[idx++].find((d) => d.value === ship.value.ShipTerminal)?.key
                ship.value.ShipTerminal = terminalKey

                if (tmpContent &&
                    (tmpContent.fileBase64.length > 0) &&
                    ((ship.value.FileContent === undefined) ||
                        (ship.value.FileContent === null))) {
                    ship.value.FileContent = tmpContent.fileBase64
                }

                if (tmpContent &&
                    (tmpContent.fileBase64.length === 0) &&
                    ((ship.value.FileContent !== undefined) ||
                        (ship.value.FileContent !== null))) {
                    ship.value.FileContent = ''
                }

                handleReportSubmit(ship.value);
            })

            if (editRecord && editRecord.Deleted) {
                editRecord.EditStatus = 'EDIT'
                handleReportSubmit(editRecord);
            }
        }
    };

    const handleValueChange = async (field: string, val: any, shipIndex: number) => {
        let shipList = [...ships];
        var terminals = [...terminalArrayList];
        if (shipIndex === -1) {
            switch (field) {
                case "fileContent":
                    setErrorMsg("");
                    setError(false);
                    const selectedFile = val.target.files[0];
                    if (selectedFile) {
                        if (selectedFile.size > 10 * 1024 * 1024) {
                            setError(true);
                            setErrorMsg("File size must be less than 10MB");
                            return;
                        }
                        const validTypes = [
                            "application/pdf",
                            "image/png",
                            "image/jpg",
                            "image/jpeg",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        ];
                        if (!validTypes.includes(selectedFile.type)) {
                            setErrorMsg(
                                "Invalid file type. Only PDF, image, or DOC files are allowed",
                            );
                            return;
                        }
                        const reader = new FileReader();
                        let base64Data: string | undefined | null = null;
                        reader.onloadend = () => {
                            base64Data = reader.result?.toString();
                            shipList[0].value.FileContent = base64Data;
                            setFileBase64(base64Data as string);
                        };
                        reader.readAsDataURL(selectedFile);
                    }
                    break;
            }
        }

        if (shipIndex >= 0) {
            let temp = shipList[shipIndex];

            switch (field) {
                case "id":
                    temp.value.ID = val;
                    break;
                case "caseNo":
                    temp.value.CaseNo = val;
                    break;
                case "shipName":
                    temp.value.ShipName = val;
                    break;
                case "Berth":
                    temp.value.Berth = val;
                    break;
                case "shipTerminal":
                    temp.value.ShipTerminal = val;
                    break;
                case "arrivalDateTime":
                    temp.value.ArrivalDateTime = val;
                    break;
                case "portLocation":
                    temp.value.Port = val;
                    let key = portDD.find((d) => d.Value === val)?.Key
                    let tmp = await terminalDropDown(key);
                    let l1: DropDown[] = tmp.filter((p) => (p.Value !== undefined))
                    const strList: { [key: string]: string }[] = [];
                    l1.forEach((p) => { strList.push({ key: p.Key as string, value: p.Value as string });});
                    terminals[shipIndex] = strList;
                    temp.value.ShipTerminal = ''  // change the port clear the existing terminal if there is one
                    setTerminalArrayList(terminals);
                    break;
            }
        }
        setShips(shipList);
    };

    const handleValidation = () => {
        let shipList = [...ships]
        let flag: boolean = true;
        let text: string = ''

        let saveShips = shipList.filter((p) => (p.value.Deleted === false))
        flag = saveShips && saveShips.length > 0;
        saveShips.forEach((ship) => {
            if (ship.value.ID === null)
                ship.value.EditStatus = 'ADD'
            else
                ship.value.EditStatus = 'EDIT'

            if (flag) {
                if (!ship.value.Berth ||
                    ship.value.Berth.trim().length === 0) {
                    flag = false;
                    text = "Berth";
                } else if (!ship.value.ShipName ||
                    ship.value.ShipName.length === 0) {
                    flag = false;
                    text = "Ship Name";
                } else if (!ship.value.Port ||
                    ship.value.Port.trim().length === 0) {
                    flag = false
                    text = "Ship Port";
                } else if (!ship.value.ShipTerminal ||
                    ship.value.ShipTerminal.trim().length === 0 ) {
                    flag = false
                    text = "Ship Terminal";
                }
            }
        });

        if (!flag) {
            setAlertText("Please complete Mandatory field " + text);
            setAlertOpen(true);
            setAlertType("error");
        }

        return flag;
    };

    const convertDate = (dateInput: string | Date | null | undefined) => {
        const date = dateInput ? new Date(dateInput) : new Date();

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
            }${day}`;
        const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""
            }${minutes}`;

        return `${formattedDate}T${formattedTime}`;
    };

    const handleAddShip = () => {
        var p = [...ships]
        p.push(
            {
                key: String(idx++),
                value: {
                    ID: null,
                    CaseNo: null,
                    ShipName: null,
                    Port: null,
                    ShipTerminal: null,
                    Berth: null,
                    ArrivalDateTime: convertDate(null),
                    CreateUser: null,
                    CreateDept: null,
                    CreateDate: null,
                    UpdateUser: null,
                    UpdateDept: null,
                    UpdateDate: null,
                    Deleted: false,
                    EditStatus: null,
                    FileContent: null,
                    errorMessages: null,
                }
            }
        )
        setShips(p)
    }

    const handleRemoveShip = (index: number) => {
        var p = [...ships]
        var m = [...terminalArrayList]
        if ((ships.filter((e: ShipsNVArray) => !e.value.Deleted).length - 1) !== 0) {
            p.splice(index, 1)
            m.splice(index, 1)

            if (editRecord &&
                index === 0 &&
                !editRecord.Deleted) {
                editRecord.Deleted = true;
            }

            setShips(p)
            setTerminalArrayList(m)
        }
    };

    const handleClear = () => {
        setClear(true)
    }

    return (
        <div>
            {ships.map((ship: ShipsNVArray, shipIndex) => {
                return (
                    <Card className=" border-2 border-gray-600 mx-4 mt-4">
                        <CardContent>
                            <div 
                                className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Ship Entry
                                <span className="inline">
                                    <IconButton size={"small"} onClick={() => handleRemoveShip(shipIndex)}
                                        color={"error"} title={"Remove Ship"}> <DeleteIcon /> </IconButton>
                                </span>
                            </div>

                            <div className="my-3">
                                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                freeSolo
                                                id="Port"
                                                disableClearable
                                                size={"medium"}
                                                options={
                                                    portDD && (portDD.length > 0) ? portDD.map((d) => (d.Value)) : ['']
                                                }
                                                onInputChange={(event, value) => handleValueChange('portLocation', value, shipIndex)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Port"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: { textAlign: 'center' },
                                                        }}
                                                    />
                                                )}
                                                value={ship.value.Port ? ship.value.Port : ''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                freeSolo
                                                id="Terminal"
                                                disableClearable
                                                size={"medium"}
                                                options={
                                                    terminalArrayList && terminalArrayList[shipIndex] ? terminalArrayList[shipIndex].map(p => p.value) : ['']
                                                }
                                                onInputChange={(event, value) => handleValueChange('shipTerminal', value, shipIndex)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Terminal"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: { textAlign: 'center' },
                                                        }}
                                                    />
                                                )}
                                                value={ship.value.ShipTerminal ? ship.value.ShipTerminal : ''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            label="Berth"
                                            value={ship.value.Berth ? ship.value.Berth : ''}
                                            onChange={
                                                (e) => handleValueChange("Berth", e.target.value, shipIndex)
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            label="Ship Name"
                                            value={ship.value.ShipName ? ship.value.ShipName : ''}
                                            onChange={
                                                (e) => handleValueChange("shipName", e.target.value, shipIndex)
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            type="datetime-local"
                                            label="Date and Time of Ship Terminal"
                                            defaultValue={convertDate(ship.value.ArrivalDateTime)}
                                            //value={convertDate(ship.value.ArrivalDateTime)}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={
                                                (e) => handleValueChange("arrivalDateTime", e.target.value, shipIndex)
                                            }

                                        // error={!report.ArrivalDateTime && editReport !== null}
                                        // helperText={
                                        // !report.ArrivalDateTime && editReport !== null
                                        // ? "Required"
                                        // : ""
                                        // }
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <IconButton size={"large"} onClick={handleAddShip} color={"inherit"}
                                    title={"Add Additional Ship(s)"}> <WeaponAddIcon /> </IconButton>
                            </div>
                        </CardContent>
                    </Card>
                )
            })}

            <div className=" border-2 border-gray-600 mx-4 mt-4">

                <Grid item xs={4} >
                    <TextField
                        type="file"
                        inputProps={{ accept: ".pdf,.jpg,.jpeg,.png,.doc,.docx" }}
                        onChange={(e) => handleValueChange("fileContent", e, -1)}
                        fullWidth
                        error={isError ? true : false}
                        helperText={isError ? errorMsg : ""}
                        disabled={fileBase64 ? true : false}
                    />
                </Grid>

                {fileBase64 && (
                    <div className="flex flex-row justify-center items-center m-2">
                        <FilePreview base64Data={fileBase64} />
                        <IconButton
                            onClick={() => {
                                setFileBase64("");
                            }}
                        >
                            <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                    </div>
                )}
            </div>

            <Snackbar
                open={alertOpen}
                autoHideDuration={7000}
                onClose={() => {
                    setAlertOpen(false);
                }}
            >
                <Alert
                    onClose={() => {
                        setAlertOpen(false);
                    }}
                    severity={alertType}
                    sx={{ width: "100%" }}
                >
                    {alertText}
                </Alert>
            </Snackbar>

            <Grid
                container
                spacing={1}
                sx={{ pl: 2, mt: 2, justifyContent: "flex-end" }}
            >
                <Grid item xs={8} sx={{ pr: 2 }}></Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                    <ButtonGroup fullWidth size="large" color="inherit">
                        <Button type="submit" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </div>
    )
}

export default ShipEntryInputForm;
import React from "react";
import {
    FormControl,
    Popper, Tooltip
} from "@mui/material";
import {
    getDropDownALPRCountyCity,
    getDropDownBody,
    getDropDownALPRCamera,
    getDropDownColor,
    getDropDownMake, getDropDownModel,
    getDropDownQuery, getDropDownTrim, getDropDownYear
} from "../../../services/getDropdown.service";
import {CheckBoxSelection, FilteringEventArgs, Inject, MultiSelectComponent} from "@syncfusion/ej2-react-dropdowns";
import {Query} from '@syncfusion/ej2-data';
import {NumericTextBoxComponent} from "@syncfusion/ej2-react-inputs";
import {GetDefaultStateForSite, GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";
import { TreeSelect } from 'primereact/treeselect';
import {MultiSelect} from "primereact/multiselect";

interface DropDownsProps {
    HandleDropDowns: any
    clear: number
    tool?: string;
    alprSelected?: boolean;
}

interface IndicatorOccurence {
    FoundCODIS: number | null,
    FoundShooting: number | null,
    FoundGunTrace: number | null,
    FoundProfile: number | null,
    FoundNIBIN: number | null,
    FoundDomViolence: number | null,
    FoundSexCrime: number | null,
    FoundReferral: number | null,
    FoundCarTheftRecent: number | null,
    FoundCarTheft: number | null,
    FoundNJGunTrace: number | null,
    FoundCarJacking: number | null,
    FoundGunArrests: number | null,
    FoundNJTrace_Pos: number | null,    
    FoundNJTrace_Asc: number | null,
    FoundETrace_Pur: number | null,
    FoundNJTrace_Ghg: number | null,
}

const parseCityGlobal = (data: any): any => {
    let city = []
    let count = 1;
    for (const [key, options] of Object.entries(data)) {
        let options1: any = options;
        let cityItem: any = {
            key: "BT_UNIQUE_GROUP" + count.toString(),
            label: key,
            children: []
        }
        for (const item of options1) {
            cityItem.children.push({
                key: item.Key,
                label: item.Value,
            })
        }
        city.push(cityItem)
        count++;
    }
    return city;
}

const parseALPRCityGlobal = (data: any): any => {
    let city = []
    let count = 1;
    let children: any;
    for (const [Key, Value] of Object.entries(data)) {
        let children: any = Value;
        let jsonData = JSON.parse(Key);
        let cityItem: any = {
            key: "BT_UNIQUE_GROUP" + count.toString(),
            label: jsonData['Value'],
            name: jsonData['Key'], 
            state: jsonData['Additional2'],
            children: []
        }
        
        for (const item of children) {
            cityItem.children.push({
                key: item.Key,
                label: item.Value,
            })
        }
        if (cityItem.state && cityItem.state.length > 0)
            cityItem.label = cityItem.label + ' (' + cityItem.state + ')'
        city.push(cityItem)
        count++;
    }
    return city;
}

const ArrestDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear, tool }) => {
    const [DDSex, setDDSex] = React.useState<any[]>([])
    const [DDCity, setDDCity] = React.useState<any[]>([])
    const [DDResidentCounty, setDDResidentCounty] = React.useState<any[]>([])
    const [DDState, setDDState] = React.useState<any[]>([])
    const [DDStatute, setDDStatute] = React.useState<any>({})
    const [DDStatuteNew, setDDStatuteNew] = React.useState<any>([])
    const [DDStatus, setDDStatus] = React.useState<any>({})
    const [DDCaseStatus, setDDCaseStatus] = React.useState<any>({})
    const [DDAgencyArrested, setDDAgencyArrested] = React.useState<any[]>([])
    const [DDArrestedCounty, setDDArrestedCounty] = React.useState<any[]>([])
    const [DDInfoDept, setDDInfoDept] = React.useState<any[]>([])
    const [DDArrestType, setDDArrestType] = React.useState<any[]>([])
    const [DDIndicator, setDDIndicator] = React.useState<any[]>([])
    const [DDIndicatorOccurrence, setDDIndicatorOccurrence] = React.useState<any[]>([])
    const [DDNibrs, setDDNibrs] = React.useState<any[]>([])
    const [DDPersonRole, setDDPersonRole] = React.useState<any[]>([])
    const [DDProfession, setDDProfession] = React.useState<any[]>([])
    const [DDPlaceOfBirth, setDDPlaceOfBirth] = React.useState<any[]>([])
    const [DDShootingPersonTypes, setDDShootingPersonTypes] = React.useState<any[]>([])
    const [DDCodisOffense, setDDCodisOffense] = React.useState<any[]>([])    
    const [DDIncidentCity, setDDIncidentCity] = React.useState<any[]>([])


    const [appliedDDSex, setAppliedDDSex] = React.useState<any[]>([])
    const [appliedDDCity, setAppliedDDCity] = React.useState<any[]>([])
    const [appliedDDResidentCounty, setAppliedDDResidentCounty] = React.useState<any[]>([])
    const [appliedDDState, setAppliedDDState] = React.useState<any[]>([])
    const [appliedDDStatute, setAppliedDDStatute] = React.useState<any[]>([])
    const [appliedDDStatuteNew, setAppliedDDStatuteNew] = React.useState<any>(null)
    const [appliedDDStatus, setAppliedDDStatus] = React.useState<any[]>([])
    const [appliedDDCaseStatus, setAppliedDDCaseStatus] = React.useState<any[]>([])
    const [appliedDDAgencyArrested, setAppliedDDAgencyArrested] = React.useState<any[]>([])
    const [appliedDDArrestedCounty, setAppliedDDArrestedCounty] = React.useState<any[]>([])
    const [appliedDDInfoDept, setAppliedDDInfoDept] = React.useState<any[]>([])
    const [appliedDDArrestType, setAppliedDDArrestType] = React.useState<any[]>([])
    const [appliedDDIndicator, setAppliedDDIndicator] = React.useState<any[]>([])
    const [appliedDDIndicatorOccurrence, setAppliedDDIndicatorOccurrence] = React.useState<number>(1)
    const [appliedDDIndicatorCount, setAppliedDDIndicatorCount] = React.useState<IndicatorOccurence>({
        FoundCODIS: null,
        FoundShooting: null,
        FoundGunTrace: null,
        FoundProfile: null,
        FoundNIBIN: null,
        FoundDomViolence: null,
        FoundSexCrime: null,
        FoundReferral: null,
        FoundCarTheftRecent: null,
        FoundCarTheft: null,
        FoundNJGunTrace: null,
        FoundCarJacking:null,
        FoundGunArrests:null,        
        FoundNJTrace_Pos:null,
        FoundNJTrace_Asc: null,
        FoundETrace_Pur : null,
        FoundNJTrace_Ghg: null,
})
    const [appliedDDNibrs, setAppliedDDNibrs] = React.useState<any[]>([])
    const [appliedDDPersonRole, setAppliedDDPersonRole] = React.useState<any[]>([])
    const [appliedDDProfession, setAppliedDDProfession] = React.useState<any[]>([])
    const [appliedDDPlaceOfBirth, setAppliedDDPlaceOfBirth] = React.useState<any[]>([])
    const [appliedDDShootingPersonTypes, setAppliedDDShootingPersonTypes] = React.useState<any[]>([])
    const [appliedDDCodisOffense, setAppliedDDCodisOffense] = React.useState<any[]>([])
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)
    const [appliedDDIncidentCity, setAppliedDDIncidentCity] = React.useState<any[]>([])


    const parseStatute = (data: any): any => {
        let statutes:any[] = [];
        let statutesNew = [];
        let count = 1;
        for (const [key, options] of Object.entries(data)) {
            let options1: any = options;
            let statusItem: any = {
                key:"BT_UNIQUE_GROUP" + count.toString(),
                label:key,
                children:[]
            }
            for (const item of options1) {
                /*statutes.push({
                    key: item.Key + ' ' + item.Value,
                    value: item.Key,
                    group: key
                })*/
                statusItem.children.push({
                    key: item.Key,
                    label: item.Key + ' ' + item.Value,
                })
            }
            statutesNew.push(statusItem)
            count ++;
        }
        setDDStatuteNew(statutesNew)
    }

    const parseCity = (data: any): any => {
        let city = parseCityGlobal(data)
        setDDCity(city)
        setDDIncidentCity(city)
    }

    const parseCodisAgency = (data: any): any => {
        let agency = []
        let count = 1;
        for (const [key, options] of Object.entries(data)) {
            let options1: any = options;
            let agencyItem: any = {
                key:"BT_UNIQUE_GROUP" + count.toString(),
                label:key,
                children:[]
            }
            for (const item of options1) {
                agencyItem.children.push({
                    key: item.Key,
                    label: item.Value,
                })
            }
            agency.push(agencyItem)
            count ++;
        }
        setDDAgencyArrested(agency)
    }

    const parseAgencyArrested = (data: any): any => {
        let agency = []
        let count = 1;
        for (const [key, Value] of Object.entries(data)) {
            let children: any = Value;            
            let agencyItem: any = {
                key: "BT_UNIQUE_GROUP" + count.toString(),
                label: key,
                children: []
            }

            for (const item of children) {
                agencyItem.children.push({
                    key: item.Key,
                    label: item.Value,
                })
            }
            agency.push(agencyItem)
            count++;
        }
        setDDAgencyArrested(agency)
    }

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            switch (tool) {
                case ("arrest"):                    
                    getDropDownQuery('Sex').then((res) => { setDDSex(res) })
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('State').then((res) => { setDDState(res) })
                    getDropDownQuery('Statute').then((res) => { parseStatute(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('InfocopDept').then((res) => { setDDInfoDept(res) })
                    getDropDownQuery('ShootingPersonType').then((res) => { setDDShootingPersonTypes(res) })
                    getDropDownQuery('ArrestType').then((res) => { setDDArrestType(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('PlaceOfBirth').then((res) => { setDDPlaceOfBirth(res) })
                    break;
                case ("frequent"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('State').then((res) => { setDDState(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('InfocopDept').then((res) => { setDDInfoDept(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("nearby"):
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("shooting"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    break;
                case ("openCaseIncident"):
                    getDropDownQuery('CADRMSAgency').then((res) => { parseCity(res) })
                    getDropDownQuery('Nibrs').then((res) => { setDDNibrs(res) })
                    getDropDownQuery('PersonRole').then((res) => { setDDPersonRole(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('CaseStatus').then((res) => { setDDCaseStatus(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("codis"):
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('CODISAgency').then((res) => {parseCodisAgency(res) })
                    getDropDownQuery('CODISOffense').then((res) => { setDDCodisOffense(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("analysis"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('State').then((res) => { setDDState(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("parole"):
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("prisonerInformation"):
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("profession"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('State').then((res) => { setDDState(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('ProfessionType').then((res) => { setDDProfession(res) })
                    break;
                case ("statute"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('InfocopDept').then((res) => { setDDInfoDept(res) })
                    break;
                case ("violent"):
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("crumb"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('State').then((res) => { setDDState(res) })
                    getDropDownQuery('Statute').then((res) => { setDDStatute(parseStatute(res)) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ArrestedCounty').then((res) => { setDDArrestedCounty(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('ShootingPersonType').then((res) => { setDDShootingPersonTypes(res) })
                    break;
                case ("shotSpotter"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    break;
                case ("StolenVehicle"):
                    getDropDownQuery('State').then((res) => { setDDState(res); setAppliedDDState([GetDefaultStateForSite(SiteName)]) })
                    break;
                case ("GVRTF"):
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("towed"):
                    getDropDownQuery('InfocopDept').then((res) => { setDDInfoDept(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("carjackingCounty"):
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    break;
                case ("carjackingCity"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    break;
                case ("carjackingAgency"):
                    getDropDownQuery('AgencyArrested').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    break;
                case ("JAIL"):
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    break;
                case ("profileReport"):
                    getDropDownQuery('Sex').then((res) => { setDDSex(res) })
                    getDropDownQuery('Status').then((res) => { setDDStatus(res) })
                    break;
                case ("njtrace"):
                    getDropDownQuery('City').then((res) => { parseCity(res) })
                    getDropDownQuery('ResidentCounty').then((res) => { setDDResidentCounty(res) })
                    getDropDownQuery('AgencyArrestedNJTrace').then((res) => { parseAgencyArrested(res) })
                    getDropDownQuery('Indicator').then((res) => { setDDIndicator(res) })
                    getDropDownQuery('IndicatorOccurrences').then((res) => { setDDIndicatorOccurrence(res) })
                    break;
            }
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "Sex": DDSex?.length === appliedDDSex?.length ? [] : appliedDDSex,
            "City": DDCity?.length === appliedDDCity?.length ? [] : appliedDDCity ?? [],
            "ResidentCounty": DDResidentCounty?.length === appliedDDResidentCounty?.length ? [] : appliedDDResidentCounty,
            "State": DDState?.length === appliedDDState?.length ? [] : appliedDDState,
            "Statute": DDStatuteNew?.length === appliedDDStatuteNew?.length ? [] : appliedDDStatuteNew ?? [],
            "Status": DDStatus?.length === appliedDDStatus?.length ? [] : appliedDDStatus,
            "CaseStatus": DDCaseStatus?.length === appliedDDCaseStatus?.length ? [] : appliedDDCaseStatus,
            "AgencyArrested": DDAgencyArrested?.length === appliedDDAgencyArrested?.length ? [] : appliedDDAgencyArrested ?? [],           
            "ArrestedCounty": DDArrestedCounty?.length === appliedDDArrestedCounty?.length ? [] : appliedDDArrestedCounty,
            "InfoCopDept": DDInfoDept?.length === appliedDDInfoDept?.length ? [] : appliedDDInfoDept,
            "ArrestType": DDArrestType?.length === appliedDDArrestType?.length ? [] : appliedDDArrestType,
            "Indicator": appliedDDIndicator,
            "IndicatorOccurrence": appliedDDIndicatorCount,
            "Nibrs": DDNibrs?.length === appliedDDNibrs?.length ? [] : appliedDDNibrs,
            "PersonRole": DDPersonRole?.length === appliedDDPersonRole?.length ? [] : appliedDDPersonRole,
            "Profession": DDProfession?.length === appliedDDProfession?.length ? [] : appliedDDProfession,
            "PlaceOfBirth": DDPlaceOfBirth?.length === appliedDDPlaceOfBirth?.length ? [] : appliedDDPlaceOfBirth,
            "ShootingPersonType": appliedDDShootingPersonTypes,  //don't imply ALL for shooting person types (Victim or Suspect or Both)
            "CodisOffense": DDCodisOffense?.length === appliedDDCodisOffense?.length ? [] : appliedDDCodisOffense,
            "IncidentCity": DDIncidentCity?.length === appliedDDIncidentCity?.length ? [] : appliedDDIncidentCity ?? []
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [appliedDDSex,
        appliedDDCity,
        appliedDDResidentCounty,
        appliedDDState,
        appliedDDStatute,
        appliedDDStatuteNew,
        appliedDDStatus,
        appliedDDCaseStatus,
        appliedDDAgencyArrested,
        appliedDDArrestedCounty,
        appliedDDInfoDept,
        appliedDDArrestType,
        appliedDDIndicator,
        appliedDDNibrs,
        appliedDDPersonRole,
        appliedDDProfession,
        appliedDDPlaceOfBirth,
        appliedDDShootingPersonTypes,
        appliedDDCodisOffense,
        appliedDDIndicatorOccurrence,
        appliedDDIndicatorCount,
        appliedDDIncidentCity
    ])

    React.useEffect(() => {
        setAppliedDDSex([])
        setAppliedDDCity([])
        setAppliedDDIncidentCity([])
        setAppliedDDResidentCounty([])
        setAppliedDDState([])
        setAppliedDDStatute([])
        setAppliedDDStatuteNew([])
        setAppliedDDStatus([])
        setAppliedDDCaseStatus([])
        setAppliedDDAgencyArrested([])
        setAppliedDDArrestedCounty([])
        setAppliedDDInfoDept([])
        setAppliedDDArrestType([])
        setAppliedDDIndicator([])
        setAppliedDDNibrs([])
        setAppliedDDPersonRole([])
        setAppliedDDProfession([])
        setAppliedDDPlaceOfBirth([])
        setAppliedDDShootingPersonTypes([])
        setAppliedDDCodisOffense([])
        setAppliedDDIndicatorOccurrence(1)
        setAppliedDDIndicatorCount({ FoundCODIS: null, FoundShooting: null, FoundGunTrace: null, FoundProfile: null, FoundNIBIN: null, FoundDomViolence: null, FoundSexCrime: null, FoundReferral: null, FoundCarTheftRecent:null, FoundCarTheft: null, FoundNJGunTrace: null, FoundCarJacking:null, FoundGunArrests:null, FoundNJTrace_Pos: null,  FoundNJTrace_Asc: null,  FoundETrace_Pur: null, FoundNJTrace_Ghg:null  })
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    React.useEffect(() => {
        if (isOccurrenceField()) {
            appliedDDIndicator.forEach(ind => {
                if (DDIndicatorOccurrence.map(o => o.Value).includes(ind)) {
                    setIndicatorOccurence(ind, 1, true);
                }
            });

            //Check indicator removed
            let key: keyof IndicatorOccurence;
            for (key in appliedDDIndicatorCount) {
                if (appliedDDIndicatorCount[key] !== null) {
                    switch (key) {
                        case 'FoundCODIS':
                            if (!appliedDDIndicator.includes('CODIS')) {
                                setIndicatorOccurence('CODIS', null);
                            }
                            break;
                        case 'FoundShooting':
                            if (!appliedDDIndicator.includes('Shooting')) {
                                setIndicatorOccurence('Shooting', null);
                            }
                            break;
                        case 'FoundGunTrace':
                            if (!appliedDDIndicator.includes('E-Trace')) {
                                setIndicatorOccurence('E-Trace', null);
                            }
                            break;
                        case 'FoundProfile':
                            if (!appliedDDIndicator.includes('Profile')) {
                                setIndicatorOccurence('Profile', null);
                            }
                            break;
                        case 'FoundNIBIN':
                            if (!appliedDDIndicator.includes('NIBIN')) {
                                setIndicatorOccurence('NIBIN', null);
                            }
                            break;
                        case 'FoundDomViolence':
                            if (!appliedDDIndicator.includes('Domestic Violence')) {
                                setIndicatorOccurence('Domestic Violence', null);
                            }
                            break;
                        case 'FoundSexCrime':
                            if (!appliedDDIndicator.includes('Sex Crime')) {
                                setIndicatorOccurence('Sex Crime', null);
                            }
                            break;
                        case 'FoundReferral':
                            if (!appliedDDIndicator.includes('Referral (ReArrest)')) {
                                setIndicatorOccurence('Referral (ReArrest)', null);
                            }
                            break;
                        case 'FoundCarTheft':
                            if (!appliedDDIndicator.includes('Car Theft (All)')) {
                                setIndicatorOccurence('Car Theft (All)', null);
                            }
                            break;
                        case 'FoundCarTheftRecent':
                            if (!appliedDDIndicator.includes('Car Theft (Recent)')) {
                                setIndicatorOccurence('Car Theft (Recent)', null);
                            }
                            break;
                        case "FoundNJGunTrace":
                            if (!appliedDDIndicator.includes(`${GetDefaultStateForSite(SiteName)}-Gun-Trace`)) {
                                setIndicatorOccurence(`${GetDefaultStateForSite(SiteName)}-Gun-Trace`, null);
                            }
                            break;
                        case "FoundCarJacking":
                            if (!appliedDDIndicator.includes('Carjacking')) {
                                setIndicatorOccurence(`Carjacking`, null);
                            }
                            break;
                        case "FoundGunArrests":
                            if (!appliedDDIndicator.includes(`Gun Arrest`)) {
                                setIndicatorOccurence(`Gun Arrest`, null);
                            }
                            break;
                        case "FoundNJTrace_Pos":
                            if (!appliedDDIndicator.includes('Gun Trace Possessor')) {
                                setIndicatorOccurence('Gun Trace Possessor', null);
                            }
                            break;
                        case "FoundNJTrace_Asc":
                            if (!appliedDDIndicator.includes('Gun Trace Associate')) {
                                setIndicatorOccurence('Gun Trace Associate', null);
                            }
                            break;
                        case "FoundETrace_Pur":
                            if (!appliedDDIndicator.includes('Gun Trace Purchaser')) {
                                setIndicatorOccurence('Gun Trace Purchaser', null);
                            }
                            break;
                        case "FoundNJTrace_Ghg":
                            if (!appliedDDIndicator.includes('Ghost GUN (privately made fire arm)')) {
                                setIndicatorOccurence('Ghost GUN (privately made fire arm)', null);
                            }
                            break;

                    }
                }
            }
        }
        else {
            setAppliedDDIndicatorCount({ FoundCODIS: null, FoundShooting: null, FoundGunTrace: null, FoundProfile: null, FoundNIBIN: null, FoundDomViolence: null, FoundSexCrime: null, FoundReferral: null, FoundCarTheftRecent: null, FoundCarTheft: null, FoundNJGunTrace: null, FoundCarJacking:null, FoundGunArrests:null, FoundNJTrace_Pos: null,  FoundNJTrace_Asc: null,  FoundETrace_Pur: null, FoundNJTrace_Ghg:null });
        }
        submitDropDowns();
    }, [appliedDDIndicator])

    const isOccurrenceField = () => {
        if (appliedDDIndicator.length > 0 && appliedDDIndicator.some(i => DDIndicatorOccurrence.map(o => o.Value).includes(i))) {
            return true;
        }
        return false;
    }

    const getIndicatorCount = (indicator: string): number => {
        if (appliedDDIndicatorCount === null) {
            return 1;
        }
        //console.log(indicator)
        switch (indicator) {
            case 'CODIS':
                return appliedDDIndicatorCount.FoundCODIS === null ? 1 : appliedDDIndicatorCount.FoundCODIS;
            case 'Shooting':
                return appliedDDIndicatorCount.FoundShooting === null ? 1 : appliedDDIndicatorCount.FoundShooting;
            case 'E-Trace':
                return appliedDDIndicatorCount.FoundGunTrace === null ? 1 : appliedDDIndicatorCount.FoundGunTrace;
            case 'Referral (ReArrest)':
                return appliedDDIndicatorCount.FoundReferral === null ? 1 : appliedDDIndicatorCount.FoundReferral;
            case 'Profile':
                return appliedDDIndicatorCount.FoundProfile === null ? 1 : appliedDDIndicatorCount.FoundProfile;
            case 'NIBIN':
                return appliedDDIndicatorCount.FoundNIBIN === null ? 1 : appliedDDIndicatorCount.FoundNIBIN;
            case 'Domestic Violence':
                return appliedDDIndicatorCount.FoundDomViolence === null ? 1 : appliedDDIndicatorCount.FoundDomViolence;
            case 'Sex Crime':
                return appliedDDIndicatorCount.FoundSexCrime === null ? 1 : appliedDDIndicatorCount.FoundSexCrime;
            case 'Car Theft (All)':
                return appliedDDIndicatorCount.FoundCarTheft === null ? 1 : appliedDDIndicatorCount.FoundCarTheft;
            case 'Car Theft (Recent)':
                return appliedDDIndicatorCount.FoundCarTheftRecent === null ? 2 : appliedDDIndicatorCount.FoundCarTheftRecent;
            case 'Carjacking':
                return appliedDDIndicatorCount.FoundCarJacking === null ? 1 : appliedDDIndicatorCount.FoundCarJacking;
            case 'Gun Arrest':
                return appliedDDIndicatorCount.FoundGunArrests === null ? 1 : appliedDDIndicatorCount.FoundGunArrests;
            case `${GetDefaultStateForSite(SiteName)}-Gun-Trace`:
                return appliedDDIndicatorCount.FoundNJGunTrace== null ? 1 : appliedDDIndicatorCount.FoundNJGunTrace;
            case 'Gun Trace Possessor':
                return appliedDDIndicatorCount.FoundNJTrace_Pos === null ? 1 : appliedDDIndicatorCount.FoundNJTrace_Pos;
            case 'Gun Trace Associate':
                return appliedDDIndicatorCount.FoundNJTrace_Asc === null ? 1 : appliedDDIndicatorCount.FoundNJTrace_Asc;    
            case 'Gun Trace Purchaser':
                return appliedDDIndicatorCount.FoundETrace_Pur === null ? 1 : appliedDDIndicatorCount.FoundETrace_Pur;
            case 'Ghost GUN (privately made fire arm)':
                return appliedDDIndicatorCount.FoundNJTrace_Ghg === null ? 1 : appliedDDIndicatorCount.FoundNJTrace_Ghg;
        }
        return 1;
    }

    const setIndicatorOccurence = (indicator: string, value: number | null, reset: boolean = false) => {
        let indicatorCount = appliedDDIndicatorCount;
        switch (indicator) {
            case 'CODIS':
                indicatorCount.FoundCODIS = indicatorCount.FoundCODIS === null ? value : value === 1 && reset ? indicatorCount.FoundCODIS : value;
                break;
            case 'Shooting':
                indicatorCount.FoundShooting = indicatorCount.FoundShooting === null ? value : value === 1 && reset ? indicatorCount.FoundShooting : value;
                break;
            case 'E-Trace':
                indicatorCount.FoundGunTrace = indicatorCount.FoundGunTrace === null ? value : value === 1 && reset ? indicatorCount.FoundGunTrace : value;
                break;
            case 'Referral (ReArrest)':
                indicatorCount.FoundReferral = indicatorCount.FoundReferral === null ? value : value === 1 && reset ? indicatorCount.FoundReferral : value;
                break;
            case 'Profile':
                indicatorCount.FoundProfile = indicatorCount.FoundProfile === null ? value : value === 1 && reset ? indicatorCount.FoundProfile : value;
                break;
            case 'NIBIN':
                indicatorCount.FoundNIBIN = indicatorCount.FoundNIBIN === null ? value : value === 1 && reset ? indicatorCount.FoundNIBIN : value;
                break;
            case 'Domestic Violence':
                indicatorCount.FoundDomViolence = indicatorCount.FoundDomViolence === null ? value : value === 1 && reset ? indicatorCount.FoundDomViolence : value;
                break;
            case 'Sex Crime':
                indicatorCount.FoundSexCrime = indicatorCount.FoundSexCrime === null ? value : value === 1 && reset ? indicatorCount.FoundSexCrime : value;
                break;
            case 'Car Theft (All)':
                indicatorCount.FoundCarTheft = indicatorCount.FoundCarTheft === null ? value : value === 1 && reset ? indicatorCount.FoundCarTheft : value;
                break;
            case 'Car Theft (Recent)':
                indicatorCount.FoundCarTheftRecent = indicatorCount.FoundCarTheftRecent === null ? value : value === 2 && reset ? indicatorCount.FoundCarTheftRecent : value;
                break;
            case 'Carjacking':
                indicatorCount.FoundCarJacking = indicatorCount.FoundCarJacking === null ? value : value === 2 && reset ? indicatorCount.FoundCarJacking : value;
                break;
            case 'Gun Arrest':
                indicatorCount.FoundGunArrests = indicatorCount.FoundGunArrests === null ? value : value === 2 && reset ? indicatorCount.FoundGunArrests : value;
                break;
            case `${GetDefaultStateForSite(SiteName)}-Gun-Trace`:
                indicatorCount.FoundNJGunTrace = indicatorCount.FoundNJGunTrace === null ? value : value === 1 && reset ? indicatorCount.FoundNJGunTrace : value;
                break;
            case 'Gun Trace Possessor':
                indicatorCount.FoundNJTrace_Pos = indicatorCount.FoundNJTrace_Asc === null ? value : value === 1 && reset ? indicatorCount.FoundNJTrace_Asc : value;
                break;
            case 'Gun Trace Associate':
                indicatorCount.FoundNJTrace_Asc = indicatorCount.FoundNJTrace_Pos === null ? value : value === 1 && reset ? indicatorCount.FoundNJTrace_Pos : value;
                break;
            case 'Gun Trace Purchaser':
                indicatorCount.FoundETrace_Pur = indicatorCount.FoundETrace_Pur === null ? value : value === 1 && reset ? indicatorCount.FoundETrace_Pur : value;
                break;
            case 'Ghost GUN (privately made fire arm)':
                indicatorCount.FoundNJTrace_Ghg = indicatorCount.FoundNJTrace_Ghg === null ? value : value === 1 && reset ? indicatorCount.FoundNJTrace_Ghg : value;
                break;
        }
        setAppliedDDIndicatorCount(indicatorCount);
    }
    
    const handleStateChange = (states:any) => {
        let tempStates = GetDefaultStateForSite(SiteName);
        if(states && states.length > 0) {
            tempStates = states.join()
        }
        
        getDropDownQuery('City', 'Dropdown', tempStates).then((res) => { parseCity(res) })

    }

    const getValueTemplate = (selected: any) => {  
        if(selected && selected.length > 0) {
            let count = 0;
            for (const statute of selected) {
                if(!statute || !statute?.key?.includes('BT_UNIQUE_GROUP'))
                    count++;
            }
            let countStr : string = count.toString();
            if (countStr === "0") {
                countStr = "None"
            }
            return (countStr + " selected");
        }
        else 
            return ("")
    }

    return (
        <div className={(tool === "StolenVehicle" || tool === "carjackingCity" || tool === "carjackingCounty" || tool === "carjackingAgency" || tool === "GVRTF"  ) ? "" : "grid grid-cols-9 gap-x-4 gap-y-4"}>
            {(tool === "arrest") || (tool === "profileReport") ?
                <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                    <MultiSelect
                        value={appliedDDSex}
                        onChange={(e) => {
                            setAppliedDDSex(e.value)
                        }}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-sex"} options={DDSex} filter optionLabel="Value" optionValue="Key"
                        placeholder="Sex" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-sex" >Sex</label>
                </FormControl>

                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === 'shooting' || tool === 'openCaseIncident' || tool === 'profession' || tool === 'analysis' || tool === 'crumb' || tool === 'shotSpotter' || tool === 'carjackingCity' || tool === 'njtrace') ?
                <FormControl className="p-float-label" sx={tool === "carjackingCity" ? { minWidth: 510, maxWidth: 510 } : { minWidth: '100%', maxWidth: '100%'}}>
                    <TreeSelect value={appliedDDCity} options={DDCity} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => {
                                    setAppliedDDCity(e.value)
                                }} selectionMode="checkbox" showClear
                                placeholder={`${GetStringNameForSite(SiteName,'CITY')}`}
                                inputId={"dd-city"}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-city">{`${(tool === 'njtrace' ? 'Recovery ' : '')}${GetStringNameForSite(SiteName,'CITY')}`}</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === 'profession' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'crumb' || tool === 'carjackingCounty' || tool === 'njtrace') ?
                <FormControl className="p-float-label" sx={tool === "carjackingCounty" ? { minWidth: 510, maxWidth: 510 } : {minWidth: '100%', maxWidth: '100%'}}>
                    <MultiSelect
                        value={appliedDDResidentCounty}
                        onChange={(e) => { setAppliedDDResidentCounty(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-residentCounty"} options={DDResidentCounty} filter optionLabel="Value" optionValue="Key"
                        placeholder={`Resident ${GetStringNameForSite(SiteName,'COUNTY')}`} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-residentCounty">{`${(tool === 'njtrace' ? 'Recovery ' : 'Resident ')}${GetStringNameForSite(SiteName,'COUNTY')}`}</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === 'profession' || tool === 'analysis' || tool === 'crumb' || tool === 'StolenVehicle') ?
                <FormControl className="p-float-label" sx={tool === "StolenVehicle" ? { minWidth: 510, maxWidth: 510 } : { minWidth: '100%', maxWidth: '100%'}}>
                    <MultiSelect
                        value={appliedDDState}
                        onChange={(e) => { handleStateChange(e.value); setAppliedDDState(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-state"} options={DDState} filter optionLabel="Value" optionValue="Key"
                        placeholder="State" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-state">State</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === 'openCaseIncident' || tool === 'profession' || tool === 'nearby' || tool === 'analysis' || tool === 'crumb') ?
                <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                    <TreeSelect value={appliedDDStatuteNew} options={DDStatuteNew} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => {
                                    setAppliedDDStatuteNew(e.value)
                                }} selectionMode="checkbox" showClear
                                placeholder={`${GetStringNameForSite(SiteName,'Statutes')}`}
                                inputId={"dd-statute"}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-statute">{`${GetStringNameForSite(SiteName,'Statutes')}`}</label>
                </FormControl>
                : <></>}
            {(tool === "codis") ?
                <FormControl className={"p-float-label"} sx={{minWidth: '100%', maxWidth: '100%'}}>
                    <MultiSelect
                        value={appliedDDCodisOffense}
                        onChange={(e) => { setAppliedDDCodisOffense(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-offense"} options={DDCodisOffense} filter optionLabel="Value" optionValue="Key"
                        placeholder="Offense" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-offense">Offense</label>
                </FormControl>
                : <></>}
            {(tool === "codis") ?
                <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                    <TreeSelect value={appliedDDAgencyArrested} options={DDAgencyArrested} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => {setAppliedDDAgencyArrested(e.value)}} selectionMode="checkbox" showClear
                                placeholder={"Agency"}
                                inputId={"dd-agency"}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-agency">{"Agency"}</label>
                </FormControl>
            : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "statute" || tool === 'shooting' || tool === 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'crumb' || tool === 'carjackingAgency' || tool === 'njtrace') ?
                <FormControl className="p-float-label" sx={tool === "carjackingAgency" ? { minWidth: 510, maxWidth: 510 } : { minWidth: '100%', maxWidth: '100%' }}>
                    <TreeSelect value={appliedDDAgencyArrested} options={DDAgencyArrested} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => {setAppliedDDAgencyArrested(e.value)}} selectionMode="checkbox" showClear
                                placeholder={"Agency Arrested"}
                                inputId={"dd-agencyArrested"}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-agencyArrested">{`${(tool === 'njtrace' ? 'Agency' : 'Agency Arrested')}`}</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "codis" || tool === "statute" || tool === 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === 'analysis' || tool === 'crumb') ?
                <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDArrestedCounty}
                        onChange={(e) => { setAppliedDDArrestedCounty(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-arrestedCounty"} options={DDArrestedCounty} filter optionLabel="Value" optionValue="Key"
                        placeholder={tool === "codis" ? `${GetStringNameForSite(SiteName,'County')}` : `Arrested ${GetStringNameForSite(SiteName,'County')}`} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-arrestedCounty">{tool === "codis" ? `${GetStringNameForSite(SiteName,'County')}` : `Arrested ${GetStringNameForSite(SiteName,'County')}`}</label>
                </FormControl>
                : <></>}
            {(tool === "arrest") && !(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ?
                <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDPlaceOfBirth}
                        onChange={(e) => { setAppliedDDPlaceOfBirth(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-pob"} options={DDPlaceOfBirth} filter optionLabel="Value" optionValue="Key"
                        placeholder="Place Of Birth" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-pob">Place Of Birth</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "statute" || tool === "towed") && !(SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ?
                <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDInfoDept}
                        onChange={(e) => { setAppliedDDInfoDept(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-infoCop"} options={DDInfoDept} filter optionLabel="Value" optionValue="Key"
                        placeholder="InfoCop Dept" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-infoCop">InfoCop Dept</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "crumb") ?
                <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDShootingPersonTypes}
                        onChange={(e) => { setAppliedDDShootingPersonTypes(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-shootingPerson"} options={DDShootingPersonTypes} filter optionLabel="Value" optionValue="Key"
                        placeholder="Shooting Person Type" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-shootingPerson">Shooting Person Type</label>
                </FormControl>
                : <></>}
            {(tool === "arrest") ?
                <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDArrestType}
                        onChange={(e) => { setAppliedDDArrestType(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-arrestType"} options={DDArrestType} filter optionLabel="Value" optionValue="Key"
                        placeholder="Arrest Type" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-arrestType">Arrest Type</label>
                </FormControl>
                : <></>}
            {(tool === "arrest" || tool === "frequent" || tool === "parole" || tool === "codis" || tool === 'openCaseIncident' || tool === 'profession' || tool === 'nearby' || tool === 'prisonerInformation' || tool === "towed" || tool === 'crumb' || tool === 'GVRTF' || tool === 'JAIL' || tool === 'njtrace') && (DDIndicatorOccurrence !== null && DDIndicatorOccurrence.length > 0) ? <>
                <FormControl className={"p-float-label"} sx={{ minWidth: tool === 'GVRTF' ? 510 : '100%', maxWidth: tool === 'GVRTF' ? 510 : '100%' }}>
                    <MultiSelect
                        value={appliedDDIndicator}
                        onChange={(e) => { setAppliedDDIndicator(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-indicator"} options={DDIndicator} filter optionLabel="Value" optionValue="Key"
                        placeholder="Indicator" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-indicator">Indicator</label>
                </FormControl>
                {isOccurrenceField() ?
                    appliedDDIndicator.map((ind, indx) => (
                        DDIndicatorOccurrence.map(o => o.Value).includes(ind) ? (
                            tool !== 'GVRTF' ?
                                <Tooltip title="The filter is an AND condition, among frequencies of indicators" className={ind.length > 15 ? 'label-extended' : ''}>
                                    <FormControl sx={{ minWidth: '100%', maxWidth: '100%', marginLeft: 1 }}>
                                        <NumericTextBoxComponent cssClass="e-outline" floatLabelType="Auto" format="n" min={1} max={100} value={getIndicatorCount(ind)} change={(e) => { setIndicatorOccurence(ind, e.value) }} placeholder={ind + " Occurrence"} />
                                    </FormControl>
                                </Tooltip> : (
                                    <Tooltip title="The filter is an AND condition, among frequencies of indicators">
                                        <FormControl sx={{ m: 0, minWidth: 510, maxWidth: 510, marginLeft: indx % 2 === 1 && indx !== 0 ? 0 : 2, marginTop: indx === 0 ? 0 : '12px' }}>
                                            <NumericTextBoxComponent cssClass="e-outline" floatLabelType="Auto" format="n" min={1} max={100} value={getIndicatorCount(ind)} change={(e) => { setIndicatorOccurence(ind, e.value) }} placeholder={ind + " Occurrence"} />
                                        </FormControl>
                                    </Tooltip>
                                )) : <></>
                    ))
                    : <></>} </>
                : <></>}
            {(tool === 'profession') ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDProfession}
                        onChange={(e) => { setAppliedDDProfession(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-profession"} options={DDProfession} filter optionLabel="Value" optionValue="Key"
                        placeholder="Profession" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-profession">Profession</label>
                </FormControl>
                : <></>}
            {(tool === 'openCaseIncident') ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDNibrs}
                        onChange={(e) => { setAppliedDDNibrs(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-nibrs"} options={DDNibrs} filter optionLabel="Value" optionValue="Key"
                        placeholder="nibrs" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-nibrs">Nibrs</label>
                </FormControl>
                : <></>}
            {(tool === 'openCaseIncident') ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDPersonRole}
                        onChange={(e) => { setAppliedDDPersonRole(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-personRole"} options={DDPersonRole} filter optionLabel="Value" optionValue="Key"
                        placeholder="Person Role" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-personRole">Person Role</label>
                </FormControl>
                : <></>}
            {(tool === 'openCaseIncident') ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDCaseStatus}
                        onChange={(e) => { setAppliedDDCaseStatus(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-status"} options={DDCaseStatus} filter optionLabel="Value" optionValue="Key"
                        placeholder="Status" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-status">Status</label>
                </FormControl>
                : <></>}
            {(tool === "profileReport") ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDStatus}
                        onChange={(e) => { setAppliedDDStatus(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-status"} options={DDStatus} filter optionLabel="Value" optionValue="Key"
                        placeholder="Status" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-status">Status</label>
                </FormControl>
                : <></>}

            {(tool === "arrest") ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%'}}>
                    <TreeSelect value={appliedDDIncidentCity} options={DDIncidentCity} filter dropdownIcon={"pi none"}
                                resetFilterOnHide={true}
                                onChange={(e: any) => {
                                    setAppliedDDIncidentCity(e.value)
                                }} selectionMode="checkbox" showClear
                                placeholder={`${GetStringNameForSite(SiteName,'CITY')}`}
                                inputId={"dd-Incidentcity"}
                                style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-Incidentcity">{`${'Incident '}${GetStringNameForSite(SiteName,'CITY')}`}</label>
                </FormControl>
                : <></>}
        </div>
    );
}

export const VinDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear, tool = 'vin' }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDVehicleMake, setDDVehicleMake] = React.useState<any[]>([])
    const [DDVehicleBody, setDDVehicleBody] = React.useState<any[]>([])
    const [DDVehicleModel, setDDVehicleModel] = React.useState<any[]>([])
    const [DDVehicleTrim, setDDVehicleTrim] = React.useState<any[]>([])
    const [DDVehicleYear, setDDVehicleYear] = React.useState<any[]>([])
    const [DDVehicleColor, setDDVehicleColor] = React.useState<any[]>([])

    const [appliedDDVehicleMake, setAppliedDDVehicleMake] = React.useState<any[]>([])
    const [appliedDDVehicleBody, setAppliedDDVehicleBody] = React.useState<any[]>([])
    const [appliedDDVehicleModel, setAppliedDDVehicleModel] = React.useState<any[]>([])
    const [appliedDDVehicleTrim, setAppliedDDVehicleTrim] = React.useState<any[]>([])
    const [appliedDDVehicleYear, setAppliedDDVehicleYear] = React.useState<any[]>([])
    const [appliedDDVehicleColor, setAppliedDDVehicleColor] = React.useState<any[]>([])

    React.useEffect(() => {
        let r: any = {
            makeList: [],
            bodyList: [],
            modelList: [],
            trimList: [],
            yearList: [],
            colorList: []
        }
        for (let i of appliedDDVehicleMake) {
            let obj = { key: i, value: i }

            r.makeList.push(obj)
        }
        getDropDownModel(r).then((res) => { setDDVehicleModel(res) })
        getDropDownBody(r).then((res) => { setDDVehicleBody(res) })
    }, [appliedDDVehicleMake])

    React.useEffect(() => {
        let r: any = {
            makeList: [],
            bodyList: [],
            modelList: [],
            trimList: [],
            yearList: [],
            colorList: []
        }
        for (let i of appliedDDVehicleMake) {
            let obj = { key: i, value: i }

            r.makeList.push(obj)
        }
        for (let i of appliedDDVehicleModel) {
            let obj = { key: i, value: i }
            r.modelList.push(obj)
        }
        for (let i of appliedDDVehicleBody) {
            let obj = { key: i, value: i }
            r.bodyList.push(obj)
        }
        getDropDownYear(r).then((res) => { setDDVehicleYear(res) })
        getDropDownTrim(r).then((res) => { setDDVehicleTrim(res) })
    }, [appliedDDVehicleModel, appliedDDVehicleBody])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)

            getDropDownMake({}).then((res) => { setDDVehicleMake(res) })
            getDropDownColor({}).then((res) => { setDDVehicleColor(res) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "VehicleMake": DDVehicleMake?.length === appliedDDVehicleMake?.length ? [] : appliedDDVehicleMake,
            "VehicleBody": DDVehicleBody?.length === appliedDDVehicleBody?.length ? [] : appliedDDVehicleBody,
            "VehicleModel": DDVehicleModel?.length === appliedDDVehicleModel?.length ? [] : appliedDDVehicleModel,
            "VehicleTrim": DDVehicleTrim?.length === appliedDDVehicleTrim?.length ? [] : appliedDDVehicleTrim,
            "VehicleYear": DDVehicleYear?.length === appliedDDVehicleYear?.length ? [] : appliedDDVehicleYear,
            "VehicleColor": DDVehicleColor?.length === appliedDDVehicleColor?.length ? [] : appliedDDVehicleColor,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDVehicleColor,
        appliedDDVehicleModel,
        appliedDDVehicleTrim,
        appliedDDVehicleBody,
        appliedDDVehicleYear,
        appliedDDVehicleMake,
    ])

    React.useEffect(() => {
        setAppliedDDVehicleMake([])
        setAppliedDDVehicleBody([])
        setAppliedDDVehicleModel([])
        setAppliedDDVehicleTrim([])
        setAppliedDDVehicleYear([])
        setAppliedDDVehicleColor([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };

    return (
        <div className={tool !== "StolenVehicle" ? "grid grid-cols-9 gap-x-4 gap-y-4" : ""}>
            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle' && DDVehicleMake && DDVehicleMake.length > 0) ?
                <FormControl className="p-float-label" sx={tool === 'StolenVehicle' ? { marginRight: 2, minWidth: 170, maxWidth: 170 } : { minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleMake}
                        onChange={(e) => { setAppliedDDVehicleMake(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleMake"} options={DDVehicleMake} filter optionLabel="Value" optionValue="Key"
                        placeholder="Vehicle Make" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleMake">Vehicle Make</label>
                </FormControl>
                : <></>}

            {(tool === "vin") ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleBody}
                        onChange={(e) => { setAppliedDDVehicleBody(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleBody"} options={DDVehicleBody} filter optionLabel="Value" optionValue="Key"
                        placeholder="Vehicle Body" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleBody">Vehicle Body</label>
                </FormControl>
                : <></>}

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?
                <FormControl className="p-float-label" sx={tool === 'StolenVehicle' ? { marginRight: 2, minWidth: 170, maxWidth: 170 } : { minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleModel}
                        onChange={(e) => { setAppliedDDVehicleModel(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleModel"} options={DDVehicleModel} filter optionLabel="Value" optionValue="Key"
                        placeholder={appliedDDVehicleMake?.length === 0 ? "Requires Make" : "Vehicle Model"} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleModel">{appliedDDVehicleMake?.length === 0 ? "Requires Make" : "Vehicle Model"}</label>
                </FormControl>
                : <></>}

            {(tool === "vin") ?
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleTrim}
                        onChange={(e) => { setAppliedDDVehicleTrim(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleTrim"} options={DDVehicleTrim} filter optionLabel="Value" optionValue="Key"
                        placeholder="Vehicle Trim" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleTrim">Vehicle Trim</label>
                </FormControl>
                : <></>}

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?
                <FormControl className="p-float-label" sx={tool === 'StolenVehicle' ? { marginRight: 2, minWidth: 170, maxWidth: 170 } : { minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleYear}
                        onChange={(e) => { setAppliedDDVehicleYear(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleYear"} options={DDVehicleYear} filter optionLabel="Value" optionValue="Key"
                        placeholder={appliedDDVehicleModel?.length === 0 ? "Requires Model" : "Vehicle Year"} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleYear">{appliedDDVehicleModel?.length === 0 ? "Requires Model" : "Vehicle Year"}</label>
                </FormControl>
                : <></>}

            {(tool === "vin" || tool === 'towed' || tool === 'StolenVehicle') ?
                <FormControl className="p-float-label" sx={tool === 'StolenVehicle' ? { minWidth: 170, maxWidth: 170 } : { minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDVehicleColor}
                        onChange={(e) => { setAppliedDDVehicleColor(e.value)}}
                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                        resetFilterOnHide={true} showClear={true}
                        inputId={"dd-vehicleColor"} options={DDVehicleColor} filter optionLabel="Value" optionValue="Key"
                        placeholder="Vehicle Color" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                    <label className={"bg-gray-100"} htmlFor="dd-vehicleColor">Vehicle Color</label>
                </FormControl>
                : <></>}
        </div>
    );
}

export const ALPRDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)
    const [DDALPRServer, setDDALPRServer] = React.useState<any[]>([])
    const [DDALPRCamera, setDDALPRCamera] = React.useState<any[]>([])
    const [DDALPRCameraLatLong, setDDALPRCameraLatLong] = React.useState<any[]>([])
    const [DDALPRReason, setDDALPRReason] = React.useState<any[]>([])

    const [appliedDDALPRServer, setAppliedDDALPRServer] = React.useState<any[]>([])
    const [appliedDDALPRCamera, setAppliedDDALPRCamera] = React.useState<any[]>([])
    const [appliedDDALPRReason, setAppliedDDALPRReason] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            if (SiteName === SITE.DCPROD || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) {
                getDropDownALPRCountyCity('DC').then((res) => {
                    let r: any = parseALPRCityGlobal(res);
                    setDDALPRServer(r)
                    setAppliedDDALPRServer([])
                })                
            }
            else if (SiteName === SITE.NYLOCAL || SiteName === SITE.NYDEMO || SiteName === SITE.NYPROD) {
                getDropDownALPRCountyCity('NY,NJ').then((res) => {  //Need to remove once demo done. It should be Only NY
                    let r: any = parseALPRCityGlobal(res);
                    setDDALPRServer(r)
                    setAppliedDDALPRServer([])
                })
            }
            else {
                getDropDownALPRCountyCity('NJ,PA').then((res) => {
                    let r: any = parseALPRCityGlobal(res);
                    setDDALPRServer(r)
                    setAppliedDDALPRServer([])
                })
            }            
            getDropDownQuery('ALPRReason').then((res) => { setDDALPRReason(res) })
        }
    }

    const submitDropDowns = () => {
        let alprCityKeys: any = []
        let alprAddList: any = []
        let alprRemoveList: any = []

        if (appliedDDALPRServer &&
            (Object?.keys(appliedDDALPRServer)?.length > 0)) {
            let alprFullCounties = Object?.entries(appliedDDALPRServer).filter(([key, value]) => key?.includes('BT_UNIQUE_GROUP') && (value?.checked))

            if (alprFullCounties && alprFullCounties.length > 0) {
                DDALPRServer.forEach((ddServer) => {
                    alprFullCounties.forEach((fullServer) => {
                        if (ddServer.key === fullServer[0]) {
                            alprAddList.push(ddServer.name)
                            ddServer.children.forEach((child: any) => {
                                alprRemoveList.push(child.key)
                            })
                        }
                    })
                })
            }

            let alprKeys = Object?.keys(appliedDDALPRServer)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
            for (let alprKey of alprKeys) {
                if (alprRemoveList.indexOf(alprKey) === -1) {
                    alprCityKeys.push(alprKey)
                }
            }
            alprAddList.forEach((add: any) => alprCityKeys.push(add))
            let alprCityList: any = []
            try {
                alprCityKeys?.forEach((alpr: any) => {
                    let str = alpr
                    /*
                    if (str.endsWith(" (NJ)"))
                        str = str.replace(" (NJ)", "County")
                    if (str.endsWith(" (PA)"))
                        str = str.replace(" (PA)", "County")
                    if (str.includes(" "))
                        str = str.replace(" ", "")
                    */
                    alprCityList.push(str)
                })
            }
            catch (e) {
                console.log(e)
            }

            HandleDropDowns({
                "ALPRServer": DDALPRServer.length === alprFullCounties.length ? [] : alprCityList,
                "ALPRCamera": DDALPRCamera?.length === appliedDDALPRCamera?.length ? [] : appliedDDALPRCamera,
                "ALPRReason": DDALPRReason?.length === appliedDDALPRReason?.length ? [] : appliedDDALPRReason,
                "ALPRCameraLatLong": DDALPRCameraLatLong
            })
        }
        else {
            HandleDropDowns({
                "ALPRServer": [],
                "ALPRCamera": DDALPRCamera?.length === appliedDDALPRCamera?.length ? [] : appliedDDALPRCamera,
                "ALPRReason": DDALPRReason?.length === appliedDDALPRReason?.length ? [] : appliedDDALPRReason,
                "ALPRCameraLatLong": DDALPRCameraLatLong
            })
        }
    }

    const parseCameras = (data: any): any => {
        let cameraList: any = []
        if (data && data.length > 0) {
            data.forEach((camera: any) => {
                cameraList.push(
                    {
                        key: camera.ID,
                        label: camera.Name
                    }
                )
            }
            );
            return cameraList
        }
    }

        React.useEffect(() => {
            let r: any = {
                ServerList: [],
            }
            try {

                let alprAddList: any = []
                let alprRemoveList: any = []

                if (appliedDDALPRServer &&
                    (Object?.keys(appliedDDALPRServer)?.length > 0)) {
                    let alprFullCounties = Object?.entries(appliedDDALPRServer).filter(([key, value]) => key?.includes('BT_UNIQUE_GROUP') && (value?.checked))

                    if (alprFullCounties && alprFullCounties.length > 0) {
                        DDALPRServer.forEach((ddServer) => {
                            alprFullCounties.forEach((fullServer) => {
                                if (ddServer.key === fullServer[0]) {
                                    alprAddList.push(ddServer.name)
                                    ddServer.children.forEach((child: any) => {
                                        alprRemoveList.push(child.key)
                                    })
                                }
                            })
                        })
                    }

                    let alprKeys = Object?.keys(appliedDDALPRServer)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))

                    for (let alprKey of alprKeys) {
                        if (alprRemoveList.indexOf(alprKey) === -1) {
                            let alprObj = { key: alprKey, value: alprKey }
                            r.ServerList.push(alprObj)
                        }
                    }

                    alprAddList.forEach((add: any) => {
                        /*
                        if (add.endsWith(" (NJ)"))
                            add = add.replace(" (NJ)", "County")
                        if (add.endsWith(" (PA)"))
                            add = add.replace(" (PA)", "County")
                        if (add.includes(" "))
                            add = add.replace(" ", "")
                        */
                        r.ServerList.push({ key: add, value: add })
                    })

                    if ((r.ServerList) &&
                        (Object.keys(r.ServerList).length > 0)) {

                        if (alprFullCounties.length === DDALPRServer.length)
                            r.ServerList = [];

                        getDropDownALPRCamera(r).then((qRes) => {
                            let ddalprCameras: any = JSON.parse(qRes.JsonObject)

                            setDDALPRCamera(parseCameras(qRes.AnyTable));
                            setDDALPRCameraLatLong(qRes.AnyTable)
                        })  //getDropDownALPRCamera returns 2 sets of data Distinct CameraList, Camera with Lat&Long (used in GeoCode)        
                    }
                }
                else {
                    r.ServerList = [];

                    if (DDALPRServer &&
                        (DDALPRServer.length > 0)) {
                        getDropDownALPRCamera(r).then((qRes) => {
                            let ddalprCameras: any = JSON.parse(qRes.JsonObject)

                            setDDALPRCamera(parseCameras(qRes.AnyTable));
                            setDDALPRCameraLatLong(qRes.AnyTable)
                        })  //getDropDownALPRCamera returns 2 sets of data Distinct CameraList, Camera with Lat&Long (used in GeoCode)        
                    }
                }
            }
            catch (e) {
                console.log(e)
            }
            setAppliedDDALPRCamera([])
        }, [appliedDDALPRServer])

        React.useEffect(() => {
            submitDropDowns();
        }, [
            appliedDDALPRServer,
            appliedDDALPRCamera,
            appliedDDALPRReason,
            DDALPRCameraLatLong,
        ])

        React.useEffect(() => {
            setAppliedDDALPRServer([])
            setAppliedDDALPRCamera([])
            setAppliedDDALPRReason([])
        }, [clear])

        React.useEffect(() => {
            if (!DDLoaded) {
                setDDLoaded(true)
                loadDropdowns()
            }
        });

        const PopperMy = function (props: any) {
            return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
        };

        return (
            <div className={"grid grid-cols-3 gap-x-4 gap-y-2"}>
                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <TreeSelect
                        value={appliedDDALPRServer}
                        options={DDALPRServer}
                        filter dropdownIcon={"pi none"}
                        resetFilterOnHide={true}
                        onChange={(e: any) => {
                                setAppliedDDALPRServer(e.value)
                        }}
                        //onHide={TreeSelectOnHide}
                        selectionMode="checkbox"
                        showClear
                        inputId={"dd-alprserver"}
                        placeholder={`${GetStringNameForSite(SiteName, "ALPRSERVER")}`}
                        style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                    //valueTemplate={(option: any) => getValueTemplate(option)}
                    ></TreeSelect>
                    <label className={"bg-gray-100"} htmlFor="dd-alprserver">{`${GetStringNameForSite(SiteName, "ALPRSERVER")}`}</label>
                </FormControl>

                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDALPRReason}
                        onChange={(e) => { setAppliedDDALPRReason(e.value) }}
                        style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-reason"} options={DDALPRReason} filter optionLabel="Value" optionValue="Key"
                        placeholder="ALPR Reason" maxSelectedLabels={1} className="w-full md:w-20rem" />
                    <label className={"bg-gray-100"} htmlFor="dd-reason">ALPR Reason</label>
                </FormControl>

                <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                    <MultiSelect
                        value={appliedDDALPRCamera}
                        onChange={(e) => { setAppliedDDALPRCamera(e.value) }}
                        style={{ border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start' }}
                        resetFilterOnHide={true} showClear={true} showSelectAll={false}
                        inputId={"dd-camera"} options={DDALPRCamera} filter
                        placeholder={appliedDDALPRCamera?.length > 0 ? "ALPR Camera" : "All Cameras Selected"} maxSelectedLabels={1} className="w-full md:w-20rem" />
                    <label className={"bg-gray-100"} htmlFor="dd-camera">{appliedDDALPRCamera?.length > 0 ? "ALPR Camera" : "All Cameras Selected"}</label>
                </FormControl>
            </div>
        );
    }


export const ALPRReasonDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)
    const [DDALPRReason, setDDALPRReason] = React.useState<any[]>([])

    const [appliedDDALPRReason, setAppliedDDALPRReason] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ALPRReason').then((res) => { setDDALPRReason(res) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ALPRReason": DDALPRReason?.length === appliedDDALPRReason?.length ? [] : appliedDDALPRReason,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDALPRReason,
    ])

    React.useEffect(() => {
        setAppliedDDALPRReason([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });
    
    return (
        <div style={{ display: "flex" }}>
            <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%' }}>
                <MultiSelect
                    value={appliedDDALPRReason}
                    onChange={(e) => { setAppliedDDALPRReason(e.value)}}
                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                    resetFilterOnHide={true} showClear={true} showSelectAll={false}
                    inputId={"dd-reason"} options={DDALPRReason} filter optionLabel="Value" optionValue="Key"
                    placeholder="ALPR Reason" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                <label className={"bg-gray-100"} htmlFor="dd-reason">ALPR Reason</label>
            </FormControl>
        </div>
    );
}

export const ETicketDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDETicketAgency, setDDETicketAgency] = React.useState<any[]>([])
    const [appliedDDETicketAgency, setAppliedDDETicketAgency] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ETicketAgency').then((res) => {
                setDDETicketAgency(res)
                setAppliedDDETicketAgency(res.map(e => e.Value))
            })

        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ETicketAgency": DDETicketAgency?.length === appliedDDETicketAgency?.length ? [] : appliedDDETicketAgency,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDETicketAgency,
    ])

    React.useEffect(() => {
        setAppliedDDETicketAgency([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    return (
        <div>
            <FormControl className="p-float-label" sx={{ minWidth: '100%', maxWidth: '100%' }}>
                <MultiSelect
                    value={appliedDDETicketAgency}
                    onChange={(e) => { setAppliedDDETicketAgency(e.value)}}
                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                    resetFilterOnHide={true} showClear={true}
                    inputId={"dd-ticket"} options={DDETicketAgency} filter optionLabel="Value" optionValue="Key"
                    placeholder="ETicket Agency" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                <label className={"bg-gray-100"} htmlFor="dd-ticket">ETicket Agency</label>
            </FormControl>
        </div>
    );
}

export const ParkMobileDropDowns: React.FC<DropDownsProps> = ({ HandleDropDowns, clear, alprSelected }) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDParkMobileAgency, setDDParkMobileAgency] = React.useState<any[]>([])
    /*const [dropdownLength, setParkMobileAgencyLength] = React.useState<any>([])*/
    const [appliedDDParkMobileAgency, setAppliedDDParkMobileAgency] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('ParkMobileAgency').then((res) => {
                setDDParkMobileAgency(res)
                setAppliedDDParkMobileAgency(res.map(e => e.Value))
            })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "ParkMobileAgency": DDParkMobileAgency?.length === appliedDDParkMobileAgency?.length ? [] : appliedDDParkMobileAgency,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDParkMobileAgency,
    ])

    React.useEffect(() => {
        setAppliedDDParkMobileAgency([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true);
            loadDropdowns();
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };

    return (
        <div>
            <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                <MultiSelect
                    value={appliedDDParkMobileAgency}
                    onChange={(e) => {setAppliedDDParkMobileAgency(e.value)}}
                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                    resetFilterOnHide={true} showClear={true}
                    inputId={"dd-park"} options={DDParkMobileAgency} filter optionLabel="Value" optionValue="Key"
                    placeholder="Parking Area" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                <label className={"bg-gray-100"} htmlFor="dd-park">Parking Area</label>
            </FormControl>
        </div>
    );
}

export const StolenStateDropdown: React.FC<DropDownsProps> = ({HandleDropDowns, clear}) => {
    const [DDLoaded, setDDLoaded] = React.useState<boolean>(false)

    const [DDStolenState, setDDStolenState] = React.useState<any[]>([])
    const [appliedDDStolenState, setAppliedDDStolenState] = React.useState<any[]>([])

    const loadDropdowns = async () => {
        if (!DDLoaded) {
            setDDLoaded(true)
            getDropDownQuery('State').then((res) => { setDDStolenState(res); setAppliedDDStolenState([GetDefaultStateForSite(SiteName)]) })
        }
    }

    const submitDropDowns = () => {
        HandleDropDowns({
            "StolenState": DDStolenState?.length === appliedDDStolenState?.length ? [] : appliedDDStolenState,
        })
    }

    React.useEffect(() => {
        submitDropDowns();
    }, [
        appliedDDStolenState,
    ])

    React.useEffect(() => {
        setAppliedDDStolenState([])
    }, [clear])

    React.useEffect(() => {
        if (!DDLoaded) {
            setDDLoaded(true)
            loadDropdowns()
        }
    });

    const PopperMy = function (props: any) {
        return <Popper {...props} style={{ "maxWidth": "fit-content" }} placement="bottom-start" />;
    };

    return (
        <div>
            <FormControl className="p-float-label" sx={{ minWidth: 510, maxWidth: 510 }}>
                <MultiSelect
                    value={appliedDDStolenState}
                    onChange={(e) => {setAppliedDDStolenState(e.value)}}
                    style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                    resetFilterOnHide={true} showClear={true}
                    inputId={"dd-state"} options={DDStolenState} filter optionLabel="Value" optionValue="Key"
                    placeholder="State" maxSelectedLabels={1} className="w-full md:w-20rem"/>
                <label className={"bg-gray-100"} htmlFor="dd-state">State</label>
            </FormControl>
        </div>
    );
}
export default ArrestDropDowns
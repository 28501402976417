import React from "react";
import { useLocation } from "react-router-dom";
import GunOffenderDetailInfo from "../../components/Detail/_GunOffenderDetailInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function GunOffenderDetail() {
    let query = useQuery();

    return (
        <div>
            <GunOffenderDetailInfo ID={query.get("ID")} department={query.get("Dept")} />
        </div>
    );
}
export default GunOffenderDetail;
import React from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileReportPersonInfo } from "../../../interfaces/getDarInfo_interface";
import {
  ColumnDirective,
  ColumnsDirective,
  ColumnMenu,
  GridComponent,
  Grid,
  Inject,
  Page,
  Sort,
  Filter,
  DetailRow,
  ExcelExport,
  PdfExport,
  Toolbar,
  Search,
  ExcelExportProperties,
  Resize,
  Reorder,
  SortSettingsModel,
  Edit,
  CommandColumn,
  CommandModel,
  rowSelected,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { JAOS } from "../../../services/JAOS.service";
// import NoPersonImage from "../../../assets/Images/noImageAvailable.png";
import {
  PostAdd,
  List,
  Person,
  ConstructionOutlined,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import {
  DeleteProfileReport,
  GetProfileReport,
  GetProfileReportPDF,
  SetProfileReport,
} from "../../../services/account.service";
import { useHistory } from "react-router-dom";
import { isUserPermission } from "../../../services/auth.service";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import { profileObject } from "../../../interfaces/PersonReport.interface";
import ShipEntryInputForm from "./shipEntryInputForm";
import { InvestigationObject } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import {
  DeleteInvestigationReport,
  DeleteShipEntry,
  submitInvestigation,
  submitShipEntry,
} from "../../../services/njport.service";
import { ShipEntyObject } from "../../../interfaces/NJPORT/ShipEntry.interface";

interface SearchResultsProps {
  data?: InvestigationObject[];
  Persons?: any;
  handleSubmit: any;
  SubmitSearch: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var varTypedPersons: InvestigationObject[];

const SearchResults: React.FC<SearchResultsProps> = ({
  handleSubmit,
  data,
  Persons = null,
  SubmitSearch,
}) => {
  const [typedPersons, setTypedPersons] = React.useState<InvestigationObject[]>(
    [],
    );

  const [params, setParams] = React.useState({
    FirstName: "",
    MI: "",
    LastName: "",
    DOB: "",
    FBI: "",
    SBI: "",
    AgeRangeStart: "",
    AgeRangeEnd: "",
    toDate: "",
    fromDate: "",
    Gender: "",
    Status: "",
    ReportNum: "",
  });
  const [tabValue, setTabValue] = React.useState("1");
  const [editTabName, setEditTabName] = React.useState("New Ship Entry Record");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [EditDialog, setEditDialog] = React.useState<boolean>(false);

  const [editDialogID, setEditDialogID] = React.useState<any>();
  const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
  const [duplicateReport, setDuplicateReport] = React.useState<boolean>(false);
  const [reportUrl, setReportUrl] = React.useState<string | undefined>(
    undefined,
  );
  const [hash, setHash] = React.useState<string | null>(null);
  const [editingRecord, setEditingRecord] =
    React.useState<InvestigationObject | null>(null);
  const [personDetails, setPersonDetails] =
    React.useState<profileObject | null>(null);
  //const [personDetails, setPersonDetails] = React.useState<any>(null);
  const [alertType, setAlertType] = React.useState<any>("success");
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<string>("");
  const [clear, setClear] = React.useState<boolean>(false);
  const [personList, setPersonList] = React.useState<any>();
  let grid: Grid | null;
  const history = useHistory();
  const [initialLoading, setInitialLoading] = React.useState(false);
  const handleDataTyping = () => {
    if (data && data.length > 0) {
      let tempP: any = [...data];

      for (let p of tempP) {
        // console.log(p);
        if (p.ArrivalDateTime) {
          p.Date_Received = new Date(p.ArrivalDateTime);
        }
        if (p.Date_Status_Change) {
          p.Date_Status_Change = new Date(p.Date_Status_Change);
        }
        if (p.DOB) {
          p.DOB = new Date(p.DOB);
        }
        if (p.Date_Fingerprint_Return) {
          p.Date_Fingerprint_Return = new Date(p.Date_Fingerprint_Return);
        }
        if (p.Date_PAC_Card_Issued) {
          p.Date_PAC_Card_Issued = new Date(p.Date_PAC_Card_Issued);
        }
        if (p.CreateDate) {
          p.CreateDate = new Date(p.CreateDate);
        }
        if (p.UpdateDate) {
          p.UpdateDate = new Date(p.UpdateDate);
        }
      }
        setTypedPersons(tempP);
        varTypedPersons = JSON.parse(JSON.stringify(tempP));
    } else {
      setTypedPersons([]);
    }
  };
  const cloneReport = async (id: number | null = null) => {
    if ((editDialogID && editDialogID > 0) || (id && id > 0)) {
      let record = {
        ProfileReportObject: { ID: editDialogID ? editDialogID : id },
      };
      let res = await GetProfileReport(record);
      res.newReport = true;
      setEditingRecord(res);
      setEditTabName("New Ship Entry Report");
      setTabValue("2");
    }
    setOpenEditDialog(false);
  };
  React.useEffect(() => {
    handleDataTyping();
  }, [data]);

  React.useEffect(() => {
    refreshDataSource();
  }, [typedPersons]);

  const showActionView = (rowData: any): any => {
    return (
      <span>
        <ButtonGroup>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEditReport(rowData.ID);
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteDialogID(rowData.ID);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </span>
    );
  };
  const deleteReport = async () => {
    let id: number = deleteDialogID;
    if (id && id > 0) {
      let data = {
        id: id,
        editStatus: "DELETE",
        deleted: 1,
      };
      let isDeleted = await DeleteShipEntry(data);
      if (isDeleted) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "Delete Successful (Changes Will Take Effect After The Next Search)",
        );
      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to delete.");
      }
    }
    setOpenDeleteDialog(false);
  };
  const viewReport = async (rowData: any) => {
    setReportUrl(undefined);
    let pdfUrl = await GetProfileReportPDF(rowData);
    if (pdfUrl) {
      setReportUrl(pdfUrl);
      setOpenDialog(true);
    }
  };
  const handleEditReport = async (rowDataID: any) => {
    setDuplicateReport(false);
      if (rowDataID && rowDataID > 0) {
      let res = typedPersons.filter((item) => item.ID === rowDataID);  // may have previous edit value
      if (varTypedPersons) {
          res = varTypedPersons.filter((item) => item.ID === rowDataID); // this will be current value
      }
      setEditDialogID(rowDataID);
      setEditingRecord(res[0]);
      setEditTabName("Edit Report");
      setTabValue("2");
    }
  };
  const gridTemplateIndicator = (person: any): any => {
    return (
      <>
        <BTPersonIndicatorGrid person={person} />
      </>
    );
  };
  const GridToolsClick = (args: ClickEventArgs) => {
    if (grid && args.item.text === "Excel Export") {
      let excelProp: ExcelExportProperties = {};
      excelProp.fileName = "Profile_Report.xlsx";

      grid.excelExport(excelProp);
    } else if (grid && args.item.text === "PDF Export") {
      grid.pdfExport();
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue == "1") {
      setEditTabName("New Report");
      setEditingRecord(null);
      setPersonDetails(null);
      setTabValue("1");
    }
    setTabValue(newValue);
  };
  const refreshDataSource = () => {
    if (grid) {
      grid.dataSource = typedPersons;
    }
  };
  const handleReportSubmit = async (submitObj: ShipEntyObject) => {
    let saveRequest = await submitShipEntry(submitObj);
    if (saveRequest && saveRequest.errorMessages?.length){
      setAlertType("error");
      setAlertText(saveRequest.errorMessages[0]);
      setAlertOpen(true);
    } else if (saveRequest) {
      setAlertType("success");
      setAlertText(
        "Ship Entry Report has been saved!(Changes Will Take Effect After The Reload)",
      );
      setAlertOpen(true);
      setTabValue("1");
      setEditTabName("New Ship Entry Record");
      setEditingRecord({});

             // Reload the page after 2 seconds
    /* setTimeout(() => {
      // window.location.reload();
    }, 2000); */
    }
  };

  const handleSentenceCase = (args: any) => {
    args = args.toLowerCase();
    args = args.split(" ");
    for (var i = 0; i < args.length; i++) {
      args[i] = args[i].charAt(0).toUpperCase() + args[i].slice(1);
    }
    return args.join(" ");
  };

  const rowSelected = (rowData: any) => {
    if (rowData && rowData.data.ID) {
      // viewReport(rowData.data.ID);
    }
  };

  let sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };

  return (
    <div className="m-5 p-5">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Box className="pt-3" style={{ justifyContent: "center" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} variant="fullWidth">
              <Tab icon={<List />} label="List View" value={"1"} />
              {isUserPermission("NJPort") ? (
                <Tab icon={<PostAdd />} label={editTabName} value={"2"} />
              ) : (
                <></>
              )}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className={"pl-10 pr-10 mt-5"}>
              {typedPersons && typedPersons.length > 0 ? (
                <>
                  <h4 className={"font-bold pl-5"}>
                    {typedPersons?.length} Records Found
                  </h4>
                  <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{
                      pageSize: 25,
                      pageSizes: [10, 25, 50, 100],
                    }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: "CheckBox" }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Both" }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={["ExcelExport", "Search"]}
                    toolbarClick={GridToolsClick}
                    ref={(g) => (grid = g)}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    rowSelected={rowSelected}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        template={showActionView}
                        width={125}
                        minWidth={125}
                        maxWidth={140}
                        headerText="Action"
                      />
                      <ColumnDirective
                        field="ID"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="ID"
                        customAttributes={{ class: ["e-attr"] }}
                      />

                      <ColumnDirective
                        field="Port"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="Port"
                        customAttributes={{ class: ["e-attr"] }}
                      />

                      <ColumnDirective
                        field="ShipTerminal"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="ShipTerminal"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="Berth"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="Birth"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="ShipName"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="ShipName"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="Date_Received"
                        width={225}
                        minWidth={225}
                        maxWidth={225}
                        format={{
                          type: "dateTime",
                          format: "MM/dd/yyyy HH:mm",
                        }}
                        headerText="Arrival DateTime"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                    </ColumnsDirective>
                    <Inject
                      services={[
                        ColumnMenu,
                        Sort,
                        Page,
                        Filter,
                        DetailRow,
                        Toolbar,
                        ExcelExport,
                        PdfExport,
                        Search,
                        Resize,
                        Reorder,
                        Edit,
                        CommandColumn,
                      ]}
                    />
                  </GridComponent>
                </>
              ) : (
                <div className="font-bold text-center text-lg pt-3">
                  NO Matching Records Found
                </div>
              )}
            </div>
          </TabPanel>
          {isUserPermission("NJPort") ? (
            <TabPanel value="2">
              {/* {tabValue && tabValue === '2' ? <ProfileReportInputForm handleReportSubmit={handleReportSubmit} duplicateReport={duplicateReport} person = {hash} editID = {editDialogID} /> : <></>} */}
              {tabValue && tabValue === "2" ? (
                <ShipEntryInputForm
                  handleReportSubmit={handleReportSubmit}
                  editReport={editingRecord}
                />
              ) : (
                <></>
              )}
            </TabPanel>
          ) : (
            <></>
          )}
        </TabContext>
      </Box>

      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Delete Report</h2>
          <IconButton
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Do you really want to delete this profile report? You can't undo
            this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteReport}>Delete Report</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchResults;

import React from 'react'
import {
    Tooltip, Alert, AlertColor, Avatar, Button, ButtonGroup, Checkbox, Snackbar,
} from "@mui/material";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListRounded from '@mui/icons-material/FilterListRounded';

import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';


import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column, ColumnMenu, Resize, Reorder
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {showCrumbScore, SiteName} from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import { green } from '@mui/material/colors';
import { JAOS } from '../../../services/JAOS.service';
import NCICIcon from '../../../assets/Images/NCIC.png';
import { isUserPermission } from '../../../services/auth.service';
import {getValue} from "@syncfusion/ej2-base";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface SearchResultsProps {
    AnkleBracelet: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ AnkleBracelet }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("error")
    const [ncicList, setNCICList] = React.useState<any[]>([])

    let grid: Grid | null;
    let selectedRows: any[] = [];

    const handleDataTyping = () => {
        if (AnkleBracelet) {
            let tempP: any = [...AnkleBracelet]
            //reset NCIClist if they search again
            setNCICList([])
            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.CreateDate) {
                    p.CreateDate = new Date(p.CreateDate)
                }
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [AnkleBracelet])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }
    
    const expandRow = (args: any) => {
        //expand if a row is selected except select checkbox
        if(args?.name === 'rowSelected' && args?.target.ariaLabel != null || args?.target.alt){
            if(grid){
                const selectedrowindex= grid.getSelectedRowIndexes()
                for (let idx of selectedrowindex) {
                    grid.detailRowModule.expand(idx)
                }
            }
        }
    }

    const rowDeselect = (args: any) => {
        if(args?.name === 'rowDeselected'){
            if(grid){
                grid.detailRowModule.collapseAll()
            }
        }
    }

    const handleNCICSearch = () => {
        if (ncicList.length > 0) {
            let l = new JAOS()
            let hashID = l.objToStack(ncicList);
            window.open('/tools/ncic_multiple_occurrence?person=' + hashID, '_blank')
        }
        else {
            setAlertType("error");
            setAlertText("Please select at least one offender to run NCIC search");
            setAlertOpen(true);
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };


    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const selectTemplate = (person: any): any => {
        return (
            <>
                <Checkbox  onClick={() => { buildNCICList(person) }} />
            </>);
    }

    const buildNCICList = (person: any) =>{
        //Not all records have PersonID so it also checks for TableID
        let NCICListTemp = ncicList
        if (person){
            if(person.PersonID){
                var result = NCICListTemp.findIndex(x => x.PersonID === person?.PersonID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
            else if (person.TableID){
                var result = NCICListTemp.findIndex(x => x.TableID === person?.TableID)

                if (result === -1) {
                    NCICListTemp.push(person)
                }
                else {
                    NCICListTemp.splice(result, 1)
                }
            }
        }
        setNCICList(NCICListTemp)
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const GridToolsClick = (args: ClickEventArgs) => {

        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'AnkleBracelet.xlsx'
            grid.excelExport(excelProp);
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            <span style={{ display: "inline-block" }} className='mb-5'>
                <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
            </span>
            {typedPersons && typedPersons?.length > 0 ? <>
                <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='AnkleBraceletID' width={140} minWidth={140} maxWidth={140} headerText='AnkleBracelet ID' visible={false} isPrimaryKey={true} />                        
                        <ColumnDirective field='CreateDate' headerText='Entry Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DateTimeIN' headerText='Report Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='CaseNumber' headerText='Case #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='CaseStatus' headerText='Case Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        {/*<ColumnDirective field='AgnecyCounty' headerText={`${GetStringNameForSite(SiteName, 'COUNTY')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />*/}
                        <ColumnDirective field='People' headerText='People' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='STATUTE' headerText='Offense' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='STATUTEDESCRIPTION' headerText='Offense Description' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        {/* <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />*/}
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResults;
import React, { ChangeEvent, useRef, useState } from 'react';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Autocomplete, StepConnector, Table, TableBody, TableCell, TableHead, TableRow, TextareaAutosize, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TSCLogo from '../../../assets/Images/Terrorist_Screening_Center.png';
import DeleteIcon from '@mui/icons-material/Delete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

import { SARS } from "../../../interfaces/SARS.interface";
import { readFileAsDataURL } from "../../../services/formatDate.service";
import { GetGUID } from "../../../services/sars.service";
import { Box, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';

interface SARSFormProps {
    handleFormSubmit: any,
    editForm: any,
}

interface dropdown {
    value: string,
    key: string
}

const steps = ['Report Start', 'Tier 1 General Information', 'Tier 1 Vehicles, Criminal History, Indices Checks', 'Tier 1 Additional Information',
    'Tier 2 General Information & Permits/Licenses', 'Tier 2 Corporate Affiliations & Employment', 'Tier 2 Associates & Insurance Claims', 'Tier 3 Financial'];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    '&.MuiStepConnector-root': {
        top: 10,  // Adjust this value as needed
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
    },
    '&.MuiStepConnector-active .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.MuiStepConnector-completed .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.MuiStepConnector-line': {
        borderColor: theme.palette.grey[400],
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const SARSInputForm: React.FC<SARSFormProps> = ({ handleFormSubmit, editForm = null }) => {
    const [form, setForm] = useState<SARS>({
        RFIPrevConducted: "No",
        SARSPhotoList: [{GUID: GetGUID()}],
        AddressList: [],
        VehicleList: [],
        CriminalHistoryList: [],
        IndicesChecks_CourtCaseInfoList: [],
        InfoObjectList: [],
        CorporateEmployment_AffiliationsList: [],
        CorporateEmployment_CorporateLicensingList: [],
        CorporateEmployment_EmploymentHistoryList: [],
        AssociateList: [],
        InsuranceClaimList: [],
        FinancialObjectList: []
    });

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const [stateList, setStateList] = React.useState<any>([]);
    const [countyList, setCountyList] = React.useState<any>([])
    const [cityList, setCityList] = React.useState<any>([]);
    const [systemList, setSystemList] = React.useState<any>(["Case Management Software", "Intelligence System", "Other"]);

    const [photoClearOpen, setPhotoClearOpen] = React.useState<boolean>(false)
    const [historicalAddressClearOpen, setHistoricalAddressClearOpen] = React.useState<boolean>(false)
    const [telephoneNumberClearOpen, setTelephoneNumberClearOpen] = React.useState<boolean>(false)
    const [emailAddressClearOpen, setEmailAddressClearOpen] = React.useState<boolean>(false)
    const [associatedVehicleClearOpen, setAssociatedVehicleClearOpen] = React.useState<boolean>(false)
    const [priorVehicleClearOpen, setPriorVehicleClearOpen] = React.useState<boolean>(false)
    const [stateHistoryClearOpen, setStateHistoryClearOpen] = React.useState<boolean>(false)
    const [arrestWarrantClearOpen, setArrestWarrantClearOpen] = React.useState<boolean>(false)
    const [indicesClearOpen, setIndicesClearOpen] = React.useState<boolean>(false)
    const [courtClearOpen, setCourtClearOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        handleInitialBuild()
    }, [])

    const handleInitialBuild = async () => {
        await handleDropdowns()
    }

    const handleDropdowns = async () => {
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)

        var counties = await getDropDownQuery('ResidentCounty')
        if (counties && counties?.length > 0) {
            setCountyList(counties)
        }
    }

    const handleDialog = (section: string, value: boolean) => {
        switch (section) {
            case 'SARS Photo':
                setPhotoClearOpen(value);
                break;
            case 'Historical Address':
                setHistoricalAddressClearOpen(value);
                break;
            case 'Telephone Numbers':
                setTelephoneNumberClearOpen(value);
                break;
            case 'Email Addresses':
                setEmailAddressClearOpen(value);
                break;
            case 'Associated Vehicles':
                setAssociatedVehicleClearOpen(value);
                break;
            case 'Prior Vehicles':
                setPriorVehicleClearOpen(value);
                break;
            case 'State Criminal History':
                setStateHistoryClearOpen(value);
                break;
            case 'Warrant and Arrest':
                setArrestWarrantClearOpen(value);
                break;
            case 'Indices Checks':
                setIndicesClearOpen(value);
                break;
            case 'Court Case':
                setCourtClearOpen(value);
                break;
        }

    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, item: any | null, origination: string, table: string = "") => {
        console.log("Event: ", event)
        console.log("Item: ", item)
        console.log("Origination: ", origination)
        console.log("Table: ", table)
        event.preventDefault();
        console.log("Dropping image for origination: ", origination);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0]; // Assuming only one file is handled per drop event
            console.log("File dropped: ", file);
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        } else {
            console.warn("No valid image found in drop event");
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, item: any | null, origination: string, table: string = "") => {
        event.preventDefault();
        console.log("Pasting image for origination: ", origination);

        const clipboardItems = event.clipboardData.items;
        let imageBlob: Blob | null = null;
        let originalFileName: string | undefined;

        for (let i = 0; i < clipboardItems.length; i++) {
            const item = clipboardItems[i];
            console.log('Clipboard item types:', item.type); // Debugging
            if (item.type.startsWith('image/')) {
                imageBlob = item.getAsFile();
                originalFileName = `pasted-image.${imageBlob?.type.split('/')[1]}`; // Default to extension from MIME type
                break; // Stop after finding the first image
            }
        }

        if (imageBlob) {
            console.log("Image Blob received for origination: ", origination);
            const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
            handlePasteOrDrop(file, item, origination, table); // Use the item and origination
        } else {
            console.warn("No valid image found in clipboard");
        }
    };

    const handleContextMenu = async (
        event: React.MouseEvent<HTMLDivElement>,
        item: any | null, // Use GUID for lists or null for standalone areas
        origination: string,
        table: string = ""
    ) => {
        event.preventDefault();
        console.log("Context menu triggered for origination: ", origination);

        const menu = document.createElement('div');
        menu.innerHTML = `
        <ul style="list-style: none; padding: 10px; border: 1px solid #ccc; background: #fff;">
            <li style="margin-bottom: 5px; cursor: pointer;" id="paste-option">Paste</li>
        </ul>
    `;
        menu.style.position = 'absolute';
        menu.style.left = `${event.pageX}px`;
        menu.style.top = `${event.pageY}px`;
        document.body.appendChild(menu);

        const pasteOption = document.getElementById('paste-option');
        if (pasteOption) {
            pasteOption.addEventListener('click', async () => {
                console.log("Paste option clicked for origination: ", origination);

                try {
                    const clipboardItems = await navigator.clipboard.read();
                    let imageBlob: Blob | null = null;
                    let originalFileName: string | undefined;

                    for (const item of clipboardItems) {
                        console.log('Clipboard item types:', item.types); // Debugging
                        const imageType = item.types.find(type => type.startsWith('image/'));
                        if (imageType) {
                            imageBlob = await item.getType(imageType);
                            originalFileName = `pasted-image.${imageBlob.type.split('/')[1]}`; // Default to extension from MIME type
                            break; // Stop after finding the first image
                        }
                    }

                    if (imageBlob) {
                        console.log("Image Blob received for origination: ", origination);
                        const file = new File([imageBlob], originalFileName || 'pasted-image.png', { type: imageBlob.type });
                        handlePasteOrDrop(file, item, origination, table); // Use the item and origination
                    } else {
                        console.warn("No valid image found in clipboard, skipping HTML data");
                    }
                } catch (err) {
                    console.error('Failed to read clipboard contents: ', err);
                } finally {
                    if (menu && menu.parentNode) {
                        menu.parentNode.removeChild(menu);
                    }
                }
            });
        }

        document.addEventListener('click', () => {
            if (menu && menu.parentNode) {
                menu.parentNode.removeChild(menu);
            }
        }, { once: true });
    };

    const handlePasteOrDrop = (file: File, item: any | null, origination: string, table: string = "") => {
        let temp = { ...form }
        /*        console.log("Dropping image file:", file);*/
        console.log("Item: ", item)
        console.log("Origination: ", origination)
        console.log("Table: ", table)
        const reader = new FileReader();

        reader.onload = (event) => {
            const result = event.target?.result;
            /*            console.log("FileReader result:", result);*/

            if (typeof result === 'string') {
                setForm((prevForm: any) => {
                    if (table !== "") {
                        let index: number | undefined = undefined;

                        if (table === "SARSPhotoList") {
                            index = temp?.SARSPhotoList?.findIndex((f: any) => f.GUID === item.GUID)
                            console.log(temp?.SARSPhotoList?.findIndex((f: any) => f.GUID === item.GUID))
                            if (index !== null && (index as number) >= 0) {
                                if (!Array.isArray(item.Files)) {
                                    item.Files = [];
                                }
                                console.log("Index: ", index)
                                if (origination === "SARS Photo") {
                                    console.log("SARS photo being pushed")
                                    item.Files.push({
                                        Content: result.split('base64,')[1],
                                        FileName: file.name,
                                        FileEncoding: file.type,
                                        Origination: origination,
                                    })
                                }

                                if (temp && temp.SARSPhotoList) {
                                    temp.SARSPhotoList[index as number] = item;
                                    console.log("Temp report: ", temp)
                                    return (temp)
                                }
                            }
                        }
                    } else {
                        const existingFiles = Array.isArray(prevForm.Files) ? prevForm.Files : [];
                        const updatedFiles = [...existingFiles];

                        updatedFiles.push({
                            Content: result.split('base64,')[1],
                            FileName: file.name,
                            FileEncoding: file.type,
                            Origination: origination,
                        });

                        console.log("Updated Files list:", updatedFiles);
                        return { ...prevForm, Files: updatedFiles };
                    }
                });
            }
        };

        console.log("Form state: ", form);
        reader.readAsDataURL(file);
    };

    const handleRemoveFile = (file: any, table: string = "", item: any = null) => {
        let temp: any = { ...form };

        if (item && table?.length > 0) {
            let objectIndex = temp[table]?.findIndex((fi: any) => fi === item);
            console.log("Object index: ", objectIndex)
            if ((objectIndex as any) >= 0) {
                let targetItem = temp[table][objectIndex];
                if ((table === "SARSPhotoList") && targetItem?.Files) {
                    let findIndex = targetItem.Files.findIndex((f: any) => f === file);
                    console.log("Find index: ", findIndex)
                    if (findIndex >= 0) {
                        console.log("File ID: ", targetItem.Files[findIndex].ID)
                        console.log("Array before anything happens: ", targetItem.Files)
                        if (targetItem.Files[findIndex]?.ID && targetItem.Files[findIndex].ID > 0) {
                            targetItem.Files[findIndex].deleted = true;
                            console.log("Array after something happens (ID is fine): ", targetItem.Files)
                        } else {
                            targetItem.Files.splice(findIndex, 1);
                            console.log("Array after something happens (ID is undefined or 0): ", targetItem.Files)
                        }
                    }
                }
                else {
                    let findIndex = temp[table][objectIndex]?.Files?.findIndex((f: any) => f === file);
                    console.log("Find index: ", findIndex)
                    if ((findIndex as any) >= 0) {
                        if (temp[table][objectIndex]?.Files[findIndex]?.ID && (temp[table][objectIndex]?.Files[findIndex].ID as any) > 0) {
                            temp[table][objectIndex].Files[findIndex].deleted = true;
                        } else {
                            temp[table][objectIndex]?.Files?.splice(findIndex, 1);
                        }
                    }
                }
            }
        } else if (temp?.Files && temp?.Files?.length > 0) {
            let findIndex = temp?.Files?.findIndex((f: any) => f === file);
            if ((findIndex as any) >= 0) {
                if (temp?.Files[findIndex]?.ID && (temp.Files[findIndex].ID as any) > 0) {
                    temp.Files[findIndex].deleted = true;
                } else {
                    temp?.Files?.splice(findIndex, 1);
                }
            }
        }

        setForm(temp); // This will trigger a re-render with the updated state
    };

    const handleInputChange = (e: { target: { name: string; value: any; }; }, table: string = "", item: any = null) => {
        const { name, value } = e.target;
        let temp: any = { ...form }

        if (table?.length > 0 && item) {
            let index = temp[table]?.findIndex((f: any) => f === item)
            if (index >= 0) {
                temp[table][index][name] = (value instanceof Date ? value.toLocaleString() : value)
            }
        }
        else {
            temp[name] = (value instanceof Date ? value.toLocaleString() : value)
        }

        setForm(temp)
    };

    const handleCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        const { name, checked } = e.target;
        let temp: any = { ...form }
        temp[name] = checked

        setForm(temp)
    }

    const addTableRow = (table: string, field: string = "", value: string = "") => {
        var temp: any = { ...form }

        if (!Array.isArray(temp[table])) {
            temp[table] = [];
        }

        if (field && value) {
            let newRow = { [field]: value };
            temp[table].push(newRow);
        } else {
            temp[table].push({});
        }

        setForm(temp)
    };

    const clearTableRows = (table: string, field = "", value = "") => {
        let temp: any = { ...form };
        if (temp[table]) {
            if (table && field && value) {
                // Clears specific items in Address based on field and value
                temp[table] = temp[table]?.filter((item: any) => item[field] !== value || item.deleted);
            } else { 
            temp[table] = [];
            }
        }
            setForm(temp);
    };

    const deleteTableRow = (table: string, item: any) => {
        var temp: any = { ...form }
        console.log("Before deletion:", temp[table]);
        if (table?.length > 0 && item) {
            if (temp[table]) {
                let index = temp[table]?.findIndex((f: any) => f === item)
                console.log("Deleting index:", index, "Item:", item);
                if ((index as any) >= 0) {
                    if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
                        temp[table][index].deleted = true
                    } else {
                        temp[table]?.splice(index, 1)
                    }

                    console.log("After deletion:", temp[table]);
                    setForm(temp)
                }
            }
        }
    };

    const isStepOptional = (step: number) => {
        return (step >= 3);
    }

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        console.log("Form state: ", form)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        })
    }

    const handleReset = () => {
        setActiveStep(0);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="w-full mt-4">
                <Stepper activeStep={activeStep} connector={<CustomStepConnector /> }>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography className="mt-2 mb-1">
                            All steps completed - you&apos;re finished
                        </Typography>
                        <div className="flex flex-row pt-2">
                            <div className="flex-auto" />
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 && (
                            <>
                                <div className="flex justify-center my-4">
                                        <img src={TSCLogo} alt="TSC Logo" className="h-32" />
                                </div>
                                <div className="grid grid-cols-2 gap-4 w-3/5 mx-auto mt-4 h-1/2">
                                        <div className="bg-sars-bg p-4 border border-black col-span-1 row-span-2 justify-center">
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Has an RFI been conducted previously on this individual?
                                                </FormLabel>
                                                <RadioGroup row className="mt-2" aria-label="RFIPrevConducted"
                                                    name="RFIPrevConducted"
                                                    value={form?.RFIPrevConducted}
                                                    onChange={handleInputChange}
                                                    defaultValue="No">
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                            <TextField
                                                label="Prior Encounter Dates"
                                                name="PriorEncounterDates"
                                                value={form?.PriorEncounterDates ?? ""}
                                                onChange={handleInputChange}
                                                multiline
                                                style={{marginTop: '10px'} }
                                                fullWidth
                                            />
                                            <FormControl component="fieldset">
                                                <FormControlLabel
                                                    control={<Checkbox checked={form?.NoUpdatedInformation ?? false}
                                                                       onChange={handleCheckboxChange}
                                                                       name="NoUpdatedInformation"
                                                                       />}
                                                    label="There is no updated information since the last RFI."
                                                    className="mt-10"
                                                />

                                            </FormControl>
                                        </div>
                                        <div className="bg-sars-bg p-4 border border-black col-span-1 row-span-2">
                                            <div className="grid grid-cols-2 grid-rows-2 gap-4 items-center">
                                                {form?.SARSPhotoList?.filter(p => !p?.deleted)?.map((row, index) => (
                                                    <React.Fragment key={index}>
                                                        {/* Photo Area */}
                                                        <div className="row-span-2">
                                                            <div>
                                                                <Button
                                                                    onClick={() => deleteTableRow("SARSPhotoList", row)}
                                                                    variant="outlined"
                                                                    color="error"
                                                                    sx={{marginBottom: 2} }
                                                                >
                                                                    Delete Entry
                                                                </Button>
                                                            </div>
                                                            <div
                                                                onDrop={(e) => handleDrop(e, row, "SARS Photo", "SARSPhotoList")}
                                                                onPaste={(e) => handlePaste(e, row, "SARS Photo", "SARSPhotoList")}
                                                                onContextMenu={(e) => handleContextMenu(e, row, "SARS Photo", "SARSPhotoList")}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                style={{
                                                                    border: '5px dashed #ccc',
                                                                    padding: '20px',
                                                                    textAlign: 'center',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                {row.Files && row.Files.length > 0 ? (
                                                                    row.Files.filter((file: any) => file.Origination === "SARS Photo" && !file.deleted).map((file: any, idx: number) => (
                                                                        <div key={idx}>
                                                                            <Box position="relative" display="inline-block">
                                                                                <img
                                                                                    src={`data:image/png;base64,${file.Content}`}
                                                                                    alt=""
                                                                                    style={{ maxWidth: '100%' }}
                                                                                />
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    onClick={() => handleRemoveFile(file, "SARSPhotoList", row)}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                        zIndex: 1,
                                                                                        color: 'red'
                                                                                    }}
                                                                                >
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    "Paste or Drag & Drop Your Photo Here"
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Photo Date Field */}
                                                        <div>
                                                            <DatePicker
                                                                label="Photo Date"
                                                                value={row?.PhotoDate ?? ""}
                                                                onChange={(date) => handleInputChange({ target: { name: "PhotoDate", value: (date ?? "") } }, "SARSPhotoList", row)}
                                                                renderInput={(params) => <TextField {...params} className="w-full" />}
                                                            />
                                                        </div>

                                                        {/* Source Field */}
                                                        <div>
                                                            <TextField
                                                                label="Source"
                                                                name="Source"
                                                                value={row?.Source ?? ""}
                                                                onChange={(e) => handleInputChange(e, "SARSPhotoList", row)}
                                                                className="w-full bg-blue-50"
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className="flex justify-center mt-4">
                                                <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                    color="inherit">
                                                    <Button
                                                        color="inherit"
                                                        onClick={() => addTableRow("SARSPhotoList", "GUID", GetGUID())}
                                                        startIcon={<AddIcon />}
                                                        variant="contained"
                                                    >
                                                        Add Another Photo
                                                    </Button>
                                                    <Button
                                                        color="inherit"
                                                        onClick={() => handleDialog("SARS Photo", true)}
                                                        startIcon={<ClearIcon />}
                                                        variant="contained"
                                                    >
                                                        Clear All Photos
                                                    </Button>
                                                    <Dialog
                                                        open={photoClearOpen}
                                                        onClose={() => handleDialog("SARS Photo", false)}
                                                        aria-labelledby="clear-dialog"
                                                        aria-describedby="clear-dyrs"
                                                    >
                                                        <DialogTitle id="clear-dialog-title">
                                                            {"Clear all existing photos?"}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="clear-dyrs">
                                                                Are you sure you would like to clear all photos?
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={() => handleDialog("SARS Photo", false)}>No</Button>
                                                            <Button onClick={() => { clearTableRows("SARSPhotoList"); handleDialog("SARS Photo", false) }} autoFocus>
                                                                Yes
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </ButtonGroup>
                                            </div>
                                        </div>

                                    <div className="bg-sars-bg p-4 border border-black col-span-2 flex space-x-4">
                                            <DatePicker
                                                label="Date"
                                                value={form?.ReportDate ?? ""}
                                                onChange={(date) => handleInputChange({ target: { name: "ReportDate", value: (date ?? "") } })}
                                                renderInput={(params) => <TextField {...params} className="flex-1" />}
                                            />
                                            <TextField
                                                label="Service Request Number (SRN)"
                                                name="SRN"
                                                value={form?.SRN ?? ""}
                                                onChange={handleInputChange}
                                                className="flex-1"
                                            />
                                            <TextField
                                                label="National Unique Identifying Number (NUIN)"
                                                name="NUIN"
                                                value={form?.NUIN ?? ""}
                                                onChange={handleInputChange}
                                                className="flex-1"
                                            />
                                    </div>
                                </div>
                            </>
                        )}
                        { activeStep === 1 && (
                            <>
                                <div className="mt-4 w-3/5 border gap-4 mx-auto border-black p-4 bg-sars-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        {/* First row, spans both columns */}
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>General Information</Typography>
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="First Name"
                                                name="GeneralInfo_FirstName"
                                                value={form?.GeneralInfo_FirstName ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Last Name"
                                                name="GeneralInfo_LastName"
                                                value={form?.GeneralInfo_LastName ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <DatePicker
                                                label="Date of Birth"
                                                value={form?.GeneralInfo_DOB ?? ""}
                                                onChange={(date) => handleInputChange({ target: { name: "GeneralInfo_DOB", value: (date ?? "") } })}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Place of Birth"
                                                name="GeneralInfo_PlaceOfBirth"
                                                value={form?.GeneralInfo_PlaceOfBirth ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="Social Security Number (SSN)"
                                                name="GeneralInfo_SSN"
                                                value={form?.GeneralInfo_SSN ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Possible Alias Information"
                                                name="GeneralInfo_AliasInfo"
                                                value={form?.GeneralInfo_AliasInfo ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                                multiline
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <Typography variant="h6">Driver's License</Typography>
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="Driver's License #"
                                                name="GeneralInfo_License_Number"
                                                value={form?.GeneralInfo_License_Number ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <Autocomplete
                                                id="free-solo-demo"
                                                autoComplete={true}
                                                options={stateList.map((option: any) => option.Key)}
                                                value={form?.GeneralInfo_License_State ?? ""}
                                                onChange={(event, newValue) => handleInputChange({ target: { name: "GeneralInfo_License_State", value: newValue as string } })}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="State Issued"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <DatePicker
                                                label="Date Issued"
                                                value={form?.GeneralInfo_License_DateIssued ?? ""}
                                                onChange={(date) => handleInputChange({ target: { name: "GeneralInfo_License_DateIssued", value: (date ?? "") } })}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <DatePicker
                                                label="Expiration Date"
                                                value={form?.GeneralInfo_License_ExpirationDate ?? ""}
                                                onChange={(date) => handleInputChange({ target: { name: "GeneralInfo_License_ExpirationDate", value: (date ?? "") } })}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="Valid Class"
                                                name="GeneralInfo_License_ValidClass"
                                                value={form?.GeneralInfo_License_ValidClass ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Restrictions, Conditional Messages, Endorsements"
                                                name="GeneralInfo_License_Restrictions"
                                                value={form?.GeneralInfo_License_Restrictions ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                                multiline
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Department of Corrections</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="State Department of Corrections Number"
                                                name="GeneralInfo_DOCNumber"
                                                value={form?.GeneralInfo_DOCNumber ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Department of Corrections Number Source"
                                                name="GeneralInfo_DOCSource"
                                                value={form?.GeneralInfo_DOCSource ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">State Driver's License Address</Typography>
                                        </div>
                                        <div className="col-span-1">
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="Source"
                                                name="GeneralInfo_StateLicenseAddress_Source"
                                                value={form?.GeneralInfo_StateLicenseAddress_Source ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                            <TextField
                                                label="Street"
                                                name="GeneralInfo_StateLicenseAddress_Street"
                                                value={form?.GeneralInfo_StateLicenseAddress_Street ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="City"
                                                name="GeneralInfo_StateLicenseAddress_City"
                                                value={form?.GeneralInfo_StateLicenseAddress_City ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10">
                                                <Autocomplete
                                                    id="free-solo-demo"
                                                    autoComplete={true}
                                                    options={stateList.map((option: any) => option.Key)}
                                                    value={form?.GeneralInfo_StateLicenseAddress_State ?? ""}
                                                    onChange={(event, newValue) => handleInputChange({ target: { name: "GeneralInfo_StateLicenseAddress_State", value: newValue as string } })}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="State"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                        </div>
                                        <div className="col-span-1 pr-10">
                                            <TextField
                                                label="ZIP Code"
                                                name="GeneralInfo_StateLicenseAddress_ZIP"
                                                value={form?.GeneralInfo_StateLicenseAddress_ZIP ?? ""}
                                                onChange={handleInputChange}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-span-1 pl-10"></div>
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Historical Address(es)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Historical Address")}
                                                >
                                                    Add Address
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Historical Address", true)}
                                                >
                                                    Clear All Addresses
                                                </Button>
                                                <Dialog 
                                                    open={historicalAddressClearOpen}
                                                    onClose={() => handleDialog("Historical Address", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title2">
                                                        {"Clear all existing historical addresses?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all historical addresses in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Historical Address", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Historical Address"); handleDialog("Historical Address", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Historical Address")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography align="center" variant="body1">Address {index + 1}</Typography>
                                                    <Tooltip title="Delete Address">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("AddressList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        value={row?.Source ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Street"
                                                        name="Street"
                                                        value={row?.Street ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="City"
                                                        name="City"
                                                        value={row?.City ?? ""}
                                                        fullWidth
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.State ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "State", value: newValue as string } }, "AddressList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="ZIP Code"
                                                        name="ZIP"
                                                        fullWidth
                                                        value={row?.State ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1"></div>
                                            </>
                                        ))}
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Associated Telephone Number(s)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Telephone Numbers")}
                                                >
                                                    Add Telephone Number
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Telephone Numbers", true)}
                                                >
                                                    Clear All Numbers
                                                </Button>
                                                <Dialog 
                                                    open={telephoneNumberClearOpen}
                                                    onClose={() => handleDialog("Telephone Numbers", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title3">
                                                        {"Clear all existing telephone numbers?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-telephone-numbers">
                                                            Are you sure you would like to clear all telephone numbers in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Telephone Numbers", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Telephone Numbers"); handleDialog("Telephone Numbers", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Telephone Numbers")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                <Typography align="center" variant="body1">Telephone Number {index + 1}</Typography>
                                                <Tooltip title="Delete Telephone Number">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("AddressList", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                </div >
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Telephone Number"
                                                        name="TelephoneNumbers"
                                                        fullWidth
                                                        value={row?.TelephoneNumbers ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                        <div className="col-span-1 pr-10">
                                            <Typography variant="h6">Associated Email Address(es)</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1 pr-10">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("AddressList", "Origination", "Email Addresses")}
                                                >
                                                    Add Email Address
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Email Addresses", true)}
                                                >
                                                    Clear All Email Addresses
                                                </Button>
                                                <Dialog 
                                                    open={emailAddressClearOpen}
                                                    onClose={() => handleDialog("Email Addresses", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title4">
                                                        {"Clear all existing email addresses?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-email-addresses">
                                                            Are you sure you would like to clear all email addresses in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Email Addresses", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("AddressList", "Origination", "Email Addresses"); handleDialog("Email Addresses", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.AddressList?.filter(p => !p?.deleted && p.Origination === "Email Addresses")?.map((row, index) => (
                                            <>
                                            <div className="col-span-2 flex items-center justify-center">
                                                <Typography align="center" variant="body1">Email Address {index + 1}</Typography>
                                                <Tooltip title="Delete Email Address">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("AddressList", row)}
                                                    >
                                                        <DeleteIcon sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                </div >
                                            <div className="col-span-1 pr-10">
                                                <TextField
                                                    label="Email Address"
                                                    name="EmailAddress"
                                                    value={row?.EmailAddress ?? ""}
                                                    fullWidth
                                                    onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                />
                                            </div>
                                            <div className="col-span-1 pl-10">
                                                <TextField
                                                    label="Source"
                                                    name="Source"
                                                    fullWidth
                                                    value={row?.Source ?? ""}
                                                    onChange={(e) => handleInputChange(e, "AddressList", row)}
                                                />
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>

                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                               <div className="mt-4 w-4/5 border gap-4 mx-auto border-black p-4 bg-sars-bg">
                                    <div className="grid grid-cols-2 gap-4 justify-center text-red">
                                        <div className="col-span-2">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Vehicles, Criminal History, Indices Checks</Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Associated Vehicles</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("VehicleList", "Origination", "Associated Vehicles")}
                                                >
                                                    Add Vehicle
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Associated Vehicles", true)}
                                                >
                                                    Clear All Vehicles
                                                </Button>
                                                <Dialog 
                                                    open={associatedVehicleClearOpen}
                                                    onClose={() => handleDialog("Associated Vehicles", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing vehicles?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all associated vehicles in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Associated Vehicles", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("VehicleList", "Origination", "Associated Vehicles"); handleDialog("Associated Vehicles", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.VehicleList?.filter(p => !p?.deleted && p.Origination === "Associated Vehicles")?.map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Vehicle {index + 1}</Typography>
                                                    <Tooltip title="Delete Vehicle">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("VehicleList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Year"
                                                        name="Year"
                                                        fullWidth
                                                        value={row?.Year ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Color"
                                                        name="Color"
                                                        fullWidth
                                                        value={row?.Color ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Make"
                                                        name="Make"
                                                        fullWidth
                                                        value={row?.Make ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Model"
                                                        name="Model"
                                                        fullWidth
                                                        value={row?.Model ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="License Plate Number"
                                                        name="LicensePlateNo"
                                                        fullWidth
                                                        value={row?.LicensePlateNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.LicensePlateState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "LicensePlateState", value: newValue as string } }, "VehicleList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="License State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Co-Owner/Registrant"
                                                        name="CoOwnerRegistrant"
                                                        fullWidth
                                                        value={row?.CoOwnerRegistrant ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Prior Vehicles</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("VehicleList", "Origination", "Prior Vehicles")}
                                                >
                                                    Add Vehicle
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Prior Vehicles", true)}
                                                >
                                                    Clear All Vehicles
                                                </Button>
                                                <Dialog 
                                                    open={priorVehicleClearOpen}
                                                    onClose={() => handleDialog("Prior Vehicles", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing prior vehicles?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all prior vehicles in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Prior Vehicles", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("VehicleList", "Origination", "Prior Vehicles"); handleDialog("Prior Vehicles", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>
                                        {form?.VehicleList?.filter(p => !p.deleted && p.Origination === "Prior Vehicles").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Vehicle {index + 1}</Typography>
                                                    <Tooltip title="Delete Vehicle">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("VehicleList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Year"
                                                        name="Year"
                                                        fullWidth
                                                        value={row?.Year ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Color"
                                                        name="Color"
                                                        fullWidth
                                                        value={row?.Color ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Make"
                                                        name="Make"
                                                        fullWidth
                                                        value={row?.Make ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Model"
                                                        name="Model"
                                                        fullWidth
                                                        value={row?.Model ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="License Plate Number"
                                                        name="LicensePlateNo"
                                                        fullWidth
                                                        value={row?.LicensePlateNo ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={stateList.map((option: any) => option.Key)}
                                                        value={row?.LicensePlateState ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "LicensePlateState", value: newValue as string } }, "VehicleList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="License State"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-span-1 pr-10">
                                                    <TextField
                                                        label="Source"
                                                        name="Source"
                                                        fullWidth
                                                        value={row?.Source ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-10">
                                                    <TextField
                                                        label="Co-Owner/Registrant"
                                                        name="CoOwnerRegistrant"
                                                        fullWidth
                                                        value={row?.CoOwnerRegistrant ?? ""}
                                                        onChange={(e) => handleInputChange(e, "VehicleList", row)}
                                                    />
                                                </div>    
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">State Criminal History</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CriminalHistoryList", "Origination", "State Criminal History")}
                                                >
                                                    Add State Criminal History
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("State Criminal History", true)}
                                                >
                                                    Clear All State History
                                                </Button>
                                                <Dialog 
                                                    open={stateHistoryClearOpen}
                                                    onClose={() => handleDialog("State Criminal History", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing state criminal history?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all state criminal history in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("State Criminal History", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CriminalHistoryList", "Origination", "State Criminal History"); handleDialog("State Criminal History", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CriminalHistoryList?.filter(p => !p.deleted && p.Origination === "State Criminal History").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">State Criminal History Summary {index + 1}</Typography>
                                                    <Tooltip title="Delete Criminal History">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("CriminalHistoryList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <DatePicker
                                                        label="Date"
                                                        value={row?.Date ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "CriminalHistoryList", row)}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <TextField
                                                        label="Charge"
                                                        name="Charge"
                                                        fullWidth
                                                        value={row?.Charge ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextField
                                                        label="Disposition"
                                                        name="Disposition"
                                                        fullWidth
                                                        value={row?.Disposition ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Local Warrant Information/Arrest Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CriminalHistoryList", "Origination", "Warrant and Arrest")}
                                                >
                                                    Add Warrant/Arrest Information
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Warrant and Arrest", true)}
                                                >
                                                    Clear All Warrants
                                                </Button>
                                                <Dialog 
                                                    open={arrestWarrantClearOpen}
                                                    onClose={() => handleDialog("Warrant and Arrest", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing arrest and warrant information?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all arrest and warrant information in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Warrant and Arrest", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("CriminalHistoryList", "Origination", "Warrant and Arrest"); handleDialog("Warrant and Arrest", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.CriminalHistoryList?.filter(p => !p.deleted && p.Origination === "Warrant and Arrest").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Warrant/Arrest Summary {index + 1}</Typography>
                                                    <Tooltip title="Delete Arrest/Warrant">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("CriminalHistoryList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5">
                                                    <DatePicker
                                                        label="Date"
                                                        value={row?.Date ?? ""}
                                                        onChange={(date) => handleInputChange({ target: { name: "Date", value: (date ?? "") } }, "CriminalHistoryList", row)}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                    />
                                                </div>
                                                <div className="col-span-1 pl-5">
                                                    <TextField
                                                        label="Charge"
                                                        name="Charge"
                                                        fullWidth
                                                        value={row?.Charge ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <TextField
                                                        label="Disposition"
                                                        name="Disposition"
                                                        fullWidth
                                                        value={row?.Disposition ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CriminalHistoryList", row)}
                                                    />
                                                </div>    
                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">State, Regional, and Local Indices Checks</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("IndicesChecks_CourtCaseInfoList", "Origination", "Indices Checks")}
                                                >
                                                    Add Indices Check
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Indices Checks", true)}
                                                >
                                                    Clear All Indices Checks
                                                </Button>
                                                <Dialog 
                                                    open={indicesClearOpen}
                                                    onClose={() => handleDialog("Indices Checks", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing indices checks?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all indices checks in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Indices Checks", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("IndicesChecks_CourtCaseInfoList", "Origination", "Indices Checks"); handleDialog("Indices Checks", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        {form?.IndicesChecks_CourtCaseInfoList?.filter(p => !p.deleted && p.Origination === "Indices Checks").map((row, index) => (
                                            <>
                                                <div className="col-span-2 flex items-center justify-center">
                                                    <Typography variant="body1" align="center">Indices Check {index + 1}</Typography>
                                                    <Tooltip title="Delete Indices Check">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => deleteTableRow("IndicesChecks_CourtCaseInfoList", row)}
                                                        >
                                                            <DeleteIcon sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="col-span-1 pr-5 mt-5">
                                                    <Autocomplete
                                                        id="free-solo-demo"
                                                        autoComplete={true}
                                                        options={systemList}
                                                        value={row?.SystemType ?? ""}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "SystemType", value: newValue as string } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="System Type"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="grid grid-cols-5 col-span-1 gap-4">
                                                    <div className="col-span-2">
                                                        <FormLabel component="legend">Findings</FormLabel>
                                                        <RadioGroup
                                                            aria-label="findings"
                                                            name="Findings"
                                                            value={row?.Findings ?? ""}
                                                            onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                        >
                                                            <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
                                                            <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
                                                        </RadioGroup>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <TextareaAutosize
                                                            id="AdditionalInfo"
                                                            name="AdditionalInfo"
                                                            placeholder="Additional Information"
                                                            value={row?.AdditionalInfo ?? ""}
                                                            onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '10px',
                                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                                borderRadius: '4px',
                                                                padding: '16.5px 14px',
                                                                fontSize: '16px',
                                                                lineHeight: '1.4375em',
                                                                backgroundColor: 'inherit',
                                                                resize: 'vertical'
                                                            }}
                                                            minRows={3}
                                                        />
                                                    </div>
                                                </div>

                                            </>
                                        ))}

                                        <div className="col-span-1">
                                            <Typography variant="h6">Court Case Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="grid grid-rows-2 grid-cols-1 col-span-2">
                                            <div><Typography variant="body1">In addition to traffic-related offenses, the subject is referenced in the following court cases.</Typography></div>
                                            <div><Typography variant="body1">**Includes any state/local case information that is not available in NCIC.</Typography></div>
                                        </div>
                                        <div className="col-span-1">
                                            <ButtonGroup>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("IndicesChecks_CourtCaseInfoList", "Origination", "Court Case")}
                                                >
                                                    Add Court Case
                                                </Button>
                                                <Button
                                                    color="inherit"
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("Court Case", true)}
                                                >
                                                    Clear All Court Cases
                                                </Button>
                                                <Dialog 
                                                    open={courtClearOpen}
                                                    onClose={() => handleDialog("Court Case", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-dialog"
                                                >
                                                    <DialogTitle id="clear-dialog-title5">
                                                        {"Clear all existing court cases?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-historical-addresses">
                                                            Are you sure you would like to clear all court cases in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("Court Case", false)}>No</Button>
                                                        <Button onClick={() => {clearTableRows("IndicesChecks_CourtCaseInfoList", "Origination", "Court Case"); handleDialog("Court Case", false)} } autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-span-1"></div>

                                        <div className="col-span-2">
                                            <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Case Number</TableCell>
                                                    <TableCell>Source</TableCell>
                                                    <TableCell>File Date</TableCell>
                                                    <TableCell>County</TableCell>
                                                    <TableCell>Case Type and/or Statute</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {form?.IndicesChecks_CourtCaseInfoList?.filter(f => !f?.deleted && f.Origination === "Court Case")?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ width: 150 }}>
                                                            <TextField
                                                                name="CaseNo"
                                                                value={row?.CaseNo ?? ""}
                                                                multiline
                                                                onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ width: 200 }}>
                                                            <TextField
                                                                name="Source"
                                                                value={row?.Source ?? ""}
                                                                multiline
                                                                onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ width: 260 }}>
                                                            <DatePicker
                                                                value={row?.FileDate ?? ""}
                                                                onChange={(date) => handleInputChange({ target: { name: "FileDate", value: (date ?? "") } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Autocomplete
                                                                id="free-solo-demo"
                                                                autoComplete={true}
                                                                options={countyList.map((option: any) => option.Key)}
                                                                value={row?.County ?? ""}
                                                                onChange={(event, newValue) => handleInputChange({ target: { name: "County", value: newValue as string } }, "IndicesChecks_CourtCaseInfoList", row)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="County"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="CaseType"
                                                                value={row?.CaseType ?? ""}
                                                                multiline
                                                                onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center'
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                multiline
                                                                name="Status"
                                                                value={row?.Status ?? ""}
                                                                onChange={(e) => handleInputChange(e, "IndicesChecks_CourtCaseInfoList", row)}
                                                                inputProps={{
                                                                    style: {
                                                                        borderRadius: "1px",
                                                                        textAlign: 'center',
                                                                        wordBreak: 'break-word',
                                                                        whiteSpace: 'pre-wrap',
                                                                        overflowWrap: 'break-word',
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Delete Case">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("IndicesChecks_CourtCaseInfoList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {activeStep === 3 && (
                            <>
                                <div className="mt-4 w-3/5 border gap-4 mx-auto border-black p-4 bg-sars-bg">
                                    <div className="grid grid-cols-1 gap-4 justify-center">
                                        <div className="col-span-1">
                                            <Typography align="center" sx={{color: 'red', fontFamily: 'sans-serif', fontStyle: 'bold', fontSize: '2rem', fontWeight: 1200, WebkitTextStroke: '1px black'}}>Additional Information</Typography>
                                        </div>
                                        <div className="col-span-1"></div>
                                        <div className="col-span-1">
                                            <Typography variant="body1">Enter any information that resulted from your search but was not included above, such as visa
                                                                        number, alien registration number, naturalization certificate number, passport number/country, Immigration
                                                                        and Customs Enforcement (ICE) records, El Paso Intelligence Center (EPIC) checks (<a className="text-blue-600 underline" href="https://esp.usdoj.gov">https://esp.usdoj.gov</a>),
                                                                        National Law Enforcement Telecommunications System (Nlets) checks (<a className="text-blue-600 underline" href="https://www.nlets.org">https://www.nlets.org</a>), deconfliction system
                                                                        checks (<a className="text-blue-600 underline" href="https://www.ncirc.gov/Deconfliction">https://www.ncirc.gov/Deconfliction</a>), and/or RISSIntel checks. In addition, include any information garnered
                                                                        from open source inquiries to include financial information, social networking profile information, media reporting, etc. Be sure to include the entire URL to the subject's various social media platforms.
                                            </Typography>
                                        </div>
                                        <div className="col-span-1">
                                            <TextareaAutosize
                                                id="AdditionalInfo_Summary"
                                                name="AdditionalInfo_Summary"
                                                placeholder="Additional Information"
                                                value={form?.AdditionalInfo_Summary ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={12}
                                            />
                                        </div>
                                        <div className="border-t-2 border-black col-span-1">
                                            <TextareaAutosize
                                                id="Disclaimers"
                                                name="Disclaimers"
                                                placeholder="Disclaimers"
                                                value={form?.Disclaimers ?? ""}
                                                onChange={handleInputChange}
                                                style={{
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                                }}
                                                minRows={3}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <Typography variant="h6">Analyst Information</Typography>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 col-span-1">
                                            <div className="col-span-1">
                                                <TextField
                                                    label="First Name"
                                                    name="AnalystFirstName"
                                                    fullWidth
                                                    value={form?.AnalystFirstName ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Last Name"
                                                    name="AnalystLastName"
                                                    fullWidth
                                                    value={form?.AnalystLastName ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 col-span-1">
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Email Address"
                                                    name="AnalystEmail"
                                                    fullWidth
                                                    value={form?.AnalystEmail ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    label="Phone Number"
                                                    name="AnalystTelephoneNumber"
                                                    fullWidth
                                                    value={form?.AnalystTelephoneNumber ?? ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {activeStep === 4 && (
                            <>

                            </>
                        )}
                        <div className="flex flex-row pt-2">
                            <Button
                                variant="contained"
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className="pl-4 ml-4"
                            >
                                Back
                            </Button>
                            <div className="flex-auto" />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} className="mr-1">
                                    Skip
                                </Button>
                            )}
                            <Button className="pr-4 mr-4" variant="contained" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </React.Fragment>)}
            </div>
        </LocalizationProvider>
    )
}

export default SARSInputForm;
import React, {useState} from "react";
import {DashboardRefresh, DashboardTimer, SiteName} from "../../../services/config.service";
import {
    GeteTraceAgencyORI,
    GetETraceStatDetails,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken,
    RunGeteTraceCountyCityList,
} from "../../../services/dashboard.service";
import {Autocomplete, Button, Card, Divider, Tab, TextField, Tooltip, Typography,} from "@mui/material";
import {DatePickerComponent, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";

import {GetDefaultStateForSite, GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import Top10ManufacturersRecovered from "./_etTop10ManufacturersRecovered";
import Top10CalibersRecovered from "./_etTop10CalibersRecovered";
import Top10NCICCrimeCodes from "./_etTop10NCICCrimeCodes";
import ETHeatMap from "./_etHeatMap";
import CaliberBreakdown from "./_etCaliberBreakdown";
import Top25CitiesBreakdown from "./_etTop25CitiesBreakdown";
import CountyBreakdown from "./_etCountyBreakdown";
import { Box, Stack } from "@mui/system";
import Top10StatesGunsAreComingFrom from "./_etTop10StatesGunsAreComingFrom";
import Top10FFLsWhereGunsWerePurchased from "./_etTop10FFLsWhereGunsWerePurchased";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ShootingVictimDashboardGrid from "./_etShootingVictimBreakdown";
import NibinBreakdownDashboardGrid from "./_etNibinBreakdown";
import Top10PossessorWithFFLs from "./_etTop10PossessorsWithFFLs";
import PersonBreakdown from "./_etPersonBreakdown";
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";

// import ShootingVictimDashboardGraph from "../NJPOP/_shootingVictimsDashboard";


interface ETDashboardLayoutProps {
    GetStats:any,
    data:any,
}


const ETDashboardLayout: React.FC<ETDashboardLayoutProps> = ({GetStats, data}) => {
    const [location, setLocation] = React.useState<string>("Loading")
    const [loading, setLoading] = React.useState<boolean>(true)
    const [county, setCounty] = React.useState<string>("")
    const [city, setCity] = React.useState<string>("")
    const [agency, setAgency] = React.useState<string>("")
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true)
    let today = new Date()
    today.setHours(23)
    today.setMinutes(59)
    today.setSeconds(59)
    let monthAgoDate = new Date();
    monthAgoDate.setMonth(monthAgoDate.getMonth()-1)
    monthAgoDate.setDate(monthAgoDate.getDate())
    monthAgoDate.setHours(0)
    monthAgoDate.setMinutes(0)
    monthAgoDate.setSeconds(0)
    
    
    const [toDate, setToDate] = React.useState<any>(today)
    const [startDate, setStartDate] = React.useState<any>(monthAgoDate)
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false)
    const [cityList, setCityList] = React.useState<any[]>([])
    const [agencyList, setAgencyList] = React.useState<any[]>([])
    const [allCityList, setAllCityList] = React.useState<any[]>([])
    const [allAgencyList, setAllAgencyList] = React.useState<any[]>([])
    const [heatMapTab, setHeatMapTab] = React.useState('1');
    const [avgTTCTab, setAvgTTCTab] = React.useState('1');
    const [countyList, setCountyList] = React.useState<any[]>([])

    React.useEffect(() => {
        if(!loading) {
            let params = {
                StartDate: startDate,
                EndDate: toDate,
                City: (city && city.trim().length > 0 ? city : "ALL CITIES"),
                County: (county && county.trim().length > 0 ? county : "ALL COUNTIES"),
                Agency: (agency && agency.trim().length > 0 ? agency : 'ALL AGENCIES'),
                StolenType:'',
                State: (SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? "DC" : "NJ",
            }
            if(initialLoad){
                GetStats(params)
            }

        }
    },[city,toDate,startDate,county,agency])
    
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!loading) {
                let params = {
                    StartDate: startDate,
                    EndDate: toDate,
                    City: (city && city.trim().length > 0 ? city : "ALL CITIES"),
                    County: (county && county.trim().length > 0 ? county : "ALL COUNTIES"),
                    Agency: (agency && agency.trim().length > 0 ? agency : ''),
                    StolenType:'',
                    State: (SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? "DC" : "NJ",
                }
                GetStats(params)
                if(dateUpdate){
                    let today = new Date()
                    setToDate(today)
                }
            }
        }, (DashboardTimer * 6));
        return () => clearInterval(interval);
    }, [city,toDate,startDate,county,agency]);

    React.useEffect(() => {
        const x = setInterval(async () => {
            let dashboard = await RunGetDashboardRefreshToken()
            if (dashboard !== null && dashboard?.AuthToken) {
                localStorage.setItem("dashboard", dashboard.AuthToken)
            }
        }, DashboardRefresh);
        return () => clearInterval(x);
    }, []);
    
    React.useEffect( () => {
        if(!initialLoad)
        {
            let temp:any = []
            let tempC:any = []
            let county:any = []
            //let tempP={County:'ALL COUNTIES', City:'ALL CITIES'}

            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken)

                    if(!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL)) {
                        RunGeteTraceCountyCityList().then((cList) => {
                            temp.push({City: "ALL " + GetStringNameForSite(SiteName, 'CITIES').toUpperCase()})
                            temp.push.apply(temp, cList)
                            setCityList(temp)
                            setAllCityList(temp)
                            county.push({County: "ALL " + GetStringNameForSite(SiteName, 'COUNTIES').toUpperCase()})
                            tempC = new Set()
                            for (let c of cList) {
                                tempC.add(c.County)
                            }
                            for (let c of Array.from(tempC)) {
                                county.push({County: c})
                            }

                            setCountyList(county)

                        })
                        GeteTraceAgencyORI('','').then((aList)=>{
                            let temp = aList
                            temp.unshift({Key: 'ALL AGENCIES', Value: 'ALL AGENCIES'})
                            setAgencyList(temp)
                        })

                    }
                    // else {
                    //     GetStolenVehicleAgencyORI(GetDefaultStateForSite(SiteName)).then((aList) => {
                    //         let temp = aList
                    //         temp.unshift({Key: 'ALL AGENCIES', Value: 'ALL AGENCIES'})
                    //         setAgencyList(temp)
                    //     })
                    // }
                    
                    setLoading(false)
                    setCity('ALL CITIES')
                    setInitialLoad(true)
                    setCounty('ALL COUNTIES')
                    setAgency('ALL AGENCIES')
                }
            })
                       
        }
    }, []);

    const getAbbreviated = (str:string) => {
        if(str != null && str === "DC")
            return str
        if(str != null && str?.length > 0) {
            let temp = str.split(" ")
            let abbreviation = ""
            for (let t of temp) {
                if (t.length > 0) {
                    abbreviation += t[0]
                }
            }
            return abbreviation.replace(/[^a-zA-Z0-9]/g, '')
        }
        return ""
    }
    
    const handleAgencyChange = (a:string) => {
        if(a !== null)
            setAgency(a)     
    }
    
    const handleCityChange = async (c:string) => {
        let tempCounty = county
        if(c === "ALL CITIES" || c === "ALL ZONES/CITIES") {
            setCity("ALL CITIES")
            setAgency("ALL AGENCIES")
        }
        else{
            setCity(c)
            setAgency("ALL AGENCIES")
        }
        let res = await GeteTraceAgencyORI(tempCounty != "ALL COUNTIES" ? tempCounty : '', c != "ALL CITIES" ? c : '')
            let aList:any = []
            aList=res
            aList.unshift({Key: 'ALL AGENCIES', Value: 'ALL AGENCIES'})
            setAgencyList(aList)

    }

    const handleCountyChange = async (county:string) => {
        let tempCity = city
        if(county) {
            setCounty(county === "ALL DISTRICTS/COUNTIES" ? "ALL COUNTIES" : county)
            if(county === "ALL COUNTIES")
            {
                setCityList(allCityList)
                setCity("ALL CITIES")
                setAgency("ALL AGENCIES")
                tempCity = ''

            }
            else
            {
                let cList:any = []
                cList.push({City:"ALL " + GetStringNameForSite(SiteName,'CITIES').toUpperCase()})
                cList.push.apply(cList, allCityList.filter(city => city.County === county))
                setCityList(cList)
                setCity("ALL CITIES")
                setAgency("ALL AGENCIES")
                
            }

            let res = await GeteTraceAgencyORI(county != "ALL COUNTIES" ? county : '', tempCity != "ALL CITIES" ? tempCity : '')
            let aList:any = []
            aList = res
            aList.unshift({Key: 'ALL AGENCIES', Value: 'ALL AGENCIES'})
            aList.push.apply(res, res.filter(agency => agency.County === county))
            setAgencyList(aList)
        }

    }
      
    const handleStateAverage = ()=>{
        if('avgTimeToCrimeBasedOnState' in data && Array.isArray(data.avgTimeToCrimeBasedOnState) && data.avgTimeToCrimeBasedOnState.length > 0){         
            let average = data.avgTimeToCrimeBasedOnState.reduce((total :any, next :any) => total + next.AverageTTC, 0) / data.avgTimeToCrimeBasedOnState.length;
            return (Math.round(average))
        }
    }

    const handleShootingVictimTotal = (category:string)=>{
        switch(category){
            case 'This month':
                if('shootingVictimBreakdownThisMonth' in data && Array.isArray(data?.shootingVictimBreakdownThisMonth) && data?.shootingVictimBreakdownThisMonth?.length > 0){         
                    let total = data.shootingVictimBreakdownThisMonth.reduce((total :any, next :any) => total + next.Count, 0);
                    return (total)
                }
                break;
            case 'Last month':
                if('shootingVictimBreakdownLastMonth' in data && Array.isArray(data?.shootingVictimBreakdownLastMonth) && data?.shootingVictimBreakdownLastMonth?.length > 0){         
                    let total = data.shootingVictimBreakdownLastMonth.reduce((total :any, next :any) => total + next.Count, 0);
                    return (total)
                }
                break;      
        }
        
    }


    const handleTabChange = (category:string, newValue: string) => {
        switch(category){
            case 'avgTTC':
                setAvgTTCTab(newValue)
                break;
            case 'HeatMap':
                setHeatMapTab(newValue);         
        }
    };

    // handleGetDDLabel(agencyList, option, 2)
    const handleGetDDLabel = (list:any[], option:any, listType:number)=>{       
        if(!option || option === '' || option === undefined)
            return ''
        if(listType && list && list.length > 0){   
            if(option && option.hasOwnProperty('Value'))
                {
                    return option.Value
                }
                else {
                    let x = list.find((e:any) => e.Key === option)
                    if(x && x.hasOwnProperty('Value')){
                        return x.Value
                    }                        
                    else
                    {
                        return option
                    }
                }       

        }
        return option
    }

    const  downloadData = async (type:string) =>{
        let category = ""

        if(type === "all"){
            category="possessorAssociateBreakdown,PossessorsWithFFLs,gunsToNibinBreakdown,avgTimeToCrime,avgTimeToCrimeBasedOnTopStates"
            +",FFLsWhereGunsWerePurchased,StatesGunsAreComingFrom,countyBreakdown,CitiesBreakdown,locationData,recoveredGunBreakdown,GunManufactureRecovered,NCICCrimeCodes"
            +",caliberBreakdown,gunRecoveryNumbersStatewide"
    
           

        }
        else {
            category = type
        }

        if(category.length > 0 ){
            let tempObj = {Agency:agency, Category:category, City:city, County:county, EndDate: toDate , StartDate:startDate, State:null, StolenType:null}
            let res = await GetETraceStatDetails(tempObj)
        }

    }


    const handleBlur = (date : any)=>{
        //handle toDate date time when user inputs date
        if(date){
           if(compareDatesByComponents(toDate, new Date(date?.target?.value))) {
            let tempDate = new Date(date?.target?.value)
            tempDate?.setHours(23)
            tempDate?.setMinutes(59)
            tempDate?.setSeconds(59)
            setToDate(tempDate)

           }
        }
        else{
            console.log('')
        }

    }


    function compareDatesByComponents(date1: Date, date2: Date): number {
        const yearDiff = date1.getFullYear() - date2.getFullYear();
        const monthDiff = date1.getMonth() - date2.getMonth();
        const dayDiff = date1.getDate() - date2.getDate();

        if (yearDiff !== 0) {
            return yearDiff;
        }

        if (monthDiff !== 0) {
            return monthDiff;
        }
        return dayDiff;
    }
    
    

      
    //BackTrace NJ Shooting Strategic Dashboard 
    return (
        <div>
            {!loading ?
                <div>
                     <div className = "text-center text-black font-bold mt-2" style={{fontSize:26}} >
                        <span >E-Trace Dashboard </span>
                    </div>
                    <div className="mx-6">
                        <Button  onClick={()=>downloadData("all")} variant="outlined" startIcon={<DownloadIcon/>}>Excel Export</Button>
                    </div>
                    <div className = "mt-3" >
                        <div className ="my-4 mx-6 grid grid-cols-12 gap-x-4 justify-items-center">
                            <div className="col-span-3" style={{width:'100%'}}> 
                                <div className = "border-gray-900 border-2 h-54" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Possessor/Associate Breakdown" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Possessor/Associate Breakdown
                                                <span className="inline">
                                                    <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("possessorAssociateBreakdown")}>
                                                        <FileDownloadIcon  />
                                                    </IconButton>                                                 
                                                </span>
                                        </div>
                                    </Tooltip>
                                    <PersonBreakdown data={data}/>                                                          
                                </div> 
                                <div className = "border-gray-900 border-2 h-30 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Guns to NIBIN based on case number" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Guns to NIBIN</div>
                                    </Tooltip>
                                    {
                                        'gunsToNIBIN' in data && Array.isArray(data?.gunsToNIBIN) && data?.gunsToNIBIN.length > 0 ?
                                        <div className={"text-center text-3xl mt-2 mb-2"} style={{color:"black", fontWeight:500 , height:"100%"}}>{data?.gunsToNIBIN[0]?.Count}</div>
                                        :
                                        <div className={"text-center text-3xl mt-2 mb-2"} style={{color:"red", fontWeight:500 , height:"100%"}}>No Data</div>
                                    }
                                </div>   
                                <div className = "border-gray-900 border-2 h-30 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="NIBIN Breakdown based on case number" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Guns to NIBIN Breakdown
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("gunsToNibinBreakdown")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <NibinBreakdownDashboardGrid location={location} dbData={data}/>
                                </div>     
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 States Where Guns are Coming From" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 States Where Guns are Coming From
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("StatesGunsAreComingFrom")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <Top10StatesGunsAreComingFrom data={data} />
                                </div> 
                                <div className = "border-gray-900 border-2 h-54 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Average Time to Crime Based on the last 12 months" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Average Time to Crime
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("avgTimeToCrime,avgTimeToCrimeBasedOnTopStates")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <TabContext value={avgTTCTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('avgTTC', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Based on the last 12 months " value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Based on the last 12 months and Top 10 States" value={'2'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'30px'}}>
                                            {'avgTimeToCrime' in data && Array.isArray(data?.avgTimeToCrime) && data?.avgTimeToCrime.length > 0 ?
                                                <div style={{height:"100%"}}>
                                                    {data?.avgTimeToCrime?.map((d:any) => (
                                                        <div className={"text-center font-bold"}>
                                                            <span style={{fontSize:'17px'}}> Average Time To Crime : {d?.AverageTTC}</span>
                                                        </div>
                                                    ))}
                                                                                                  
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-2 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'30px'}}>
                                            {'avgTimeToCrimeBasedOnState' in data && Array.isArray(data.avgTimeToCrimeBasedOnState) && data.avgTimeToCrimeBasedOnState.length > 0 ?
                                                    <div style={{height:"100%"}}>                                                                                            
                                                        <div className={"text-center font-bold"}>
                                                            <span style={{fontSize:'17px'}}>Average Time To Crime : {handleStateAverage()}</span>
                                                        </div>  
                                                                                                      
                                                    </div>
                                                    :
                                                    <div className={"text-center text-3xl mt-2 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                    </TabContext>
                                </div>         
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 FFLs Where Guns were Purchased" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 FFLs Where Guns were Purchased
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("FFLsWhereGunsWerePurchased")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <Top10FFLsWhereGunsWerePurchased data={data} />
                                </div>                                       
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="County Breakdown" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">{GetDefaultStateForSite(SiteName)} Counties
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("countyBreakdown")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <CountyBreakdown data={data} />
                                </div>                                                                                   

                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 25 Cities Breakdown" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "Top Districts"  : "Top 25 Cities"}
                                            <span style={{fontStyle:'italic', marginLeft:'4px'}}></span>
                                            <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("CitiesBreakdown")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <Top25CitiesBreakdown data={data} />
                                </div>
                                                                                
                            </div>
                            <div className="col-span-6" style={{width:'100%'}} >
                                <div className = "border-gray-900 border-2 mb-4 " style={{borderRadius:'6px'}}>
                                    <Tooltip title="Filter the dashboards data to a specific County and City" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">Dashboard Filters</div>
                                    </Tooltip>
                                    <div className="grid grid-cols-12 gap-4 p-2">
                                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && 
                                            <div className="col-span-4">
                                                <Autocomplete 
                                                    id="county"
                                                    disableClearable
                                                    size={"small"} 
                                                    options={countyList.map((option) => option.County)}
                                                    autoComplete = {true} 
                                                    onChange={(event, value :any) => handleCountyChange(value)}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            label={"Select A " + GetStringNameForSite(SiteName,'COUNTY')}
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'input',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            />
                                                        )}
                                                        value={county === "ALL COUNTIES" ? "ALL " + GetStringNameForSite(SiteName,'COUNTIES').toUpperCase() : county} 
                                                                           
                                                />
                                            </div> 
                                        }
                                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                            <div className="col-span-4">
                                                <Autocomplete 
                                                    id="city"
                                                    disableClearable
                                                    size={"small"} 
                                                    options={cityList.map((option) => option.City)} 
                                                    autoComplete = {true} 
                                                    onChange={(event, value :any) => handleCityChange(value)}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            label={"Select A " + GetStringNameForSite(SiteName,'CITY')}
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'input',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            />
                                                        )}
                                                        value={city === "ALL CITIES" ? "ALL " + GetStringNameForSite(SiteName,'CITIES').toUpperCase() : city} 
                                                                           
                                                />
                                            </div>
                                        }
                                         {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                            <div className="col-span-4">
                                                <Autocomplete 
                                                    id="agency"
                                                    disableClearable
                                                    size={"small"} 
                                                    options={agencyList.map((option)=> option.Key)} 
                                                    getOptionLabel={(option: any) => handleGetDDLabel(agencyList, option, 2)}
                                                    autoComplete = {true} 
                                                    onChange={(event, value :any) => handleAgencyChange(value)}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            //label={"Select An " + GetStringNameForSite(SiteName,'CITY')}
                                                            label={"Select An Agency"}
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'input',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            />
                                                        )}
                                                        value={agency === "ALL AGENCIES" ? "ALL AGENCIES" : agency} 
                                                                           
                                                />
                                            </div>
                                        }
                                        
                                        {/* {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                            <div className="col-span-4">
                                                <FormControl className="p-float-label" sx={{ width:'100%'}}>
                                                    <Dropdown
                                                        value={agency}
                                                        onChange={(e) => {handleAgencyChange(e.value)}}
                                                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                                        resetFilterOnHide={true}
                                                        inputId={"dd-state"} options={agencyList} filter optionLabel="Value" optionValue="Key"
                                                        placeholder="Agency" className="w-full md:w-20rem"/>
                                                    <label className={"bg-white"} htmlFor="dd-state">Agency</label>
                                                </FormControl>
                                            </div>
                                        } */}
                                        <div className={(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent  cssClass="e-outline" value={startDate} onBlur={(date:any) => {compareDatesByComponents(startDate, new Date(date?.target?.value)) ? setStartDate(new Date(date?.target?.value)) : console.log('') }} onChange={(date:any) => {date.value ? setStartDate(date.value) : setStartDate(startDate)}} floatLabelType="Auto" placeholder="Recovery Start Date"/>                               

                                        </div>
                                        <div className={(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent  cssClass="e-outline" value={toDate} onBlur={(date:any) => {handleBlur(date)}} onChange={(date:any) => {date.value ? setToDate(date.value) : setToDate(toDate)}} floatLabelType="Auto" placeholder="Recovery End Date"/>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className = "border-gray-900 border-2 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Heatmap locations are plotted on the center of the municipality boundar" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">Location Data
                                        <span className="inline">
                                                <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("locationData")}>
                                                    <FileDownloadIcon  />
                                                </IconButton>                                                 
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <div className="grid grid-cols-8 gap-2 mb-2 mt-1 justify-items-center">
                                        
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '1' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '1' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','1')}>Privately Made</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '2' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '2' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','2')}>MCD</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '4' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '4' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','4')}>Stolen</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '5' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '5' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','5')}>Narco</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '6' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '6' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','6')}>Juve</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '7' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '7' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','7')}>Youth</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '8' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '8' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','8')}>NIBIN</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '3' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '3' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','3')}>All</Button></span>
                                    </div>
                                    <div className="px-2 pb-2">
                                        <ETHeatMap data={data} county={county} city={city} tab={heatMapTab} startDate={startDate} toDate={toDate} />
                                    </div>
                                </div>
                                <div className = "border-gray-900 border-2 h-54 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 Possessors with FFLs" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 Possessors with FFLs
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("PossessorsWithFFLs")}>
                                                <FileDownloadIcon  />
                                            </IconButton>  
                                        </div>
                                    </Tooltip>
                                    <Top10PossessorWithFFLs data={data}/>                            
                                </div>                                                            
                            </div>
                            <div className="col-span-3 " style={{width:'100%'}} >   
                                <div className = "border-gray-900 border-2 h-54" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Gun Recovery Numbers Statewide" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Gun Recovery Numbers Statewide
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("gunRecoveryNumbersStatewide")}>
                                                <FileDownloadIcon  />
                                            </IconButton>  
                                        </div>
                                    </Tooltip>
                                    {'gunRecoveryNumbersStatewide' in data && Array.isArray(data?.gunRecoveryNumbersStatewide) && data?.gunRecoveryNumbersStatewide?.length > 0 ?                                      
                                        <Card variant="outlined">
                                            <Box sx={{ p: 2 }}>
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Total Crime Guns Recovered</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Total Recovered')?.length > 0 ?
                                                        data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Total Recovered')[0]?.Count : '0'}
                                                    </Typography>
                                                </Stack>
                                                <Divider/>      
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Found/Turned in</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Found/Turned in')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Found/Turned in')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack>
                                                <Divider/>      
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Guns with Possessors</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Guns with Possessors')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Guns with Possessors')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack>
                                                <Divider/>           
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Individuals Arrested with more than One Crime Gun</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Arrested with more than One Crime Gun')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Arrested with more than One Crime Gun')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack>      
                                                <Divider/>           
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Possessors #</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Possessors')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Possessors')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack> 
                                                <Divider/>           
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Associates #</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Associates')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Associates')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack>
                                                <Divider/>           
                                                <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography  sx={{fontWeight:'bold'}}>Possessors/Associates #</Typography>
                                                    <Typography  sx={{fontWeight:'bold'}}>
                                                        {data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Possessors/Associates')?.length > 0 ?
                                                       data?.gunRecoveryNumbersStatewide.filter((e:any)=> e.Type == 'Possessors/Associates')[0]?.Count : '0'}
                                                       </Typography>
                                                </Stack>             
                                            </Box>
                                        </Card>                                                                       
                                        :<div className={"text-center text-3xl mt-6 mb-6"} style={{color:"red", fontWeight:500 , height:"100%"}}>No Data</div>
                                    } 
                                </div>   
                                <div className = "border-gray-900 border-2 h-54 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Recovered Gun Breakdown" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Recovered Gun Breakdown
                                        <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("recoveredGunBreakdown")}>
                                                <FileDownloadIcon  />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    {'HeatMap' in data && Array.isArray(data.HeatMap) && data.HeatMap.length > 0 ?
                                    <Card variant="outlined">
                                        <Box sx={{ p: 2 }}>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography  sx={{fontWeight:'bold'}}>Privately made/assembled from parts</Typography>
                                                <Typography  sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'PRIVATELY MADE')?.length}</Typography>
                                            </Stack>
                                            <Divider/>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography  sx={{fontWeight:'bold'}}>Equipped with a machine gun conversion</Typography>
                                                <Typography  sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'MACHINE GUN')?.length}</Typography>
                                            </Stack>
                                            <Divider/>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography  sx={{fontWeight:'bold'}}>Reported stolen</Typography>
                                                <Typography  sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'STOLEN')?.length}</Typography>
                                            </Stack>
                                            <Divider/>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography  sx={{fontWeight:'bold'}}>Narcotics related</Typography>
                                                <Typography  sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'NARCO')?.length}</Typography>
                                            </Stack>
                                            <Divider/>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography sx={{fontWeight:'bold'}}>Juvenile related</Typography>
                                                <Typography sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'JUVE')?.length}</Typography>
                                            </Stack>
                                            <Divider/>
                                            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography  sx={{fontWeight:'bold'}}>Youth related</Typography>
                                                <Typography  sx={{fontWeight:'bold'}}>{data?.HeatMap.filter((e:any)=> e.Type == 'YOUTH')?.length}</Typography>
                                            </Stack>
                                        </Box>
                                    </Card>
                                    :<div className={"text-center text-3xl mt-6 mb-6"} style={{color:"red", fontWeight:500 , height:"100%"}}>No Data</div>
                                    }
                                   
                                </div>                          
                                <div className = "border-gray-900 border-2 h-72 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 Gun Manufacture Recovered" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 Gun Manufacture Recovered
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("GunManufactureRecovered")}>
                                                <FileDownloadIcon  />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Top10ManufacturersRecovered data = {data}/>
                                </div>
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 Gun Caliber Recovered" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 Gun Caliber Recovered
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("caliberBreakdown")}>
                                                <FileDownloadIcon  />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Top10CalibersRecovered data={data} />
                                </div>                             
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 NCIC Crime Codes" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 NCIC Crime Codes
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("NCICCrimeCodes")}>
                                                <FileDownloadIcon  />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <Top10NCICCrimeCodes data={data} />
                                </div>
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Caliber Breakdown" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Weapon Breakdown By Caliber
                                            <IconButton size="small" title="Excel Export" color="inherit" onClick={()=> downloadData("caliberBreakdown")}>
                                                <FileDownloadIcon  />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <CaliberBreakdown data={data} />
                                </div>   
                                                                                
                            </div>                                                      
                        </div>
                    </div>
                </div>
                :
                <></>}
        </div>
    );
}

export default ETDashboardLayout;
import React, { useState } from 'react';
import { SiteName } from '../../services/config.service';
import { getUser, isUserPermission } from "../../services/auth.service";
import { getDarInfoResponse, GetDarInfo_Query, PersonInfo, ProfileReportPersonInfo } from "../../interfaces/getDarInfo_interface";
import { Account } from "../../interfaces/auth_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { JAOS } from "../../services/JAOS.service";
import SARSInputForm  from '../../components/tools/sars/_sarsInputForm';


function SARSForm() {
    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest') ?
                <>
                    <SARSInputForm handleFormSubmit={null} editForm={null} />
                </>
                : <></>
            }
        </div>
    )
}

export default SARSForm;
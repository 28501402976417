import React from "react";
import {
    Alert,
    Autocomplete,
    Button,
    ButtonGroup,
    Snackbar,
    TextField,
    FormControlLabel,
    Checkbox,
    Card,
    CardContent,
    Tooltip,
    RadioGroup,
    Radio,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    CardMedia, CardActions, DialogTitle, DialogContent, DialogContentText, DialogActions,
    FormHelperText
} from "@mui/material";
import {isUserPermission} from "../../../services/auth.service";
import {DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
    NJTraceObject,
    NJTraceReportingOfficerObject, NJTraceIncidentObject, NJTraceWeaponObject,
    NJTracePersonObject, NJTraceVehicleObject,
} from "../../../interfaces/Detail/NJTraceDetail.interface"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { JAOS } from "../../../services/JAOS.service";
import { GetDuplicateNJTraceWeapon, GetNJTrace, SetNJTrace } from "../../../services/account.service";
import {
    getCounty,
    getDropDownColor,
    getDropDownMake,
    getDropDownModel,
    getDropDownYear,
    getNJTraceAgencyList,
    getNJTraceCrimeCodeList,
    getLocationTypeList,
    getCountryList,
    getWeaponTypeList,
    getWeaponManufacturerList, getIdentificationTypeList, getATFStateList, getATFRolesList, getATFCaliberList,
    getATFStreetSuffix,
    getWeaponModelList,
    getWeaponCaliberList,
    getWeaponTypeFilteredList,
    getWeaponImporterList,
    getATFSex, getATFRace
} from "../../../services/getDropdown.service";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WeaponAddIcon from '@mui/icons-material/AddCircle';  //"../../../assets/Images/WeaponTrace.png";
import IncidentAddIcon from '@mui/icons-material/AddLocation';
import ReportingOfficerAddIcon from '@mui/icons-material/PersonAddAlt1';
import DeleteIcon from "@mui/icons-material/Delete";
import { base64_to_blob } from "../../../services/detail.service";
import {GetFileContentFirearm, GetFileContentFirearmNonDownload} from "../../../services/getDar.service";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UndoIcon from '@mui/icons-material/Undo';
import DownloadIcon from '@mui/icons-material/Download';
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {formatInputDate, useMatchMedia} from "../../../services/formatDate.service";
import Geocode from "react-geocode";
import {Google_API_Key, SiteName} from "../../../services/config.service";
import {GetDefaultStateForSite, GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import { content } from "pdfkit/js/page";
import { style } from "@mui/system";
import { color } from "echarts";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

Geocode.setApiKey(Google_API_Key);

interface NJTraceProps {
    handleReportSubmit: any,
    isEdit: boolean,
    trace?: any
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const NJTraceInputForm: React.FC<NJTraceProps> = ({ handleReportSubmit, isEdit = null, trace = null }) => {

    var today = new Date();
    var todayDate = today?.toISOString()?.split('T')[0];
    const isDesktopResolution = useMatchMedia('(min-width:1700px)', true)
    
    const [initialBuild, setInitialBuild] = React.useState<boolean>(false)
    const [checkEdit, setCheckEdit] = React.useState<boolean>(false)
    const [entryDateTime, setEntryDateTime] = React.useState<Date | undefined>(new Date())

    const [params, setParams] = React.useState<NJTraceObject>({})
    const [reportingOfficers, setReportingOfficers] = React.useState<NJTraceReportingOfficerObject[]>([{}])
    const [incidents, setIncidents] = React.useState<NJTraceIncidentObject[]>([{}])
    const [weapons, setWeapons] = React.useState<NJTraceWeaponObject[]>([{imgType:'Full Gun Image'}])

    const [vehicles, setVehicles] = React.useState<NJTraceVehicleObject[]>([{ VehicleCountry: 'US' }])
    const [possessors, setPossessors] = React.useState<NJTracePersonObject[]>([{ INDIV_COUNTRY:'US', INDIV_ROLE:'S', INDIV_ID1_COUNTRY:'US', INDIV_ID2_COUNTRY:'US', INDIV_TRIGGER_LOCK:'N', INDIV_CRIME_HISTORY:'N' }])
 
    const [agencyList, setAgencyList] = React.useState<any>([])
    const [NCICCrimeCodeList, setNCICCrimeCodeList] = React.useState<any>([])

    const [allCaliberList, setAllCaliberList] = React.useState<any>([])
    const [WeaponManufacturerList, setWeaponManufacturerList] = React.useState<any>([])
    const [WeaponImporterList, setWeaponImporterList] = React.useState<any>([])
    const [WeaponTypeList, setWeaponTypeList] = React.useState<any>([])
    const [CountryList, setCountryList] = React.useState<any>([])
    const [stateList, setStateList] = React.useState<any>([])
    const [LocationTypeList, setLocationTypeList] = React.useState<any>([])
    const [triggerLockList, setTriggerLockList] = React.useState<any[]>([{ID:'Y',Description:'Yes'},{ID:'N',Description:'No'} ])
    const [identificationTypeList, setIdentificationTypeList] = React.useState<any>([])
    const [vehicleMakeList, setVehicleMakeList] = React.useState<any>([])
    const [vehicleColorList, setVehicleColorList] = React.useState<any>([])
    const [streetSuffixList, setStreetSuffixList] = React.useState<any>([])
    const [rolesList, setRolesList] = React.useState<any>([])
    const [disableOriBox, setDisableOriBox] = React.useState<boolean>(true)
    const [sexList, setSexList] = React.useState<any>([])
    const [raceList, setRaceList] = React.useState<any>([])

    const [editBuild, setEditBuild] = React.useState<boolean>(false)
    const [dropdownsLoading, setDropdownsLoading] = React.useState<boolean>(true)
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const [refreshCount, setRefreshCount] = React.useState<number>(0)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogText, setDialogText] = React.useState<string>("")   
    const [optionDialog, setOptionDialog] = React.useState<string>("")   
    const [disableSave, setDisableSave] = React.useState<boolean>(false)

    
    const handleSave = async (saveAnyway:boolean=false) => {
        let p = { ...params }
        let bValid: boolean;
        let noWarnings:boolean;
        let minDate = new Date('1900-01-01').valueOf()
        let maxDate = new Date().valueOf()
        let errorList = ""
        let warningList = ""
        bValid = true;
        noWarnings = true;
        //assign and update ori number (to fix invalid ori issue when sending to atf)
        reportingOfficers[0].ORI_NUMBER = params?.main?.DEPT
        
        if (!params?.main?.DEPT || params?.main?.DEPT?.trim()?.length < 1) {
            bValid = false;
            errorList += "Please select an Agency Name.\n"
        }
        if (!params?.main?.CaseNumber || params?.main?.CaseNumber?.trim()?.length < 1) {
            bValid = false;
            errorList += "Please input a Case Number.\n"
        }
        if (!params?.main?.Crime_Code || params?.main?.Crime_Code?.trim()?.length < 1) {
            bValid = false;
            errorList += "Please select a NCIC Crime Code.\n"
        }
        

        for (let pos of reportingOfficers) {
            if (!pos?.ORI_FIRSTNAME || pos?.ORI_FIRSTNAME?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input a full Reporting Officer name (First Name).\n"
            }
            if (!pos?.ORI_LASTNAME || pos?.ORI_LASTNAME?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input a full Reporting Officer name (Last Name).\n"
            }
            //this is not a mandatory field only validate email if entered
            if(pos?.ORI_EMAIL && pos?.ORI_EMAIL?.trim()?.length > 0){
                let emails:string[] = pos?.ORI_EMAIL?.trim()?.split(/[;,]/);
                let emailAddressValidation = validateEmails(pos?.ORI_EMAIL.trim())
                if(emailAddressValidation?.length !== emails?.length){
                    bValid = false;
                    errorList += "Please input a valid Reporting Officer email address(You can enter multiple emails separated by a comma or semicolon).\n"
                }
            }
        }

        for (let inc of incidents) {
            if (!inc?.REC_DATE) {
                bValid = false;
                errorList += "Please input a proper Incident Recovery Date (MM/DD/YYYY).\n"
            }
            else{
                let vDate = new Date(inc?.REC_DATE).valueOf()
                if((vDate < minDate || vDate > maxDate)){
                    bValid = false;
                    errorList += "Please input a valid Incident Recovery Date (MM/DD/YYYY).\n"
                }
           }
            if (!inc?.REC_STREET_NAME || inc?.REC_STREET_NAME?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input an Incident Street Name.\n"
            }
            if (!inc?.REC_CITY || inc?.REC_CITY?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input an Incident City.\n"
            }
            if (!inc?.REC_ZIP || inc?.REC_ZIP?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please enter an Incident zip code.\n"
            }
            //Validate zip code accept 5 digit or 5 digit + '-' + 4 digit (mandatory for Incident)
            if(inc?.REC_ZIP && inc?.REC_ZIP?.trim().length > 0){
               if(!inc?.REC_ZIP?.trim()?.match(/^\d{5}$/) && !inc?.REC_ZIP?.trim()?.match(/^\d{5}-\d{4}$/) && !inc?.REC_ZIP?.trim()?.match(/^\d{9}$/)){
                    bValid = false;
                    errorList += "Please enter a valid Incident zip code (Ex: 5 digits, 9 digits or 9 digits separated by hyphen)."
               }
            }
            if (!inc?.REC_COUNTY || inc?.REC_COUNTY?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please select an Incident County.\n"
            }
            if (!inc?.REC_Location_Type || inc?.REC_Location_Type?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input an Incident Location.\n"
            }
           
        }
        
        let wpns = [...weapons]
        for (let wpn of wpns.filter((e:any) => !e.Deleted)) {
            if (!wpn?.RECOVERY_DATE) {
                bValid = false;
                errorList += "Please input a proper Weapon Recovery Date (MM/DD/YYYY HH:MM:SS).\n"
            }
            else{
                let vDate = new Date(wpn?.RECOVERY_DATE).valueOf()
                let vTimeHours = new Date(wpn?.RECOVERY_DATE).getHours()
                let vTimeMinutes = new Date(wpn?.RECOVERY_DATE).getMinutes()
                if((vDate < minDate || vDate > maxDate)){
                    bValid = false;
                    errorList += "Please input a proper Weapon Recovery Date (MM/DD/YYYY HH:MM:SS).\n"
                }
                //when selecting a date component sets time to midnight so warning telling user they may have not enter the right time
                if(vTimeHours == 0 && vTimeMinutes == 0){
                    warningList+="Time was set to midnight. If this is not correct please input a correct Weapon Recovery Time (HH:MM).\n"
                    noWarnings = false;
                }
           }
           //if weapon type is machine gun conversion device with no weapon disable mandatory fields for weapon 
            if(wpn.W_TYPE !== "MX"){
                if (!wpn?.CALIBER || wpn?.CALIBER?.trim()?.length < 1) {
                    bValid = false;
                    errorList += "Please select a Weapon Caliber for all weapons.\n"
                }
                if (!wpn?.MANUFACTURER || wpn?.MANUFACTURER?.trim()?.length < 1) {
                    bValid = false;
                    errorList += "Please select a Weapon Manufacturer for all weapons.\n"
                }
                //if defaced/obliterated gun checkbox is null or N (not checked) serial number is required
                if(wpn?.DEFACED_GUN === 'N' && wpn?.Privately_made_firearm === 'N'){
                    if (!wpn?.SERIAL_NUMBER || wpn?.SERIAL_NUMBER?.trim()?.length < 1) {
                        bValid = false;
                        errorList += "Please input a Weapon Serial Number for all weapons.\n"
                    }
                }   
                if (!wpn?.MODEL || wpn?.MODEL?.trim()?.length < 1) {
                    bValid = false;
                    errorList += "Please select a Weapon Model for all weapons.\n"
                }
                if (!wpn?.W_TYPE || wpn?.W_TYPE?.trim()?.length < 1) {
                    bValid = false;
                    errorList += "Please select a Weapon Type for all weapons.\n"
                }
                if(wpn?.Reported_Stolen === "Y"){
                    if(!wpn?.Stolen_Date){
                        bValid = false;
                        errorList += "Please input a proper Weapon Stolen Date (MM/DD/YYYY).\n"
                    }
                    if(!wpn?.Stolen_AgencyName){
                        bValid = false;
                        errorList += "Please input Weapon Stolen Agency Name for all stolen weapons.\n"
                    }
                    if(!wpn?.Stolen_AgencyORI){
                        bValid = false;
                        errorList += "Please input Weapon Stolen Agency ORI for all stolen weapons.\n"
                    }
                }
            }
            if(wpn?.MANUFACTURER && wpn?.ORIGIN !== "US"){
                if(!wpn?.IMPORTER_NAME || wpn?.IMPORTER_NAME.trim()?.length < 1){
                    bValid = false;
                    errorList += "Please select a Weapon Importer for all weapons.\n"
                }               
            }
           
            
            wpn.Privately_made_firearm = !("Privately_made_firearm" in wpn) ? 'N' : wpn.Privately_made_firearm
            wpn.CONVERSION_DEVICE = !("CONVERSION_DEVICE" in wpn) ? 'N' : wpn.CONVERSION_DEVICE
            wpn.Not_Recovered = !("Not_Recovered" in wpn) ? 'N' : wpn.Not_Recovered
            wpn.VaultReview_TraceStudy = !("VaultReview_TraceStudy" in wpn) ? 'N' : wpn.VaultReview_TraceStudy
            wpn.Reported_Stolen = !("Reported_Stolen" in wpn) ? 'N' : wpn.Reported_Stolen
            //Using SEAR MODIFIED AS NARCOTICS RELATED SINCE SEAR MODIFIED WAS COMBINED WITH CONVERSION DEVICE CHECKBOX
            wpn.NARCOTICS_RELATED = !("NARCOTICS_RELATED" in wpn) ? 'N' : wpn.NARCOTICS_RELATED
            wpn.DEFACED_GUN = !("DEFACED_GUN" in wpn) ? 'N' : wpn.DEFACED_GUN
            wpn.FileOrImages = wpn.ExistingFiles ? wpn.ExistingFiles.concat(wpn.AddedFiles ? wpn.AddedFiles : []) : wpn.AddedFiles ? wpn.AddedFiles : []

        }
    
        let possessor = [...possessors]
        if(params?.main?.Crime_Code === '0098' || !(weapons.filter((weapon:any)=> weapon?.Agency_Possession_How !== 'Found' && weapon?.Agency_Possession_How !== 'Turned in' && weapon?.Agency_Possession_How !== 'Buy-back').length > 0)){
            //If found firearm is selected or all weapons have found, turned in, buy-back is selected delete exisiting persons
            if(possessor.length > 0){
                for(let pos of possessor){
                    if(pos?.FTS_ID && pos?.FTS_ID.length > 0){
                        pos.Deleted = true
                    }
                    else{
                        //possessor = []
                       let index= possessor?.findIndex((e:any) => e === pos)
                       possessor?.splice(index, 1)
                    }                     
                }
            }
            else{
                possessor = []
            }
        }
        else {
            if(possessor.filter((e:any) => e.INDIV_ROLE === 'S' && !e.Deleted).length == 0){
                bValid= false;
                errorList += "Please select the correct role all People (Must have 1 Possessor).\n"
            }
        }
        if (possessor.filter((e:any) => e.INDIV_ROLE === 'S' && !e.Deleted).length > 1){
            bValid = false;
            errorList += "Please select a role for all People (1 Possessor Maximum).\n"
        }
       
        for (let pos of possessor.filter((e:any) => !e.Deleted)) {                 
            if (!pos?.INDIV_ROLE || pos?.INDIV_ROLE?.length < 1) {
                bValid = false;
                errorList += "Please select a role for all People (1 Possessor Maximum).\n"
            }
            if (!pos?.INDIV_FIRSTNAME || pos?.INDIV_FIRSTNAME?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input a full name for all People (First Name).\n"
            }
            if (!pos?.INDIV_LASTNAME || pos?.INDIV_LASTNAME?.trim()?.length < 1) {
                bValid = false;
                errorList += "Please input a full name for all People (Last Name).\n"
            }
            if (!pos?.INDIV_DOB ) {
                bValid = false;
                errorList += "Please input a proper DOB for all People (MM/DD/YYYY).\n"
                pos.JUVENILE_RELATED = 'N'
                pos.YOUTH_RELATED = 'N'
            }   
            else{
                let vDate = new Date(pos?.INDIV_DOB).valueOf()
                if((vDate < minDate || vDate > maxDate)){
                    bValid = false;
                    errorList += "Please input a valid DOB for all People (MM/DD/YYYY).\n"
                    pos.JUVENILE_RELATED = 'N'
                    pos.YOUTH_RELATED = 'N'
                }            
            }
            //Validate zip code accept 5 digit or 5 digit + '-' + 4 digit (only when entered)
            if(pos?.INDIV_ZIP && pos?.INDIV_ZIP?.trim().length > 0){
                if(!pos?.INDIV_ZIP.match(/^\d{5}$/) && !pos?.INDIV_ZIP.match(/^\d{5}-\d{4}$/) && !pos?.INDIV_ZIP.match(/^\d{9}$/)){
                     bValid = false;
                     errorList += "Please enter a valid zip code for all People(Ex: 5 digits, 9 digits or 9 digits separated by hyphen)."
                }
            }                    
            pos.JUVENILE_RELATED = !("JUVENILE_RELATED" in pos) ? 'N' : pos.JUVENILE_RELATED
            pos.YOUTH_RELATED = !("YOUTH_RELATED" in pos) ? 'N' : pos.YOUTH_RELATED
            params.main?.main_confidential && params.main?.main_confidential == 'Y' ? pos.INDIV_CONFIDENTIAL = 'Y' : pos.INDIV_CONFIDENTIAL = 'N'
            

        }
         //check duplicate weapons (only if weapon has make, type, caliber, model, and serial number)
         p.weapons = weapons.filter((w:any) => w.MANUFACTURER?.trim()?.length > 0 && w.W_TYPE?.trim()?.length > 0 && w.CALIBER?.trim()?.length > 0 && w.MODEL?.trim()?.length > 0 && w.SERIAL_NUMBER?.trim()?.length > 0)
         if(p.weapons?.length > 0){
            let dups = await GetDuplicateNJTraceWeapon(p)
            if(dups && dups.length > 0){
               for(let dup of dups){
                   warningList+="Duplicate Weapon: The combination entered has already been saved previously "
                   + (dup?.Main?.CaseNumber && dup?.Main?.CaseNumber != undefined  ? "under case # " + dup?.Main?.CaseNumber : "") 
                   + (dup?.Main?.OFFICE && dup?.Main?.OFFICE != undefined ? " by " + dup?.Main?.OFFICE +"." : "") + ParseDupWeaponRes(dup?.Weapons) + ".\n"                 
                   noWarnings = false;
                }
            }
         }
        
        //Save if user enters trace without errors and warning OR if user enter trace without errors and clicks saves anyway after reviewing warnings
        if ((bValid && noWarnings) || (bValid && saveAnyway)) {
            //get long and lat from api to store
                 for(let incident of incidents){  
                    if(incident.REC_STREET_NUMBER !== incident.PREV_REC_STREET_NUMBER || incident.REC_STREET_NAME !== incident.PREV_REC_STREET_NAME || incident.REC_STREET_SUFFIX !== incident.PREV_REC_STREET_SUFFIX || incident.REC_COUNTY !== incident.PREV_REC_COUNTY || incident.REC_STATE !== incident.PREV_REC_STATE){
                        let streetNum= incident.REC_STREET_NUMBER ? incident.REC_STREET_NUMBER : ""
                        let state=incident.REC_STATE ? incident.REC_STATE : ""
                        let location= streetNum + " " + incident.REC_STREET_NAME + " " + incident?.REC_STREET_SUFFIX  
                        let response = await Geocode.fromAddress(location + ',' + incident?.REC_COUNTY + ',' + incident?.REC_CITY + ',' + state)
                        const {lat, lng} = response.results[0].geometry.location
                        incident.Latitude = lat.toString()
                        incident.Longitude = lng.toString()
                    }
                } 
                for(let wpn of wpns){
                    //handle date time issue where it changes time
                    if(wpn?.RECOVERY_DATE){
                        wpn.RECOVERY_DATE = wpn?.RECOVERY_DATE?.toLocaleString()
                    }
                    //remove country from manufacturer before saving
                    if(wpn?.MANUFACTURER){
                        wpn.MANUFACTURER = wpn?.MANUFACTURER && wpn?.MANUFACTURER?.trim()?.length !== 0 ? wpn.MANUFACTURER?.split(" ")[0] : ''
                    }
                }  
            p.reportingOfficers = reportingOfficers;
            p.incidents = incidents;
            p.weapons = wpns;
            p.vehicles = vehicles;
            p.persons = possessor;
            if(p.main){
                p.main.TraceType = GetDefaultStateForSite(SiteName)
            }

            let r = await SetNJTrace(p);
            if (r?.IsValid) {
                //auto submit changes
                // let NJTraceSaveString ='NJTrace Entry ' + p?.main?.CaseNumber + ' has been submitted successfully.\n'
                
                //  let atfResultsString="" 
                // if(r?.ATFResults && r?.ATFResults.length > 0){
                //     for(let result of r?.ATFResults){
                //         if(result && result?.traces && result?.traces?.length > 0){
                //             for(let trace of result?.traces){
                //                 if(trace?.record_number)
                //                     atfResultsString+= 'Record Number : ' + trace?.record_number + "."
                //                 if(trace?.trace_id)
                //                     atfResultsString+= 'ATF ID : ' + trace?.trace_id + "."
                //                 if(trace?.status)
                //                     atfResultsString+= 'Status : ' + trace?.status + "."
                //                 if(trace?.messages && trace?.messages?.warnings?.length > 0){
                //                     for(let warning of trace?.messages?.warnings){
                //                         if(warning?.code)
                //                             atfResultsString+= 'Warning Code : ' + warning?.code + "."
                //                         if(warning?.description)
                //                             atfResultsString+= 'Warning Description : ' + warning?.description + "."
                                        
                //                     }
                //                 }
                //                 if(trace?.messages && trace?.messages?.errors?.length > 0){
                //                     for(let error of trace?.messages?.errors){
                //                         console.log(error)
                //                         if(error?.code)
                //                             atfResultsString+= 'Error Code : ' + error?.code + "."
                //                         if(error?.description)
                //                             atfResultsString+= 'Error Description : ' + error?.description + "."
                                        
                //                     }
                //                 }
                //             }
                //         }                        
                //     }
                   

                // }else{
                //     atfResultsString+= "No Results/Unable to connect to ATF."
                // }                               
                setOpenDialog(true)
                setOptionDialog('Save')
                setDialogText('NJTrace Entry ' + p?.main?.CaseNumber + ' has been submitted successfully')
                //setDialogText(NJTraceSaveString + "ATF Results. "+ atfResultsString )
            } 
           
            else {
                setOpenDialog(true)
                setOptionDialog('Error')
                setDialogText('Something went wrong. NJTrace has not been saved correctly.')
            }
            setParams(p)          
        }
         //allow submitting if only warning
        else if(bValid && !noWarnings && !saveAnyway){
            setOpenDialog(true)
            setOptionDialog('Warning')
            setDialogText(warningList)

        }
        else {
            setOpenDialog(true)
            setOptionDialog('Error')
            setDialogText(errorList)
        }
    }

    const handleCancel = () => {

        if(params?.main?.CaseNumber || params?.main?.CRIME || params?.main?.DEPT){
            setOpenDialog(true)
            setOptionDialog('Cancel')
            setDialogText('Any unsaved changes will be lost.')
        }
        else{

            if(window.location.href.includes('AddNJTrace')){
                window.location.href = '/tools/trace'
            }
            else if(window.location.href.includes('SharedNJTrace')){
                 window.location.href = '/login'
            }
            else{
                handleReportSubmit('Cancel')
            }
        }
       
    }

    const ParseDupWeaponRes = (weapon:string) =>{
        let res = ""
        if(weapon && weapon.length > 0){
            try{
                let wpnJson = JSON.parse(weapon)
                if(wpnJson){
                    res += wpnJson?.MANUFACTURER_NAME != undefined && wpnJson?.MANUFACTURER_NAME ? "Make: "+ wpnJson?.MANUFACTURER_NAME + "." : ""
                    res += wpnJson?.W_TYPE_DESC != undefined && wpnJson?.W_TYPE_DESC ?  "Type: " + wpnJson?.W_TYPE_DESC + "." : ""
                    res += wpnJson?.CALIBER != undefined && wpnJson?.CALIBER ? "Caliber: " + wpnJson?.CALIBER + "." : ""
                    res += wpnJson?.MODEL != undefined && wpnJson?.MODEL ? "Model: "+wpnJson?.MODEL + "." : ""
                    res += wpnJson?.SERIAL_NUMBER != undefined && wpnJson?.SERIAL_NUMBER ? "Serial Number: " + wpnJson?.SERIAL_NUMBER + "." : ""

                }

            }
            catch(e){
                res = ""
                console.log(e)
            }
        }

        return res

    }

    const handleValueChange = async (field: string, value: any, index: number = 0) => {
        //SET UP OBJECT IF IT DOESNT EXIST   
        let p = { ...params }
        let r = [...reportingOfficers]
        let i = [...incidents]
        let w = [...weapons]
        let v = [...vehicles]
        let pos = [...possessors]
        let minDate = new Date('1900-01-01').valueOf()
        let maxDate = new Date().valueOf()
        if (!p.main) {
            p.main = {}
        }
        
        switch (field) {
            //MAIN CASES
            case 'DEPT':
                p.main.DEPT = value
                break;
            case 'Agency ORI':
                p.main.DEPT = value;
                break;
            case 'NCIC Crime Code':
                if (!editBuild) {
                    p.main.Crime_Code = value
                    /* Not sure what youre trying to do here.
                        p.main.Crime_Code = value?.toUpperCase();
                        let ncic_cc = NCICCrimeCodeList.find((ncicCrimeCode: any) => ncicCrimeCode.Description.trim() === value?.toUpperCase().trim())
                        if(ncic_cc)
                            p.main.Crime_Code = ncic_cc ? ncic_cc?.ID : ''
                    */
                }
                break;
            case 'Case #':
                p.main.CaseNumber = value
                break;
            case 'Remark':
                p.main.REMARK = value
                break;
            case 'Confidential':
                p.main.main_confidential = value ? 'Y' : 'N'
                break;

            //REPORTING OFFICER
            case 'Badge #':
                r[index].ORI_BADGE_NUMBER = value
                break;
            case 'First Name':
                r[index].ORI_FIRSTNAME = value
                break;
            case 'MI':
                r[index].ORI_MIDDLENAME = value
                break;
            case 'LastName':
                r[index].ORI_LASTNAME = value
                break;

            case 'Email':
                r[index].ORI_EMAIL = value
                break;

            //INCIDENT
            case 'Recovery Date':   //BAC-496: Changed to Recovery Date where time is not required
                i[index].REC_DATE = value
                break;
            case 'INC Street #':
                i[index].REC_STREET_NUMBER = value
                break;
            case 'INC Street Name':
                i[index].REC_STREET_NAME = value
                break;
            case 'INC Type':
                i[index].REC_STREET_SUFFIX = value
                break;
            case 'INC Apt #':
                i[index].REC_APT_NUMBER = value
                break;
            case 'INC City':
                i[index].REC_CITY = value
                break;
            case 'INC State':
                i[index].REC_STATE = value
                if(value !== '') {
                    i[index].REC_COUNTY = ''
                    i[index].CountyList = await getCounty(value ?? GetDefaultStateForSite(SiteName))
                }
                break;
            case 'INC Zip':
                i[index].REC_ZIP = value
                break;
            case 'INC County':
                i[index].REC_COUNTY = value
                break;
            case 'INC Recovery Location Type':
                i[index].REC_Location_Type = value
                break;

            // WEAPON
            case 'Weapon Caliber':
                if(!editBuild && value){
                    w[index].MODEL = ''
                    w[index].WEAPON_MODEL_LIST=[]
                }
                else if(!editBuild && !value){
                    w[index].CALIBER = ''
                    w[index].MODEL = ''
                    w[index].WEAPON_MODEL_LIST=[]
                    break
                }
                w[index].CALIBER = value.ID ? value.ID : ''

                if(w[index].W_TYPE?.length !== 0 && w[index].MANUFACTURER?.length !== 0 && w[index].CALIBER?.length !== 0){
                    w[index].WEAPON_MODEL_LIST = await handleDDModel(w[index].MANUFACTURER?.split(' ')[0].trim(),w[index].W_TYPE, w[index].ORIGIN, w[index].CALIBER)
                    
                }
                break;
            case 'Weapon Make':
                let make = ""
                let country = ""
                w[index].MANUFACTURER = value.ID ? value.ID : ''
                w[index].MANUFACTURER_NAME = value.Description ? value.Description : ''
                w[index].ORIGIN = value.Country ? value.Country : ''                
                make = w[index].MANUFACTURER ?? ''           
                country = w[index].ORIGIN  ?? ''
                
                //clear drop down 
                if(!editBuild){
                    w[index].W_TYPE = ''
                    w[index].CALIBER = ''
                    w[index].MODEL = ''
                    w[index].WEAPON_TYPE_LIST = []
                    w[index].WEAPON_CALIBER_LIST = []
                    w[index].WEAPON_MODEL_LIST = []
                    w[index].IMPORTER_NAME = ''
                }
                if(make && make.length !== 0 && country && country.length !==0){
                    make = make.split(' ')[0]
                    w[index].WEAPON_TYPE_LIST = await getWeaponTypeFilteredList(make.trim(), country)
                }
                
                break;
            case 'Weapon Model':
                if(value && value?.Description){
                    w[index].MODEL = value.Description
                }
                else {
                    w[index].MODEL = value
                }

                //w[index].MODEL = value
                break;
            case 'Serial #':
                w[index].SERIAL_NUMBER = value
                break;
            case 'Weapon Importer':
                w[index].IMPORTER_NAME = value
                break;
            case 'Weapon Type':
                //clear cascading drop down when value changes
                if(!editBuild && value){
                    w[index].CALIBER =''
                    w[index].WEAPON_CALIBER_LIST=[]
                    w[index].MODEL = ''
                    w[index].WEAPON_MODEL_LIST = []                  
                }
                else if (!editBuild && !value){
                    w[index].W_TYPE = ''
                    w[index].CALIBER =''
                    w[index].WEAPON_CALIBER_LIST=[]
                    w[index].MODEL = ''
                    w[index].WEAPON_MODEL_LIST = []  
                    break
                }

                w[index].W_TYPE = value.ID  ? value.ID : ''                
                //if weapon type is without a firearm disable all fields accordingly  
                if(!editBuild && w[index].W_TYPE === "MX"){
                    w[index].MODEL = ''
                    w[index].SERIAL_NUMBER = ''
                    w[index].Reported_Stolen = 'N'
                    w[index].Stolen_Date = ''
                    w[index].Stolen_AgencyORI = ''
                    w[index].Stolen_AgencyName = ''
                    w[index].Privately_made_firearm ='N'
                    w[index].CONVERSION_DEVICE = 'N'
                    w[index].Not_Recovered = 'N'
                    w[index].VaultReview_TraceStudy = 'N'
                    w[index].DEFACED_GUN = 'N'
                }                        
                if(w[index].MANUFACTURER && w[index].MANUFACTURER?.length !== 0 && w[index].W_TYPE && w[index].W_TYPE?.length !==0){
                    w[index].WEAPON_CALIBER_LIST= await handleDDCaliber(w[index].MANUFACTURER?.split(' ')[0].trim(), w[index].W_TYPE , w[index].ORIGIN)
                    if(w[index].W_TYPE === "MX" && w[index].WEAPON_CALIBER_LIST &&  w[index].WEAPON_CALIBER_LIST[0].Description === "ZZ"){
                        w[index].CALIBER = w[index].WEAPON_CALIBER_LIST[0].Description
                    }
                }
                
                break;
            case 'Recovery DateTime':
                w[index].RECOVERY_DATE = value
                break;
            case 'Weapon Privately Made':
                w[index].Privately_made_firearm = value ? 'Y' : 'N'
                w[index].SERIAL_NUMBER = value ? '' : w[index].SERIAL_NUMBER
                break;
                case 'Weapon Conversion Device':
                    w[index].CONVERSION_DEVICE = value ? 'Y' : 'N'
                    break;
            case 'Weapon Agency Possession How':
                w[index].Agency_Possession_How = value
                break;
            case 'Weapon Not Recovered':
                w[index].Not_Recovered = value ? 'Y' : 'N'
                break;
            case 'Weapon VaultReview TraceStudy':
                w[index].VaultReview_TraceStudy = value ? 'Y' : 'N'
                break;
            case 'Weapon Stolen':
                w[index].Reported_Stolen = value ? 'Y' : 'N'
                if(!value){
                    w[index].Stolen_AgencyName = ''
                    w[index].Stolen_AgencyORI = ''
                    w[index].Stolen_Date = ''
                }
                break;
            case 'Weapon Stolen AgencyName':
                w[index].Stolen_AgencyName = value
                break;
            case 'Weapon Stolen AgencyORI':
                w[index].Stolen_AgencyORI = value
                break;
            case 'Weapon Stolen Date':
                w[index].Stolen_Date = value
                break;
            case 'Weapon Image Type':
                w[index].imgType = value
                break;
            case 'Narcotics Related':
                w[index].NARCOTICS_RELATED = value ? 'Y' : 'N'
                break;
            case 'Defaced Gun':
                w[index].DEFACED_GUN = value ? 'Y' : 'N'
                break;
            case 'Additional Markings':
                w[index].Additional_Markings = value
                break;

            //VEHICLE DETAILS
            case 'Vehicle Make':
                v[index].VehicleMake = value
                if (vehicleMakeList && vehicleMakeList.length > 0 && vehicleMakeList.filter((e: any) => e.Key === value).length > 0) {
                    v[index].MODEL_LIST = await getDropDownModel({
                        bodyList: [],
                        colorList: [],
                        modelList: [],
                        trimList: [],
                        yearList: [],
                        makeList: [{ key: value, value: value }]
                    });
                }
                if (!editBuild && v[index].VehicleMake?.length === 0) {
                    v[index].VehicleModel = ""
                    v[index].VehicleYear = ""
                }
                break;
            case 'Vehicle Model':
                v[index].VehicleModel = value
                if (v[index].MODEL_LIST && v[index].MODEL_LIST.length > 0 && v[index].MODEL_LIST.filter((e: any) => e.Key === value).length > 0) {
                    v[index].YEAR_LIST = await getDropDownYear({
                        bodyList: [],
                        colorList: [],
                        makeList: [{ key: v[index].VehicleMake, value: v[index].VehicleMake }],
                        modelList: [{ key: value, value: value }],
                        trimList: [],
                        yearList: []
                    });
                }
                if (!editBuild && v[index].VehicleModel?.length === 0) {
                    v[index].VehicleYear = ""
                }
                break;
            case 'Year':
                v[index].VehicleYear = value
                break;
            case 'Color':
                v[index].VehicleColor = value
                break;
            case 'Plate Number':
                v[index].PlateNum = value
                break;
            case 'Plate State':
                v[index].PlateSt = value
                break;
            case 'Vehicle Country':
                v[index].VehicleCountry = value
                break;

            // PERSON
            case 'POS Role':
                pos[index].INDIV_ROLE = value              
                break;
            case 'POS First Name':
                pos[index].INDIV_FIRSTNAME = value
                break;
            case 'POS Middle Name':
                pos[index].INDIV_MIDDLENAME = value
                break;
            case 'POS Last Name':
                pos[index].INDIV_LASTNAME = value
                break;
            case 'POS DOB':
                pos[index].INDIV_DOB = value
                let vDate = new Date(value).valueOf()
                if(vDate > minDate && vDate < maxDate){                    
                    //format yyyy/mm/dd date this way so that it doesn't show incorrect date
                    let personBirthDate = new Date (value?.replace(/-/g, '\/'))                   
                    let age = getAgeFromDOB(personBirthDate)
                    if(age >=18 && age <=24){
                        pos[index].YOUTH_RELATED = 'Y'
                        pos[index].JUVENILE_RELATED = 'N'
                    }
                    else if(age < 18){
                        pos[index].JUVENILE_RELATED = 'Y'
                        pos[index].YOUTH_RELATED = 'N'
                    }
                    else{
                        pos[index].YOUTH_RELATED = 'N'
                        pos[index].JUVENILE_RELATED = 'N'
                    }
                }
                break;
            case 'POS Sex':
                pos[index].INDIV_SEX = value
                break;
            case 'POS Race':
                pos[index].INDIV_RACE = value
                break;
            case 'POS Street #':
                pos[index].INDIV_STREET_NUMBER = value
                break;
            case 'POS Street Name':
                pos[index].INDIV_STREET_NAME = value
                break;
            case 'POS Type':
                pos[index].INDIV_STREET_SUFFIX = value
                break;
            case 'POS Apt #':
                pos[index].INDIV_APT_NUMBER = value
                break;
            case 'POS City':
                pos[index].INDIV_CITY = value
                break;
            case 'POS State':
                pos[index].INDIV_STATE = value
                if(value !== '') {
                    pos[index].INDIV_COUNTY = ''
                    pos[index].CountyList = await getCounty(value ?? GetDefaultStateForSite(SiteName))
                }
                break;
            case 'POS Zip':
                pos[index].INDIV_ZIP = value
                break;
            case 'POS County':
                pos[index].INDIV_COUNTY = value
                break;
            case 'POS Country':
                pos[index].INDIV_COUNTRY = value
                break;
            case 'POS Gang Name':
                pos[index].GANG_NAME = value
                break;
            case 'POS Trigger Lock':
                pos[index].INDIV_TRIGGER_LOCK = value
                break;
            case 'POS Crime History':
                pos[index].INDIV_CRIME_HISTORY = value
                break;
            case 'POS FBI':
                pos[index].FBI = value
                break;
            case 'POS SBI':
                pos[index].SBINo = value
                break;
            case 'POS DailyCrimeGun':
                pos[index].DailyCrimeGun = value
                break;
            case 'POS Arrests':
                pos[index].Arrests = value
                break;
            case 'POS Felonys':
                pos[index].Felonys = value
                break;
            case 'POS PriorGun':
                pos[index].PriorGun = value
                break;
            case 'POS CRUMB':
                pos[index].CRUMB = value
                break;
            case 'POS POPHITS':
                pos[index].POPHITS = value
                break;
            case 'POS NOTES':
                pos[index].NOTES = value
                break;
            case 'POS ID1 Country':
                pos[index].INDIV_ID1_COUNTRY = value
                if(value !== '') {
                    pos[index].INDIV_ID1_STATE = ''
                    pos[index].ID1StateList = await getATFStateList(value ?? 'US')
                }
                break;
            case 'POS ID1 State':
                pos[index].INDIV_ID1_STATE = value
                break;
            case 'POS ID1 Type':
                pos[index].INDIV_ID1_TYPE = value
                break;
            case 'POS ID1 Number':
                pos[index].INDIV_ID1_NUMBER = value
                break;
            case 'POS ID2 Country':
                pos[index].INDIV_ID2_COUNTRY = value
                if(value !== '') {
                    pos[index].INDIV_ID2_STATE = ''
                    pos[index].ID2StateList = await getATFStateList(value ?? 'US')
                }
                break;
            case 'POS ID2 State':
                pos[index].INDIV_ID2_STATE = value
                break;
            case 'POS ID2 Type':
                pos[index].INDIV_ID2_TYPE = value
                break;
            case 'POS ID2 Number':
                pos[index].INDIV_ID2_NUMBER = value
                break;
            case 'POS Stolen NCIC Inquiry':
                pos[index].STOLEN_NCIC_INQUIRY = value ? 'Y' : 'N'
                break;
            case 'POS Narcotics Related':
                pos[index].NARCOTICS_RELATED = value ? 'Y' : 'N'
                break;
            case 'POS Juvenile Related':
                pos[index].JUVENILE_RELATED = value ? 'Y' : 'N'
                break;
            case 'POS Youth Related':
                pos[index].YOUTH_RELATED = value ? 'Y' : 'N'
                break;
        }
        setParams(p)
        setReportingOfficers(r)
        setIncidents(i)
        setWeapons(w)
        setVehicles(v)
        setPossessors(pos)
    }
    
    const getAgeFromDOB = (personBirthDate : any)=>{
        var today = new Date();
        var birthDate = new Date(personBirthDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age
    }

    const handleAddPossessor = async () => {
        var p = [...possessors]
        let cList = await getCounty(GetDefaultStateForSite(SiteName))
        p.push({ INDIV_COUNTRY:'US', INDIV_ROLE:'A', INDIV_ID1_COUNTRY:'US', INDIV_ID2_COUNTRY:'US', INDIV_TRIGGER_LOCK:'N', INDIV_CRIME_HISTORY:'N',ID1StateList: stateList, ID2StateList: stateList, CountyList: cList})
        setPossessors(p)
    }

    const handleRemovePossessor = (index: number) => {
        var p = [...possessors]
        if ((possessors.filter((e:any) => !e.Deleted).length - 1) != 0) {
            if(!p[index].ID)
            {
                p.splice(index, 1)
            }
            else {
                p[index].Deleted = true
            }
            setPossessors(p)
        }
    }

    const handleAddWeapon = () => {
        var p = [...weapons]
        p.push({imgType:'Full Gun Image'})
        setWeapons(p)
    }

    const handleRemoveWeapon = (index: number) => {
        var p = [...weapons]
        if ((weapons.filter((e:any) => !e.Deleted).length - 1) != 0) {
            if(!p[index].ID)
            {
                p.splice(index, 1)
            }
            else {
                p[index].Deleted = true
            }
            setWeapons(p)
        }
    }

    const handleAddVehicle = () => {
        var p = [...vehicles]
        p.push({ VehicleCountry: 'US', PlateStateList:stateList })
        setVehicles(p)
    }

    const handleRemoveVehicle = (index: number) => {
        var v = [...vehicles]
        if ((v.filter((e:any) => !e.Deleted).length - 1) != 0) {
            if(!v[index].ID)
            {
                v.splice(index, 1)
            }
            else {
                v[index].Deleted = true
            }
            setVehicles(v)
        }
    }

    const handleAddIncident = async () => {
        var p = [...incidents]
        let c = await getCounty(GetDefaultStateForSite(SiteName))
        p.push({CountyList:c})
        setIncidents(p)
    }

    const handleRemoveIncident = (index: number) => {
        var p = [...incidents]
        if ((p.filter((e:any) => !e.Deleted).length - 1) != 0) {
            if(!p[index].ID)
            {
                p.splice(index, 1)
            }
            else {
                p[index].Deleted = true
            }
            setIncidents(p)
        }
    }

    const handleAddReportingOfficer = () => {
        var p = [...reportingOfficers]
        p.push({})
        setReportingOfficers(p)
    }

    const handleRemoveReportingOfficer = (index: number) => {
        var p = [...reportingOfficers]
        if ((p.filter((e:any) => !e.Deleted).length - 1) != 0) {
            if(!p[index].ID)
            {
                p.splice(index, 1)
            }
            else {
                p[index].Deleted = true
            }
            setReportingOfficers(p)
        }
    }

    React.useEffect(() => {
        if (!initialBuild) {
            setInitialBuild(true)
            handleDropdowns()
        }

    }, [trace])

    React.useEffect(() => {
        if (!checkEdit) {
            if (trace && trace?.length > 0 && agencyList?.length > 0 && WeaponManufacturerList?.length > 0 && WeaponImporterList?.length > 0 
                && CountryList?.length > 0 && vehicleColorList?.length > 0 && vehicleMakeList?.length > 0 
                && NCICCrimeCodeList?.length > 0 && LocationTypeList?.length > 0 && identificationTypeList?.length > 0
                && stateList?.length > 0 && rolesList?.length > 0) {
                setCheckEdit(true)
                handleEditData()
            }
        }

    }, [agencyList, allCaliberList, WeaponManufacturerList, WeaponImporterList, CountryList,
        vehicleColorList, vehicleMakeList, NCICCrimeCodeList, LocationTypeList,
        identificationTypeList,stateList, rolesList])

    const handleDropdowns = async () => {
        setAgencyList([])
        //setAllCaliberList([])
        setWeaponManufacturerList([])
        setWeaponImporterList([])
        //setWeaponTypeList([])
        setCountryList([])
        setVehicleMakeList([])
        setVehicleColorList([])
        setNCICCrimeCodeList([])
        setLocationTypeList([])
        setIdentificationTypeList([])
        setStreetSuffixList([])
        setStateList([])
        setRolesList([])
        setSexList([])
        setRaceList([])

        var agency = await getNJTraceAgencyList()
        var ncic_crime_code_list = await getNJTraceCrimeCodeList()
        var inc_rec_loc_type_list = await getLocationTypeList()
        //var caliber = await getATFCaliberList()
        var weapon_manufacturer_list = await getWeaponManufacturerList()
        var weapon_importer_list = await getWeaponImporterList()
        //var weapon_type_list = await getWeaponTypeList()
        var identification_type_list = await getIdentificationTypeList()
        var state = await getATFStateList('US')
        var roles:any = await getATFRolesList()
        var streetSuffix = await getATFStreetSuffix()
        var sex = await getATFSex()
        var race = await getATFRace()

        //var state = await getStateList('');
        var country = await getCountryList()
        var vehicle_make = await getDropDownMake({})
        var vehicle_color = await getDropDownColor({})
        
        if(vehicles) {
            for (let v of vehicles) {
                v.PlateStateList = await getATFStateList(v.VehicleCountry ?? 'US')
            }
        }

        if(incidents) {
            for (let i of incidents) {
                i.CountyList = await getCounty(GetDefaultStateForSite(SiteName))
                i.REC_STATE = GetDefaultStateForSite(SiteName)
            }
        }

        if(possessors) {
            for (let p of possessors) {
                p.ID1StateList = await getATFStateList(p.INDIV_ID1_COUNTRY ?? 'US')
                p.ID2StateList = await getATFStateList(p.INDIV_ID2_COUNTRY ?? 'US')
                p.CountyList = await getCounty(GetDefaultStateForSite(SiteName))
            }
        }

        if (agency && agency?.length > 0) {
            setAgencyList(agency)
        }
        if (weapon_manufacturer_list && weapon_manufacturer_list?.length > 0) {
            setWeaponManufacturerList(weapon_manufacturer_list)
        }
        if(weapon_importer_list && weapon_importer_list.length > 0) {
            setWeaponImporterList(weapon_importer_list)
        }
        // if (weapon_type_list && weapon_type_list?.length > 0) {
        //     setWeaponTypeList(weapon_type_list)
        // }
        // if (caliber && caliber?.length > 0) {
        //     setAllCaliberList(caliber)
        // }
        if (identification_type_list && identification_type_list?.length > 0) {
            setIdentificationTypeList(identification_type_list)
        }
        if (vehicle_make && vehicle_make?.length > 0) {
            setVehicleMakeList(vehicle_make)
        }
        if (ncic_crime_code_list && ncic_crime_code_list?.length > 0) {
            setNCICCrimeCodeList(ncic_crime_code_list)
        }
        if (inc_rec_loc_type_list && inc_rec_loc_type_list?.length > 0) {
            setLocationTypeList(inc_rec_loc_type_list)
        }
        if (country && country?.length > 0) {
            setCountryList(country)
        }
        if (state && state?.length > 0) {
            setStateList(state)
        }
        if (streetSuffix && streetSuffix?.length > 0) {
            setStreetSuffixList(streetSuffix)
        }
        if (vehicle_color && vehicle_color?.length > 0) {
            setVehicleColorList(vehicle_color)
        }
        if (roles && roles?.length > 0) {
            setRolesList(roles)
        }
        if (sex && sex?.length > 0) {
            setSexList(sex)
        }
        if (race && race?.length > 0) {
            setRaceList(race)
        }
        if (!trace || trace?.length === 0) {
            setDropdownsLoading(false)
        }
    }
    
    const handleEditData = async () => {
        let l = new JAOS()
        let njTraceMain = l.objFromStack(trace)
        setEditBuild(true)
        let r = await GetNJTrace({ main: njTraceMain })
        if (r?.ErrorMessages?.length === 0) {
           
            if (r?.Main) {
                setParams({ main: r?.Main })
                setEntryDateTime(r?.Main?.REQUEST_DATE)
            }

            if (r?.Persons && r?.Persons.length > 0) {
                let items = [...r?.Persons]
                for (let pos of items) {
                    if (pos?.INDIV_DOB && pos?.INDIV_DOB.length > 0) {
                        let newDOB = new Date(pos.INDIV_DOB)
                        pos.INDIV_DOB = (newDOB.getFullYear() + '-' + ((newDOB.getMonth() + 1).toString()).padStart(2, '0') + '-' + newDOB.getDate().toString().padStart(2, '0')).toString()
                    }                  
                    pos.ID1StateList = await getATFStateList(pos.INDIV_ID1_COUNTRY ?? 'US')
                    pos.ID2StateList = await getATFStateList(pos.INDIV_ID2_COUNTRY ?? 'US')
                    pos.CountyList = await getCounty(pos?.INDIV_STATE ?? GetDefaultStateForSite(SiteName))
                }
                setPossessors(items)
            }

            if (r?.Incidents && r?.Incidents.length > 0) {
               let items = [...r?.Incidents] 
                for (let pos of items) {
                    if (pos?.REC_DATE && pos?.REC_DATE.length > 0) {
                        let newRecDate = new Date(pos.REC_DATE)
                        pos.REC_DATE = (newRecDate.getFullYear() + '-' + ((newRecDate.getMonth() + 1).toString()).padStart(2, '0') + '-' + newRecDate.getDate().toString().padStart(2, '0')).toString()
                    }
                    pos.CountyList = await getCounty(pos?.REC_STATE ?? GetDefaultStateForSite(SiteName))
                    pos.PREV_REC_STREET_NUMBER = pos.REC_STREET_NUMBER
                    pos.PREV_REC_STREET_NAME = pos.REC_STREET_NAME
                    pos.PREV_REC_STREET_SUFFIX = pos.REC_STREET_SUFFIX
                    pos.PREV_REC_STREET = pos.REC_STREET
                    pos.PREV_REC_CITY = pos.REC_CITY
                    pos.PREV_REC_COUNTY = pos.REC_COUNTY
                    pos.PREV_REC_STATE = pos.REC_STATE
                }
                //set incidents to the latest
                setIncidents([r?.Incidents[r?.Incidents?.length - 1]])
                
            }

            if (r?.Weapons && r?.Weapons.length > 0) {
                setWeapons([])
                let items = weapons
                items = [...r?.Weapons]
                let statusResult = []
                for (let wpn of items) {
                
                    if (wpn?.Stolen_Date && wpn?.Stolen_Date.toString().length > 0) {
                        let newStolenDate = new Date(wpn.Stolen_Date)
                        wpn.Stolen_Date = (newStolenDate.getFullYear() + '-' + ((newStolenDate.getMonth() + 1).toString()).padStart(2, '0') + '-' + newStolenDate.getDate().toString().padStart(2, '0')).toString()
                    }
                    if(wpn?.FileOrImages) {
                        wpn.ExistingFiles = wpn.FileOrImages
                        for(let pic of wpn.ExistingFiles)
                        {
                            let temp = await downloadContentForFile(pic)
                            if(temp)
                                pic.FileContent = temp.FileContent
                        }
                    }
                    if(wpn?.ATFStatus){
                        //disable save if (all weapons have been accepted/retained when sent to ATF)
                        let status = wpn?.ATFStatus.some((e:any) => e.ATF_Status === "Accepted" || e.ATF_Status === "Retained" || e.ATF_Status === "Duplicate")
                        statusResult.push(status)                                                                        
                        if(statusResult.some((e:any)=> e === true)){
                            setDisableSave(true)
                        }
                    }
                    //handle dropdowns
                    if(wpn?.MANUFACTURER && wpn?.MANUFACTURER.length > 0 ){
                        if(wpn?.ORIGIN && wpn.ORIGIN.length > 0){
                            wpn.WEAPON_TYPE_LIST = await getWeaponTypeFilteredList(wpn?.MANUFACTURER, wpn?.ORIGIN)
                            if(wpn?.W_TYPE && wpn?.W_TYPE.length > 0){
                                wpn.WEAPON_CALIBER_LIST = await getWeaponCaliberList(wpn?.W_TYPE, wpn?.MANUFACTURER, wpn?.ORIGIN)
                                if(wpn.CALIBER && wpn.CALIBER.length > 0){
                                    wpn.WEAPON_MODEL_LIST = await getWeaponModelList(wpn?.W_TYPE, wpn?.MANUFACTURER, wpn?.ORIGIN, wpn?.CALIBER)        
                                }
                            }
                            //set value to manufacturer and country for handling labeling
                            wpn.MANUFACTURER = wpn.MANUFACTURER + " (" + wpn.ORIGIN + ")"
                        }
                       
                    }
                    
                }
                setWeapons(items)
            }

            if (r?.Vehicles && r?.Vehicles.length > 0) {
                let items = [...r?.Vehicles]
                for (let pos of items) {
                    pos.PlateStateList = await getATFStateList(pos.VehicleCountry ?? 'US')
                }
                setVehicles(items)
            }
            
            if (r?.ReportingOfficers && r?.ReportingOfficers.length > 0) {
                let items = [...r?.ReportingOfficers]
                for (let pos of items) {
                    // change date if present
                }   
                //set reporting officers to latest
                setReportingOfficers([r?.ReportingOfficers[r?.ReportingOfficers?.length - 1]])                   
            }
            
        }
        setEditBuild(false)
        setDropdownsLoading(false)
    }

    const handleAdditionalFiles = (e: any, wpnIndex: number = 0) => {
        let files = e.files
        for (var i = 0; i < files.length; i++) {
            handleChangeFile(files[i], wpnIndex)
        }
    };

    const handleChangeFile = (file: any, wpnIndex: number = 0) => {
        let fileData = new FileReader();
        fileData.onloadend = (e) => handleFile(e, file, wpnIndex);
        fileData.readAsDataURL(file);
    }

    const handleFile = (e: any, file: any, wpnIndex: number = 0) => {
        let pending:any = weapons[wpnIndex].AddedFiles ?? []
        let ex:any = weapons[wpnIndex].ExistingFiles ?? []
        let allPending:any = []
        let allExisting:any = []
        let weaponList:any = [...weapons]
        
        for(let w of weaponList){
            allPending = allPending.concat(w.AddedFiles ?? []) //Check All weapons for Image Name so it does not override
            allExisting = allExisting.concat(w.ExistingFiles ?? []) //Check All weapons for Image Name so it does not override
        }
        
        let pIndex = allPending.findIndex((fil: any) => fil.FileName === file.name)   //if file.name does not exist in list returns -1
        let exIndex = allExisting.findIndex((fil: any) => fil.FileName === file.name)       //if file.name does not exist in list returns -1
        
        if (pIndex === -1 && exIndex === -1) //if is not already in list
        {
            pending.push({
                FileContent: e.target.result.split('base64,')[1],
                FileName: file.name,
                FileEncoding: file.type,
                FileDescription: weapons[wpnIndex].imgType ?? 'Full Gun Image'
            })
        }
        else {
            let name = file.name.split('.') //grabs NAME part of file temp.jpg -> [temp, jpg]
            let x = allPending.filter((res:any) => res.FileName?.includes(name[0]))?.length
            let y = allExisting.filter((res:any) => res.FileName?.includes(name[0]))?.length
            let fileName = name[0] + '(' + (x + y) + ')' //y > x ? name[0] + '(' + y + ')' : name[0] + '(' + x + ')'
            pending.push({
                FileContent: e.target.result.split('base64,')[1],
                FileName: fileName + (name?.length > 1 ? '.' + name[1] : ''),
                FileEncoding: file.type,
                FileDescription: weapons[wpnIndex].imgType ?? 'Full Gun Image'
            })
        }
        weapons[wpnIndex].AddedFiles = pending
        setWeapons(weapons)
        setRefreshCount(refreshCount + 1)
    }

    const removeFile = (file: any, index: number, wpnIndex: number) => {
        let temp:any = []
        if (!file.ID) {
            temp = weapons[wpnIndex].AddedFiles ?  weapons[wpnIndex].AddedFiles : []
            temp.splice(index, 1)
            weapons[wpnIndex].AddedFiles = temp
        }
        else {
            temp = weapons[wpnIndex].ExistingFiles ?  weapons[wpnIndex].ExistingFiles : []
            let findIndex = temp.findIndex((e:any) => e.ID === file.ID)
            if (findIndex >= 0 && weapons[wpnIndex].ExistingFiles) {
                temp[findIndex].Deleted = true
                weapons[wpnIndex].ExistingFiles = temp
            }
        }
        setWeapons(weapons)
        setRefreshCount(refreshCount + 1)
    }

    const reinstateFile = (file: any, index: number, wpnIndex: number) => {
        let temp: any = weapons[wpnIndex].ExistingFiles ?  weapons[wpnIndex].ExistingFiles : []
        let findIndex = temp.findIndex((e:any) => e.ID === file.ID)
        if (findIndex >= 0 && weapons[wpnIndex].ExistingFiles) {
            temp[findIndex].Deleted = false
            weapons[wpnIndex].ExistingFiles = temp
        }
        setWeapons(weapons)
        setRefreshCount(refreshCount + 1)
    }

    const downloadFile = async (file: any, index: number, wpnIndex: number) => {
        let temp:any = []
        if (file.FileContent != null) {
            await base64_to_blob(file.FileContent, file.FileEncoding).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
        else if (file.ID) {
            temp =  weapons[wpnIndex].ExistingFiles ? weapons[wpnIndex].ExistingFiles : []
            await GetFileContentFirearm(temp[index])
        }
    }

    const downloadContentForFile = async (file: any) => {
        if (file.ID) {
            let r = await GetFileContentFirearmNonDownload(file)
            if(r) {
                return r
            }
            else
                return null
        }
        else
            return null
    }

    const camelCase = (s: string) => {
        if (s && s.length > 0) {
            let t = s.split(' ')
            let res = ''
            for (let word of t) {

                if (word != 'PD' && word != 'NJ' && word != 'ATF') {
                    let lower = word.substring(1).toLowerCase() + ' '
                    res += word.substring(0, 1) + lower;
                }
                else
                    res += word + ' '
            }
            return res
        }
    }

    const handleGetDDLabel = (list:any[], option:any, listType:number) => {
        if(!option || option === '' || option === undefined)
            return ''
        if(listType && list && list.length > 0){          
            switch(listType){
                case 1: //ID, Description
                    if(option && option.hasOwnProperty('Description'))
                    {
                        return option.Description
                    }
                    else {
                        let x = list.find((e:any) => {return(e.ID === option)})
                        if(x && x.hasOwnProperty('Description')){
                            return x.Description 
                        }                                              
                        else
                        {
                            return option
                        } 
                    }
                    break
                case 2: //Key, Value
                    if(option && option.hasOwnProperty('Value'))
                    {
                        return option.Value
                    }
                    else {
                        let x = list.find((e:any) => e.Key === option)
                        if(x && x.hasOwnProperty('Value')){
                            return x.Value
                        }                        
                        else
                        {
                            return option
                        }
                    }
                    break
                case 3: //ID, Description
                if(option && option.hasOwnProperty('Description'))
                {
                    let str= option.Description
                    if(option.hasOwnProperty('Country')){
                        str+= " (" + option.Country +")"
                    }
                    return str
                }
                else {                       
                    let x = list.find((e:any) => {return(e.ID === option)})
                    if(x && x.hasOwnProperty('Description')){
                        let str = x.Description
                        if(x.hasOwnProperty('Country')){
                            str+= " (" + x.Country +")"
                        }
                        return str 
                    }               
                    else
                    {
                        return option
                    }                    
                }
                break
            }    
        }
        return option       
    }

    const handleDDCaliber = async (w_make:any, w_type:any, country:any) => {
       let res = await getWeaponCaliberList(w_type, w_make, country)
       if(res.length === 0){
        //if not getting values back give user entire list
        return allCaliberList
       }    
       return res
    }

    const handleDDModel = async (w_make:any, w_type:any, country:any, caliber:any) => {
        let res = await getWeaponModelList(w_type, w_make, country, caliber)  
        return res
     }

    const handleDialogClick = async (option : string)=>{
        if(option === 'Save'){
            if(window.location.href.includes('AddNJTrace')){
                window.location.href = '/tools/trace'
            }
            else if(window.location.href.includes('SharedNJTrace')){
                window.location.href = '/login'
            }
            else{
                handleReportSubmit(params?.main?.ID ? 'Edit' : 'New', params)
            }
        }
        else if (option === 'Cancel'){
            if(window.location.href.includes('AddNJTrace')){
                window.location.href = '/tools/trace'
            }
            else if(window.location.href.includes('SharedNJTrace')){
                 window.location.href = '/login'
            }
            else{
                handleReportSubmit('Cancel')
            }
        }
        else if (option === 'Error'){
            setOpenDialog(false)
        }
    }
    
    const getRolesList = (possessor:NJTracePersonObject) => {
        let tRolesList = [...rolesList]
        let p = [...possessors].filter((e:any) => { return(e.Deleted != true) })
        if(possessor && !possessor.Deleted && possessor.INDIV_ROLE === 'S'){
            return tRolesList
        }
        else if(p.filter((e:any) => e.INDIV_ROLE === 'S').length >= 1){
            let index = tRolesList.findIndex((e:any) => e.ID === 'S')
            tRolesList.splice(index,1)
            return tRolesList
        }
        return tRolesList
        
    }

    const validateEmails = (emailText :string) => {
        const emails = emailText?.trim().split(/[;,]/);
        const emailRegex = /<([^>]+)>|([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
        const validatedEmails : string[] = [];

        emails.forEach(email => {
            const match = email.match(emailRegex);
            if (match) {
                const emailAddress = match[1] || match[0];
                if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
                    validatedEmails.push(emailAddress);
                }
            }
        });

        return validatedEmails;
    }
    
    return (
        <div>
            <Dialog
            style={{backgroundColor:"rgba(245,245,245,0.45)", border:'1px solid black'}}
            open={openDialog}
            maxWidth={'xl'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                {optionDialog === 'Cancel' || optionDialog == 'Error' || optionDialog === 'Warning' ? 
                    <IconButton 
                        aria-label="close" 
                        onClick={()=>setOpenDialog(false)}  
                        sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    :<></>
                }
                <DialogTitle style={{marginTop:'2vh'}}>
                    <h1>NJTrace Entry: {optionDialog}</h1>
                </DialogTitle>
                <DialogContent>
                    {dialogText.split('.').map(str => 
                        <div className={ (optionDialog !== 'Save' ? "text-red-500 pb-1 font-bold" : "text-black pb-1")}>
                            {str}
                        </div>
                     )
                    }
                </DialogContent>
                { optionDialog === 'Warning' ?
                    <DialogActions>
                        <Button variant={"outlined"} color='inherit' disabled={disableSave} type="submit" onClick={()=>{handleSave(true)}}>Save Anyway</Button>
                    </DialogActions>                       
                    :
                    <DialogActions className={"mr-2 mb-2"}>
                        <Button variant={"outlined"} color='inherit' onClick={()=>handleDialogClick(optionDialog)}>Accept</Button>
                    </DialogActions>
                }
               
            </Dialog>
        {dropdownsLoading ? 
            <div className="mx-auto" style={{textAlign:'center'}}>
                <div className="mx-auto px-10 py-8 font-bold" style={{width:'40%', borderRadius:'6px', fontSize:'18px'}}>
                    <div> Please wait... Your page is being prepared. </div>
                    <div className={"mt-8"}>
                        <HourglassBottomIcon
                            sx={{
                                animation: "spin 2s linear infinite",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(360deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(0deg)",
                                    },
                                },
                                fontSize:'60px',
                                color:'#3b63c1'
                            }}
                        />
                    </div>
                </div>
            </div> 
            :
            <div className=" border-2 border-gray-200 w-10/12 m-auto">
                <div className=" border-2 border-gray-600 m-4">
                    <div
                        className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Main
                    </div>
                    <div className="grid grid-cols-3 gap-x-3 gap-y-1.5 px-4 py-4">
                        <fieldset className="w-full pl-2 pr-2 text-sm">
                            <legend>Entry DateTime</legend>
                            <DateTimePickerComponent 
                                value={entryDateTime}
                                strictMode={true}
                                onChange={(e: { target: { value: any; }; }) => setEntryDateTime(e.target.value)}
                                format='MM-dd-yyyy HH:mm'
                                floatLabelType="Auto"
                                style={{width: 'full', padding: 0, margin: 0 }}
                                max={today}
                            />
                        </fieldset>
                        <div className="">
                            <Autocomplete
                                //freeSolo
                                popupIcon=""
                                id="Agency_ID"
                                disableClearable
                                size={"small"}
                                options={agencyList}
                                getOptionLabel={(option: any) => handleGetDDLabel(agencyList, option, 2)}
                                onChange={(event, value: any) => handleValueChange('DEPT', value.Key)}
                                onInputChange={(event, value: any) => value === '' ? handleValueChange('DEPT', '') : () => {}}
                                renderInput={(p) => (
                                    <TextField
                                        {...p}
                                        label="Agency Name"
                                        InputProps={{
                                            ...p.InputProps,
                                            type: 'search',
                                            style: {textAlign: 'center'},
                                        }}
                                        error={!(params?.main?.DEPT && params?.main?.DEPT?.length > 0)}
                                    />
                                )}
                                value={params?.main?.DEPT ? params?.main?.DEPT : ''}
                            />
                        </div>
                        <div className="">
                            <TextField variant="outlined" margin='none' fullWidth size={"small"} label="Agency ORI"
                                       disabled={disableOriBox}
                                       InputLabelProps={{shrink: (!!(params?.main?.DEPT && params?.main?.DEPT?.length > 0))}}
                                       inputProps={{style: {textAlign: 'center'}}} value={params?.main?.DEPT}
                                       onChange={(e) => handleValueChange('Agency ORI', e.target.value)}/>
                        </div>
                        <div className="">
                            <Autocomplete
                                freeSolo
                                id="NCIC_Crime_Code_ID"
                                disableClearable
                                size={"small"}
                                options={NCICCrimeCodeList}
                                getOptionLabel={(option: any) => handleGetDDLabel(NCICCrimeCodeList, option, 1)}
                                onChange={(event, value: any) => handleValueChange('NCIC Crime Code', value.ID)}
                                onInputChange={(event, value: any) => value === '' ? handleValueChange('NCIC Crime Code', '') : () => {}}
                                renderInput={(p) => (
                                    <TextField
                                        {...p}
                                        label="NCIC Crime Code"
                                        InputProps={{
                                            ...p.InputProps,
                                            type: 'search',
                                            style: {textAlign: 'center'},
                                        }}
                                        error={!params?.main?.Crime_Code || params?.main?.Crime_Code?.length === 0}
                                    />
                                )}
                                value={params?.main?.Crime_Code ? params?.main?.Crime_Code : ''}
                            />
                        </div>
                        <div className="">
                            <TextField variant="outlined" margin='none' fullWidth size={"small"} label={GetStringNameForSite(SiteName,"Case #")}
                                       InputLabelProps={{shrink: (!!(params?.main?.CaseNumber && params?.main?.CaseNumber?.length > 0))}}
                                       inputProps={{style: {textAlign: 'center'}}} value={params?.main?.CaseNumber}
                                       onChange={(e) => handleValueChange('Case #', e.target.value)}
                                       error={!params?.main?.CaseNumber || params?.main?.CaseNumber?.length === 0}
                            />
                        </div>
                        <div className="">
                            <TextField variant="outlined" margin='none' fullWidth size={"small"} label="Remark"
                                       InputLabelProps={{shrink: (!!(params?.main?.REMARK && params?.main?.REMARK?.length > 0))}}
                                       inputProps={{style: {textAlign: 'center'}}} value={params?.main?.REMARK}
                                       onChange={(e) => handleValueChange('Remark', e.target.value)}/>
                        </div>
                        <div className="">
                            {isUserPermission('NJTrace') ? 
                                <div>
                                    <span>
                                        <Checkbox
                                            value={params?.main?.main_confidential === 'Y'}
                                            checked={params?.main?.main_confidential === 'Y'}
                                            onChange={(event, value) => handleValueChange('Confidential', value)}
                                            sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                            
                                        />
                                    </span>
                                    <span style={{fontSize: '15px'}}>
                                    Confidential Trace
                                    </span>
                                </div>  
                                : <></>}    
                        </div>
                    </div>
                    
                </div>

                <div className="">
                    {reportingOfficers.map((reportingOfficer: any, indx) => {
                        return (
                            (reportingOfficer?.Deleted != true ?
                                <div className=" border-2 border-gray-600 mx-4 mt-4">
                                    <div
                                        className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Reporting
                                        Officer
                                        <span className="inline">
                                    {/* <IconButton size={"small"} onClick={() => handleRemoveReportingOfficer(indx)}
                                                color={"error"}
                                                title={"Remove Officer"}> <DeleteIcon/> </IconButton> */}
                                </span>
                                    </div>
                                    <div>
                                        <div className="grid grid-cols-5 gap-x-2 gap-y-1.5 px-4 py-6">
                                            <div className="col-span-1">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="Badge #"
                                                           InputLabelProps={{shrink: (!!(reportingOfficer?.ORI_BADGE_NUMBER && reportingOfficer?.ORI_BADGE_NUMBER?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}, maxLength:6}}
                                                           value={reportingOfficer?.ORI_BADGE_NUMBER}
                                                           onChange={(e) => handleValueChange('Badge #', e.target.value, indx)}/>
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    error={reportingOfficer?.ORI_FIRSTNAME && reportingOfficer?.ORI_FIRSTNAME?.length > 0 ? false : true}
                                                    variant="outlined" margin='none' fullWidth size={"small"}
                                                    label="First Name"
                                                    InputLabelProps={{shrink: (!!(reportingOfficer?.ORI_FIRSTNAME && reportingOfficer?.ORI_FIRSTNAME?.length > 0))}}
                                                    inputProps={{style: {textAlign: 'center'}}}
                                                    value={reportingOfficer?.ORI_FIRSTNAME}
                                                    onChange={(e) => handleValueChange('First Name', e.target.value, indx)}/>
                                            </div>
                                            <div className="col-span-1">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="Middle Name"
                                                           InputLabelProps={{shrink: (!!(reportingOfficer?.ORI_MIDDLENAME && reportingOfficer?.ORI_MIDDLENAME?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}}}
                                                           value={reportingOfficer?.ORI_MIDDLENAME}
                                                           onChange={(e) => handleValueChange('MI', e.target.value, indx)}/>
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    error={reportingOfficer?.ORI_LASTNAME && reportingOfficer?.ORI_LASTNAME?.length > 0 ? false : true}
                                                    variant="outlined" margin='none' fullWidth size={"small"}
                                                    label="Last Name"
                                                    InputLabelProps={{shrink: (!!(reportingOfficer?.ORI_LASTNAME && reportingOfficer?.ORI_LASTNAME?.length > 0))}}
                                                    inputProps={{style: {textAlign: 'center'}}}
                                                    value={reportingOfficer?.ORI_LASTNAME}
                                                    onChange={(e) => handleValueChange('LastName', e.target.value, indx)}/>
                                            </div>
                                            <div className="col-span-1">                                         
                                                <TextField
                                                    variant="outlined" margin='none' fullWidth size={"small"}
                                                    label="Email"
                                                    InputLabelProps={{shrink: (!!(reportingOfficer?.ORI_EMAIL && reportingOfficer?.ORI_EMAIL?.length > 0))}}
                                                    inputProps={{style: {textAlign: 'center'}}}
                                                    value={reportingOfficer?.ORI_EMAIL}
                                                    onChange={(e) => handleValueChange('Email', e.target.value, indx)}
                                                    helperText="Enter email to receive E-Trace notifications."
                                                />
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                : <></>)
                        )
                    })}
                </div>

                {/* <div className="mx-4 mb-2">
                    <IconButton size={"large"} onClick={handleAddReportingOfficer} color={"inherit"}
                                title={"Add Additional Officer(s)"}> <ReportingOfficerAddIcon/> </IconButton>
                </div> */}

                <div className="">
                    {incidents.map((incident: any, indx) => {
                        return (
                            (incident?.Deleted != true ?
                                <div className=" border-2 border-gray-600 mx-4 mt-4">
                                    <div
                                        className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Incident
                                        <span className="inline">
                                    {/* <IconButton size={"small"} onClick={() => handleRemoveIncident(indx)}
                                                color={"error"}
                                                title={"Remove Incident"}> <DeleteIcon/> </IconButton> */}
                                </span>
                                    </div>
                                    <div className="grid grid-cols-5 gap-x-3 gap-y-1.5 px-4 pt-4">
                                        <div>
                                            <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                                       size={"small"} label="Recovery Date"
                                                       InputLabelProps={{shrink: true}}
                                                       inputProps={{style: {textAlign: 'center'}, max: todayDate}}
                                                       value={incident.REC_DATE}
                                                       onChange={(e) => handleValueChange("Recovery Date", e.target.value, indx)}
                                                       error={incident.REC_DATE === undefined || incident.REC_DATE.length === 0}
                                                       />
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Street #"
                                                       InputLabelProps={{shrink: (!!(incident?.REC_STREET_NUMBER && incident?.REC_STREET_NUMBER?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}, maxLength:6}}
                                                       value={incident?.REC_STREET_NUMBER}
                                                       onChange={(e) => handleValueChange("INC Street #", e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <TextField error={incident?.REC_STREET_NAME ? false : true}
                                                       variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Street Name"
                                                       InputLabelProps={{shrink: (!!(incident?.REC_STREET_NAME && incident?.REC_STREET_NAME?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={incident?.REC_STREET_NAME}
                                                       onChange={(e) => handleValueChange("INC Street Name", e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="INC Type"
                                                disableClearable
                                                size={"small"}
                                                options={streetSuffixList}
                                                getOptionLabel={(option: any) => handleGetDDLabel(streetSuffixList, option, 1)}
                                                onChange={(event, value) => handleValueChange('INC Type', value.ID, indx)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('INC Type', '', indx) : () => {}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Type"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                    />
                                                )}
                                                value={incident?.REC_STREET_SUFFIX ?? ''}
                                            />
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Apt #"
                                                       InputLabelProps={{shrink: (!!(incident?.REC_APT_NUMBER && incident?.REC_APT_NUMBER?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}, maxLength:6}}
                                                       value={incident?.REC_APT_NUMBER}
                                                       onChange={(e) => handleValueChange("INC Apt #", e.target.value, indx)}/>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 gap-x-3 gap-y-1.5 px-4 pb-6 pt-2">
                                        <div className="">
                                            <TextField error={incident?.REC_CITY ? false : true} variant="outlined"
                                                       margin='none' fullWidth size={"small"} label="City"
                                                       InputLabelProps={{shrink: (!!(incident?.REC_CITY && incident?.REC_CITY?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={incident?.REC_CITY}
                                                       onChange={(e) => handleValueChange("INC City", e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="INC State"
                                                disableClearable
                                                size={"small"}
                                                options={stateList}
                                                getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
                                                onChange={(event, value) => handleValueChange('INC State', value.ID, indx)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('INC State', '', indx) : () => {}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="State"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                    />
                                                )}
                                                value={incident?.REC_STATE ?? ''}
                                            />
                                        </div>
                                        <div className="">
                                            <TextField error={incident?.REC_ZIP ? false : true}
                                                       variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Zip"
                                                       InputLabelProps={{shrink: (!!(incident?.REC_ZIP && incident?.REC_ZIP?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}, maxLength: 10}}
                                                       value={incident?.REC_ZIP}
                                                       onChange={(e) => handleValueChange("INC Zip", e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                freeSolo
                                                id="INC County"
                                                disableClearable
                                                size={"small"}
                                                options={incident.CountyList}
                                                onChange={(event, value: any) => handleValueChange('INC County', value.Key, indx)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('INC County', '', indx) : () => {}}
                                                getOptionLabel={(option: any) => handleGetDDLabel(incident.CountyList, option, 2)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Incident County"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={incident?.REC_COUNTY ? false : true}
                                                    />
                                                )}
                                                value={incident?.REC_COUNTY ? incident?.REC_COUNTY : ''}
                                            />
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                freeSolo
                                                id="INC Recovery Location Type"
                                                disableClearable
                                                size={"small"}
                                                options={LocationTypeList}
                                                getOptionLabel={(option: any) => handleGetDDLabel(LocationTypeList, option, 1)}
                                                onChange={(event, value) => handleValueChange('INC Recovery Location Type', value.ID, indx)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('INC Recovery Location Type', '', indx) : () => {}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Recovery Location Type"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={incident?.REC_Location_Type ? false : true}
                                                    />
                                                )}
                                                value={incident?.REC_Location_Type ? incident?.REC_Location_Type : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : <></>)
                        )
                    })}
                </div>

                <div className="">
                    {enlarged ?
                        <Dialog
                            fullWidth={true}
                            maxWidth={'md'}
                            onClose={()=>{setEnlarged(null)}}
                            open={open}
                            TransitionComponent={Transition}

                        >
                            <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                        </Dialog>
                        :
                        <></>
                    }
                    {weapons.map((weapon: any, wpnIndex) => {
                        return (
                            (weapon?.Deleted != true ?
                                <Card className=" border-2 border-gray-600 mx-4 mt-4">
                                    <div
                                        className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Weapon
                                        <span className="inline">
                                    <IconButton size={"small"} onClick={() => handleRemoveWeapon(wpnIndex)}
                                                color={"error"} title={"Remove Weapon"}> <DeleteIcon/> </IconButton>
                                </span>
                                    </div>
                                    <div className="mb-4">
                                        <Alert severity="info">
                                            If your weapon has a defaced/obliterated serial number, select the <strong>Defaced/Obliterated </strong>  
                                            indicator box and leave the serial number blank.
                                            <br/>
                                            For partial serial numbers, select the <strong>Defaced/Obliterated</strong> indicator box and replace 
                                            any missing characters in the serial number with an asterisk (*). Example 12*45**8.
                                            <br/>
                                            {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <>
                                                If you are having problems entering and need assistance, call
                                                the <strong>NJ Crime Gun Center at 609-963-6900 ext.
                                                6839</strong> during working hours for assistance.
                                            </>}
                                            
                                        </Alert>
                                    </div>
                                    <div className="grid grid-cols-4 gap-x-3 gap-y-1.5 px-4 pt-4">
                                        <fieldset className="w-full pl-5 pr-5 text-sm">
                                                <span >
                                                    {!weapon?.RECOVERY_DATE || weapon?.RECOVERY_DATE?.length === 0 ? <span style={{color:'red'}}> Recovery DateTime</span> : <span>Recovery DateTime</span> }
                                                </span>   
                                                <DateTimePickerComponent
                                                    value={weapon.RECOVERY_DATE}
                                                    strictMode={true}
                                                    onChange={(e: {
                                                        target: { value: any; };
                                                    }) => handleValueChange('Recovery DateTime', e.target.value, wpnIndex)}
                                                    format='MM-dd-yyyy HH:mm'
                                                    floatLabelType="Auto"
                                                    style={{width: 'full', padding: 0, margin: 0}}
                                                    max={today}                                                      
                                                />
                                        </fieldset>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="Weapon Make" 
                                                disableClearable
                                                size={"small"}
                                                options={WeaponManufacturerList && WeaponManufacturerList.length > 0 ? WeaponManufacturerList : []}
                                                onChange={(event, value: any) => handleValueChange('Weapon Make', value, wpnIndex)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('Weapon Make', '', wpnIndex) : () => {}}                                          
                                                getOptionLabel={(option: any,) => handleGetDDLabel(WeaponManufacturerList, option , 3)}
                                                
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Weapon Make"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={weapon?.MANUFACTURER || weapon?.W_TYPE === "MX" ? false : true}
                                                    />
                                                )}
                                                value={weapon?.MANUFACTURER ? weapon?.MANUFACTURER : ''}
                                            />
                                        </div>
                                        <div className="">
                                            <TextField disabled={true} variant="outlined" margin='none' fullWidth
                                                       size={"small"}
                                                       label="Manufacturer Code"
                                                       InputLabelProps={{shrink: (!!(weapon?.MANUFACTURER && weapon?.MANUFACTURER?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={weapon?.MANUFACTURER}/>
                                        </div>                                       
                                        <div className="">
                                            <Autocomplete
                                                popupIcon=""
                                                //disableClearable
                                                id="Weapon Type"                                           
                                                size={"small"}
                                                options={weapon?.WEAPON_TYPE_LIST && weapon?.WEAPON_TYPE_LIST.length > 0 ? weapon?.WEAPON_TYPE_LIST :[]}
                                                onChange={(event, value: any) => handleValueChange('Weapon Type', value , wpnIndex)}                                               
                                                getOptionLabel={(option: any) => handleGetDDLabel(weapon.WEAPON_TYPE_LIST, option, 1)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Weapon Type"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'input',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={weapon?.W_TYPE ? false : true}
                                                    />
                                                )}
                                                value={weapon?.W_TYPE ? weapon?.W_TYPE : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-x-3 gap-y-1.5 px-4 pb-6 pt-2">
                                        <div className="">
                                            <Autocomplete
                                                popupIcon=""
                                                id="weapon_list_id"
                                                //disableClearable
                                                size={"small"}
                                                options={weapon?.WEAPON_CALIBER_LIST && weapon?.WEAPON_CALIBER_LIST.length > 0 ? weapon?.WEAPON_CALIBER_LIST : []}
                                                disabled={weapon?.W_TYPE === "MX"}
                                                onChange={(event, value: any) => handleValueChange('Weapon Caliber', value, wpnIndex)}
                                               getOptionLabel={(option: any) => handleGetDDLabel(weapon.WEAPON_CALIBER_LIST && weapon.WEAPON_CALIBER_LIST.length > 0 ? weapon.WEAPON_CALIBER_LIST : [], option, 1)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Weapon Caliber"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'input',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={weapon?.CALIBER || weapon?.W_TYPE === "MX" ? false : true}
                                                    />
                                                )}
                                                value={weapon?.CALIBER ? weapon?.CALIBER : ''}
                                            />
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="Weapon Model"
                                                disabled={weapon?.W_TYPE === "MX"}
                                                //disableClearable
                                                size={"small"}
                                                options={weapon?.WEAPON_MODEL_LIST && weapon?.WEAPON_MODEL_LIST.length > 0 ? weapon?.WEAPON_MODEL_LIST : []}
                                                onChange={(event, value: any) => handleValueChange('Weapon Model', value , wpnIndex)}
                                                onInputChange={weapon?.WEAPON_MODEL_LIST && weapon?.WEAPON_MODEL_LIST.length == 0 ? (event, value: any) => event ? handleValueChange('Weapon Model', value , wpnIndex) : () => {} : () => {}}
                                                getOptionLabel={(option: any) => handleGetDDLabel(weapon?.WEAPON_MODEL_LIST && weapon?.WEAPON_MODEL_LIST.length > 0 ? weapon?.WEAPON_MODEL_LIST : [], option, 1)}
                                                // style={{textAlign: 'center',backgroundColor:'lightyellow'}}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Weapon Model"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'input',
                                                            style: {textAlign: 'center'},
                                                            
                                                        }}
                                                        error={weapon?.MODEL?.trim()?.length > 0  || weapon?.W_TYPE === "MX" ? false : true}
                                                    />

                                                )}
                                                value={weapon?.MODEL ? weapon?.MODEL : ''}
                                            />
                                        </div>
                                        <div className="">
                                            <TextField error={weapon?.SERIAL_NUMBER || weapon?.W_TYPE === "MX" || weapon?.DEFACED_GUN === "Y" || weapon?.Privately_made_firearm === "Y" ? false : true}
                                                       disabled={weapon?.W_TYPE === "MX"}          
                                                       variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Serial #"
                                                       InputLabelProps={{shrink: (!!(weapon?.SERIAL_NUMBER && weapon?.SERIAL_NUMBER?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={weapon?.SERIAL_NUMBER}
                                                       onChange={(e) => handleValueChange('Serial #', e.target.value, wpnIndex)}/>
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                freeSolo
                                                popupIcon=""
                                                clearOnEscape
                                                id="Weapon Importer" 
                                                //disableClearable
                                                size={"small"}
                                                options={WeaponImporterList && WeaponImporterList?.length > 0 ? WeaponImporterList : []}
                                                onInputChange={(event, value: any) => event ? handleValueChange('Weapon Importer', value, wpnIndex) : () => {} }
                                                getOptionLabel={(option: any,) => handleGetDDLabel(WeaponImporterList, option , 1)}                         
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Weapon Importer"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'input',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                        error={weapon?.ORIGIN !== "US" && weapon?.IMPORTER_NAME?.trim()?.length === 0 ? true : false}
                       
                                                    />
                                                )}
                                                value={weapon?.IMPORTER_NAME ? weapon?.IMPORTER_NAME : ''}
                                            />
                                        </div>
                                       
                                    </div>
                                    <div className="px-4">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={weapon?.DEFACED_GUN === 'Y'}
                                                    checked={weapon?.DEFACED_GUN === 'Y'}
                                                    disabled={weapon?.W_TYPE === "MX"}
                                                    onChange={(event, value) => handleValueChange('Defaced Gun', value, wpnIndex)}
                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                />}
                                            label="Defaced/Obliterated Gun"
                                        />
                                    </div>
                                    <div className="px-4">
                                        <FormControlLabel control={
                                            <Checkbox
                                            value={weapon?.Reported_Stolen === 'Y'}
                                            disabled={weapon?.W_TYPE === "MX"}
                                            checked={weapon?.Reported_Stolen === 'Y'}
                                            onChange={(event, value) => handleValueChange('Weapon Stolen', value, wpnIndex)}/>}
                                                          label="Firearm has been reported stolen"
                                                          sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>

                                    </div>
                                    { weapon?.Reported_Stolen === 'Y' ?
                                        <div className="grid grid-cols-3 gap-x-3 gap-y-1.5 px-4 pb-6 pt-2">
                                            <div className="">
                                                <TextField error={!weapon?.Stolen_Date || weapon?.Stolen_Date.length === 0} variant="outlined"
                                                           margin='none' fullWidth size={"small"}
                                                           label="Stolen Date"
                                                           type={"date"}
                                                           InputLabelProps={{shrink: true}}
                                                           inputProps={{style: {textAlign: 'center'}}}
                                                           value={weapon?.Stolen_Date}
                                                           onChange={(e) => handleValueChange('Weapon Stolen Date', e.target.value, wpnIndex)}/>
                                            </div>
                                            <div className="">
                                                <TextField error={!(weapon?.Stolen_AgencyName && weapon?.Stolen_AgencyName?.trim()?.length > 0)}
                                                        variant="outlined" margin='none' fullWidth size={"small"}
                                                        label="Agency Name"
                                                        InputLabelProps={{shrink: (!!(weapon?.Stolen_AgencyName && weapon?.Stolen_AgencyName?.length > 0))}}
                                                        inputProps={{style: {textAlign: 'center'}}}
                                                        value={weapon?.Stolen_AgencyName}
                                                        onChange={(e) => handleValueChange('Weapon Stolen AgencyName', e.target.value, wpnIndex)}/>
                                            </div>
                                            {/* <div className="">
                                                <Autocomplete
                                                    freeSolo
                                                    id="Agency_ID"
                                                    disableClearable
                                                    size={"small"}
                                                    options={agencyList}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(agencyList, option, 2)}
                                                    onChange={(event, value: any) => handleValueChange('Weapon Stolen AgencyORI', value.Key, wpnIndex)}
                                                    onInputChange={(event, value: any) => value === '' ? handleValueChange('Weapon Stolen AgencyORI', '') : () => {}}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            label="Agency Name"
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            error={!(weapon?.Stolen_AgencyORI && weapon?.Stolen_AgencyORI.length > 0)}
                                                        />
                                                    )}
                                                    value={weapon?.Stolen_AgencyORI ? weapon?.Stolen_AgencyORI : ''}
                                                />
                                            </div> */}
                                            <div className="">
                                                <TextField error={!(weapon?.Stolen_AgencyORI && weapon?.Stolen_AgencyORI?.trim()?.length > 0)}
                                                            variant="outlined" margin='none' fullWidth size={"small"} label="Agency ORI"
                                                           //disabled={true}
                                                           InputLabelProps={{shrink: (!!(weapon?.Stolen_AgencyORI && weapon?.Stolen_AgencyORI?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}}} 
                                                           value={weapon?.Stolen_AgencyORI}
                                                           onChange={(e) => handleValueChange('Weapon Stolen AgencyORI', e.target.value, wpnIndex)}

                                                />
                                            </div>
                                        </div>
                                        :<></>
                                    }
                                      
                                    <div className="px-4">
                                        <FormControlLabel control={<Checkbox
                                            value={weapon?.Privately_made_firearm === 'Y'}
                                            checked={weapon?.Privately_made_firearm === 'Y'}
                                            disabled={weapon?.W_TYPE === "MX"}
                                            onChange={(event, value) => handleValueChange('Weapon Privately Made', value, wpnIndex)}/>}
                                                        label="Firearm appears to be privately made/assembled from parts"
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>

                                    </div>
                                    <div className="px-4">
                                        <FormControlLabel control={<Checkbox
                                            value={weapon?.CONVERSION_DEVICE === 'Y'}
                                            checked={weapon?.CONVERSION_DEVICE === 'Y'}
                                            disabled={weapon?.W_TYPE === "MX"}
                                            onChange={(event, value) => handleValueChange('Weapon Conversion Device', value, wpnIndex)}/>}
                                                        label="Firearm is equipped with a machine gun conversion device/Sear Modified"
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>

                                    </div>
                                    <div className="">
                                                    <div className="py-3 ">
                                                        <Alert severity="info">
                                                            <strong>For a Machine Gun Conversion Device (MCD)Recovered Without a Firearm: </strong>                                                          
                                                            If you recover a firearm with an MCD installed on it, check off the box below the weapon information that reads 
                                                            “Firearm is equipped with a machine gun conversion device / Sear Modified.”
                                                            <br/>
                                                            <br/>
                                                            If you recover a standalone machine gun conversion device, select “Privately Made Firearm (PMF) (ZZ)” from the Weapon 
                                                            Make list and “Machine Gun Conversion Device” from the weapon type drop down and enter notes under additional markings 
                                                            to describe the type of device (i.e. Glock Switch) and the quantity recovered.
                                                        </Alert>
                                                    </div>
                                                    
                                                    <div className="px-4 pb-3">
                                                    <TextField multiline minRows={4} variant="outlined" margin='none'
                                                       fullWidth label="Additional Markings"
                                                       InputLabelProps={{shrink: (!!(weapon?.Additional_Markings && weapon.Additional_Markings?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'left'}}}
                                                       value={weapon.Additional_Markings ?? ''}
                                                       onChange={(e) => handleValueChange('Additional Markings', e.target.value, wpnIndex)}
                                                    />
                                                    </div>  
                                     </div> 

                                  
                                    <hr className={'mx-4'}/>
                                                                   
                                    <div className={"ml-4 my-4"}>
                                            <div className="grid grid-cols-2">
                                                <div className={"font-bold"} style={{fontSize: '16px'}}>
                                                    Indicate how your agency came into possession of this firearm:
                                                    <div className={"ml-10"}>
                                                        <RadioGroup
                                                            name="radio-buttons-group"
                                                            onChange={(event, value) => handleValueChange('Weapon Agency Possession How', value, wpnIndex)}
                                                            value={weapon?.Agency_Possession_How}
                                                        >
                                                            <FormControlLabel value="As part of a law enforcement investigation"
                                                                control={<Radio
                                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>}
                                                                label="As part of a law enforcement investigation"/>
                                                            <FormControlLabel value="Found" control={<Radio
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>} label="Found"/>
                                                            <FormControlLabel value="Turned in" control={<Radio
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>}
                                                                            label="Turned in"/>
                                                            <FormControlLabel value="Buy-back" control={<Radio
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>}
                                                                            label="Buy-back"/>
                                                        </RadioGroup>
                                                    </div>
                                                    <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                value={weapon?.Not_Recovered === 'Y'}
                                                                checked={weapon?.Not_Recovered === 'Y'}
                                                                disabled={weapon?.W_TYPE === "MX"}
                                                                onChange={(event, value) => handleValueChange('Weapon Not Recovered', value, wpnIndex)}
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                            />}
                                                        label="Firearm was not Recovered"
                                                    />
                                                    </div>
                                                    <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                value={weapon?.VaultReview_TraceStudy === 'Y'}
                                                                checked={weapon?.VaultReview_TraceStudy === 'Y'}
                                                                disabled={weapon?.W_TYPE === "MX"}
                                                                onChange={(event, value) => handleValueChange('Weapon VaultReview TraceStudy', value, wpnIndex)}
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                            />}
                                                        label="VAULT REVIEW/TRACE STUDY"
                                                    />
                                                    </div>           
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={weapon?.NARCOTICS_RELATED === 'Y'}
                                                                    disabled={weapon.W_TYPE === "MX"}
                                                                    checked={weapon?.NARCOTICS_RELATED === 'Y'}
                                                                    onChange={(event, value) => handleValueChange('Narcotics Related', value, wpnIndex)}
                                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                                />}
                                                                label={<span>The recovery of this firearm was <strong>NARCOTICS RELATED</strong></span>}
                                                                
                                                        />
                                                    </div>     
                                                    
                                                    {/* {isUserPermission('NJTrace') ? 
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={weapon?.SEAR_MODIFIED === 'Y'}
                                                                    disabled={weapon.W_TYPE === "MX"}
                                                                    checked={weapon?.SEAR_MODIFIED === 'Y'}
                                                                    onChange={(event, value) => handleValueChange('Sear Modified', value, wpnIndex)}
                                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                                />}
                                                            label="Sear Modified"
                                                        />
                                                    </div>                                                   
                                                    :<></>}                                   */}
                                                </div>
                                                
                                                  
                                                                                                                                               
                                            </div>
                                        </div>                                  
                                        <hr className={'mx-4'}/>
                                        <Card sx={{maxWidth: '100%', minWidth: '100%'}}>
                                            <CardContent>
                                                <span className="text-blue-400 font-bold text-xl ">Upload Firearm Image(s)</span>
                                                <br/>
                                                <br/> 
                                                    <FormControl sx={{m:1}}>
                                                        <InputLabel>Image Type</InputLabel>
                                                        <Select 
                                                         sx={{width:225, height:45}}
                                                         value={weapon.imgType ?? 'Full Gun Image'}
                                                         onChange={(e) => handleValueChange('Weapon Image Type', e.target.value, wpnIndex)}
                                                         label="Image Type"
                                                        >
                                                        <MenuItem value={'Full Gun Image'}>Full Gun Image</MenuItem>
                                                        <MenuItem value={'Serial Number'}>Serial Number</MenuItem>
                                                        <MenuItem value={'Make and Model Stamp'}>Make and Model Stamp</MenuItem>
                                                        <MenuItem value={'Other'}>Other</MenuItem>
                                                        </Select>
                                                        <FormHelperText>Max upload file quantity: 4 Images.</FormHelperText>
                                                    </FormControl>
                                                    <FormControl sx={{mt:1}}>
                                                        <label
                                                            style={{border:'1px solid lightgray', borderColor:'#bfbfbf', padding:'2px 2px 1.1px 8px', borderRadius:'3px'}}
                                                            htmlFor={"icon" + wpnIndex}>                                           
                                                            <input
                                                                id={"icon" + wpnIndex}
                                                                hidden
                                                                className="input"
                                                                onChange={(e) => handleAdditionalFiles(e.target, wpnIndex)}
                                                                type="file"
                                                                accept=".png, .jpg, .jpeg, .gif"
                                                                value=''
                                                                disabled={weapon?.AddedFiles ? weapon?.AddedFiles?.length >= 4 : false}
                                                            />
                                                            Add Image - Upload
                                                            <IconButton color="primary" component="span">
                                                                <AttachFileIcon/>
                                                            </IconButton>                                                           
                                                        </label>  
                                                        {/* <FormHelperText>Max upload file size: 20 MB.</FormHelperText> */}
                                                    </FormControl>                                                 
                                                                                              
                                                {weapon.ExistingFiles && weapon.ExistingFiles.filter((res:any) => res.Deleted === false).length > 0
                                                    ? <div className={"font-bold mb-3"}>
                                                        Files Attached
                                                    </div>
                                                    : <></>
                                                }
                                                <div className = "grid grid-cols-6 gap-y-1 gap-x-2">
                                                    {weapon.ExistingFiles ?
                                                        weapon.ExistingFiles.filter((res:any) => res.Deleted === false).map((file: any, fileIndex: number) => {
                                                            return (
                                                                <Card sx={{ maxWidth: 345, boxShadow:'none'}} style={{border:'1px solid black', borderRadius:'5px', borderColor:'#bfbfbf'}}>
                                                                    
                                                                    <CardContent sx={{margin:0, padding:0}}>
                                                                        <div className="col-span-6 text-center" style={{fontSize:'14px', fontWeight:'bolder'}}>
                                                                            {file.FileDescription}
                                                                        </div>
                                                                    </CardContent>
                                                                    <CardMedia>
                                                                        <Tooltip title={"Click to Enlarge"}>
                                                                            <img src={"data:" + file.FileEncoding + ";base64," + file.FileContent} style={{height:'100px', width:'100%', objectFit:'contain'}}
                                                                                 onClick={()=>{setEnlarged("data:" + file.FileEncoding + ";base64," + file.FileContent); setOpen(true)}}/>
                                                                        </Tooltip>
                                                                    </CardMedia>
                                                                    <CardActions style={{justifyContent:'space-between'}}>
                                                                        {isDesktopResolution ?
                                                                            <Tooltip title="Download File">
                                                                                <Button
                                                                                    size="small"
                                                                                    style={{margin: 0, padding: '2px 2px 2px 8px'}}
                                                                                    variant={"contained"}
                                                                                    onClick={() => downloadFile(file, fileIndex, wpnIndex)}>DOWNLOAD <DownloadIcon fontSize={"small"}/></Button>
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Download File">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    style={{margin: 0, padding: 0}}
                                                                                    color="primary"
                                                                                    onClick={() => downloadFile(file, fileIndex, wpnIndex)}><DownloadIcon fontSize={"small"}/></IconButton>
                                                                            </Tooltip>}
                                                                        {isDesktopResolution ?
                                                                            <Tooltip title="Delete File">
                                                                                <Button
                                                                                    size="small"
                                                                                    color="error"
                                                                                    style={{margin: 0, padding: '2px 2px 2px 8px'}}
                                                                                    variant={"contained"}
                                                                                    onClick={() => removeFile(file, fileIndex, wpnIndex)}>DELETE <DeleteIcon fontSize={"small"}/></Button>
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Delete File">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    color="error"
                                                                                    style={{margin: 0, padding: 0}}
                                                                                    onClick={() => removeFile(file, fileIndex, wpnIndex)}><DeleteIcon fontSize={"small"}/></IconButton>
                                                                            </Tooltip>}
                                                                    </CardActions>
                                                                </Card>
                                                            );
                                                        }) : <></>}
                                                </div>
                                                {weapon.ExistingFiles && weapon.ExistingFiles.filter((res:any) => res.Deleted === true).length > 0
                                                    ? <div className={"font-bold mb-3 mt-3"}>
                                                        Files Pending For Deletion
                                                    </div>
                                                    : <></>
                                                }
                                                {weapon.ExistingFiles ?
                                                    weapon.ExistingFiles.filter((res:any) => res.Deleted === true).map((file: any, fileIndex: number) => {
                                                        return (
                                                            <span
                                                                className={"border-2 rounded-lg border-yellow-500 px-1 mx-1 my-1"}>
                                                        <span>
                                                            <Tooltip title="Undo Deleting">
                                                                <IconButton style={{margin: 0, padding: 0}}
                                                                            onClick={() => reinstateFile(file, fileIndex, wpnIndex)}><UndoIcon/></IconButton>
                                                            </Tooltip>
                                                        </span>
                                                        <span className={"font-bold text-yellow-500 mr-1"}
                                                                onClick={() => reinstateFile(file, fileIndex, wpnIndex)}>
                                                            {file.FileName}
                                                        </span>
                                                    </span>
                                                        );
                                                    }) : <></>}
                                                {weapon.AddedFiles && weapon.AddedFiles.length > 0
                                                    ? <div className={"font-bold mb-3 mt-3"}>
                                                        Files Pending for Upload
                                                    </div>
                                                    : <></>
                                                }
                                                <div className = "grid grid-cols-6 gap-y-1 gap-x-2">
                                                    {weapon.AddedFiles ?
                                                        weapon.AddedFiles.map((file: any, fileIndex: number) => {
                                                            return (
                                                                <Card sx={{ maxWidth: 345, boxShadow:'none'}} style={{border:'1px solid black', borderRadius:'5px',  borderColor:'#bfbfbf'}}>
                                                                    <CardContent sx={{margin:0, padding:0}}>
                                                                        <div className="col-span-6 text-center" style={{fontSize:'14px', fontWeight:'bolder'}}>
                                                                            {file.FileDescription}
                                                                        </div>
                                                                    </CardContent>
                                                                    <CardMedia>
                                                                        <Tooltip title={"Click to Enlarge"}>
                                                                            <img src={"data:" + file.FileEncoding + ";base64," + file.FileContent} style={{height:'100px', width:'100%', objectFit:'contain'}}
                                                                                 onClick={()=>{setEnlarged("data:" + file.FileEncoding + ";base64," + file.FileContent); setOpen(true)}}/>
                                                                        </Tooltip>
                                                                    </CardMedia>
                                                                    <CardActions style={{justifyContent:'space-between'}}>
                                                                        {isDesktopResolution ?
                                                                            <Tooltip title="Download File">
                                                                                <Button
                                                                                    size="small"
                                                                                    style={{margin: 0, padding: '2px 2px 2px 8px'}}
                                                                                    variant={"contained"}
                                                                                    onClick={() => downloadFile(file, fileIndex, wpnIndex)}>DOWNLOAD <DownloadIcon fontSize={"small"}/></Button>
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Download File">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    style={{margin: 0, padding: 0}}
                                                                                    onClick={() => downloadFile(file, fileIndex, wpnIndex)}><DownloadIcon fontSize={"small"}/></IconButton>
                                                                            </Tooltip>}
                                                                        {isDesktopResolution ?
                                                                            <Tooltip title="Delete File">
                                                                                <Button
                                                                                    size="small"
                                                                                    color="error"
                                                                                    style={{margin: 0, padding: '2px 2px 2px 8px'}}
                                                                                    variant={"contained"}
                                                                                    onClick={() => removeFile(file, fileIndex, wpnIndex)}>DELETE <DeleteIcon fontSize={"small"}/></Button>
                                                                            </Tooltip>
                                                                            :
                                                                            <Tooltip title="Delete File">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    color="error"
                                                                                    style={{margin: 0, padding: 0}}
                                                                                    onClick={() => removeFile(file, fileIndex, wpnIndex)}><DeleteIcon fontSize={"small"}/></IconButton>
                                                                            </Tooltip>}
                                                                    </CardActions>
                                                                </Card>
                                                            );
                                                        }) : <></>}
                                                </div>
                                            </CardContent>
                                        </Card>
                                </Card>
                                : <></>)
                        )
                    })}
                </div>

                <div className="mx-4 mb-2">
                    <IconButton size={"large"} onClick={handleAddWeapon} color={"inherit"}
                        title={"Add Additional Weapon(s)"}> <WeaponAddIcon/>
                    </IconButton>
                    To add additional guns click here
                </div>

                <div className=""></div>
                {vehicles.map((vehicle: NJTraceVehicleObject, indx) => {
                    return (
                        (vehicle?.Deleted != true ?
                            <div className=" border-2 border-gray-600 mx-4 mt-4">
                                <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">Vehicle
                                    <span className="inline">
                                        <IconButton size={"small"} onClick={() => handleRemoveVehicle(indx)} color={"error"}
                                                    title={"Remove Vehicle"}> <DeleteIcon/> </IconButton>
                                    </span>
                                </div>
                                <div className="grid grid-cols-3 gap-x-3 gap-y-1.5 px-4 pt-4">
                                    <div className="">
                                        <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                   label="Plate Number"
                                                   InputLabelProps={{shrink: (!!(vehicle?.PlateNum && vehicle?.PlateNum?.length > 0))}}
                                                   inputProps={{style: {textAlign: 'center'}, maxLength:10}}
                                                   value={vehicle?.PlateNum}
                                                   onChange={(e) => handleValueChange('Plate Number', e.target.value, indx)}/>
                                    </div>
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="VehiclePlateState"
                                            disableClearable
                                            size={"small"}
                                            options={vehicle.PlateStateList}
                                            onChange={(event, value: any) => handleValueChange('Plate State', value.ID ?? value, indx)}
                                            onInputChange={(event, value:any) => value === '' ? handleValueChange('Vehicle Country', value, indx) : () => {}}
                                            getOptionLabel={(option: any) => handleGetDDLabel(vehicle.PlateStateList ?? [], option, 1)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Plate State"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                />
                                            )}
                                            value={vehicle?.PlateSt ? vehicle?.PlateSt : ''}
                                        />
                                    </div>
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="VehicleCountry"
                                            disableClearable
                                            size={"small"}
                                            options={CountryList}
                                            onChange={(event, value: any) => handleValueChange('Vehicle Country', value.ID ?? value, indx)}
                                            onInputChange={(event, value:any) => value === '' ? handleValueChange('Vehicle Country', value, indx) : () => {}}
                                            getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Country"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                />
                                            )}
                                            value={vehicle?.VehicleCountry ? vehicle?.VehicleCountry : ''}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 gap-x-3 gap-y-1.5 px-4 pb-6 pt-4">
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="Color"
                                            disableClearable
                                            size={"small"}
                                            options={vehicleColorList.map((option: any) => option.Key)}
                                            onInputChange={(event, value) => handleValueChange('Color', value, indx)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Color"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                    //error={!vehicle?.VehicleColor || vehicle?.VehicleColor == undefined ? true : !vehicle?.VehicleColor || vehicle?.VehicleColor == undefined ? true : false}
                                                />
                                            )}
                                            value={vehicle?.VehicleColor ? vehicle?.VehicleColor : ''}
                                        />
                                    </div>
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="Vehicle Make"
                                            disableClearable
                                            size={"small"}
                                            options={vehicleMakeList.map((option: any) => option.Key)}
                                            onInputChange={(event, value) => handleValueChange('Vehicle Make', value, indx)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vehicle Make"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                    /*error={!vehicle?.VehicleMake || vehicle?.VehicleMake == undefined ? true : !vehicle?.VehicleMake || vehicle?.VehicleModel == undefined ? true : false}*/
                                                />
                                            )}
                                            value={vehicle?.VehicleMake ? vehicle?.VehicleMake : ''}
                                        />
                                    </div>
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="Vehicle Model"
                                            disableClearable
                                            size={"small"}
                                            options={vehicle?.MODEL_LIST ? vehicle?.MODEL_LIST?.map((option: any) => option.Key) : []}
                                            onInputChange={(event, value) => handleValueChange('Vehicle Model', value, indx)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vehicle Model"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                    /*error={!vehicle?.VehicleModel || vehicle?.VehicleModel == undefined ? true : !vehicle?.VehicleYear || vehicle?.VehicleYear == undefined ? true : false}*/
                                                />
                                            )}
                                            value={vehicle?.VehicleModel ? vehicle?.VehicleModel : ''}
                                        />
                                    </div>
                                    <div className="">
                                        <Autocomplete
                                            freeSolo
                                            id="Year"
                                            disableClearable
                                            size={"small"}
                                            options={vehicle?.YEAR_LIST ? vehicle?.YEAR_LIST?.map((option: any) => option.Key) : []}
                                            onInputChange={(event, value) => handleValueChange('Year', value, indx)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Year"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        style: {textAlign: 'center'},
                                                    }}
                                                    /*error={!vehicle?.VehicleModel || vehicle?.VehicleModel == undefined ? true : !vehicle?.VehicleYear || vehicle?.VehicleYear == undefined ? true : false}*/
                                                />
                                            )}
                                            value={vehicle?.VehicleYear ? vehicle?.VehicleYear : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            : <></>)
                    )
                })}

                <div className="mx-4 mb-2">
                    <IconButton size={"large"} onClick={handleAddVehicle} color={"inherit"}
                                title={"Add Additional Vehicle(s)"}> <WeaponAddIcon/> </IconButton>
                </div>
                {/* If crime code is Found Firearm(0098) remove possessor/associate
                    If how your agency came into possession of this firearm: Found, Turned in, Buy-back is selected remove possessor/associate
                */}
                <div className="">
                    {!(params?.main?.Crime_Code && params?.main?.Crime_Code === '0098') && (weapons.filter((weapon:any)=> weapon?.Agency_Possession_How !== 'Found' && weapon?.Agency_Possession_How !== 'Turned in' && weapon?.Agency_Possession_How !== 'Buy-back').length > 0) && possessors.map((possessor:any, indx) => {
                        return (
                            (possessor.Deleted !== true ?
                                <div className=" border-2 border-gray-600 mx-4 mt-4" key={"per_"+indx}>
                                    <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
                                        <div className="grid grid-cols-3 gap-x-3 gap-y-3 pt-1.5" >
                                            <div className={"col-start-2"} >
                                                <Autocomplete
                                                    popupIcon=""
                                                    freeSolo
                                                    id="Role"
                                                    disableClearable
                                                    size={"small"}
                                                    style={{width: '20%', display: 'inline'}}
                                                    options={getRolesList(possessor)}                                                
                                                    onChange={(event, value: any) => handleValueChange('POS Role', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS Role', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel([{ID: "A", Description: "A - ASSOCIATE"}, {ID: "G", Description: "G - SUSPECT"}, {ID: "M", Description: "M - MULTIPLE"}, {ID: "S", Description: "S - POSSESSOR"}], option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField                                                                                                                 
                                                            {...params}
                                                            label="Role"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center', fontWeight: 'bold'},
                                                            }}
                                                              
                                                            
                                                            
                                                            
                                                            
                                                                error={!possessor?.INDIV_ROLE || possessor?.INDIV_ROLE.length === 0} 
                                                        />
                                                    )}
                                                    value={possessor?.INDIV_ROLE ? possessor?.INDIV_ROLE : ''}
                                                />
                                            </div>
                                            <div className={"justify-self-start"}>
                                                <IconButton size={"small"}
                                                            onClick={() => handleRemovePossessor(indx)}
                                                            color={"error"} title={"Remove Possessor"}>
                                                    <DeleteIcon/> </IconButton>
                                            </div>
                                        </div>
                                        {/* <span className={"inline bg-blue-700"}>
                                            Possessor ({(possessor.INDIV_FIRSTNAME && possessor.INDIV_LASTNAME) ? (possessor.INDIV_FIRSTNAME + ' ' + possessor.INDIV_LASTNAME) : (indx + 1)})
                                        </span>*/}
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-3 gap-y-3 px-4 pt-6 pb-3">
                                        <div className="">
                                            <TextField error={possessor?.INDIV_FIRSTNAME || (params?.main?.Crime_Code && params?.main?.Crime_Code == '0098') ? false : true}
                                                       variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="First Name"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_FIRSTNAME && possessor.INDIV_FIRSTNAME?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={possessor.INDIV_FIRSTNAME}
                                                       onChange={(e) => handleValueChange('POS First Name', e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Middle Name"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_MIDDLENAME && possessor.INDIV_MIDDLENAME?.length > 0))}}
                                                        inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('MI') ? {style: {textAlign: 'center', background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                       value={possessor.INDIV_MIDDLENAME}
                                                       onChange={(e) => handleValueChange("POS Middle Name", e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <TextField error={possessor?.INDIV_LASTNAME || (params?.main?.Crime_Code && params?.main?.Crime_Code == '0098') ? false : true}
                                                       variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Last Name"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_LASTNAME && possessor.INDIV_LASTNAME?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={possessor.INDIV_LASTNAME}
                                                       onChange={(e) => handleValueChange("POS Last Name", e.target.value, indx)}/>
                                        </div>
                                        <div className="pr-1">
                                            <TextField error={possessor?.INDIV_DOB ? false : true}
                                                        variant="outlined" margin='none' type={"date"} fullWidth
                                                        size={"small"} label="DOB"
                                                        InputLabelProps={{shrink: (!!(possessor.INDIV_DOB))}}
                                                        inputProps={{style: {textAlign: 'center'}, min:'1900-01-01', max:formatInputDate(todayDate)}}
                                                        value={possessor.INDIV_DOB}
                                                        onChange={(e) => handleValueChange("POS DOB", e.target.value, indx)}/>
                                        </div>   
                                        <div className="grid grid-cols-2">
                                            <div className="pr-1">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="FBI" 
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('FBI') ? {style: {textAlign: 'center', background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                           value={possessor.FBI}
                                                           onChange={(e) => handleValueChange("POS FBI", e.target.value, indx)}/>
                                            </div>
                                            <div className="pl-1">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label={GetStringNameForSite(SiteName,"SBI #")} 
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('SBINo') ? {style: {textAlign: 'center', background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                           value={possessor.SBINo}
                                                           onChange={(e) => handleValueChange("POS SBI", e.target.value, indx)}/>
                                            </div>
                                        </div> 
                                        <div className="grid grid-cols-2">
                                            <div className="pr-1">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="POS Sex"
                                                    disableClearable
                                                    size={"small"}
                                                    options={sexList}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(sexList, option, 1)}
                                                    onChange={(event, value) => handleValueChange('POS Sex', value.ID, indx)}
                                                    onInputChange={(event, value: any) => value === '' ? handleValueChange('POS Sex', '', indx) : () => { }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Sex"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_SEX') ? { textAlign: 'center', background:'yellow'}: { textAlign: 'center' }
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor?.INDIV_SEX ?? ''}
                                                />
                                            </div>
                                            <div className="pl-1">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="POS Race"
                                                    disableClearable
                                                    size={"small"}
                                                    options={raceList}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(raceList, option, 1)}
                                                    onChange={(event, value) => handleValueChange('POS Race', value.ID, indx)}
                                                    onInputChange={(event, value: any) => value === '' ? handleValueChange('POS Race', '', indx) : () => { }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Race"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style:{ textAlign: 'center' }
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor?.INDIV_RACE ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Street #"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_STREET_NUMBER && possessor.INDIV_STREET_NUMBER?.length > 0))}}
                                                       inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_STREET_NUMBER')  ? {style: {textAlign: 'center', background: 'yellow'}, maxLength:6} : {style: {textAlign: 'center'}, maxLength:6}}
                                                       value={possessor.INDIV_STREET_NUMBER}
                                                       onChange={(e) => handleValueChange('POS Street #', e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Street Name"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_STREET_NAME && possessor.INDIV_STREET_NAME?.length > 0))}}
                                                       inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_STREET_NAME') ? {style: {textAlign: 'center', background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                       value={possessor.INDIV_STREET_NAME}
                                                       onChange={(e) => handleValueChange('POS Street Name', e.target.value, indx)}/>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="pr-1">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="POS Type"
                                                    disableClearable
                                                    size={"small"}
                                                    options={streetSuffixList}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(streetSuffixList, option, 1)}
                                                    onChange={(event, value) => handleValueChange('POS Type', value.ID, indx)}
                                                    onInputChange={(event, value: any) => value === '' ? handleValueChange('POS Type', '', indx) : () => {}}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Type"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center'}
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor?.INDIV_STREET_SUFFIX ?? ''}
                                                />
                                            </div>
                                            <div className="pl-1">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="Apt #"
                                                           InputLabelProps={{shrink: (!!(possessor.INDIV_APT_NUMBER && possessor.INDIV_APT_NUMBER?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}, maxLength:6}}
                                                           value={possessor.INDIV_APT_NUMBER}
                                                           onChange={(e) => handleValueChange('POS Apt #', e.target.value, indx)}/>
                                            </div>
                                        </div>                                        
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="City"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_CITY && possessor.INDIV_CITY?.length > 0))}}
                                                       inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_CITY') ? {style: {textAlign: 'center',background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                       value={possessor.INDIV_CITY}
                                                       onChange={(e) => handleValueChange('POS City', e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="POS State"
                                                    disableClearable
                                                    size={"small"}
                                                    options={stateList}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(stateList, option, 1)}
                                                    onChange={(event, value) => handleValueChange('POS State', value.ID, indx)}
                                                    onInputChange={(event, value: any) => value === '' ? handleValueChange('POS State', '', indx) : () => {}}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="State"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_STATE') ? {textAlign: 'center', background:'yellow'} : {textAlign: 'center'}
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor?.INDIV_STATE ?? ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                       label="Zip"
                                                       InputLabelProps={{shrink: (!!(possessor.INDIV_ZIP && possessor.INDIV_ZIP?.length > 0))}}
                                                       inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_ZIP') ? {style: {textAlign: 'center', background: 'yellow'}, maxLength: 10} : {style: {textAlign: 'center'}, maxLength: 10}}
                                                       value={possessor.INDIV_ZIP}
                                                       onChange={(e) => handleValueChange('POS Zip', e.target.value, indx)}/>
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="INC County"
                                                disableClearable
                                                size={"small"}
                                                options={possessor.CountyList ?? []}
                                                onChange={(event, value: any) => handleValueChange('POS County', value.Key, indx)}
                                                onInputChange={(event, value: any) => value === '' ? handleValueChange('POS County', '', indx) : () => {}}
                                                getOptionLabel={(option: any) => handleGetDDLabel(possessor.CountyList, option, 2)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="County"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_COUNTY') ? {textAlign: 'center', background:'yellow'} : {textAlign: 'center'}
                                                        }}
                                                    />
                                                )}
                                                value={possessor?.INDIV_COUNTY ? possessor?.INDIV_COUNTY : ''}
                                            />
                                        </div>
                                        <div className="">
                                            <Autocomplete
                                                //freeSolo
                                                popupIcon=""
                                                id="PossesorCountry"
                                                disableClearable
                                                size={"small"}
                                                options={CountryList} // {ID:'US', Description:'UNITED STATES'}
                                                onChange={(event, value: any) => handleValueChange('POS Country', value.ID ?? value, indx)}
                                                onInputChange={(event, value:any) => value === '' ? handleValueChange('POS Country', value, indx) : () => {}}
                                                getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Country"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            style: {textAlign: 'center'},
                                                        }}
                                                    />
                                                )}
                                                value={possessor?.INDIV_COUNTRY ? possessor?.INDIV_COUNTRY : ''}
                                            />
                                        </div>                                       
                                        {isUserPermission('NJTrace') ? 
                                        <div className="">
                                            <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                    label="Gang Name"
                                                    InputLabelProps={{shrink: (!!(possessor.GANG_NAME && possessor.GANG_NAME?.length > 0))}}
                                                    inputProps={{style: {textAlign: 'center'}}}
                                                    value={possessor.GANG_NAME}
                                                    onChange={(e) => handleValueChange('POS Gang Name', e.target.value, indx)}/>
                                        </div>                                                                                 
                                        : <></>}
                                                                         
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="Daily Crime Gun"
                                                           InputLabelProps={{shrink: (!!(possessor.DailyCrimeGun && possessor.DailyCrimeGun?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}}}
                                                           value={possessor.DailyCrimeGun}
                                                           onChange={(e) => handleValueChange('POS DailyCrimeGun', e.target.value, indx)}/>
                                            </div>
                                                    : <></>}
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="Arrests"
                                                           InputLabelProps={{shrink: (!!(possessor.Arrests && possessor.Arrests?.length > 0))}}
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('Arrests') ? {style: {textAlign: 'center', background: 'yellow'}} :{style: {textAlign: 'center'}}}
                                                           value={possessor.Arrests}
                                                           onChange={(e) => handleValueChange('POS Arrests', e.target.value, indx)}/>
                                            </div>
                                                : <></>}
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="Felonys"
                                                           InputLabelProps={{shrink: (!!(possessor.Felonys && possessor.Felonys?.length > 0))}}
                                                           inputProps={{style: {textAlign: 'center'}}}
                                                           value={possessor.Felonys}
                                                           onChange={(e) => handleValueChange('POS Felonys', e.target.value, indx)}/>
                                            </div>
                                            : <></>}
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="PriorGun"
                                                           InputLabelProps={{shrink: (!!(possessor.PriorGun && possessor.PriorGun?.length > 0))}}
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('PriorGun') ? {style: {textAlign: 'center', background: 'yellow'}} :  {style: {textAlign: 'center'}}}
                                                           value={possessor.PriorGun}
                                                           onChange={(e) => handleValueChange('POS PriorGun', e.target.value, indx)}/>
                                            </div>
                                        : <></>}
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="CRUMB"
                                                           InputLabelProps={{shrink: (!!(possessor.CRUMB && possessor.CRUMB?.length > 0))}}
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('CRUMB') ? {style: {textAlign: 'center', background:'yellow'}}: {style: {textAlign: 'center'}}}
                                                           value={possessor.CRUMB}
                                                           onChange={(e) => handleValueChange('POS CRUMB', e.target.value, indx)}/>
                                            </div>
                                        : <></>}
                                        {isUserPermission('NJTrace') ?
                                            <div className="">
                                                <TextField type={"number"} variant="outlined" margin='none'
                                                           fullWidth size={"small"} label="POPHITS"
                                                           InputLabelProps={{shrink: (!!(possessor.POPHITS && possessor.POPHITS?.length > 0))}}
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('POPHITS') ? {style: {textAlign: 'center', background:'yellow'}} : {style: {textAlign: 'center'}}}
                                                           value={possessor.POPHITS}
                                                           onChange={(e) => handleValueChange('POS POPHITS', e.target.value, indx)}/>
                                            </div>
                                        : <></>}
                                        <div className="grid grid-cols-2 gap-x-3 pb-6 pt-2">
                                            {isUserPermission('NJTrace') ?
                                                <div>
                                                    <Autocomplete
                                                        id="Trigger_Lock_ID"
                                                        disableClearable
                                                        size={"small"}
                                                        style={{width: '100%'}}
                                                        options={triggerLockList} // {ID:'Y', Description:'Yes'}
                                                        onChange={(event, value: any) => handleValueChange('POS Trigger Lock', value.ID ?? value.ID, indx)}
                                                        onInputChange={(event, value:any) => value === '' ? handleValueChange('POS Trigger Lock', value, indx) : () => {}}
                                                        getOptionLabel={(option: any) => handleGetDDLabel(triggerLockList, option, 1)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Trigger Lock"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                    style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_TRIGGER_LOCK') ? {textAlign: 'center', background: 'yellow'} : {textAlign: 'center'}
                                                                }}
                                                                style={{width: '100%'}}
                                                            />
                                                        )}
                                                        value={possessor?.INDIV_TRIGGER_LOCK ? possessor?.INDIV_TRIGGER_LOCK : ''}
                                                    />
                                                </div>
                                            : <></>}
                                            {isUserPermission('NJTrace') ?
                                                <div className="">
                                                    <Autocomplete
                                                        id="Crime_History"
                                                        disableClearable
                                                        size={"small"}
                                                        style={{width: '100%'}}
                                                        options={triggerLockList} // {ID:'Y', Description:'Yes'}
                                                        onChange={(event, value: any) => handleValueChange('POS Crime History', value.ID ?? value, indx)}
                                                        onInputChange={(event, value:any) => value === '' ? handleValueChange('POS Crime History', value, indx) : () => {}}
                                                        getOptionLabel={(option: any) => handleGetDDLabel(triggerLockList, option, 1)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Crime History"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    type: 'search',
                                                                    style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_CRIME_HISTORY') ? {textAlign: 'center', background:'yellow'} : {textAlign: 'center'}
                                                                }}
                                                                style={{width: '100%'}}
                                                            />
                                                        )}
                                                        value={possessor?.INDIV_CRIME_HISTORY ? possessor?.INDIV_CRIME_HISTORY : ''}
                                                    />
                                                </div>
                                            : <></>}
                                        </div>
                                        <div/>
                                        <div className="">
                                            <TextField multiline minRows={4} variant="outlined" margin='none'
                                                       fullWidth size={"small"} label="NOTES"
                                                       InputLabelProps={{shrink: (!!(possessor.NOTES && possessor.NOTES?.length > 0))}}
                                                       inputProps={{style: {textAlign: 'center'}}}
                                                       value={possessor.NOTES}
                                                       onChange={(e) => handleValueChange('POS NOTES', e.target.value, indx)}/>
                                        </div>
                                    </div>
                                    <hr className={"mx-4"}/>
                                    <div className="pl-4 mt-4 ">
                                        <div className="font-bold">
                                            Identification
                                        </div>
                                        <div className="grid grid-cols-3 gap-x-3 pb-2 pt-2 pr-4">
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="ID1COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={CountryList} // {ID:'US', Description:'UNITED STATES'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID1 Country', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID1 Country', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #1 Country of Issuance"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_ID1_COUNTRY') ? {textAlign: 'center', background: 'yellow'} : {textAlign: 'center'}
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID1_COUNTRY ? possessor.INDIV_ID1_COUNTRY : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-x-3 gap-y-1.5 pb-6 pt-2 pr-4">
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="ID1COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={possessor.ID1StateList} // {ID:'NJ', Description:'NEW JERSEY'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID1 State', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID1 State', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(possessor.ID1StateList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #1 State of Issuance"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_ID1_STATE') ? {textAlign: 'center', background:'yellow'} : {textAlign: 'center'}
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID1_STATE ? possessor.INDIV_ID1_STATE : ''}
                                                />
                                            </div>
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="ID1COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={identificationTypeList} // {ID:'NJ', Description:'NEW JERSEY'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID1 Type', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID1 Type', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(identificationTypeList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #1 Type of ID"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_ID1_TYPE') ? {textAlign: 'center', background:'yellow'} : {textAlign: 'center'}
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID1_TYPE ? possessor.INDIV_ID1_TYPE : ''}
                                                />
                                            </div>
                                            <div className="">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="ID #1 ID Number"
                                                           InputLabelProps={{shrink: (!!(possessor.INDIV_ID1_NUMBER && possessor.INDIV_ID1_NUMBER?.length > 0))}}
                                                           inputProps={possessor?.HIGHLIGHTED_FIELDS && possessor?.HIGHLIGHTED_FIELDS?.length > 0 && possessor?.HIGHLIGHTED_FIELDS.includes('INDIV_ID1_NUMBER') ? {style: {background: 'yellow'}} : {}}
                                                           value={possessor.INDIV_ID1_NUMBER}
                                                           onChange={(e) => handleValueChange('POS ID1 Number', e.target.value, indx)}/>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-x-3 pb-2 pt-2 pr-4">
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="ID2COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={CountryList} // {ID:'NJ', Description:'NEW JERSEY'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID2 Country', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID2 Country', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(CountryList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #2 Country of Issuance"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID2_COUNTRY ? possessor.INDIV_ID2_COUNTRY : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-x-3 gap-y-1.5 pb-6 pt-2 pr-4">
                                            <div className="">
                                                <Autocomplete
                                                    //freeSolo
                                                    popupIcon=""
                                                    id="ID1COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={possessor.ID2StateList} // {ID:'NJ', Description:'NEW JERSEY'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID2 State', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID2 State', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(possessor.ID2StateList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #2 State of Issuance"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID2_STATE ? possessor.INDIV_ID2_STATE : ''}
                                                />
                                            </div>
                                            <div className="">
                                                <Autocomplete
                                                    freeSolo
                                                    id="ID1COS"
                                                    disableClearable
                                                    size={"small"}
                                                    options={identificationTypeList} // {ID:'NJ', Description:'NEW JERSEY'}
                                                    onChange={(event, value: any) => handleValueChange('POS ID2 Type', value.ID ?? value, indx)}
                                                    onInputChange={(event, value:any) => value === '' ? handleValueChange('POS ID2 Type', value, indx) : () => {}}
                                                    getOptionLabel={(option: any) => handleGetDDLabel(identificationTypeList, option, 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="ID #2 Type of ID"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                type: 'search',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                        />
                                                    )}
                                                    value={possessor.INDIV_ID2_TYPE ? possessor.INDIV_ID2_TYPE : ''}
                                                />
                                            </div>
                                            <div className="">
                                                <TextField variant="outlined" margin='none' fullWidth size={"small"}
                                                           label="ID #2 ID Number"
                                                           InputLabelProps={{shrink: (!!(possessor.INDIV_ID2_NUMBER && possessor.INDIV_ID2_NUMBER?.length > 0))}}
                                                           value={possessor.INDIV_ID2_NUMBER}
                                                           onChange={(e) => handleValueChange('POS ID2 Number', e.target.value, indx)}/>
                                            </div>
                                        </div>
                                        <hr className={"mx-4"}/>                         
                                        <div>
                                            {/* {isUserPermission('NJTrace') ? 
                                            <div>
                                                <span>
                                                    <Checkbox
                                                        value={possessor?.STOLEN_NCIC_INQUIRY === 'Y'}
                                                        checked={possessor?.STOLEN_NCIC_INQUIRY === 'Y'}
                                                        onChange={(event, value) => handleValueChange('POS Stolen NCIC Inquiry', value, indx)}
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                    />
                                                </span>
                                                <span style={{fontSize: '15px'}}>
                                                This firearm was <span className={"font-bold"}>REPORTED STOLEN PER NCIC INQUIRY</span>
                                                </span>
                                            </div>  
                                            : <></>}                                           */}
                                            {/* <div>
                                                <span>
                                                    <Checkbox
                                                        value={possessor?.NARCOTICS_RELATED === 'Y'}
                                                        checked={possessor?.NARCOTICS_RELATED === 'Y'}
                                                        onChange={(event, value) => handleValueChange('POS Narcotics Related', value, indx)}
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                    />
                                                </span>
                                                <span style={{fontSize: '15px'}}>
                                                    The recovery of this firearm was <span className={"font-bold"}>NARCOTICS RELATED</span>
                                                </span>
                                            </div> */}
                                            <div>
                                                <span>
                                                    <Checkbox
                                                        value={possessor?.JUVENILE_RELATED === 'Y'}
                                                        checked={possessor?.JUVENILE_RELATED === 'Y'}
                                                        onChange={(event, value) => handleValueChange('POS Juvenile Related', value, indx)}
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                    />
                                                </span>
                                                <span style={{fontSize: '15px'}}>
                                                    <span className={"font-bold"}>JUVENILE RELATED</span> (Person involved under 18 years old)
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    <Checkbox
                                                        value={possessor?.YOUTH_RELATED === 'Y'}
                                                        checked={possessor?.YOUTH_RELATED === 'Y'}
                                                        onChange={(event, value) => handleValueChange('POS Youth Related', value, indx)}
                                                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}
                                                    />
                                                </span>
                                                <span style={{fontSize: '15px'}}>
                                                    <span className={"font-bold"}>YOUTH RELATED</span> (Person involved 18-24 years old)
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <></>)
                        )
                    }
                    )}
                </div>
                {!(params?.main?.Crime_Code && params?.main?.Crime_Code === '0098') && (weapons.filter((weapon:any)=> weapon?.Agency_Possession_How !== 'Found' && weapon?.Agency_Possession_How !== 'Turned in' && weapon?.Agency_Possession_How !== 'Buy-back').length > 0) ?
                 <div className="mx-4 mb-2">                  
                    <IconButton size={"large"} onClick={handleAddPossessor} color={"inherit"}
                             title={"Add Additional Possessor(s)"}> <PersonAddIcon/> 
                    </IconButton>
                    </div>: <></>   
                }                   
                <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => {
                    setAlertOpen(false)
                }}>
                    <Alert onClose={() => {
                        setAlertOpen(false)
                    }} severity={alertType} sx={{width: '100%'}}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <div className={"mr-4 mb-4"} style={{"display": "flex", "justifyContent": "end"}}>
                    <ButtonGroup size="large" color="inherit">
                        <Button aria-haspopup disabled={disableSave} type="submit" onClick={()=>handleSave(false)}>Save</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </ButtonGroup>
                </div>
            </div>
        }
        </div>
    );
}

export default NJTraceInputForm;


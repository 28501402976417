import React, { useState } from "react";
import NJTraceSearch from "../../components/tools/njtrace/_njtraceSearch";
import SearchResults from "../../components/tools/njtrace/_searchResultsNJTrace";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import { getUser, isUserPermission } from "../../services/auth.service";
import { useLocation } from "react-router-dom";
import {formatDT, handleSearchTime} from "../../services/formatDate.service";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";
import { TraceDashboard } from "../../services/account.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function NJTrace() {
    let query = useQuery();
    let [njtraceMain, setnjtraceMain] = useState<any[]>([])
    let [njtraceCase, setnjtraceCase] = useState<any[]>([])
    let [persons, setnjtracePersons] = useState<any[]>([])
    let [njtraceWeapon, setnjtraceWeapon] = useState<any[]>([])
    let [njtraceWeaponStatus, setnjtraceWeaponStatus] = useState<any[]>([])
    let [dashboardInfo, setDashboardInfo] = useState<any[]>([])



    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)
    let [searchViewSelected, setSearchViewSelected] = useState<string>("")



    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any , searchHours: boolean) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "S",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": searchHours ? formatDT(params?.fromDate) : handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": searchHours ? formatDT(params?.toDate) : handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": dropDowns.ResponseStatus ? dropDowns.ResponseStatus : [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.Status ? dropDowns.Status : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "SerialNumbers": params.Serial ? params.Serial.split(',') : [],
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "SSN" : params?.PersonDetail ? params?.PersonDetail.split(',') : [],
            "AgeRangeStart" : params?.TTCRangeStart ? params?.TTCRangeStart : null,
            "AgeRangeEnd" : params?.TTCRangeEnd ? params?.TTCRangeEnd : null,
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        let njtraceMain: any[] = []
        let njtraceCase: any[] = []
        let njtracePerson: any[] = []
        let njtraceWeapon: any[] = []
        let njtraceWeaponStatus: any[] = []

        setnjtraceMain([])
        setnjtraceCase([])
        setnjtracePersons([])
        setnjtraceWeapon([])
        setnjtraceWeaponStatus([])



        if (qRes && qRes?.AnyTable) {
            for (var i of qRes.AnyTable) {
                if (!njtraceMain.some((main: any) => main.FTS_ID === i.FTS_ID)) {
                    njtraceMain.push(i)
                }
                // if (!njtraceCase.some((cCase: any) => cCase.NJTraceCaseID === i.NJTraceCaseID)) {
                //     njtraceCase.push(i)
                // }
                //using this to export everything into excel
                njtraceCase.push(i)
                if(i.NJTracePersonID){
                    if (!njtracePerson.some((person: any) => person.NJTracePersonID === i.NJTracePersonID)) {
                        njtracePerson.push(i)
                    }
                }           
                if(i.NJTraceWeaponID){
                    if (!njtraceWeapon.some((weapon: any) => weapon.NJTraceWeaponID === i.NJTraceWeaponID)) {
                        njtraceWeapon.push(i)
                    }
                }
                if(i.NJTraceATFStatusID){
                    if (!njtraceWeaponStatus.some((weaponStatus: any) => weaponStatus.NJTraceATFStatusID === i.NJTraceATFStatusID)) {
                        njtraceWeaponStatus.push(i)
                    }
                }            
            }
        }

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
        let Dashboard = await TraceDashboard({SearchType:dateType})

        if(params?.PersonDetail && params?.PersonDetail?.length > 0){
            setSearchViewSelected(params?.PersonDetail)
        }
        
        setDashboardInfo(Dashboard)
        setnjtraceMain(njtraceMain)
        setnjtraceCase(njtraceCase)
        setnjtracePersons(njtracePerson)
        setnjtraceWeapon(njtraceWeapon)
        setnjtraceWeaponStatus(njtraceWeaponStatus)
    }

    return (
        <div id="ToolsArrest">
            {(isUserPermission('NJTrace') || isUserPermission('NJTraceAdd') || (SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ) ?
                <>
                    {
                        query.get('record') ?
                        <NJTraceSearch SubmitSearch={SubmitSearch} passedData={query.get("record")}/>
                        : <NJTraceSearch SubmitSearch={SubmitSearch} />
                        
                    }
                    <SearchResults SubmitSearch={SubmitSearch} NJTraceMain={njtraceMain} NJTraceCase={njtraceCase} Persons={persons} NJTraceWeapon={njtraceWeapon} NJTraceWeaponStatus={njtraceWeaponStatus} DashboardInformation={dashboardInfo} searchListViewSelected={searchViewSelected} passedData={null} isNew={query.has("EditStatus")} /> 
                </>
                : <></>}
        </div>
    );
}

export default NJTrace;
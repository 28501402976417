import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/GunOffender.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';

interface PanelGunOffenderProps {
    person: PersonInfo,
    GunInfo: WeaponTraceInfo[],
    isLoading:boolean
}

const PanelGunOffender: React.FC<PanelGunOffenderProps> = ({ person, GunInfo, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...GunInfo]
   
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [GunInfo])

    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>Gun Offender <img src={GunTraceIcon} alt={''} width={25} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {GunInfo?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='Offender_Level_Score' headerText='Offender Level Score' />
                                    <ColumnDirective field='Offender_Level_Rating' headerText='Offender Level Rating' />
                                    <ColumnDirective field='CCN_Numbers' headerText='CCN' />
                                    <ColumnDirective field='Court_Case_No' headerText='Court Case #' />
                                    <ColumnDirective field='Court_Sentence' headerText='Court Sentence' />
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/GunOffender?ID=' + item?.id + '&Dept=' + item?.GunOffenderID, '_blank') }} >View</Button>)} headerText='View' />
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelGunOffender;
import React, {useState} from "react";
import {DashboardRefresh, DashboardTimer, SiteName} from "../../../services/config.service";
import {
    GetStolenVehicleAgencyORI,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken, RunGetStolenVehicleCountyCityList,
} from "../../../services/dashboard.service";
import {Autocomplete, Button, FormControl, Tab, TextField, Tooltip,} from "@mui/material";
import {DatePickerComponent, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";
import SVHeatMap from "./_svHeatMap";

import StolenVehicleDashboardGrid from "./_svStolenVehicles";
import Top25Grid from "./_svTop25Locations";
import RecoveredVehiclesDashboardGrid from "./_svRecoveredVehicles";
import {ComboBoxComponent} from "@syncfusion/ej2-react-dropdowns";
import {formatDate} from "../../../services/formatDate.service";
import {TabList, TabPanel} from "@mui/lab";
import TabContext from '@mui/lab/TabContext';
import Top10Brands from "./_svTop10Brands";
import SixHourBreakdown from "./_svSixHourBreakdown";
import TwentyFourHourBreakdown from "./_svTwentyFourHourBreakdown";
import {GetDefaultStateForSite, GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import {MultiSelect} from "primereact/multiselect";
import { Dropdown } from 'primereact/dropdown';


interface SVDashboardLayoutProps {
    GetStats:any,
    data:any,
}


const SVDashboardLayout: React.FC<SVDashboardLayoutProps> = ({GetStats, data}) => {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [county, setCounty] = React.useState<string>("")
    const [city, setCity] = React.useState<string>("")
    const [agency, setAgency] = React.useState<string>("")
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true)
    let today = new Date()
    today.setHours(23)
    today.setMinutes(59)
    today.setSeconds(59)
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    sevenDaysAgo.setHours(0)
    sevenDaysAgo.setMinutes(0)
    sevenDaysAgo.setSeconds(0)
    const [toDate, setToDate] = React.useState<any>(today)
    const [startDate, setStartDate] = React.useState<any>(sevenDaysAgo)
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false)
    const [cityList, setCityList] = React.useState<any[]>([])
    const [agencyList, setAgencyList] = React.useState<any[]>([])
    const [allCityList, setAllCityList] = React.useState<any[]>([])
    const [heatMapTab, setHeatMapTab] = React.useState('1');
    const [stolenTab, setStolenTab] = React.useState('1');
    const [recoveredTab, setRecoveredTab] = React.useState('1');
    const [top25Tab, setTop25Tab] = React.useState('1');
    const [sixHourTab, setSixHourTab] = React.useState('1');
    const [twentyFourHourTab, setTwentyFourHourTab] = React.useState('1');
    const [avgRecoveryTab, setAvgRecoveryTab] = React.useState('1');
    const [periodComparisonTab, setPeriodComparisonTab] = React.useState('1');
    const [countyList, setCountyList] = React.useState<any[]>([])

    React.useEffect(() => {
        if(!loading) {
            let params = {
                StartDate: startDate,
                EndDate: toDate,
                City: (city && city.trim().length > 0 ? city : "ALL CITIES"),
                County: (county && county.trim().length > 0 ? county : "ALL COUNTIES"),
                Agency: (agency && agency.trim().length > 0 ? agency : ''),
                StolenType:'',
                State: GetDefaultStateForSite(SiteName),
            }
            if(initialLoad){
                GetStats(params)
            }

        }
    },[city,toDate,startDate,county,agency])
    
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!loading) {
                let params = {
                    StartDate: startDate,
                    EndDate: toDate,
                    City: (city && city.trim().length > 0 ? city : "ALL CITIES"),
                    County: (county && county.trim().length > 0 ? county : "ALL COUNTIES"),
                    Agency: (agency && agency.trim().length > 0 ? agency : ''),
                    StolenType:'',
                    State: GetDefaultStateForSite(SiteName),
                }
                GetStats(params)
                if(dateUpdate){
                    let today = new Date()
                    setToDate(today)
                }
            }
        }, (DashboardTimer * 6));
        return () => clearInterval(interval);
    }, [city,toDate,startDate,county,agency]);

    React.useEffect(() => {
        const x = setInterval(async () => {
            let dashboard = await RunGetDashboardRefreshToken()
            if (dashboard !== null && dashboard?.AuthToken) {
                localStorage.setItem("dashboard", dashboard.AuthToken)
            }
        }, DashboardRefresh);
        return () => clearInterval(x);
    }, []);
    
    React.useEffect( () => {
        if(!initialLoad)
        {
            let temp:any = []
            let tempC:any = []
            let county:any = []

            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken)

                    if(!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL)) {
                        RunGetStolenVehicleCountyCityList(GetDefaultStateForSite(SiteName)).then((cList) => {
                            //temp.push({City:"ALL CITIES"})
                            temp.push({City: "ALL " + GetStringNameForSite(SiteName, 'CITIES').toUpperCase()})
                            temp.push.apply(temp, cList)
                            setCityList(temp)
                            setAllCityList(temp)
                            //county.push({County:"ALL COUNTIES"})
                            county.push({County: "ALL " + GetStringNameForSite(SiteName, 'COUNTIES').toUpperCase()})
                            tempC = new Set()
                            for (let c of cList) {
                                tempC.add(c.County)
                            }
                            for (let c of Array.from(tempC)) {
                                county.push({County: c})
                            }

                            setCountyList(county)
                        })
                    }
                    else {
                        GetStolenVehicleAgencyORI(GetDefaultStateForSite(SiteName)).then((aList) => {
                            let temp = aList
                            temp.unshift({Key: 'ALL AGENCIES', Value: 'ALL AGENCIES'})
                            setAgencyList(temp)
                        })
                    }
                    
                    setLoading(false)
                    setCity('ALL CITIES')
                    setInitialLoad(true)
                    setCounty('ALL COUNTIES')
                    setAgency('ALL AGENCIES')
                }
            })
            
            
        }
    }, []);

    const getAbbreviated = (str:string) => {
        if(str != null && str === "DC")
            return str
        if(str != null && str?.length > 0) {
            let temp = str.split(" ")
            let abbreviation = ""
            for (let t of temp) {
                if (t.length > 0) {
                    abbreviation += t[0]
                }
            }
            return abbreviation.replace(/[^a-zA-Z0-9]/g, '')
        }
        return ""
    }
    
    const handleAgencyChange = (a:string) => {
        if(a !== null)
            setAgency(a)
    }
    
    const handleCityChange = (c:string) => {
        // if(c) {
        //     setCity(c)
        // }
        if(c === "ALL CITIES" || c === "ALL ZONES/CITIES") {
            setCity("ALL CITIES")
        }
        else{
            setCity(c)
        }
    }

    const handleCountyChange = (county:string) => {
        if(county) {
            setCounty(county === "ALL DISTRICTS/COUNTIES" ? "ALL COUNTIES" : county)
            if(county === "ALL COUNTIES")
            {
                setCityList(allCityList)
                setCity("ALL CITIES")
            }
            else
            {
                let cList:any = []
                //cList.push({City:"ALL CITIES"})
                cList.push({City:"ALL " + GetStringNameForSite(SiteName,'CITIES').toUpperCase()})
                cList.push.apply(cList, allCityList.filter(city => city.County === county))
                setCityList(cList)
                setCity("ALL CITIES")
                
            }
        }

    }
    
    const getPeriodComparisonBoth = () => {
        if(data?.PeriodComparisonVehicles && data?.PeriodComparisonPlates)
        {
            let temp = []
            for(let i = 0; i < data?.PeriodComparisonVehicles.length; i++){
                for(let j = 0; j < data?.PeriodComparisonPlates.length; j++){
                    if(data?.PeriodComparisonVehicles[i].Type === data?.PeriodComparisonPlates[j].Type){
                        temp.push({
                            Count: data?.PeriodComparisonVehicles[i].Count + data?.PeriodComparisonPlates[j].Count,
                            FromDate: data?.PeriodComparisonVehicles[i].FromDate,
                            ToDate: data?.PeriodComparisonVehicles[i].ToDate,
                            Type: data?.PeriodComparisonVehicles[i].Type
                        })
                    }
                }
            }
            return temp
        }
        return []
    }
    
    const getPercentageChange = (category:string) => {
        switch(category){
            case 'Vehicle':
                if('PeriodComparisonVehicles' in data && Array.isArray(data.PeriodComparisonVehicles))
                {
                    if(data.PeriodComparisonVehicles.length > 1) {

                        if (data?.PeriodComparisonVehicles[1]?.Count === 0)
                            return data.PeriodComparisonVehicles[0]?.Count

                        let decrease = data.PeriodComparisonVehicles[0]?.Count - data.PeriodComparisonVehicles[1]?.Count
                        let change = (decrease / data.PeriodComparisonVehicles[1]?.Count) * 100
                        return (Math.round(change * 100) / 100)

                    }
                }
                break;
            case 'Plate':
                if('PeriodComparisonPlates' in data && Array.isArray(data.PeriodComparisonPlates))
                {
                    if(data.PeriodComparisonPlates.length > 1) {

                        if (data?.PeriodComparisonPlates[1]?.Count === 0)
                            return data.PeriodComparisonPlates[0]?.Count

                        let decrease = data.PeriodComparisonPlates[0]?.Count - data.PeriodComparisonPlates[1]?.Count
                        let change = (decrease / data.PeriodComparisonPlates[1]?.Count) * 100
                        return (Math.round(change * 100) / 100)

                    }
                }
                break;
            case 'Both':
                if('PeriodComparisonVehicles' in data && Array.isArray(data.PeriodComparisonVehicles))
                {
                    if(data.PeriodComparisonVehicles.length > 1) {

                        if (data?.PeriodComparisonVehicles[1]?.Count + data?.PeriodComparisonPlates[1]?.Count === 0)
                            return (data.PeriodComparisonVehicles[0]?.Count + data.PeriodComparisonPlates[0]?.Count)

                        let decrease = (data.PeriodComparisonVehicles[0]?.Count + data.PeriodComparisonPlates[0]?.Count  ) - (data.PeriodComparisonVehicles[1]?.Count + data.PeriodComparisonPlates[1]?.Count)
                        let change = (decrease / (data.PeriodComparisonVehicles[1]?.Count + data.PeriodComparisonPlates[1]?.Count)) * 100
                        return (Math.round(change * 100) / 100)

                    }
                }
                break;
        }
        
    }
    const handleTabChange = (category:string, newValue: string) => {
        switch(category){
            case 'stolen':
               setStolenTab(newValue)
               break;
            case 'recovered':
                setRecoveredTab(newValue)
                break;
            case 'top25':
                setTop25Tab(newValue)
                break;
            case 'HeatMap':
                setHeatMapTab(newValue);
                break;
            case 'sixHour':
                setSixHourTab(newValue);
                break;
            case 'twentyFourHour':
                setTwentyFourHourTab(newValue);
                break;
            case 'avgRecovery':
                setAvgRecoveryTab(newValue);
                break;
            case 'periodComparison':
                setPeriodComparisonTab(newValue);
                break;
        }
    };

    function compareDatesByComponents(date1: Date, date2: Date): number {
        const yearDiff = date1.getFullYear() - date2.getFullYear();
        const monthDiff = date1.getMonth() - date2.getMonth();
        const dayDiff = date1.getDate() - date2.getDate();

        if (yearDiff !== 0) {
            return yearDiff;
        }

        if (monthDiff !== 0) {
            return monthDiff;
        }

        return dayDiff;
    }
    
    //BackTrace NJ Shooting Strategic Dashboard 
    return (
        <div>
            {!loading ?
                <div>
                     <div className = "text-center text-black font-bold mt-2" style={{fontSize:26}} >
                        <span >Stolen Vehicle Dashboard </span>
                    </div>
                    <div className = "mt-3" >
                        <div className ="my-4 mx-6 grid grid-cols-12 gap-x-4 justify-items-center">
                            <div className="col-span-3" style={{width:'100%'}}>
                                
                                <div className = "border-gray-900 border-2 h-72" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Stolen Vehicles/Plates from within the date interval" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Stolen</div>
                                    </Tooltip>
                                    <TabContext value={stolenTab}>
                                        <TabList
                                            onChange={(e,value) => handleTabChange('stolen', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Vehicle" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Plate" value={'2'} style={{fontSize:"12px"}} />
                                            <Tab label="Both" value={'3'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'10px'}}>
                                            <StolenVehicleDashboardGrid data={data} tab={stolenTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'10px'}}>
                                            <StolenVehicleDashboardGrid data={data} tab={stolenTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'3'} style={{padding:'10px'}}>
                                            <StolenVehicleDashboardGrid data={data} tab={stolenTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                    </TabContext>
                                </div>

                               
                                <div className = "border-gray-900 border-2 h-72 mt-4" style={{borderRadius:'6px'}}>
                                    
                                    <Tooltip title="Recovered Vehicles/Plates from within the date interval" placement={"top"} style={{fontSize:'16px'}} arrow >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Recovered</div>
                                    </Tooltip>
                                   
                                    <TabContext value={recoveredTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('recovered', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Vehicle" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Plate" value={'2'} style={{fontSize:"12px"}} />
                                            <Tab label="Both" value={'3'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'10px'}}>
                                            <RecoveredVehiclesDashboardGrid data={data} tab={recoveredTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'10px'}}>
                                            <RecoveredVehiclesDashboardGrid data={data} tab={recoveredTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'3'} style={{padding:'10px'}}>
                                            <RecoveredVehiclesDashboardGrid data={data} tab={recoveredTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                    </TabContext>
                                    
                                </div>

                                <div className = "border-gray-900 border-2 h-72 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 25 most stolen from Cities" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "Top Districts"  : "Top 25 Locations"}
                                            <span style={{fontStyle:'italic', marginLeft:'4px'}}>(Stolen)</span>
                                        </div>
                                    </Tooltip>
                                    <TabContext value={top25Tab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('top25', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Vehicle" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Plate" value={'2'} style={{fontSize:"12px"}} />
                                            <Tab label="Both" value={'3'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'10px'}}>
                                            <Top25Grid data={data} tab={top25Tab} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'10px'}}>
                                            <Top25Grid data={data} tab={top25Tab} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'3'} style={{padding:'10px'}}>
                                            <Top25Grid data={data} tab={top25Tab} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                    </TabContext>
                                </div>
                                
                                <div className = "border-gray-900 border-2 h-64 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Top 10 Stolen Vehicle brands" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Top 10 Stolen Brands</div>
                                    </Tooltip>
                                    <Top10Brands data={data} />
                                </div>
                                
                                
                            </div>
                            <div className="col-span-6" style={{width:'100%'}} >
                                <div className = "border-gray-900 border-2 mb-4 " style={{borderRadius:'6px'}}>
                                    <Tooltip title="Filter the dashboards data to a specific County and City" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">Dashboard Filters</div>
                                    </Tooltip>
                                    <div className="grid grid-cols-12 gap-4 p-2">
                                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && 
                                            <div className="col-span-6">
                                                <Autocomplete 
                                                    id="county"
                                                    disableClearable
                                                    size={"small"} 
                                                    options={countyList.map((option) => option.County)}
                                                    autoComplete = {true} 
                                                    onChange={(event, value :any) => handleCountyChange(value)}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            label={"Select A " + GetStringNameForSite(SiteName,'COUNTY')}
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'input',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            />
                                                        )}
                                                        value={county === "ALL COUNTIES" ? "ALL " + GetStringNameForSite(SiteName,'COUNTIES').toUpperCase() : county} 
                                                                           
                                                />
                                            </div> 
                                        }
                                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                            <div className="col-span-6">
                                                <Autocomplete 
                                                    id="city"
                                                    disableClearable
                                                    size={"small"} 
                                                    options={cityList.map((option) => option.City)} 
                                                    autoComplete = {true} 
                                                    onChange={(event, value :any) => handleCityChange(value)}
                                                    renderInput={(p) => (
                                                        <TextField
                                                            {...p}
                                                            label={"Select A " + GetStringNameForSite(SiteName,'CITY')}
                                                            InputProps={{
                                                                ...p.InputProps,
                                                                type: 'input',
                                                                style: {textAlign: 'center'},
                                                            }}
                                                            />
                                                        )}
                                                        value={city === "ALL CITIES" ? "ALL " + GetStringNameForSite(SiteName,'CITIES').toUpperCase() : city} 
                                                                           
                                                />
                                            </div>
                                        }
                                        {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&
                                            <div className="col-span-4">
                                                <FormControl className="p-float-label" sx={{ width:'100%'}}>
                                                    <Dropdown
                                                        value={agency}
                                                        onChange={(e) => {handleAgencyChange(e.value)}}
                                                        style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                                                        resetFilterOnHide={true}
                                                        inputId={"dd-state"} options={agencyList} filter optionLabel="Value" optionValue="Key"
                                                        placeholder="Agency" className="w-full md:w-20rem"/>
                                                    <label className={"bg-white"} htmlFor="dd-state">Agency</label>
                                                </FormControl>
                                            </div>
                                        }
                                        <div className={(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent  cssClass="e-outline" value={startDate} onBlur={(date:any) => {compareDatesByComponents(startDate, new Date(date.target.value)) ? setStartDate(new Date(date.target.value)) : console.log('') }} onChange={(date:any) => {date.value ? setStartDate(date.value) : setStartDate(startDate)}} floatLabelType="Auto" placeholder="Start Date"/>                               
                                        </div>
                                        <div className={(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent  cssClass="e-outline" value={toDate} onBlur={(date:any) => {compareDatesByComponents(toDate, new Date(date.target.value)) ?  setToDate(new Date(date.target.value)) : console.log('') }} onChange={(date:any) => {date.value ? setToDate(date.value) : setToDate(toDate)}} floatLabelType="Auto" placeholder="End Date"/>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className = "border-gray-900 border-2 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Heatmap locations are plotted on the center of the municipality boundar" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">Location Data</div>
                                    </Tooltip>
                                    <div className="grid grid-cols-5 gap-2 mb-2 mt-1 justify-items-center">
                                        
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '1' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '1' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','1')}>Stolen Vehicle</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '2' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '2' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','2')}>Recovered Vehicle</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '4' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '4' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','4')}>Stolen Plate</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '5' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '5' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','5')}>Recovered Plate</Button></span>
                                        <span className="w-full" style={{borderBottom:(heatMapTab === '3' ? '1px solid blue' : '' )}}><Button fullWidth style={{color:(heatMapTab === '3' ? 'rgb(30 58 138)' : 'black' ), fontSize:13}} onClick={() => handleTabChange('HeatMap','3')}>All</Button></span>
                                    </div>
                                    <div className="px-2 pb-2">
                                        <SVHeatMap data={data} county={county} city={city} tab={heatMapTab}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 " style={{width:'100%'}} >

                                <div className = "border-gray-900 border-2 h-36" style={{borderRadius:'6px'}}>
                                    <Tooltip title="Average number of days to recover a Stolen Vehicle/Plate" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">AVG Recovery Time</div>
                                    </Tooltip>
                                    <TabContext value={avgRecoveryTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('avgRecovery', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Vehicle" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Plate" value={'2'} style={{fontSize:"12px"}} />
                                            <Tab label="Both" value={'3'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'5px'}}>
                                            {'AvgRecoveryTimeVehicles' in data && !isNaN(data.AvgRecoveryTimeVehicles) ?
                                                <div style={{height:"100%"}}>
                                                    <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>
                                                        <span> {data?.AvgRecoveryTimeVehicles} Days</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'5px'}}>
                                            {'AvgRecoveryTimePlates' in data && !isNaN(data.AvgRecoveryTimePlates) ?
                                                <div style={{height:"100%"}}>
                                                    <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>
                                                        <span> {data?.AvgRecoveryTimePlates} Days</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={'3'} style={{padding:'5px'}}>
                                            {'AvgRecoveryTimeBoth' in data && !isNaN(data.AvgRecoveryTimeBoth) ?
                                                <div style={{height:"100%"}}>
                                                    <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>
                                                        <span> {data?.AvgRecoveryTimeBoth} Days</span>
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-1 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                    </TabContext>
                                    
                                </div>
                                <div className = "border-gray-900 border-2 h-52 mt-4" style={{borderRadius:'6px'}}>
                                    <Tooltip title="A comparison of Stolen Vehicles/Plates based off the interval between Start and End date " placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">Stolen Comparison</div>
                                    </Tooltip>
                                    <TabContext value={periodComparisonTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('periodComparison', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Vehicle" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Plate" value={'2'} style={{fontSize:"12px"}} />
                                            <Tab label="Both" value={'3'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'5px'}}>
                                            {'PeriodComparisonVehicles' in data && Array.isArray(data.PeriodComparisonVehicles) && data.PeriodComparisonVehicles.length > 0 ?
                                                <div style={{height:"100%"}}>
                                                    {data?.PeriodComparisonVehicles.map((d:any) => (
                                                        <div className={"text-center font-bold"}>
                                                            <span style={{fontSize:'17px'}}> {d.Type}: {d.Count}</span>
                                                            <div style={{fontSize:'10px', fontStyle:'italic'}}>{formatDate(d.FromDate)} - {formatDate(d.ToDate)}</div>
                                                        </div>
                                                    ))}
                                                    <div className={"mt-2"}>
                                                        {getPercentageChange('Vehicle') > 0 ?
                                                            <div className={"text-center font-bold"} style={{color:"red", fontSize:'14px'}}>%Increase {getPercentageChange('Vehicle')}%</div>
                                                            :
                                                            <div className={"text-center font-bold"} style={{color:"green", fontSize:'14px'}}>% Decreased {(-1 * getPercentageChange('Vehicle'))}%</div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-2 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'5px'}}>
                                            {'PeriodComparisonPlates' in data && Array.isArray(data.PeriodComparisonPlates) && data.PeriodComparisonPlates.length > 0 ?
                                                <div style={{height:"100%"}}>
                                                    {data?.PeriodComparisonPlates.map((d:any) => (
                                                        <div className={"text-center font-bold"}>
                                                            <span style={{fontSize:'17px'}}> {d.Type}: {d.Count}</span>
                                                            <div style={{fontSize:'10px', fontStyle:'italic'}}>{formatDate(d.FromDate)} - {formatDate(d.ToDate)}</div>
                                                        </div>
                                                    ))}
                                                    <div className={"mt-2"}>
                                                        {getPercentageChange('Plate') > 0 ?
                                                            <div className={"text-center font-bold"} style={{color:"red", fontSize:'14px'}}>%Increase {getPercentageChange('Plate')}%</div>
                                                            :
                                                            <div className={"text-center font-bold"} style={{color:"green", fontSize:'14px'}}>% Decreased {(-1 * getPercentageChange('Plate'))}%</div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-2 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                        <TabPanel value={'3'} style={{padding:'5px'}}>
                                            {'PeriodComparisonVehicles' in data && Array.isArray(data.PeriodComparisonVehicles) && data.PeriodComparisonVehicles.length > 0 ?
                                                <div style={{height:"100%"}}>
                                                    {getPeriodComparisonBoth().map((d:any) => (
                                                        <div className={"text-center font-bold"}>
                                                            <span style={{fontSize:'17px'}}> {d.Type}: {d.Count}</span>
                                                            <div style={{fontSize:'10px', fontStyle:'italic'}}>{formatDate(d.FromDate)} - {formatDate(d.ToDate)}</div>
                                                        </div>
                                                    ))}
                                                    <div className={"mt-2"}>
                                                        {getPercentageChange('Both') > 0 ?
                                                            <div className={"text-center font-bold"} style={{color:"red", fontSize:'14px'}}>%Increase {getPercentageChange('Both')}%</div>
                                                            :
                                                            <div className={"text-center font-bold"} style={{color:"green", fontSize:'14px'}}>% Decreased {(-1 * getPercentageChange('Both'))}%</div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className={"text-center text-3xl mt-2 mb-1"} style={{color:"red", fontWeight:500}}>No Data</div>
                                            }
                                        </TabPanel>
                                    </TabContext>
                                    
                                </div>

                                <div className = "border-gray-900 border-2 mt-4" style={{borderRadius:'6px', height:'300px'}}>
                                    <Tooltip title="Stolen and Recovered Data from the last 6 Hours" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">6 Hour Breakdown</div>
                                    </Tooltip>
                                    <TabContext value={sixHourTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('sixHour', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Stolen" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Recovered" value={'2'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'10px'}}>
                                            <SixHourBreakdown data={data} tab={sixHourTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'10px'}}>
                                            <SixHourBreakdown data={data} tab={sixHourTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                    </TabContext>
                                </div>

                                <div className = "border-gray-900 border-2 h-48 mt-4" style={{borderRadius:'6px', height:'300px'}}>
                                    <Tooltip title="Stolen and Recovered Data from the last 24 Hours" placement="top" style={{fontSize:'16px'}} arrow>
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">24 Hour Breakdown</div>
                                    </Tooltip>
                                    <TabContext value={twentyFourHourTab} >
                                        <TabList
                                            onChange={(e,value) => handleTabChange('twentyFourHour', value)}
                                            indicatorColor="primary"
                                            textColor="inherit"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Stolen" value={'1'} style={{fontSize:"12px"}} />
                                            <Tab label="Recovered" value={'2'} style={{fontSize:"12px"}} />
                                        </TabList>
                                        <TabPanel value={'1'} style={{padding:'10px'}}>
                                            <TwentyFourHourBreakdown data={data} tab={twentyFourHourTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                        <TabPanel value={'2'} style={{padding:'10px'}}>
                                            <TwentyFourHourBreakdown data={data} tab={twentyFourHourTab} city={city} getAbbreviated={getAbbreviated}/>
                                        </TabPanel>
                                    </TabContext>
                                </div>
                                
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                :
                <></>}
        </div>
    );
}

export default SVDashboardLayout;

import React, { useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
    Autocomplete,
    Box, Button, ButtonGroup, Checkbox,
    Container,
    Drawer,
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    Grid as MUIGrid, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SetPIRSupplement } from "../../../services/pir.service";
import { PIRReport, PIRSupplementReport } from "../../../interfaces/PIR.interface";
import {formatDate, formatDT, formatInputDate} from "../../../services/formatDate.service";

interface PreliminarySupplementReportProps {
    handleReportSubmit: any,
    Record: any,
    ParentReportId: any,
    btPerson: any,
    drawerDisplay: any,
    HandleSuppClose: any,
    SummaryInfo: any,
    ccnList: any
}

const PreliminarySupplementReportInputForm: React.FC<PreliminarySupplementReportProps> = ({ handleReportSubmit, Record = null, ParentReportId = null, btPerson = null, drawerDisplay = false, HandleSuppClose, SummaryInfo = null, ccnList = null }) => {
    const [supplement, setSupplement] = useState<PIRSupplementReport>({ Background_UpdatedBackground: false, Summary_Requester: "RTCC", ShotSpotter_UpdatedShotSpotter: false, Class_UpdatedClassification: false, ReportID: ParentReportId })

    const [open, setOpen] = useState<boolean>(true)
    const [drawerNoCCNDisable, setDrawerNOCCNDisable] = useState<boolean>(drawerDisplay && (!supplement.Summary_CCN || supplement?.Summary_CCN?.length))

    React.useEffect(() => {
        if (Record) {
            let temp = {...Record}
            temp.Summary_DateTime = formatDT(temp.Summary_DateTime ?? "")
            temp.Background_DOB = formatInputDate(temp.Background_DOB ?? "")
            setSupplement(temp)

        } else {
            clearSuppForm();
        }
    }, [Record])

    React.useEffect(() => {
        if (SummaryInfo && Object.keys(SummaryInfo)?.length > 0) {
            setSupplement((prevState) => ({
                ...prevState,
                Summary_Requester: SummaryInfo.Summary_Requester,
                Summary_DateTime: formatDT(SummaryInfo.Summary_DateTime ?? ""),
                Summary_Type: SummaryInfo.Summary_Type,
                Summary_Location: SummaryInfo.Summary_Location,
                Summary_District: SummaryInfo.Summary_District,
                Summary_PSA: SummaryInfo.Summary_PSA,
                Summary_CCN: SummaryInfo.Summary_CCN,
                Summary_Rank: SummaryInfo.Summary_Rank,
                Summary_SpecialistFirstName: SummaryInfo.Summary_SpecialistFirstName,
                Summary_SpecialistLastName: SummaryInfo.Summary_SpecialistLastName,
                Summary_Radio: SummaryInfo.Summary_Radio,
                Summary_Detective: SummaryInfo.Summary_Detective,
                Summary_Detective_District: SummaryInfo.Summary_Detective_District,
                Summary_Narrative: SummaryInfo.Summary_Narrative,
            }))
        }
    }, [SummaryInfo])

    React.useEffect(() => {
        if (btPerson && Object.keys(btPerson)?.length > 0) {
            const formatDate = (date: any) => {
                if (!date) return "";
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const day = String(d.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;  // Format as yyyy-MM-dd for the input field
            };
            setSupplement((prevState) => ({
                ...prevState,
                Background_UpdatedBackground: true,
                Background_CaseRelationship: "Person of Interest",
                Summary_Requester: "RTCC",
                Background_DOB: formatDate(btPerson.PersonInfo.DOB),
                Background_FName: btPerson.PersonInfo.FName,
                Background_LName: btPerson.PersonInfo.LName,
                Background_PDID: btPerson.PersonInfo.SBINo
            }))
        }
    }, [btPerson])

    React.useEffect(() => {
        if (drawerDisplay) {
            if (!open) {
                HandleSuppClose()
            }
        }
    }, [open])

    const clearSuppForm = () => {
        setSupplement({ Background_UpdatedBackground: false, Summary_Requester: "RTCC", ShotSpotter_UpdatedShotSpotter: false, Class_UpdatedClassification: false, ReportID: ParentReportId })

    }

    const handleInputChange = (e: { target: { name: string; value: any; }; }) => {
        var { name, value } = e.target;

        let temp: any = { ...supplement }
        temp[name] = (value instanceof Date ? value.toLocaleString() : value)
        if(drawerDisplay && name === 'Summary_CCN'){
            if(value == ""){
                setDrawerNOCCNDisable(true)
                temp.ReportID = ""
                temp.Summary_Requester = "RTCC"
                temp.Summary_DateTime = undefined
                temp.Summary_Type = ""
                temp.Summary_Location = ""
                temp.Summary_District = ""
                temp.Summary_PSA = ""
                temp.Summary_CCN = ""
                temp.Summary_Rank = ""
                temp.Summary_SpecialistFirstName = ""
                temp.Summary_SpecialistLastName = ""
                temp.Summary_Radio = ""
                temp.Summary_Detective = ""
                temp.Summary_Detective_District = ""
                temp.Summary_Narrative = ""
            }
            else {
                let SummaryInfo = ccnList.find((s: any) => s.Summary_CCN === value)
                setDrawerNOCCNDisable(false)
                temp.ReportID = SummaryInfo.ID
                temp.Summary_Requester = SummaryInfo.Summary_Requester
                temp.Summary_DateTime = formatDT(SummaryInfo.Summary_DateTime ?? "")
                temp.Summary_Type = SummaryInfo.Summary_Type
                temp.Summary_Location = SummaryInfo.Summary_Location
                temp.Summary_District = SummaryInfo.Summary_District
                temp.Summary_PSA = SummaryInfo.Summary_PSA
                temp.Summary_CCN = SummaryInfo.Summary_CCN
                temp.Summary_Rank = SummaryInfo.Summary_Rank
                temp.Summary_SpecialistFirstName = SummaryInfo.Summary_SpecialistFirstName
                temp.Summary_SpecialistLastName = SummaryInfo.Summary_SpecialistLastName
                temp.Summary_Radio = SummaryInfo.Summary_Radio
                temp.Summary_Detective = SummaryInfo.Summary_Detective
                temp.Summary_Detective_District = SummaryInfo.Summary_Detective_District
                temp.Summary_Narrative = SummaryInfo.Summary_Narrative
            }
        }
        
        setSupplement(temp)
        
    };

    const handleCheckboxInputChange = (e: { target: { name: string; checked: any; }; }) => {
        const { name, checked } = e.target;

        let temp: any = { ...supplement }
        temp[name] = checked

        setSupplement(temp)
    }

    const handleSuppSubmit = () => {
        if(supplement?.ReportID) {
            SetPIRSupplement(supplement).then((r: any) => {
                handleReportSubmit(r)
            }).catch((e: any) => {
                console.log(e)
            })
        }

        clearSuppForm();
    };

    const closeHandler = () => {
        handleReportSubmit(null)
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const display = () => (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container>
                    <form className="border-double border-2 border-inherit px-4">
                        <div className="container mt-4">
                            {/* SUMMARY Header */}
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    SUMMARY
                                </Typography>
                            </Box>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Requested By:
                                    </FormLabel>
                                    <RadioGroup row className="mt-2" aria-label="Requester"
                                        name="Summary_Requester"
                                        value={supplement?.Summary_Requester}
                                        onChange={handleInputChange}
                                        defaultValue="RTCC">
                                        <FormControlLabel value="RTCC" control={<Radio />} label="RTCC" />
                                        <FormControlLabel value="Detective" control={<Radio />} label="Detective" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid container spacing={3} marginTop="2%" className="pl-2 pr-2 mb-6">
                                {/* Date and Time*/}
                                <Grid item xs={6}>
                                    <DateTimePicker
                                        label="Date and Time"
                                        ampm={false}
                                        value={supplement?.Summary_DateTime ?? ""}
                                        onChange={(date) => handleInputChange({
                                            target: {
                                                name: "Summary_DateTime",
                                                value: (date ?? null)
                                            }
                                        })}
                                        disabled={drawerNoCCNDisable}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                {/* Incident Type */}
                                <Grid item xs={6}>
                                    <TextField
                                        label="Incident Type"
                                        name="Summary_Type"
                                        value={supplement?.Summary_Type ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                        disabled={drawerNoCCNDisable}
                                    />
                                </Grid>

                                {/* Location */}
                                <Grid item xs={3}>
                                    <TextField
                                        label="Location"
                                        name="Summary_Location"
                                        value={supplement?.Summary_Location ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                        disabled={drawerNoCCNDisable}
                                    />
                                </Grid>

                                {/* PSA */}
                                <Grid item xs={1}>
                                    <TextField
                                        label="PSA"
                                        name="Summary_PSA"
                                        value={supplement?.Summary_PSA ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                        disabled={drawerNoCCNDisable}
                                    />
                                </Grid>

                                {/* CCN */}
                                <Grid item xs={2}>
                                    {drawerDisplay && ccnList?.length > 0 ?
                                        <Autocomplete
                                            placeholder={"CCN"}
                                            options={ccnList}
                                            getOptionLabel={(option) => option.Summary_CCN}
                                            value={ccnList.find((s:any) => s.Summary_CCN === supplement?.Summary_CCN) ?? null}
                                            onChange={(event, newValue) => handleInputChange({ target: { name: "Summary_CCN", value: newValue?.Summary_CCN ?? "" } })}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" fullWidth placeholder={"CCN"} />}
                                            style={(supplement?.Summary_CCN && supplement?.Summary_CCN?.length > 0) ? {background:'transparent'} : {background:'yellow'}}                                            
                                        />
                                    :
                                        <TextField
                                            label="CCN"
                                            name="Summary_CCN"
                                            value={supplement?.Summary_CCN ?? ""}
                                            onChange={handleInputChange}
                                            fullWidth
                                            
                                        />
                                    }
                                </Grid>

                                {/*Rank/Title*/}
                                <Grid item xs={2}>
                                    <TextField
                                        label="Rank/Title"
                                        name="Summary_Rank"
                                        value={supplement?.Summary_Rank ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>

                                {/* CRS First Name */}
                                <Grid item xs={2}>
                                    <TextField
                                        label="First Name"
                                        name="Summary_SpecialistFirstName"
                                        value={supplement?.Summary_SpecialistFirstName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                        disabled={drawerNoCCNDisable}
                                    ></TextField>
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        label="Last Name"
                                        name="Summary_SpecialistLastName"
                                        value={supplement?.Summary_SpecialistLastName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth
                                        disabled={drawerNoCCNDisable}
                                    ></TextField>
                                </Grid>
                            </Grid>

                            {/* Radio */}
                            <Grid item xs={12} className="pl-2 pr-2">
                                {supplement?.Summary_Requester === "RTCC" && (
                                    <>
                                        <Typography variant="body1" component="span">
                                            The RTCC initiated a Preliminary Investigative Report in reference to the above
                                            offense based on the CAD call and actively monitoring the
                                        </Typography>
                                        <TextField
                                            name="Summary_Radio"
                                            value={supplement?.Summary_Radio ?? ""}
                                            onChange={handleInputChange}
                                            rows={1}
                                            disabled={drawerNoCCNDisable}
                                            size="small"
                                            style={{ width: '8%', margin: '0 10px', verticalAlign: 'middle' }}
                                            inputProps={{
                                                style: {
                                                    borderRadius: "1px",
                                                    height: "10px",
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                        <Typography variant="body1" component="span">
                                            radio transmissions. Please advise if further investigation reveals additional or
                                            conflicting information necessary to update this initial report.
                                        </Typography>
                                    </>
                                )}
                                {supplement?.Summary_Requester === "Detective" && (
                                    <>
                                        <Typography
                                            style={{
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                            }}>
                                            Detective
                                        </Typography>
                                        <TextField
                                            name="Summary_Detective"
                                            value={supplement?.Summary_Detective ?? ""}
                                            onChange={handleInputChange}
                                            rows={1}
                                            disabled={drawerNoCCNDisable}
                                            size="small"
                                            style={{ width: '15%', margin: '0 10px', verticalAlign: 'middle' }}
                                            inputProps={{
                                                style: {
                                                    borderRadius: "1px",
                                                    height: "10px",
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                        <Typography variant="body1" component="span">
                                            of the
                                        </Typography>
                                        <TextField
                                            name="Summary_Detective_District"
                                            value={supplement?.Summary_Detective_District ?? ""}
                                            onChange={handleInputChange}
                                            rows={1}
                                            disabled={drawerNoCCNDisable}
                                            size="small"
                                            style={{ width: '15%', margin: '0 10px', verticalAlign: 'middle' }}
                                            inputProps={{
                                                style: {
                                                    borderRadius: "1px",
                                                    height: "10px",
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                        <Typography variant="body1" component="span">
                                            requested a Preliminary Investigative Report in reference to the incident
                                            listed above.
                                            <br />
                                            <br />
                                        </Typography>
                                    </>
                                )}
                                <Typography>
                                    Add any positive results from CCTV/cameras, DYRS, ShotSpotter, or LPR. Do NOT
                                    include any other information (e.g., no more running resume).
                                </Typography>
                                <TextField
                                    label="Summary of Positive Results"
                                    name="Summary_Narrative"
                                    value={supplement?.Summary_Narrative ?? ""}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={10}
                                    fullWidth
                                    disabled={drawerNoCCNDisable}
                                    style={{ marginTop: '10px' }}
                                />
                                <Typography variant="body1" component="span">
                                    For any questions or additional requests for information, please contact the Real
                                    Time Crime Center at 202-727-0559 or <a className="a" href="mailto:mpdcrs@dc.gov">mpdcrs@dc.gov</a>
                                </Typography>
                            </Grid>
                        </div>

                        <div className={"container mt-4"}>
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    SUPPLEMENTAL INFORMATION
                                </Typography>
                            </Box>
                            <MUIGrid container spacing={3} marginTop="2%" className="pl-2 pr-2 mb-6">
                                <MUIGrid item xs={12}>
                                    <Box className="p-0 w-full" display="flex" width="100%">
                                        <Typography variant="body1">Please choose what you are updating: </Typography>
                                    </Box>
                                </MUIGrid>
                                <MUIGrid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Update Options</FormLabel>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={"Class_UpdatedClassification"}
                                                        checked={supplement?.Class_UpdatedClassification ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="Classification"
                                            />
                                        </MUIGrid>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={"ShotSpotter_UpdatedShotSpotter"}
                                                        checked={supplement?.ShotSpotter_UpdatedShotSpotter ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="ShotSpotter"
                                            />
                                        </MUIGrid>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={"Background_UpdatedBackground"}
                                                        checked={supplement?.Background_UpdatedBackground ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="Background"
                                            />
                                        </MUIGrid>
                                    </FormControl>
                                </MUIGrid>
                                <MUIGrid item xs={12}>
                                    {supplement?.Class_UpdatedClassification && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 p-0 w-full" display="flex"
                                                    justifyContent="center" width="100%">
                                                    <Typography variant="h6">Classification</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <MUIGrid container spacing={2}>
                                                <MUIGrid item xs={6}>
                                                    <TextField
                                                        label="New Classification"
                                                        name="Class_NewClassification"
                                                        value={supplement?.Class_NewClassification ?? ""}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        margin={"normal"}
                                                    />
                                                </MUIGrid>
                                                <MUIGrid item xs={6}>
                                                    <TextField
                                                        label="CCN"
                                                        name="Class_NewCCN"
                                                        value={supplement?.Class_NewCCN ?? ""}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        margin={"normal"}
                                                    />
                                                </MUIGrid>
                                            </MUIGrid>
                                        </div>
                                    )}
                                    {supplement?.ShotSpotter_UpdatedShotSpotter && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 mb-2 p-0 w-full" display="flex"
                                                    justifyContent="center" width="100%">
                                                    <Typography variant="h6">ShotSpotter Response</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <MUIGrid item xs={6}>
                                                <DateTimePicker
                                                    label="Response Date/Time"
                                                    ampm={false}
                                                    value={supplement?.ShotSpotter_DateTime ?? ""}
                                                    onChange={(date) => handleInputChange({
                                                        target: {
                                                            name: "ShotSpotter_DateTime",
                                                            value: (date ?? "")
                                                        }
                                                    })}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={12}>
                                                <TextField
                                                    label="ShotSpotter Details"
                                                    name="ShotSpotter_ResponseDetails"
                                                    value={supplement?.ShotSpotter_ResponseDetails ?? ""}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                    margin={"normal"}
                                                />
                                            </MUIGrid>
                                        </div>
                                    )}
                                    {supplement?.Background_UpdatedBackground && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 mb-2 p-0 w-full" display="flex"
                                                    justifyContent="center" width="100%">
                                                    <Typography variant="h6">Background</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <FormControl>
                                                <FormLabel>Relationship to Case</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="Background_CaseRelationship"
                                                    value={supplement?.Background_CaseRelationship ?? ""}
                                                    onChange={handleInputChange}
                                                >
                                                    <FormControlLabel value="Complainant" control={<Radio />}
                                                        label="Complainant" />
                                                    <FormControlLabel value="Defendant" control={<Radio />}
                                                        label="Defendant" />
                                                    <FormControlLabel value="Person of Interest" control={<Radio />}
                                                        label="Person of Interest" />
                                                </RadioGroup>
                                            </FormControl>
                                            {(supplement?.Background_CaseRelationship !== "") && (
                                                <div>
                                                    <MUIGrid container spacing={2}>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="First Name"
                                                                name="Background_FName"
                                                                value={supplement?.Background_FName ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="Last Name"
                                                                name="Background_LName"
                                                                value={supplement?.Background_LName ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                    </MUIGrid>
                                                    <MUIGrid container spacing={2}>
                                                        <MUIGrid item xs={6}>
                                                            <TextField fullWidth margin='normal' type="date"
                                                                label="Date of Birth"
                                                                InputLabelProps={{ shrink: true }}
                                                                name="Background_DOB"
                                                                value={supplement?.Background_DOB ?? ""}
                                                                onChange={handleInputChange}
                                                            />
                                                        </MUIGrid>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="PDID"
                                                                name="Background_PDID"
                                                                value={supplement?.Background_PDID ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                    </MUIGrid>
                                                    <MUIGrid item xs={12}>
                                                        <TextField
                                                            label="Background Details"
                                                            name="Background_Information"
                                                            value={supplement?.Background_Information ?? ""}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            margin="normal"
                                                            multiline
                                                            rows={30}

                                                        />
                                                    </MUIGrid>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </MUIGrid>
                            </MUIGrid>
                        </div>
                        

                        <div className="my-4" style={{ display: 'flex', justifyContent: "flex-end", gap: '1%' }}>
                            <Button onClick={drawerDisplay ? handleOpen : closeHandler} color="error" variant="contained" className={"mr-2"}>
                                Cancel
                            </Button>
                            <Button onClick={handleSuppSubmit} color="primary" variant="contained" disabled={drawerNoCCNDisable}>
                                Submit Supplement
                            </Button>
                        </div>

                    </form>
                </Container>
            </LocalizationProvider>



        </>
    )

    return (
        <>
        {drawerDisplay ? (
            <div>
                <React.Fragment key={'right'}>
                    <Drawer
                        anchor={'right'}
                        open={open}
                        onClose={handleOpen}
                        variant="persistent"
                    >
                        <div>
                            <div className="flex justify-end"><Button color={"error"} onClick={handleOpen}><span className="text-xl">X</span></Button></div>
                            {display()}
                        </div>
                    </Drawer>
                </React.Fragment>
            </div>
        ) : (
        display()
        )}
        </>
            
    );
}


export default PreliminarySupplementReportInputForm;
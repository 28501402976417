import React, {useState} from "react";
import ArrestSearch from "../../components/tools/arrest/_arrestSearch";
import SearchResults from "../../components/tools/arrest/_searchResultsArrest";
import {GetDarInfo_Query, PersonInfo, getDarInfoResponse} from "../../interfaces/getDarInfo_interface";
import {RunDarQuery} from "../../services/getDar.service";
import {Account} from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {useLocation} from "react-router-dom";
import {handleSearchTime} from "../../services/formatDate.service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Arrest() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [secondaryTable, setSecondaryTable] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [showPlate, setShowPlate] = useState<boolean>(false)
    let query = useQuery();
    let [noData, setNoData] = useState<boolean>(false)
    // let [showGunTraceRecent, setShowGunTraceRecent] = useState<boolean>(false)
    
    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        if(params.Plate && (params.Plate.includes("%") || params.Plate.includes("*") || params.Plate.includes("?") || params.Plate.includes("_")|| params?.Plate?.length > 10))
        {
            SubmitSearchConnection(params, dateType, andOrType, dropDowns)
        }
        else
        {
            let search_query: GetDarInfo_Query = {
                "QueryType": "\0",
                "Dept": user.Dept,
                "EmpID": user.EmpID,
                "DeptORI": user.DeptORI,
                "DateRange": params.showDate,
                "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
                "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
                "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
                "SearchType": andOrType,
                "IndicatorOn": true,
                "TopArrestNumber": 0,
                "FNames": params.FirstName ? params.FirstName.split(',') : [],
                "MNames": params.MI ? params.MI.split(',') : [],
                "LNames": params.LastName ? params.LastName.split(',') : [],
                "DOBs": params.DOB ? params.DOB.split(',') : [],
                "SEX": dropDowns.Sex ? dropDowns.Sex : [],
                "Address": "",
                "Cities": dropDowns.City ? dropDowns.City : [],
                "IncidentCities": dropDowns.IncidentCity ? dropDowns.IncidentCity : [],
                "States": dropDowns.State ? dropDowns.State : [],
                "Zip": "",
                "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
                "Gang": [],
                "FBIs": params.FBI ? params.FBI.split(',') : [],
                "SBIs": params.SBI ? params.SBI.split(',') : [],
                "DLNos": params.DL ? params.DL.split(',') : [],
                "PlateNos": params.Plate ? params.Plate.split(',') : [],
                "VINs": [],
                "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
                "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
                "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
                "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
                "Latitude": null,
                "Longitude": null,
                "Radius": 0,
                "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
                "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
                "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
                "CaseNumbers": params.Case ? params.Case.split(',') : [],
                "SSN": params.SSN ? params.SSN?.split(',') : [],
                "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
                "AgeRangeStart": params.AgeRangeStart,
                "AgeRangeEnd": params.AgeRangeEnd,
                "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
                "ShootingPersonTypes": dropDowns.ShootingPersonType ? dropDowns.ShootingPersonType : [],
                "PhoneNumbers":  params.Phone ? params.Phone?.replaceAll('+1','')?.replaceAll('-','')?.split(',') : [],
                "SystemCall": params.systemCall ?? false,
            }
            let qRes:getDarInfoResponse = await RunDarQuery(search_query)
            setSecondaryTable([])
            setSecondaryTable(qRes?.SecondaryTable)
            setDarTbl([])
            setDarTbl(qRes.PersonList)
            if (qRes?.PersonList?.length === 0) {
                setNoData(true)
            }
            else {
                setNoData(false)
            }
        }
        
    }
    

    const SubmitSearchConnection = async (params: any, dateType: string, andOrType: string, dropDowns: any, QueryType:any = "V") => {
        let search_query: GetDarInfo_Query = {
            "QueryType": QueryType,
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": params.showDate,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": params.MI ? params.MI.split(',') : [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": params.DOB ? params.DOB.split(',') : [],
            "SEX": dropDowns.Sex ? dropDowns.Sex : [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "IncidentCities": dropDowns.IncidentCity ? dropDowns.IncidentCity : [],
            "States": dropDowns.State ? dropDowns.State : [],
            "Zip": "",
            "Statutes": dropDowns.Statute ? dropDowns.Statute : [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": params.DL ? params.DL.split(',') : [],
            "PlateNos": params.Plate ? params.Plate.split(',') : [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": dropDowns.InfoCopDept ? dropDowns.InfoCopDept : [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": dropDowns.ArrestType ? dropDowns.ArrestType : [],
            "CaseNumbers": params.Case ? params.Case.split(',') : [],
            "SSN": params.SSN ? params.SSN?.split(',') : [],
            "PlaceOfBirth": dropDowns.PlaceOfBirth ? dropDowns.PlaceOfBirth : [],
            "AgeRangeStart": params.AgeRangeStart,
            "AgeRangeEnd": params.AgeRangeEnd,
            "FTSIDs": params.FTSID ? params.FTSID.split(',') : [],
            "PhoneNumbers": params.Phone ? params.Phone.split(',') : [],
            "ShootingPersonTypes": dropDowns.ShootingPersonTypes ? dropDowns.ShootingPersonTypes : [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes:getDarInfoResponse = await RunDarQuery(search_query)
        setSecondaryTable([])
        setSecondaryTable(qRes?.SecondaryTable)
        setDarTbl([])
        setDarTbl(qRes?.PersonList)
        if (qRes?.PersonList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
        if(params.Plate && params.Plate.length !== 0)
        {
            setShowPlate(true)
        }
        else
        {
            setShowPlate(false)
        }
    }

    // const HandleShowTraceRecent = (value:any) => {
    //     setShowGunTraceRecent(value)
    // }

    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest')?
                <>
                    {query.get("personList") ?
                    <ArrestSearch SubmitSearch={SubmitSearchConnection} passedData={query.get("personList")} />
                    :
                    query.get("plateList") ?
                    <ArrestSearch SubmitSearch={SubmitSearchConnection} passedData={query.get("plateList")} />
                    :
                            <ArrestSearch SubmitSearch={SubmitSearch}/>}
                    {!noData ? <SearchResults Persons={darTbl} SecondaryTable={secondaryTable} showPlate={showPlate} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default Arrest;
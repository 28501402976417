import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog, Tooltip
} from "@mui/material";
import ALPRDetail from "../../interfaces/Detail/ALPRDetail.interface"
import {RunALPRDetailNo_IDQuery, RunALPRDetailQuery} from "../../services/detail.service";
import './Detail.css'
import DetailMap from "./_DetailMap";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

interface DetailProps {
    ID?: string | null
    ALPRObject?: string | null
    department: string | null
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const ALPRDetailInfo: React.FC<DetailProps> = ({ ID = null, ALPRObject = null, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<ALPRDetail>({})
    const [pin, setPin] = React.useState<any>({})
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const [imgPosition, setImgPosition] = React.useState<string>('0% 0%')

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            if(ID) 
            {
                let res = await RunALPRDetailQuery(ID, department);
                setData(res)
                createPin(res)
            }
            else
            {
                let res = await RunALPRDetailNo_IDQuery(ALPRObject, department);
                setData(res)
                createPin(res)
            }
            
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.Src_Latitude && Data?.Src_Longitude) {
            tPin = ({
                lat: Data?.Src_Latitude,
                lng: Data?.Src_Longitude,
                date: Data?.Red_TimeStamp,
                name: Data?.Src_Description,
                plate: Data?.Red_Vrm,
                detail: "alpr"
            })
        }
        setPin(tPin)
    }
    
    const handleMouseMove = (e:any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setImgPosition(x+'% ' + y+'%') 
    }
    

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            {enlarged ?
                <Dialog 
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={()=>{setEnlarged(null)}}
                    open={open}
                    TransitionComponent={Transition}
                
                >
                    <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                </Dialog>
                :
                <></>
            }
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Details</h3>

                    <table>                        
                        <tbody>
                            <tr>
                                <td className = "img-wrapper">
                                    <Tooltip title={"Click to Enlarge"}><img src={Data?.PlateImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.PlateImage); setOpen(true)}}/></Tooltip>
                                </td>
                                <td className = "img-wrapper">
                                    <Tooltip title={"Click to Enlarge"}><img src={Data?.CarImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.CarImage); setOpen(true)}}/></Tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data?.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read ID</span><span className="DetailTableData">{Data?.Red_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate #</span><span className="DetailTableData">{Data?.Red_Vrm}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Data?.Red_Vrm_State}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read Date</span><span className="DetailTableData">{Data?.Red_TimeStamp}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Make</span><span className="DetailTableData">{Data?.VehicleMake}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Model</span><span className="DetailTableData">{Data?.VehicleModel}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Color</span><span className="DetailTableData">{Data?.VehicleColor}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Year</span><span className="DetailTableData">{Data?.VehicleYear}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Vehicle Trim</span><span className="DetailTableData">{Data?.VehicleTrim}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Latitude</span><span className="DetailTableData">{Data?.Src_Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data?.Src_Longitude}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="alpr" /> </div> : <></>}
                </CardContent>
            </Card>

            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Camera Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Site ID</span><span className="DetailTableData">{Data?.Ste_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Server</span><span className="DetailTableData">{Data?.Ste_Name}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Camera ID</span><span className="DetailTableData">{Data?.Src_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Agency Name</span><span className="DetailTableData">{Data?.Src_Name}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Agency ORI</span><span className="DetailTableData">{Data?.ORI}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Camera Name</span><span className="DetailTableData">{Data?.Src_Description}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Fixed Camera</span><span className="DetailTableData">{Data?.Src_Fixed}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Other Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">External Link</span><span className="DetailTableData">{Data?.Src_IPV4Address}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Mapped User ID</span><span className="DetailTableData">{Data?.Msp_User_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read Source ID</span><span className="DetailTableData">{Data?.Red_Src_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Source</span><span className="DetailTableData">{Data?.ServerDesc}</span></div>
                </CardContent>
            </Card>
        </div>
    );
}

export default ALPRDetailInfo;
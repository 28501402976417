import React, { useState, useEffect } from "react";
import {
    Tooltip,
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    ButtonGroup,
    FormControl,
    InputLabel, Select, MenuItem, FormControlLabel
} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatInputDate } from "../../../services/formatDate.service";
import { GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface SCORSearchProps {
    SubmitSearch: any
    HandleHideJail: any
}

const SCORSearch: React.FC<SCORSearchProps> = ({ SubmitSearch, HandleHideJail }) => {
    let today: Date = new Date();
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", CrumbScore: 0, CrumbPercentile: 20, toDate: formatInputDate(today), fromDate: formatInputDate(today), systemCall: false })
    const [dateType, setDateType] = React.useState<string>("SCOR Date");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [customRank, setCustomRank] = React.useState<boolean>(false);
    const [hideJail, setHideJail] = React.useState<boolean>(false);
    const [rank, setRank] = React.useState<string>("20");
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [andOrType, setAndOrType] = React.useState<string>("AND");

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", AgeRangeStart: "", AgeRangeEnd: "", CrumbScore: 0, CrumbPercentile: 20, toDate: formatInputDate(today), fromDate: formatInputDate(today), systemCall: false })
        setRank("20")
        setDateType("SCOR Date")
        setHideJail(false)
        setClear(clear + 1)
        setCustomRank(false)
    }

    const handleHideJail = (value: any) => {
        setHideJail(value)
        HandleHideJail(value)
    }

    const handleDropDownChange = (e: any) => {
        let p = { ...params }
        setRank(e.target.value)
        switch (e.target.value) {
            case ('5'):
                p.CrumbPercentile = 5
                setCustomRank(false)
                break;
            case ('10'):
                p.CrumbPercentile = 10
                setCustomRank(false)
                break;
            case ('20'):
                p.CrumbPercentile = 20
                setCustomRank(false)
                break;
            case ('40'):
                p.CrumbPercentile = 40
                setCustomRank(false)
                break;
            case ('50'):
                p.CrumbPercentile = 50
                setCustomRank(false)
                break;
            case ('Custom'):
                setCustomRank(true)
                break;
        }
        setParams(p)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.Statute = Object?.keys(dropDownParam.Statute)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.AgencyArrested = Object?.keys(dropDownParam.AgencyArrested)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={handleDateType}
                    sx={{ mr: 2 }}
                >
                    <ToggleButton value="SCOR Date" sx={{ pr: 2, pl: 2 }} >{GetStringNameForSite(SiteName, "SCOR")} Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                        size={"small"} label="From Date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={params.fromDate}
                        onChange={(e: any) => {
                            setParams({ ...params, fromDate: e.target.value })
                        }}
                    />
                </div>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                        size={"small"} label="To Date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        value={params.toDate}
                        onChange={(e: any) => {
                            setParams({ ...params, toDate: e.target.value })
                        }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><TextField margin='none' size={"small"} fullWidth label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Age Lower Range" type="number" value={params.AgeRangeStart} onChange={(e: any) => setParams({ ...params, AgeRangeStart: e.target.value })} title="Age of offender at the time of Arrest. (Lower Limit)" /></div>
                <div><TextField margin='none' size={"small"} fullWidth label="Age Upper Range" type="number" value={params.AgeRangeEnd} onChange={(e: any) => setParams({ ...params, AgeRangeEnd: e.target.value })} title="Age of offender at the time of Arrest. (Upper Limit)" /></div>
                <div><TextField margin='none' size={"small"} fullWidth label={GetStringNameForSite(SiteName, "SCOR_DETAILS_TBL")} type="number" value={params.CrumbScore} onChange={(e: any) => setParams({ ...params, CrumbScore: e.target.value })} /></div>
                <div className="mr-5" style={{ width: '100%' }}>
                    <FormControl fullWidth>
                        <InputLabel id="timeFrame">{GetStringNameForSite(SiteName, "SCOR_TBL")}</InputLabel>
                        <Select
                            labelId="timeFrame"
                            value={rank}
                            label="Time Frame"
                            fullWidth
                            onChange={handleDropDownChange}
                            size={'small'}
                        >
                            <MenuItem value={"5"}>Top 5</MenuItem>
                            <MenuItem value={"10"}>Top 10</MenuItem>
                            <MenuItem value={"20"}>Top 20</MenuItem>
                            <MenuItem value={"40"}>Top 40</MenuItem>
                            <MenuItem value={"50"}>Top 50</MenuItem>
                            <MenuItem value={"Custom"}>Custom Rank</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {customRank ?
                    <div>
                        <TextField margin='none' size={"small"} fullWidth label={"Custom " + GetStringNameForSite(SiteName, "SCOR_TBL")} type="number"
                            value={params.CrumbPercentile}
                            onChange={(e: any) => setParams({ ...params, CrumbPercentile: e.target.value })} />
                    </div>
                    : <div></div>}
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"crumb"} /></div>
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                    <div>
                    <Tooltip title="Filter out those who are currently incarcerated or those who are currently in jail" placement="top" arrow>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={hideJail}
                                    checked={hideJail}
                                    onChange={(e: any, value: any) => handleHideJail(value)}
                                />}
                            label="Hide Jail"
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20, } }} />
                    </Tooltip>
                    </div>
                    
                <div>
                    <ButtonGroup size="large" color="inherit" >
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                    </ButtonGroup>
                </div>

            </div>
        </div>
    );
}

export default SCORSearch;
import React from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  ColumnDirective,
  ColumnsDirective,
  ColumnMenu,
  GridComponent,
  Grid,
  Inject,
  Page,
  Sort,
  Filter,
  DetailRow,
  ExcelExport,
  PdfExport,
  Toolbar,
  Search,
  ExcelExportProperties,
  Resize,
  Reorder,
  SortSettingsModel,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { PostAdd, List } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import {
  GetProfileReport,
  GetProfileReportPDF,
} from "../../../services/account.service";
import { useHistory } from "react-router-dom";
import { isUserPermission } from "../../../services/auth.service";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import { profileObject } from "../../../interfaces/PersonReport.interface";
import {
  DeleteTerminalHire, submitTerminalHires,
} from "../../../services/njport.service";
import { PDOHireObject } from "../../../interfaces/NJPORT/PDOHire.interface";
import TerminalHireInputForm from "./terminalhireInputForm";
import { TerminalHireObject } from "../../../interfaces/NJPORT/TerminalHire.interface";
import { editPdoHire } from "./apical";
import TableDialog from "./TableDialog";

interface SearchResultsProps {
  data?: TerminalHireObject[];
  Persons?: any;
  handleSubmit: any;
  SubmitSearch: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TerminalHireSearchResults: React.FC<SearchResultsProps> = ({
  handleSubmit,
  data,
  Persons = null,
  SubmitSearch,
}) => {
  const [typedPersons, setTypedPersons] = React.useState<TerminalHireObject[]>(
    [],
  );
  const [tabValue, setTabValue] = React.useState("1");
  const [editTabName, setEditTabName] = React.useState("New Terminal Hire Report");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [EditDialog, setEditDialog] = React.useState<boolean>(false);

  // const [editDialogID, setEditDialogID] = React.useState<number>(0);
  const [editDialogID, setEditDialogID] = React.useState<any>();
  const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
  const [deleterow,setDeleterow]=React.useState<any>();
  const [duplicateReport, setDuplicateReport] = React.useState<boolean>(false);
  const [reportUrl, setReportUrl] = React.useState<string | undefined>(
    undefined,
  );
  const [hash, setHash] = React.useState<string | null>(null);
  const [editingRecord, setEditingRecord] =
    React.useState<TerminalHireObject | null>(null);
  const [personDetails, setPersonDetails] =
    React.useState<profileObject | null>(null);
  //const [personDetails, setPersonDetails] = React.useState<any>(null);
  const [alertType, setAlertType] = React.useState<any>("success");
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<string>("");
  const [clear, setClear] = React.useState<boolean>(false);
  const [personList, setPersonList] = React.useState<any>();
  const [dialogData, setDialogData] = React.useState<any>([]);
  const [isAdd, setisAdd] = React.useState<any>(false);
  let grid: Grid | null;
  const history = useHistory();

  const [initialLoading, setInitialLoading] = React.useState(false);

  const handleDataTyping = () => {
    if (data && data.length > 0) {
      let tempP: any = [...data];

      for (let p of tempP) {
        if (p.HiringDateTime) {
          p.HiringDateTime = new Date(p.HiringDateTime);
        }
      }
      setTypedPersons(tempP);
    } else {
      setTypedPersons([]);
    }
  };

  const cloneReport = async (id: number | null = null) => {
    if ((editDialogID && editDialogID > 0) || (id && id > 0)) {
      let record = {
        ProfileReportObject: { ID: editDialogID ? editDialogID : id },
      };
      let res = await GetProfileReport(record);
      res.newReport = true;
      setEditingRecord(res);
      setEditTabName("New Terminal Hire Report");
      setTabValue("2");
    }
    setOpenEditDialog(false);
  };
  const handleEditReport = async (rowData: any) => {
    let hiringDateTime = rowData.HiringDateTime;
  
    // Parse the hiringDateTime
    const date = new Date(hiringDateTime);
  
    // Check if the time is exactly 19:00:00.000Z
    if (date.getUTCHours() === 19 && date.getUTCMinutes() === 0 && date.getUTCSeconds() === 0) {
      // Adjust to the next day and set time to 00:00:00
      date.setUTCDate(date.getUTCDate() + 1);
      date.setUTCHours(0, 0, 0, 0);
      hiringDateTime = date.toISOString(); // Convert back to ISO string
    }
  
    const requestData = {
      hiringDateTime: hiringDateTime,
    };
  
    console.log(requestData.hiringDateTime); // Check the adjusted date-time
  
    const response = await editPdoHire(requestData);
    if (response) {
      setDialogData(response);
      setOpenEditDialog(true);
    }
  };
  React.useEffect(() => {
    handleDataTyping();
  }, [data]);

  React.useEffect(() => {
    refreshDataSource();
  }, [typedPersons]);
  // React.useEffect(() => {
  //   if (dialogData) {
  //     setOpenEditDialog(true);
  //   }
  // }, [dialogData]);

  const showActionView = (rowData: any): any => {
    return (
      <span>
        <ButtonGroup>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton onClick={()=>handleEditReport(rowData)}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteDialogID(rowData.ID);
                setDeleterow(rowData);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </span>
    );
  };

  const deleteReport = async () => {
    let id: number = deleteDialogID;
    let date: Date=deleterow.HiringDate;
    let time: any=deleterow.HiringTime;
    if (id && id > 0) {
      let data = [{
        id: id,
        editStatus: "DELETE",
        deleted: false,
        HiringDate: date,
        HiringTime: time
      }];
      
      let isDeleted = await DeleteTerminalHire(data);
      if (isDeleted) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "Delete Successful (Changes Will Take Effect After The Next Search)",
        );
      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to delete.");
      }
    }
    setOpenDeleteDialog(false);
  };

  const viewReport = async (rowData: any) => {
    setReportUrl(undefined);
    let pdfUrl = await GetProfileReportPDF(rowData);
    if (pdfUrl) {
      setReportUrl(pdfUrl);
      setOpenDialog(true);
    }
  };
  
  const handleAdd = async () => {
    try {
      const currentDate = new Date();
        const dateWithZeroTime = new Date(
        Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), 0, 0, 0, 0)
      ).toISOString();
  
      const requestData = {
        hiringDateTime: dateWithZeroTime,
      };
        const response = await editPdoHire(requestData);
      console.log("Add Report Response:", response); // Log the response
      setDialogData(response);
      setOpenEditDialog(true); // Open the dialog after making the API call
      setisAdd(true); // Set isAdd to true to indicate it's an add operation
    } catch (error) {
      console.error("Error adding report:", error);
    }
  };
  

  const gridTemplateIndicator = (person: any): any => {
    return (
      <>
        <BTPersonIndicatorGrid person={person} />
      </>
    );
  };

  const GridToolsClick = (args: ClickEventArgs) => {
    if (grid && args.item.text === "Excel Export") {
      let excelProp: ExcelExportProperties = {};
      excelProp.fileName = "Profile_Report.xlsx";

      grid.excelExport(excelProp);
    } else if (grid && args.item.text === "PDF Export") {
      grid.pdfExport();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue == "1") {
      setEditTabName("New Terminal Hire Report");
      setEditingRecord(null);
      setPersonDetails(null);
      setTabValue("1");
      //handleSubmit();
    }

    setTabValue(newValue);
  };

  const refreshDataSource = () => {
    if (grid) {
      grid.dataSource = typedPersons;
    }
  };
  const handleadd = () => {
    setEditDialog(true);
    setisAdd(!isAdd);
  }

  const handleReportSubmit = async (submitObj: TerminalHireObject) => {
    let saveRequest = await submitTerminalHires(submitObj);
    if (saveRequest && saveRequest.ErrorMessages?.length) {
      setAlertType("error");
      setAlertText(saveRequest.ErrorMessages[0]);
      setAlertOpen(true);
    } else if (saveRequest) {
      setAlertType("success");
      setAlertText(
        "Report has been saved!(Changes Will Take Effect After The Next Search)",
      );
      setAlertOpen(true);
      setTabValue("1");
      setEditTabName("New Record");
      setEditingRecord({});
    }
  };

  const handleTableRecordUpdate = (saveRequest: TerminalHireObject) => {
    setOpenEditDialog(false);
    if (saveRequest) {
      setAlertType("success");
      setAlertText(
        "Report has been saved!(Changes Will Take Effect After The Next Search)",
      );
      setAlertOpen(true);
      setTabValue("1");
      setEditTabName("New Record");
      setEditingRecord({});
    } else if (saveRequest) {
      setAlertType("success");
      setAlertText("Report has been saved!");
      setAlertOpen(true);
    } else {
      setAlertType("error");
      setAlertText("Record Saved Unsuccessful ");
      setAlertOpen(true);
    }
  };


  const closeeditdialouge=()=>{
    setOpenEditDialog(false);
    setisAdd(false);
    setDialogData([]);
  
  }

  const handleSentenceCase = (args: any) => {
    //return(args[0].toUpperCase() + args.slice(1).toLowerCase())
    args = args.toLowerCase();
    args = args.split(" ");
    for (var i = 0; i < args.length; i++) {
      args[i] = args[i].charAt(0).toUpperCase() + args[i].slice(1);
    }
    return args.join(" ");
  };

  const rowSelected = (rowData: any) => {
    if (rowData && rowData.data.ID) {
      // // viewReport(rowData.data.ID);
    }
  };

  let sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };

  return (
    <div className="m-5 p-5">
           <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Box className="pt-3" style={{ justifyContent: "center" }}>
        <TabContext value={tabValue}>

        
          <TabPanel value="1">
          <ButtonGroup  size="large" color="inherit"  className={"pl-10 pr-10 mt-5"}>
            <Button type="submit" onClick={handleAdd}>
              Add Report
            </Button>

          </ButtonGroup>
            <div className={"pl-10 pr-10 mt-5"}>
              {typedPersons && typedPersons.length > 0 ? (
                <>
                  <h4 className={"font-bold pl-5"}>
                    {" "}
                    {typedPersons?.length} Records Found
                  </h4>
                  <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{
                      pageSize: 25,
                      pageSizes: [10, 25, 50, 100],
                    }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: "CheckBox" }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Both" }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={["ExcelExport", "Search"]}
                    toolbarClick={GridToolsClick}
                    ref={(g) => (grid = g)}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    rowSelected={rowSelected}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        template={showActionView}
                        width={80}
                        minWidth={70}
                        maxWidth={100}
                        headerText="Action"
                      />
                      <ColumnDirective
                        field="ID"
                        width={60}
                        minWidth={50}
                        maxWidth={100}
                        headerText="ID"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="HiringDateTime"
                        width={115}
                        minWidth={115}
                        maxWidth={115}
                        format={{ type: "date", format: "MM/dd/yyyy" }}
                        headerText="HiringDateTime"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="TotalHired"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="TotalHired"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                    </ColumnsDirective>
                    <Inject
                      services={[
                        ColumnMenu,
                        Sort,
                        Page,
                        Filter,
                        DetailRow,
                        Toolbar,
                        ExcelExport,
                        PdfExport,
                        Search,
                        Resize,
                        Reorder,
                        Edit,
                        CommandColumn,
                      ]}
                    />
                  </GridComponent>
                </>
              ) : (
                <div className="font-bold text-center text-lg pt-3">
                  NO Matching Records Found
                </div>
              )}
            </div>
          </TabPanel>
          {isUserPermission("NJPort") ? (
            <TabPanel value="2">
              {tabValue && tabValue === "2" ? (
                <TerminalHireInputForm
                  handleReportSubmit={handleReportSubmit}
                  editReport={editingRecord}
                />
              ) : (
                <></>
              )}
            </TabPanel>
          ) : (
            <></>
          )}
        </TabContext>
      </Box>
      <Dialog
        open={openEditDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"lg"}
        scroll="paper"
        //onClose={EditDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>{isAdd ? 'Add Report' : 'Edit Report'}</h2>
          <IconButton
            onClick={closeeditdialouge}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
        <Box p={2}> {/* Add padding here */}
          <TableDialog
            isAdd={isAdd}
            dataSource={dialogData}
            handleTableRecordUpdate={handleTableRecordUpdate}
          />
        </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Delete Report</h2>
          <IconButton
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Do you really want to delete this profile report? You can't undo
            this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteReport}>Delete Report</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TerminalHireSearchResults;

import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatDT, formatInputDate, handleSearchTime } from "../../../services/formatDate.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";
import { getHours } from "date-fns";
import {TraceDashboard } from "../../../services/account.service";
import {MultiSelect} from "primereact/multiselect";
import { getDropDownQuery, getNJTraceAgencyList } from "../../../services/getDropdown.service";
import { isUserPermission } from "../../../services/auth.service";
import { JAOS } from "../../../services/JAOS.service";


interface ArrestSearchProps {
    SubmitSearch: any
     passedData?: any | null
}

const NJTraceSearch: React.FC<ArrestSearchProps> = ({ SubmitSearch, passedData = null }) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();
    if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else
        fromday = toDate;
    const [params, setParams] = React.useState({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", Case: "", Serial: "", FTSID: "" ,TTCRangeStart: "", TTCRangeEnd: "" ,toDate: today, fromDate: fromday, PersonDetail: !isUserPermission('NJTrace') ? "Person" : "Trace", systemCall: false })
    const [dateType, setDateType] = React.useState<string>("Recovery Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({Status:[], ResponseStatus:[]});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)
    const [time, setTime] = React.useState<string>("Custom Range");
    const [searchHours, setSearchHours] = React.useState<boolean>(false)
    const [hideDate, setHideDate] = React.useState<boolean>(false)
    const [appliedDDATFResponse, setAppliedDDATFResponse] = React.useState<any[]>([])
    const [DDATFResponse, setDDATFResponse] = React.useState<any[]>([])
    const [appliedDDTraceStatus, setAppliedDDTraceStatus] = React.useState<any[]>([])
    const [DDTraceStatus, setDDTraceStatus] = React.useState<any[]>([])
    //const [DDNJTraceAgency, setDDNJTraceAgency] = React.useState<any[]>([])
    const [customDate, setCustomDate] = React.useState<boolean>(true);




    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = { ...params }   
            let tempDateType = dateType     
            p.systemCall = true
            if(passedData){
                let l = new JAOS()
                let data = l.objFromStack(passedData)
                if(data?.CaseNumber){
                    p.Case = data.CaseNumber
                }
                if(data?.startDate){
                    p.fromDate = new Date(data?.startDate)
                }
                if(data?.toDate){
                    p.toDate = new Date(data?.toDate)
                }
                tempDateType="Recovery Date"
                p.systemCall = false
                setParams(p)
                setDateType(tempDateType)

            }
            SubmitSearch(p, tempDateType, andOrType, dropDownParams)
            getDropDownQuery('ATF_Response').then((res)=> setDDATFResponse(res))
            getDropDownQuery('Trace_Status').then((res)=> setDDTraceStatus(res))
            //getNJTraceAgencyList().then((res)=> setDDNJTraceAgency(res))
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if (andOrType)
            setAndOrType(andOrType);
    };

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", Case: "", Serial: "", FTSID: "", TTCRangeStart:"", TTCRangeEnd:"" ,toDate: today, fromDate: fromday, PersonDetail:!isUserPermission('NJTrace') ? "Person": "Trace",systemCall: false })
        setDateType("Recovery Date")
        setAndOrType("AND")
        setClear(clear + 1)
        setSearchHours(false)
        setTime("Custom Range")
        setCustomDate(true)
    }

    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.City = Object?.keys(dropDownParams.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.AgencyArrested = Object?.keys(dropDownParam.AgencyArrested)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam, searchHours)
    }

    const handleTimeDDChange = (e:any) => {
        setTime(e.target.value)
        setCustomDate(false)
        fromday = new Date()
        today = new Date()
        switch(e.target.value){
            case '24 Hours':
                fromday.setHours(fromday.getHours() - 24)
                setSearchHours(true)       
                break;
            case '48 Hours':
                fromday.setHours(fromday.getHours() - 48)
                setSearchHours(true)       
                break;
            case '72 Hours':
                fromday.setHours(fromday.getHours() - 72)
                setSearchHours(true)       
                break;
            case 'Custom Range':
                fromday = today
                setCustomDate(true)
                setSearchHours(false)       
                break;
        }
             
        setParams({ ...params, toDate:today, fromDate: fromday})
            
    }

    const getValueTemplate = (selected: any) => {
        
        if(selected && selected.length > 0) {
            let count = 0;
            for (const statute of selected) {
                if(!statute || !statute?.key?.includes('BT_UNIQUE_GROUP'))
                    count++;
            }
            return (count + " selected");
        }
        else 
            return ("Statutes")
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={handleDateType}
                    sx={{ mr: 2 }}
                >
                    <ToggleButton value="Recovery Date" sx={{ pr: 2, pl: 2 }} >Recovery Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div className="mr-5" style={{width:'100%'}}>
                    <FormControl fullWidth>
                    <InputLabel id="timeFrame">Time Frame</InputLabel>
                        <Select
                        labelId="timeFrame"
                        value={time}
                        size="small"
                        fullWidth
                        label="Time Frame"                     
                        onChange={handleTimeDDChange}
                        >
                        <MenuItem value={"24 Hours"}>24 Hours</MenuItem>
                        <MenuItem value={"48 Hours"}>48 Hours</MenuItem>
                        <MenuItem value={"72 Hours"}>72 Hours</MenuItem>
                        <MenuItem value={"Custom Range"}>Custom Range</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                { customDate ? <>
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="From Date"
                                value={params.fromDate}
                                onChange={(e:any) => {
                                    setParams({...params, fromDate: e})
                                }}
                                renderInput={(params) => <TextField margin='none' size={"small"} type="date" InputLabelProps={{ shrink: true }} {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To Date"
                                    value={params.toDate}
                                    onChange={(e:any) => {
                                        setParams({...params, toDate: e})
                                    }}
                                    renderInput={(params) => <TextField margin='none' size={"small"} type="date" InputLabelProps={{ shrink: true }} {...params} />}
                                />
                        </LocalizationProvider>
                    </div>                       
                    </>
                :<></>}              
            </div>
            
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><TextField margin='none' fullWidth size={"small"} label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label={GetStringNameForSite(SiteName, "Case #")} value={params.Case} onChange={(e: any) => setParams({ ...params, Case: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Serial #" value={params.Serial} onChange={(e: any) => setParams({ ...params, Serial: e.target.value })} /></div>  
            
                <div>
                    <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                        <MultiSelect
                            //value={appliedDDTraceStatus}
                            value={dropDownParams?.Status}
                            //onChange={(e) => {setAppliedDDTraceStatus(e.value)}}
                            onChange={(e) => {setDropDownParams({...dropDownParams, Status: e.value})}}
                            style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                            resetFilterOnHide={true} showClear={true} showSelectAll={false}
                            inputId={"dd-traceStatus"} options={DDTraceStatus} filter optionLabel="Value" optionValue="Key"
                            placeholder={`${GetDefaultStateForSite(SiteName)}Trace Status`} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                        <label className={"bg-gray-100"} htmlFor="dd-traceStatus">{`${GetDefaultStateForSite(SiteName)}`}Trace Status</label>
                    </FormControl>
                </div>
                <div className="mr-5" style={{width:'100%'}}>
                    <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>
                        <MultiSelect
                            value={dropDownParams?.ResponseStatus}
                            onChange={(e) => {setDropDownParams({...dropDownParams, ResponseStatus: e.value})}}
                            style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}
                            resetFilterOnHide={true} showClear={true} showSelectAll={false}
                            inputId={"dd-atfWeaponStatus"} options={DDATFResponse} filter optionLabel="Value" optionValue="Key"
                            placeholder={'ATF Weapon Status'} maxSelectedLabels={1} className="w-full md:w-20rem"/>
                        <label className={"bg-gray-100"} htmlFor="dd-atfWeaponStatus">ATF Status</label>
                    </FormControl>
                </div>

                <div><TextField margin='none' fullWidth size={"small"} label="TTC Lower Range" type="number" value={params?.TTCRangeStart} onChange={(e: any) => setParams({ ...params, TTCRangeStart: e.target.value })} title="Time To Crime in days. (Lower Limit)" /></div>              
                <div><TextField margin='none' fullWidth size={"small"} label="TTC Upper Range" type="number" value={params?.TTCRangeEnd} onChange={(e: any) => setParams({ ...params, TTCRangeEnd: e.target.value })} title="Time To Crime in days. (Upper Limit)" /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="ATF #" value={params.FTSID} onChange={(e: any) => setParams({ ...params, FTSID: e.target.value })} title="ETrace ATF FTS ID#" /></div>
               
                {/*<div className="mr-5" style={{width:'100%'}}>*/}
                {/*    <FormControl className="p-float-label" sx={{minWidth: '100%', maxWidth: '100%'}}>*/}
                {/*        <MultiSelect*/}
                {/*            value={dropDownParams?.AgencyArrested}*/}
                {/*            onChange={(e) => {setDropDownParams({...dropDownParams, AgencyArrested: e.value})}}*/}
                {/*            style={{border: '1px solid #b9b9bb', height: '2.75rem', textAlign: 'start'}}*/}
                {/*            resetFilterOnHide={true} showClear={true} showSelectAll={false}*/}
                {/*            inputId={"dd-agency"} options={DDNJTraceAgency} filter optionLabel="Value" optionValue="Key"*/}
                {/*            placeholder={'Agency'} maxSelectedLabels={1} className="w-full md:w-20rem"/>*/}
                {/*        <label className={"bg-gray-100"} htmlFor="dd-agency">Agency</label>*/}
                {/*    </FormControl>*/}
                {/*</div>                  */}
            </div>
            
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"njtrace"} /></div>
            </div>
            
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>  
                    <FormLabel sx={{marginTop:1}} >List View Based On</FormLabel>                                   
                    <span className="ml-5 ">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    title="List View Based On"
                                    row
                                    name="row-radio-buttons-group"
                                    value={params?.PersonDetail}
                                    onChange={e => setParams({ ...params, PersonDetail: e.target.value})}

                                >
                                    <FormControlLabel value="Trace" control={<Radio />} label={GetDefaultStateForSite(SiteName) + "Trace"}/>
                                    <FormControlLabel value="Person" control={<Radio />} label="Person" />
                                    <FormControlLabel value="Weapon" control={<Radio />} label="Weapon" />

                                </RadioGroup>
                            </FormControl>
                    </span>           
                <ButtonGroup size="large" color="inherit" >
                    <Button type="submit" onClick={handleSearch}>Search</Button>
                    <Button onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default NJTraceSearch;
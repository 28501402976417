import {
    Button,
    ButtonGroup, Card,
    CardContent, Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import React, {useEffect} from "react";
import DeptAttributes, {DeptsSSOAttributes} from "../../interfaces/Dept.interface";
import {RunGetDept, RunGetUser, RunMangers, RunSetDept, RunSetUser} from "../../services/account.service";
import ErrorIcon from "@mui/icons-material/Error";
import {SiteName} from "../../services/config.service";
import {SITE} from "../../interfaces/config.interface";


interface DeptProps {
    ID:string|null
    CloseBox:any
}


const UpdateDepts: React.FC<DeptProps> = ({ID, CloseBox}) => {
    const [params, setParams] = React.useState<DeptAttributes>({})
    const [sso, setSSOParams] = React.useState<DeptsSSOAttributes>({})


    const [deptCodeError, setDeptCodeError] = React.useState<string>("")
    const [deptNameError, setDeptNameError] = React.useState<string>("")
    const [deptORIError,  setDeptORIError] = React.useState<string>("")
    const [accountTypeError, setAccountTypeError] = React.useState<string>("")
    const [LIAError, setLIAError] = React.useState<string>("")
    const [NOUError, setNOUError] = React.useState<string>("")
    const [deactDateError, setDeactDateError] = React.useState<string>("")
    const [addressError, setAddressError] = React.useState<string>("")
    const [cityError, setCityError] = React.useState<string>("")
    const [stateError, setStateError] = React.useState<string>("")
    const [zipError, setZipError] = React.useState<string>("")
    const [notesError, setNotesError] = React.useState<string>("")
    const [submitError, setSubmitError] = React.useState<string>("")

    const LoadDeptData = async (ID:string) => {
        let query = {
            "uniqueID": ID,
        }
        let r = await RunGetDept(query)
        if(r.AnyData){
            await setParams(r.AnyData)
            if(r.AnyData?.SSOSettings)
                await setSSOParams(r.AnyData?.SSOSettings)
        }
    }

    useEffect(() => {
        if(ID !== null)
            LoadDeptData(ID)
    }, [ID]);
    
    const handleSearch = () => {
    }

    const submitDept = async () => {
        setSubmitError("")
        if(checkValidation()) {
            params.SSOSettings = sso;
            let r = await RunSetDept(params)
            if(r.AnyData.IsValid && r.AnyData.ErrorMessage?.length <= 0)
            {
                CloseBox(r?.AnyData, 'Add/Edit')
            }
            else
            {
                let errors = r.AnyData.ErrorMessage?.join(",")
                setSubmitError(errors)
            }
        }
    }

    const checkValidation = () => {
        setDeptCodeError("")
        setDeptNameError("")
        setDeptORIError("")
        setAccountTypeError("")
        setLIAError("")
        setNOUError("")
        setDeactDateError("")
        setAddressError("")
        setCityError("")
        setStateError("")
        setZipError("")
        setNotesError("")

        var response = true
        if(params.DeptCode === null || (params.DeptCode && params.DeptCode.length <= 0))
        {
            setDeptCodeError("The Dept Code field is required.")
            response = false
        }
        if(params.DeptName === null || (params.DeptName && params.DeptName.length <= 0))
        {
            setDeptNameError("The Dept Name field is required.")
            response = false
        }
        if(params.DeptORI === null || (params.DeptORI && params.DeptORI.length <= 0))
        {
            setDeptORIError("The Dept ORI field is required.")
            response = false
        }
        if(params.DeptAccountTypeMain === null || (params.DeptAccountTypeMain && params.DeptAccountTypeMain.length <= 0))
        {
            setAccountTypeError("The Account Type field is required.")
            response = false
        }
        if(params.BacktraceShow === null || (params.BacktraceShow && params.BacktraceShow.length <= 0))
        {
            setLIAError("The Listed in Arrest field is required.")
            response = false
        }
        if(params.NumberOfUsers === null)
        {
            setNOUError("The Number of Users field is required.")
            response = false
        }
        if(params.DateTerminated === null || (params.DateTerminated && params.DateTerminated.length <= 0))
        {
            setDeactDateError("The Deactivation Date field is required.")
            response = false
        }
        if(params.Address === null || (params.Address && params.Address.length <= 0))
        {
            setAddressError("The Address field is required.")
            response = false
        }
        if(params.City === null || (params.City && params.City.length <= 0))
        {
            setCityError("The City field is required.")
            response = false
        }
        if(params.State === null || (params.State && params.State.length <= 0))
        {
            setStateError("The State field is required.")
            response = false
        }
        if(params.Zip === null || (params.Zip && params.Zip.length <= 0))
        {
            setZipError("The Zip field is required.")
            response = false
        }
        if(params.Notes === null || (params.Notes && params.Notes.length <= 0)) {
            setNotesError("The Notes Field is required.")
            response = false
        }
        return response
    }
    
    return (
        <div className = "mx-auto">
            {(params && params?.DeptGroupList && params?.DeptGroupList.length > 0 && sso?.hasOwnProperty("SSOType") ?
            <Card sx={{ maxWidth: 900, minWidth: 900 }} className = "mx-auto" >
                <CardContent>
                    <span className="text-blue-400 font-bold text-xl ">
                        {params?.UniqueID === 0 ? <span>Add New Department</span> :
                            <span>Edit Department Details </span>}
                    </span>
                    <hr/>
                    <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={deptCodeError.length > 0 ? deptCodeError : "Dept Code"}
                                value={params?.DeptCode}
                                onChange={e => setParams({...params, DeptCode: e.target.value})}
                                color={deptCodeError.length > 0 ? "error" : "primary"}
                                focused={deptCodeError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={deptORIError.length > 0 ? deptORIError : "Dept ORI"}
                                value={params?.DeptORI}
                                onChange={e => setParams({...params, DeptORI: e.target.value})}
                                color={deptORIError.length > 0 ? "error" : "primary"}
                                focused={deptORIError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-5 gap-y-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={deptNameError.length > 0 ? deptNameError : "Dept Name"}
                                value={params?.DeptName}
                                onChange={e => setParams({...params, DeptName: e.target.value})}
                                color={deptNameError.length > 0 ? "error" : "primary"}
                                focused={deptNameError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className= {"grid mt-5 gap-y-5 gap-x-5 grid-cols-3"}>
                        <div className="w-full">
                            <FormControl fullWidth>
                                <InputLabel id="DeptGroupLabel">Dept Group</InputLabel>
                                <Select
                                    size="small"
                                    labelId="DeptGroupLabel"
                                    label="Dept Group"
                                    value={params?.DeptGroup}
                                    onChange={e => setParams({...params, DeptGroup: e.target.value})}
                                >
                                    {params.DeptGroupList && params?.DeptGroupList.map((group) => (
                                        <MenuItem value={group.Key}>{group.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="w-full">
                            <FormControl fullWidth color={accountTypeError.length > 0 ? "error" : "primary"}
                                         focused={accountTypeError.length > 0 ? true : undefined}>
                                {accountTypeError.length > 0 ?
                                    <InputLabel id="AccountTypeLevel">{accountTypeError}</InputLabel> :
                                    <InputLabel id="AccountTypeLevel">Account Type</InputLabel>}
                                <Select
                                    size="small"
                                    labelId="AccountTypeLevel"
                                    label={accountTypeError.length > 0 ? accountTypeError : "Account Type"}
                                    value={params?.DeptAccountTypeMain}
                                    onChange={e => setParams({...params, DeptAccountTypeMain: e.target.value})}
                                >
                                    {params.DeptAccountTypeList && params?.DeptAccountTypeList.map((type) => (
                                        <MenuItem value={type.Key}>{type.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        
                       
                            <div className="w-full">
                                <FormControl fullWidth>
                                    <InputLabel id="DeptGroupLabel">SSO Type</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="DeptGroupLabel"
                                        label="Dept Group"
                                        value={sso?.SSOType}
                                        onChange={e => setSSOParams({...sso, SSOType: e.target.value})}
                                    >
                                        <MenuItem value="LOCAL">LOCAL</MenuItem>
                                        <MenuItem value="SAML">SAML</MenuItem>
                                        {/*<MenuItem value="OIDC">OIDC</MenuItem>*/}
                                    </Select>
                                </FormControl>
                            </div>
                        
                    </div>

                    {sso.SSOType === "SAML" &&
                        <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Issuer/Entity ID"}
                                    value={sso?.SAML2_IDP_ENTITY_ID}
                                    onChange={e => setSSOParams({...sso, SAML2_IDP_ENTITY_ID: e.target.value})}
                                />
                            </div>

                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"SAML Post EndPoint"}
                                    value={sso?.SAML2_IDP_REQ_ENDPOINT}
                                    onChange={e => setSSOParams({...sso, SAML2_IDP_REQ_ENDPOINT: e.target.value})}
                                />
                            </div>
                            
                        </div>
                    }

                    {sso.SSOType === "SAML" &&
                        <div className="grid grid-cols-1 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    multiline
                                    minRows={3}
                                    label={"Public Key (Base64 CER)"}
                                    value={sso?.SAML2_IDP_PUB509KEY}
                                    onChange={e => setSSOParams({...sso, SAML2_IDP_PUB509KEY: e.target.value})}
                                />
                            </div>

                        </div>
                    }

                    {sso.SSOType === "OIDC" &&
                        <div className="grid grid-cols-3 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Client ID"}
                                    value={sso?.OIDC_ClientID}
                                    onChange={e => setSSOParams({...sso, OIDC_ClientID: e.target.value})}
                                />
                            </div>
                        
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Client Secret"}
                                    value={sso?.OIDC_ClientSecret}
                                    onChange={e => setSSOParams({...sso, OIDC_ClientSecret: e.target.value})}
                                />
                            </div>
                        
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Wellknown Configuration"}
                                    value={sso?.OIDC_WellknownConfiguration}
                                    onChange={e => setSSOParams({...sso, OIDC_WellknownConfiguration: e.target.value})}
                                />
                            </div>
                        </div> 
                    }

                    {sso.SSOType === "OIDC" &&
                        <div className="grid grid-cols-3 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Authorization Endpoint"}
                                    value={sso?.OIDC_AuthorizationEndpoint}
                                    onChange={e => setSSOParams({...sso, OIDC_AuthorizationEndpoint: e.target.value})}
                                />
                            </div>

                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"Token Endpoint"}
                                    value={sso?.OIDC_TokenEndpoint}
                                    onChange={e => setSSOParams({...sso, OIDC_TokenEndpoint: e.target.value})}
                                />
                            </div>

                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"User Info Endpoint"}
                                    value={sso?.OIDC_UserInfoEndpoint}
                                    onChange={e => setSSOParams({...sso, OIDC_UserInfoEndpoint: e.target.value})}
                                />
                            </div>
                        </div>
                    }
                    {sso.SSOType === "OIDC" &&
                        <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"JWKS Endpoint"}
                                    value={sso?.OIDC_JWKSEndpoint}
                                    onChange={e => setSSOParams({...sso, OIDC_JWKSEndpoint: e.target.value})}
                                />
                            </div>

                            <div className="w-full">
                                <TextField
                                    size="small"
                                    InputLabelProps={{'shrink': true}}
                                    className="w-full"
                                    label={"End Session Endpoint"}
                                    value={sso?.OIDC_EndSessionEndpoint}
                                    onChange={e => setSSOParams({...sso, OIDC_EndSessionEndpoint: e.target.value})}
                                />
                            </div>
                        </div>
                    }
                    
                    <div className="grid grid-cols-3 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <FormControl fullWidth color={LIAError.length > 0 ? "error" : "primary"}
                                         focused={LIAError.length > 0 ? true : undefined}>
                                {LIAError.length > 0 ? <InputLabel id="ArrestLabel">{LIAError}</InputLabel> :
                                    <InputLabel id="ArrestLabel">Listed in Arrest</InputLabel>}
                                <Select
                                    size="small"
                                    labelId="ArrestLabel"
                                    label={LIAError.length > 0 ? LIAError : "Listed in Arrest"}
                                    value={params?.BacktraceShow}
                                    onChange={e => setParams({...params, BacktraceShow: e.target.value})}
                                >
                                    {params.BacktraceShowList && params?.BacktraceShowList.map((group) => (
                                        <MenuItem value={group.Key}>{group.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                type={'number'}
                                label={NOUError.length > 0 ? NOUError : "Number of Users"}
                                value={params?.NumberOfUsers}
                                onChange={e => setParams({...params, NumberOfUsers: parseInt(e.target.value)})}
                                color={NOUError.length > 0 ? "error" : "primary"}
                                focused={NOUError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                type={'date'}
                                label={deactDateError.length > 0 ? deactDateError : "Deactivation Date"}
                                value={params?.DateTerminated?.substring(0, 10)}
                                onChange={e => setParams({...params, DateTerminated: e.target.value})}
                                color={deactDateError.length > 0 ? "error" : "primary"}
                                focused={deactDateError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={addressError.length > 0 ? addressError : "Address"}
                                value={params?.Address}
                                onChange={e => setParams({...params, Address: e.target.value})}
                                color={addressError.length > 0 ? "error" : "primary"}
                                focused={addressError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={cityError.length > 0 ? cityError : "City"}
                                value={params?.City}
                                onChange={e => setParams({...params, City: e.target.value})}
                                color={cityError.length > 0 ? "error" : "primary"}
                                focused={cityError.length > 0 ? true : undefined}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={stateError.length > 0 ? stateError : "State"}
                                value={params?.State}
                                onChange={e => setParams({...params, State: e.target.value})}
                                color={stateError.length > 0 ? "error" : "primary"}
                                focused={stateError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={zipError.length > 0 ? zipError : "Zip"}
                                value={params?.Zip}
                                onChange={e => setParams({...params, Zip: e.target.value})}
                                color={zipError.length > 0 ? "error" : "primary"}
                                focused={zipError.length > 0 ? true : undefined}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={"County"}
                                value={params?.County}
                                onChange={e => setParams({...params, County: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={"Contact Name"}
                                value={params?.ContractName}
                                onChange={e => setParams({...params, ContractName: e.target.value})}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={"Contact Phone #"}
                                value={params?.ContractPhone}
                                onChange={e => setParams({...params, ContractPhone: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                label={"Contact EMail"}
                                value={params?.ContractEmail}
                                onChange={e => setParams({...params, ContractEmail: e.target.value})}
                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                multiline minRows={2}
                                InputLabelProps={{'shrink': true}}
                                margin='none'
                                className="w-full"
                                label={notesError.length > 0 ? notesError : "Notes"}
                                value={params?.Notes}
                                onChange={e => setParams({...params, Notes: e.target.value})}
                                color={notesError.length > 0 ? "error" : "primary"}
                                focused={notesError.length > 0 ? true : undefined}
                            />
                        </div>
                        {params?.UniqueID !== 0 ?
                            <div className="w-full">
                                <TextField
                                    size="small"
                                    disabled
                                    multiline
                                    minRows={4}
                                    InputLabelProps={{'shrink': true}}
                                    margin='none'
                                    className="w-full"
                                    label="Previous Notes"
                                    value={params?.PreviousNotes}
                                    onChange={e => setParams({...params, PreviousNotes: e.target.value})}
                                />
                            </div>
                            : <></>}
                    </div>
                    <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-5">
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                disabled
                                variant="filled"
                                label={"Updated User"}
                                value={params?.UpdUID}

                            />
                        </div>
                        <div className="w-full">
                            <TextField
                                size="small"
                                InputLabelProps={{'shrink': true}}
                                className="w-full"
                                disabled
                                variant="filled"
                                label={"Updated Date"}
                                value={params?.UpdDate}
                            />
                        </div>
                    </div>
                    {submitError?.length > 0 ?
                        <span style={{color: "red", fontSize: 14}}>
                           {submitError.split(",").map((error) => <div><ErrorIcon style={{color: "red"}}
                                                                                  fontSize='small'/>{error}</div>)}
                        </span>
                        : <></>}
                    <div className="mt-8 flex justify-center">
                        <ButtonGroup variant="outlined" size={"large"} color={"inherit"}>
                            <Button onClick={submitDept}>Save</Button>
                            <Button onClick={() => CloseBox({}, 'Close')}>CANCEL</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
            </Card>
                : <div className="text-blue-400 text-6xl font-bold"></div>)}
        </div>
    );
}

export default UpdateDepts;
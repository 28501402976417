import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup, Tooltip, FormControlLabel } from "@mui/material";
import ArrestDropDowns from "./_arrestDropDowns";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { JAOS } from "../../../services/JAOS.service";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatInputDate } from "../../../services/formatDate.service";
import { GetDefaultStateForSite, GetStringNameForSite, SITE } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface ArrestSearchProps {
    SubmitSearch: any
    //HandleShowGunTraceRecent: any
    passedData?: any | null
}

const ArrestSearch: React.FC<ArrestSearchProps> = ({ SubmitSearch, passedData = null }) => {
    let today: Date = new Date();
    var toDate = new Date();
    var prevDate = new Date();
    var fromday = new Date();

    if (new Date().getDay() === 1) {
        prevDate = new Date(prevDate.setDate(prevDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    if(new Date().getHours() < 6 && new Date().getDay() !== 1)
    {
        fromday = new Date(prevDate.setDate(prevDate.getDate() - 1));
    }
    else{
        if((SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL || SiteName == SITE.NYPROD || SiteName == SITE.NYDEMO || SiteName == SITE.NYLOCAL) && new Date().getDay() !== 1){

            fromday = new Date(prevDate.setDate(prevDate.getDate() - 1));
        }
    }

    /*if (toDate.getDay() === 1) {
        prevDate = new Date(toDate.setDate(toDate.getDate() - 2));
        fromday = prevDate //Default - Previous weekend
    }
    else {
        if(SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL){
            prevDate = new Date(toDate.setDate(toDate.getDate() - 1));
            fromday = prevDate //Default - Previous Day
        }
        else
            fromday = toDate;
    }*/
    
    const [params, setParams] = React.useState<any>({ FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: "", Case: "", Plate: "", SSN: "", AgeRangeStart: "", AgeRangeEnd: "", FTSID: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true, systemCall: false, Phone:"" })
    const [dateType, setDateType] = React.useState<string>((SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL) ? "Arrest Date" : "Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [showALPR, setShowALPR] = React.useState<boolean>(false)
    //const [showGunTraceRecent, setShowGunTraceRecent] = React.useState<boolean>(false);
    //const [showGTRecentButton, setShowGTRecentButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (passedData) {
            let l = new JAOS()
            let data = l.objFromStack(passedData)
            let p = params
            if (data.CCN) {
                p.Case = data.CCN
            }
            if (data.FBI) {
                p.FBI = data.FBI
            }
            if (data.SBI) {
                p.SBI = data.SBI
            }
            if (data.DOB || data.LName || data.FName) {
                p.FirstName = data.FName
                p.MI = data.MI
                p.LastName = data.LName
                p.DOB = data.DOB
            }
            else if (data.Plates) {
                p.Plate = data.Plates
            }
            p.showDate = false
            setParams(p)
            if(SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL){
                SubmitSearch(p, dateType, andOrType, dropDownParams, 'E')
            } else {
                SubmitSearch(p, dateType, andOrType, dropDownParams)
            }
        }
        else {
            let p = { ...params }
            p.systemCall = true;
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [passedData])

    React.useEffect(() => {
        let p = params
        if (params.FirstName?.length !== 0)
            p.showDate = false
        if (params.MI?.length !== 0)
            p.showDate = false
        if (params.LastName?.length !== 0)
            p.showDate = false
        if (params.DOB?.length !== 0)
            p.showDate = false
        if (params.FBI?.length !== 0)
            p.showDate = false
        if (params.SBI?.length !== 0)
            p.showDate = false
        if (params.DL?.length !== 0)
            p.showDate = false
        if (params.Case?.length !== 0)
            p.showDate = false
        if (params.Plate?.length !== 0) {
            p.showDate = false
            params?.Plate?.includes(",") ? setShowALPR(true) : setShowALPR(false)
        }
        if (params.SSN?.length !== 0)
            p.showDate = false
        if (params.FTSID?.length !== 0)
            p.showDate = false
        if (params.Phone?.length !== 0)
            p.showDate = false
        setParams(p)
    }, [params.FirstName, params.MI, params.LastName, params.DOB, params.FBI, params.SBI, params.DL, params.Case, params.Plate, params.SSN, params.AgeRangeStart, params.AgeRangeEnd, params.FTSID,params.Phone])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
        // if(dropDowns?.Indicator && dropDowns?.Indicator?.length > 0 && dropDowns?.Indicator?.includes("NJ-Gun-Trace")){
        //     setShowGTRecentButton(true)
        // }
        // else{
        //     setShowGTRecentButton(false)
        //     setShowGunTraceRecent(false)
        // }
    }

    const handleClear = () => {
        setParams({ ...params, FirstName: "", MI: "", LastName: "", DOB: "", FBI: "", SBI: "", DL: "", Case: "", Plate: "", SSN: "", AgeRangeStart: "", AgeRangeEnd: "", FTSID: "", toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: true, systemCall: false, Phone:"" })
        setDateType((SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL) ? "Arrest Date" : "Entry Date")
        setAndOrType("AND")
        setShowALPR(false)
        setClear(clear + 1)
        //setShowGTRecentButton(false)
        //handleShowGunTraceRecent(false)
    }

    // const handleShowGunTraceRecent = (value: any) => {
    //     setShowGunTraceRecent(value)
    //     HandleShowGunTraceRecent(value)
    // }

    const handleDate = (e: any) => {
        let show = params.showDate
        setParams({ ...params, showDate: !show })
    }

    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.Statute = Object?.keys(dropDownParam.Statute)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.IncidentCity = Object?.keys(dropDownParam.IncidentCity)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.AgencyArrested = Object?.keys(dropDownParam.AgencyArrested)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    const handleALPRSearch = () => {
        let tempParams = { ...params }
        let l = new JAOS()
        let hashID = l.objToStack(tempParams.Plate)
        window.open('/tools/alpr_vin_search?PlateList=' + hashID, '_blank')
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" >
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={handleDateType}
                    sx={{ mr: 2 }}
                >
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >{(SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL) ? "Offense Date" : "Entry Date"}</ToggleButton>
                </ToggleButtonGroup>
                <Checkbox checked={params.showDate} icon={<EventBusyOutlinedIcon />} checkedIcon={<EventAvailableOutlinedIcon />} onChange={handleDate} />

                {(params.showDate) ?
                    <>
                        <div>
                            <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                size={"small"} label="From Date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={params.fromDate}
                                onChange={(e: any) => {
                                    setParams({ ...params, fromDate: e.target.value })
                                }}
                            />
                        </div>
                        <div>
                            <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                size={"small"} label="To Date"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ style: { textAlign: 'center' } }}
                                value={params.toDate}
                                onChange={(e: any) => {
                                    setParams({ ...params, toDate: e.target.value })
                                }}
                            />
                        </div>
                    </>
                    : <></>
                }
            </div>
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><TextField margin='none' fullWidth size={"small"} label="First Name" value={params.FirstName} onChange={(e: any) => setParams({ ...params, FirstName: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Middle Initial" value={params.MI} onChange={(e: any) => setParams({ ...params, MI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Last Name" value={params.LastName} onChange={(e: any) => setParams({ ...params, LastName: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} type="date" label="DOB" InputLabelProps={{ shrink: true }} value={params.DOB} onChange={(e: any) => setParams({ ...params, DOB: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="FBI #" value={params.FBI} onChange={(e: any) => setParams({ ...params, FBI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label={GetStringNameForSite(SiteName, "SBI #")} value={params.SBI} onChange={(e: any) => setParams({ ...params, SBI: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="DL #" value={params.DL} onChange={(e: any) => setParams({ ...params, DL: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="SSN #" value={params.SSN} onChange={(e: any) => setParams({ ...params, SSN: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Age Lower Range" type="number" value={params.AgeRangeStart} onChange={(e: any) => setParams({ ...params, AgeRangeStart: e.target.value })} title="Age of offender at the time of Arrest. (Lower Limit)" /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Age Upper Range" type="number" value={params.AgeRangeEnd} onChange={(e: any) => setParams({ ...params, AgeRangeEnd: e.target.value })} title="Age of offender at the time of Arrest. (Upper Limit)" /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="Plate #" value={params.Plate} onChange={(e: any) => setParams({ ...params, Plate: e.target.value })} title="Search Sample: PL*E, PL%E, PL_TE, PL[A-E]TE, PL[^E-H]TE, 'PLATE1,PLATE2,PLATE3'" /></div>
                <div><TextField margin='none' fullWidth size={"small"} label={GetStringNameForSite(SiteName, "Case #")} value={params.Case} onChange={(e: any) => setParams({ ...params, Case: e.target.value })} /></div>
                <div><TextField margin='none' fullWidth size={"small"} label="ATF #" value={params.FTSID} onChange={(e: any) => setParams({ ...params, FTSID: e.target.value })} title="ETrace ATF FTS ID#" /></div>
                {(SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL) && <div><TextField margin='none' fullWidth size={"small"} label="Phone #" value={params.Phone} onChange={(e: any) => setParams({ ...params, Phone: e.target.value })} title="Phone #" /></div>}
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "paddingTop": "10px", marginTop: '10px' }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"arrest"} /></div>
            </div>
            <div className={"pt-3"} style={{ "display": "flex", "justifyContent": "end" }}>
                {/* {showGTRecentButton ?
                <div>
                    <FormControlLabel
                        control={
                            <Tooltip title={`Recent 24-Hour ${GetDefaultStateForSite(SiteName)}-Gun-Trace`}>
                                <Checkbox
                                value={showGunTraceRecent}
                                checked={showGunTraceRecent}
                                onChange={(e: any, value:any) => handleShowGunTraceRecent(value)}
                                />
                            </Tooltip>}                       
                        //label="Recent Gun Trace"
                        label={`Recent 24-Hour ${GetDefaultStateForSite(SiteName)}-Gun-Trace`}
                        sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}}/>
                </div>
                :<></>}                 */}
                <div>
                    <ButtonGroup size="large" color="inherit" >
                        <Button type="submit" onClick={handleSearch}>Search</Button>
                        <Button onClick={handleClear}>CLEAR</Button>
                        {showALPR ? <Button title="Search Make & Model of Plate List" onClick={handleALPRSearch}>Make and Model</Button> : <div ></div>}
                    </ButtonGroup>
                </div>

            </div>
        </div>
    );
}

export default ArrestSearch;
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import { MoneyObject } from "../../../interfaces/NJPORT/money.interface";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: MoneyObject | null;
}

const MoneyInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<MoneyObject>({});
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");

  React.useEffect(() => {
    if (editReport) setReport(editReport);
  }, []);

  const handleValueChange = (field: string, val: any) => {
    // Create a copy of the report object
    const updatedReport = { ...report, [field]: val };
  
    // Check if Date_Received is not present
    if (!updatedReport.Date_Received) {
      updatedReport.Date_Received = convertDate(updatedReport.Date_Received);
      console.log("Date_Received was not present, set to today's date");
    }
    if (!updatedReport.Date_Transported) {
      updatedReport.Date_Transported = convertDate(updatedReport.Date_Transported);
      console.log("Date_Received was not present, set to today's date");
    }
  
    // Update the report state
    setReport(updatedReport);
  };

  const handleSubmit = async () => {
    let data = report;
    console.log(data);
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (
        !temp.ApplicantFirstName ||
        temp.ApplicantFirstName.trim().length === 0
      ) {
        flag = true;
        text = "ApplicantFirstName";
      } else if (
        !temp.ApplicantLastName ||
        temp.ApplicantLastName.trim().length === 0
      ) {
        flag = true;
        text = "ApplicantLastName";
      } else if (!temp.InvestigatorName) {
        flag = true;
        text = "InvestigatorName";
      } else if (!temp.MoneyOrderNumber || temp.MoneyOrderNumber.length === 0) {
        flag = true;
        text = "MoneyOrderNumber";
      } else if (!temp.MoneyOrderAmount) {
        flag = true;
        text = "MoneyOrderAmount";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields " + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };


  const convertDate = (dateInput: Date | string | null | undefined) => {
    if (!dateInput) {
      const today = new Date();
      return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is a string, try to parse it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
  
    if (date instanceof Date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  
    // If dateInput is invalid, fallback to today's date
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  };
  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("InvestigatorName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Received"
                value={convertDate(report.Date_Received)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Date_Received", e.target.value)
                }
                error={report.Date_Received ? false : true}
                helperText={report.Date_Received ? "" : "Required"}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Person
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="First Name"
                value={report.ApplicantFirstName}
                onChange={(e) =>
                  handleValueChange("ApplicantFirstName", e.target.value)
                }
                error={report.ApplicantFirstName ? false : true}
                helperText={report.ApplicantFirstName ? "" : "Required"}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Middle Name"
                value={report.ApplicantMiddleName}
                onChange={(e) =>
                  handleValueChange("ApplicantMiddleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Last Name"
                value={report.ApplicantLastName}
                onChange={(e) =>
                  handleValueChange("ApplicantLastName", e.target.value)
                }
                error={report.ApplicantLastName ? false : true}
                helperText={report.ApplicantLastName ? "" : "Required"}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Money Order
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={2.4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Money Order Number"
                value={report.MoneyOrderNumber}
                onChange={(e) =>
                  handleValueChange("MoneyOrderNumber", e.target.value)
                }
                error={report.MoneyOrderNumber ? false : true}
                helperText={report.MoneyOrderNumber ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={2.4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Money Order Amount"
                value={report.MoneyOrderAmount}
                onChange={(e) =>
                  handleValueChange("MoneyOrderAmount", e.target.value)
                }
                error={report.MoneyOrderAmount ? false : true}
                helperText={report.MoneyOrderAmount ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={2.4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Transport Date"
                value={convertDate(report.Date_Transported)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("Date_Received", e.target.value)
                }
                error={report.Date_Transported ? false : true}
                helperText={report.Date_Transported ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={2.4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Transporter Name"
                value={report.TransporterName}
                onChange={(e) =>
                  handleValueChange("TransporterName", e.target.value)
                }
                error={report.TransporterName ? false : true}
                helperText={report.TransporterName ? "" : "Required"}
              />
            </Grid>

            <Grid item xs={2.4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Transporter Number"
                value={report.TransporterNumber}
                onChange={(e) =>
                  handleValueChange("TransporterNumber", e.target.value)
                }
                error={report.TransporterNumber ? false : true}
                helperText={report.TransporterNumber ? "" : "Required"}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MoneyInputForm;

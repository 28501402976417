import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DropDown } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { RenewalQuery } from "../../../interfaces/NJPORT/renewal.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { MultiSelect } from "primereact/multiselect";

const RenewalReportSearch: React.FC<any> = ({ SubmitSearch }) => {
  let today: Date = new Date();
  var fromday: Date = new Date();
  if (today.getDay() === 1) {
    fromday.setDate(today.getDate() - 2);
  }

  const [invdata, setInvData] = React.useState<RenewalQuery>({
    fromDate : fromday.toISOString(),
    toDate: today.toISOString(),
  });

  const [initialRun, setInitialRun] = React.useState<boolean>(false);
  const [params, setParams] = React.useState({
    FirstName: "",
    MI: "",
    LastName: "",
    DOB: "",
    FBI: "",
    SBI: "",
    AgeRangeStart: "",
    AgeRangeEnd: "",
    toDate: today,
    fromDate: fromday,
    Gender: "",
    Status: "",
    ReportNum: "",
    SystemCall: false,
  });
  const [dateType, setDateType] = React.useState<string>("Entry Date");
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [jobTitle, setJobTitle] = React.useState("");
  const [pacCard, setPacCard] = React.useState("");

  React.useEffect(() => {
    if (!initialRun) {
      setInitialRun(true);
      SubmitSearch(invdata);
      invStatusDropdown();
      jobTitleDropdown();
      pacCardDropdown();
      setDateValues();
    }
  });

  const convertDate = (date: Date) => {
    let a = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let b =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let d = date.getFullYear() + "-" + b + "-" + a;
    return d;
  };

  const setDateValues = () => {
    let data = { ...invdata };
    data.fromDate = convertDate(fromday);
    data.toDate = convertDate(today);
    setInvData(data);
  };

  const handleChange = (field: string, val: any) => {
    let data = { ...invdata };
    switch (field) {
      case "fromDate":
        data.fromDate = val;
        break;
      case "toDate":
        data.toDate = val;
        break;
    }
    setInvData(data);
  };

  const handleSubmit = () => {
    SubmitSearch(invdata);
  };

  const handleDateType = (
    event: React.MouseEvent<HTMLElement>,
    dateType: string
  ) => {
    if (dateType === "Entry Date") setInvData({ ...invdata, dateTypeValue: 1 });
    else setInvData({ ...invdata, dateTypeValue: 0 });
    if (dateType) {
      setDateType(dateType);
    }
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
  };

  return (
    <div className="mt-5 ml-5 mr-5 p-1">
      <Box className="pt-3" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ pl: 2 }}>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={dateType}
              exclusive
              fullWidth
              onChange={handleDateType}
            >
              <ToggleButton value="Report Date">Report Date</ToggleButton>
              <ToggleButton value="Entry Date">Entry Date</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="fromDate"
              label="From Date"
              defaultValue={convertDate(fromday)}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("fromDate", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              name="toDate"
              label="To Date"
              defaultValue={convertDate(today)}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange("toDate", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="First Name"
              // value={invdata.fNames}
              // onChange={(e: any) =>
              //   setInvData({ ...invdata, fNames: [e.target.value] })
              // }
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Middle Initial"
              // value={invdata.mNames}
              // onChange={(e: any) =>
              //   setInvData({ ...invdata, mNames: [e.target.value] })
              // }
            />
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Last Name"
              // value={invdata.lNames}
              // onChange={(e: any) =>
              //   setInvData({ ...invdata, lNames: [e.target.value] })
              // }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              type="date"
              label="DOB"
              InputLabelProps={{ shrink: true }}
              // value={invdata.doBs}
              // onChange={(e: any) =>
              //   setInvData({ ...invdata, doBs: [e.target.value] })
              // }
            />
          </Grid>
          <Grid item xs={3} sx={{ pr: 2 }}>
            <TextField
              fullWidth
              margin="none"
              label="Case #"
              // value={invdata.caseNumbers}
              // onChange={(e: any) =>
              //   setInvData({ ...invdata, caseNumbers: [e.target.value] })
              // }
            />
          </Grid>

          <Grid item xs={6} sx={{ pr: 2 }}>
          
          <FormControl className={"p-float-label"} sx={{ minWidth: '100%', maxWidth: '100%' }}>
            <MultiSelect
              value={jobTitle}
              onChange={(e) => { setJobTitle(e.value) }}
              style={{ border: '1px solid #b9b9bb', height: '100%', textAlign: 'start' }}
              resetFilterOnHide={true} showClear={true} showSelectAll={false}
              inputId={"dd-pob"} options={jobTitleDD} filter optionLabel="Value" optionValue="Key"
              placeholder="Place Of Birth" maxSelectedLabels={1} className="w-full md:w-20rem" />
            <label htmlFor="dd-pob">Job Title</label>
          </FormControl>
        </Grid>
        
        </Grid>
  
        <Grid
          container
          spacing={1}
          sx={{ pl: 2, mt: 2, justifyContent: "flex-end" }}
        >
          <Grid item xs={8} sx={{ pr: 2 }}></Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            <ButtonGroup fullWidth size="large" color="inherit">
              <Button type="submit" onClick={handleSubmit}>
                Search
              </Button>
              <Button>CLEAR</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RenewalReportSearch;

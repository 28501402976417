import React, { useState, useEffect } from "react";
import { TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import ProfessionDetail from "../../interfaces/Detail/ProfessionDetail.interface"
import {RunGunOffenderDetailQuery, RunProfessionDetailQuery} from "../../services/detail.service";
import { formatDate } from "../../services/formatDate.service"

import './Detail.css'

interface DetailProps {
    ID: string | null
    department: string | null
}


const GunOffenderDetailInfo: React.FC<DetailProps> = ({ ID, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<any>([])

    const loadData = async () => {

        if (!DataLoaded) {
            setDataLoaded(true)
            let res = await RunGunOffenderDetailQuery(ID, department);
            setData(res)
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }} >
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Gun Offender Information</h3>
                    {Data.map((d:any,i:any) => (<>

                        {Object.keys(d).map((k:any) => (
                            <div className="DetailTableRow"><span className="DetailTableLabelGunOffender">{k?.replaceAll('_',' ')?.toUpperCase()}</span><span
                                className="DetailTableDataGunOffender">{d[k]}</span></div>
                        ))}
                    </>))}
                </CardContent>
            </Card>
        </div>
    );
}

export default GunOffenderDetailInfo;
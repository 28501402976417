import React, {useState} from 'react' 
import {Card, CardActions, CardContent, Typography, Button, TextField} from "@mui/material"; 
import {getUser} from "../../../services/auth.service";
import {Account} from "../../../interfaces/auth_interface";
import { SiteName } from '../../../services/config.service';
import { SITE } from '../../../interfaces/config.interface';

interface AgreementProps {
    AcceptAgreement:any
}
//@ts-ignore
const Agreement: React.FC<AgreementProps> = ({AcceptAgreement}) => {
    const [initials, setInitials] = useState("")
    const [initError, setInitError] = useState<string>("")
    let [user, setUser] = useState<Account>(getUser())

    const DeclineLogout = () => {
        window.location.reload()
    }

    const submitNotice = (e:any) => {
        e.preventDefault();
        setInitError("")
        if(initials.length <= 0)
        {
            setInitError("The Initials Field Is Required.")
        }
        else {
            AcceptAgreement(initials) 
        }
        
    }

    return (
        <div className="body-content">
            <form onSubmit={submitNotice}>
            <Card>
                    <CardContent>
                        <Typography sx={{ fontSize: 30, fontWeight:"bold" }} gutterBottom className={"text-center w-min-screen"}>
                            ANNUAL USER AGREEMENT
                        </Typography>
                        {(SiteName === SITE.LOCAL || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT || SiteName === SITE.NJPROD)
                            ?
                            <div>
                                <Typography sx={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                                    In order to maintain the highest standards concerning information collection, stored data, and trust in public safety, any authorized law enforcement (LE) officer or authorized civilian investigative analyst [User] accessing or using ALPRs, ALPR data, ALPR databases or any database which incorporates ALPR data owned or administered by New Jersey (NJ) LE agencies, must acknowledge this User Agreement.                        </Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 2 }}>
                                    Use your initials to indicate you {user.Firstname} {user.Lastname} have read and fully understand each requirement listed below.
                                </Typography>
                                <ul style={{ textAlign: 'left', listStyleType: "circle" }} className={"p-5"}>
                                    <li>
                                        I have read NJ Attorney General LE Directive 2022-12 (the Directive) and all NJ SNAP
                                        ALPR Coordinator policies (NJ SNAP Security Policy; NJ’s ALPR Search, BOLO, and POI
                                        Policy; and NJ SNAP Audit Policy). I will act in accordance with the Directive and those
                                        policies, as supplemented and amended.
                                    </li>
                                    <li>
                                        I have completed 28 CFR Part 23 criminal intelligence training, such as US Department of
                                        Justice, Bureau of Justice Assistance, 28 CFR Part 23 or comparable training.
                                    </li>

                                    <li>
                                        I will not share my account/login and will only use my own account/login to access ALPR
                                        data.
                                    </li>
                                    <li>
                                        The download and dissemination of ALPR data must follow the Directive, as supplemented
                                        and amended.
                                    </li>
                                    <li>
                                        I will only access, use, or share ALPR data for legitimate, specific, and documented LE
                                        purposes/justifications, the same as I would for Criminal Justice Information Services
                                        (CJIS) data.
                                    </li>
                                    <li>
                                        I will not use ALPR data to disclose personal identifying information (PII) about an
                                        individual, such as a name, address, or date of birth, unless there is a legitimate,
                                        specific, and documented LE purpose/justification for disclosing the PII to a LE officer
                                        or civilian investigative analyst.
                                    </li>
                                    <li>
                                        The retention period for NJ’s LE ALPR data is three years from the time of capture.
                                    </li>
                                    <li>
                                        A User can export and retain ALPR data beyond three years if it is related to an active investigation or pending judicial process.
                                    </li>
                                    <li>
                                        BOLO lists, hot lists, or other compilation of license plates associated with specific vehicles or persons must have a legitimate, specific, and documented LE purpose/justification and LE report number.
                                    </li>
                                    <li>
                                        I understand that every transaction within NJ CAP5 is tracked as part of a User’s history. ALPR searches and POIs are subject to a yearly audit by the NJ SNAP team and Agency ALPR Coordinators.
                                    </li>
                                </ul>
                                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                                    By signing this User Agreement, I verify and affirm I have read and fully understand each of the above requirements. I understand the sensitivity associated with ALPR data. I will comply with NJ Attorney General LE Directive 2022-12, NJ SNAP ALPR Coordinator, and any other policy prescribed by my employer governing the use of ALPR data. Failure to accept and acknowledge the requirements and this User Agreement will result in a user’s loss of access to NJ CAP5 and NJ SNAP until acknowledgement is provided. Misuse or violations of this User Agreement or any referenced policies may result in the termination of access, recommendation for disciplinary action, and/or criminal charges.
                                </Typography>
                            </div>
                            :
                            <div>
                                <Typography sx={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                                    In order to maintain the highest standards concerning information collection, stored data, and trust in public safety, any authorized law enforcement (LE) officer or authorized civilian investigative analyst [User] accessing or using ALPRs, ALPR data, ALPR databases or any database which incorporates ALPR data owned or administered by LE agencies, must acknowledge this User Agreement.</Typography>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold", mt: 2 }}>
                                    Use your initials to indicate you {user.Firstname} {user.Lastname} have read and fully understand each requirement listed below.
                                </Typography>
                                <ul style={{ textAlign: 'left', listStyleType: "circle" }} className={"p-5"}>
                                    <li>
                                        I have read LE Directive and all 
                                        ALPR Coordinator policies (Security Policy; ALPR Search, BOLO, and POI
                                        Policy; and Audit Policy). I will act in accordance with the Directive and those
                                        policies, as supplemented and amended.
                                    </li>
                                    <li>
                                        I have completed 28 CFR Part 23 criminal intelligence training, such as US Department of
                                        Justice, Bureau of Justice Assistance, 28 CFR Part 23 or comparable training.
                                    </li>

                                    <li>
                                        I will not share my account/login and will only use my own account/login to access ALPR
                                        data.
                                    </li>
                                    <li>
                                        The download and dissemination of ALPR data must follow the Directive, as supplemented
                                        and amended.
                                    </li>
                                    <li>
                                        I will only access, use, or share ALPR data for legitimate, specific, and documented LE
                                        purposes/justifications, the same as I would for Criminal Justice Information Services
                                        (CJIS) data.
                                    </li>
                                    <li>
                                        I will not use ALPR data to disclose personal identifying information (PII) about an
                                        individual, such as a name, address, or date of birth, unless there is a legitimate,
                                        specific, and documented LE purpose/justification for disclosing the PII to a LE officer
                                        or civilian investigative analyst.
                                    </li>
                                    <li>
                                        The retention period for ALPR data is three years from the time of capture.
                                    </li>
                                    <li>
                                        A User can export and retain ALPR data beyond three years if it is related to an active investigation or pending judicial process.
                                    </li>
                                    <li>
                                        BOLO lists, hot lists, or other compilation of license plates associated with specific vehicles or persons must have a legitimate, specific, and documented LE purpose/justification and LE report number.
                                    </li>
                                    <li>
                                        I understand that every transaction within application is tracked as part of a User’s history. ALPR searches and POIs are subject to a yearly audit by the team and Agency ALPR Coordinators.
                                    </li>
                                </ul>
                                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                                    By signing this User Agreement, I verify and affirm I have read and fully understand each of the above requirements. I understand the sensitivity associated with ALPR data. I will comply with LE Directive, ALPR Coordinator, and any other policy prescribed by my employer governing the use of ALPR data. Failure to accept and acknowledge the requirements and this User Agreement will result in a user’s loss of access until acknowledgement is provided. Misuse or violations of this User Agreement or any referenced policies may result in the termination of access, recommendation for disciplinary action, and/or criminal charges.
                                </Typography>
                            </div>
                        }                        
                        <TextField
                            margin="normal"
                            label={initError.length > 0 ? initError:"Initials"}
                            type="text"
                            onChange={e => setInitials(e.target.value)}
                            value = {initials}
                            color = {initError.length > 0 ? "error": "primary"}
                            focused = {initError.length > 0 ? true : undefined}
                        />
                    </CardContent>
                    <CardActions>
                        <Button style={{"margin":"5px"}} variant={"outlined"} color={"success"} type="submit" >
                            Accept
                        </Button>
                        <Button style={{ "margin": "5px" }} variant={"outlined"} color={"error"} type="button" onClick={DeclineLogout}>
                           Decline
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}

export default Agreement
import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { TempCardObject } from "../../../interfaces/NJPORT/TempCard.interface";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: TempCardObject | null;
}

const TempCardInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<TempCardObject>({});
  const [invStatusDD, setInvStatusDD] = React.useState<DropDown[]>([]);
  const [jobTitleDD, setJobTitleDD] = React.useState<DropDown[]>([]);
  const [pacCardDD, setPacCardDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [defaultstatus, setDefaultStatus] = React.useState<string>('');
  const [defaultjobTilte, setDefaultjobTilte] = React.useState<string>('');
  const [defaultpacCard, setDefaultpacCard] = React.useState<string>('');

  React.useEffect(() => {
    invStatusDropdown();
    jobTitleDropdown();
    pacCardDropdown();
    if (editReport) setReport(editReport);

    defaultStatusDD(invStatusDD);
    defaultjobTilteDD(jobTitleDD);
    defaultpacCardDD(pacCardDD);

  }, []);


  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.Investigation_Status)?.Value! : ''
    setDefaultStatus(v);
  }

  const defaultjobTilteDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.Job_Title)?.Value! : ''
    setDefaultjobTilte(v);
  }

  const defaultpacCardDD = (d: DropDown[]) => {
    let v: string = d ? d.find(d => d.Key == editReport?.PAC_Card_Issued)?.Value! : ''
    setDefaultpacCard(v);
  }

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "investigatorName":
        temp.InvestigatorName = val;
        break;
      case "date_Received":
        temp.Date_Received = val;
        break;
      case "date_Status_Change":
        temp.Date_Status_Change = val;
        break;
      case "investigation_Status":
        temp.Investigation_Status = val;
        setDefaultStatus(invStatusDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "firstName":
        temp.FirstName = val;
        break;
      case "middleName":
        temp.MiddleName = val;
        break;
      case "lastName":
        temp.LastName = val;
        break;
      case "job_Code":
        temp.Job_Title = val;
        break;
      case "job_Title":
        temp.Job_Title = val;
        setDefaultjobTilte(jobTitleDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "sponsorName":
        temp.SponsorName = val;
        break;
      case "ssn":
        temp.SSN = val;
        break;
      case "dob":
        temp.DOB = val;
        break;
      case "phoneNo":
        temp.PhoneNo = val;
        break;
      case "email":
        temp.Email = val;
        break;
      case "date_Fingerprint_Return":
        temp.Date_Fingerprint_Return = val;
        break;
      case "pac_Card_Issued":
        temp.PAC_Card_Issued = val;
        setDefaultpacCard(pacCardDD.find(d => d.Key === val)?.Value ?? '');
        break;
      case "pac_Card_Number":
        temp.PAC_Card_Number = val;
        break;
      case "date_pac_Card_Issued":
        temp.Date_PAC_Card_Issued = val;
        break;
      case "notes":
        temp.Notes = val;
        break;

      case "temp_Card_Number":
        temp.Temp_Card_Number = val;
        break;
      case "date_Temp_Card_Issues":
        temp.Date_Temp_Card_Issued = val;
        break;
      case "createUser":
        temp.CreateUser = val;
        break;
      case "createDept":
        temp.CreateDept = val;
        break;
      case "createDate":
        temp.CreateDate = val;
        break;
      case "updateUser":
        temp.UpdateUser = val;
        break;
      case "updateDept":
        temp.UpdateDept = val;
        break;
      case "updateDate":
        temp.UpdateDate = val;
        break;
      case "deleted":
        temp.Deleted = val;
        break;
    }
    setReport(temp);
  };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.FirstName || temp.FirstName.trim().length === 0) {
        flag = true;
        text = "FirstName";
      } else if (
        !temp.Investigation_Status ||
        temp.Investigation_Status.length === 0
      ) {
        flag = true;
        text = "Investigation Status";
      } else if (!temp.Email || temp.Email.trim().length === 0) {
        flag = true;
        text = "Email";
      } else if (!temp.Job_Title || temp.Job_Title.length === 0) {
        flag = true;
        text = "Job Title";
      } else if (!temp.SponsorName || temp.SponsorName.trim().length === 0) {
        flag = true;
        text = "Sponsor Name";
      }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const invStatusDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_INVSTATUS",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setInvStatusDD(response);
    defaultStatusDD(response);
  };

  const jobTitleDropdown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_JOBCODE",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setJobTitleDD(response);
    defaultjobTilteDD(response);
  };

  const pacCardDropdown = async () => {
    let data = {
      params: {
        DropDownName: "YESNO",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPacCardDD(response);
    defaultpacCardDD(response);
  };

  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Case
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Case No"
                value={report.CaseNo}
                onChange={(e) =>
                  handleValueChange("caseNo", e.target.value)
                }
                error={report.CaseNo ? false : true}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Investigator Name"
                value={report.InvestigatorName}
                onChange={(e) =>
                  handleValueChange("investigatorName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Received"
                value={report.Date_Received}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Received", e.target.value)
                }
                error={report.Date_Received ? false : true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={5} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Inv Status</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="invStatus-select"
                  value={defaultstatus}
                  label="InvStatus"
                  renderValue={() => defaultstatus}
                  onChange={(e: any) =>
                    handleValueChange("investigation_Status", e.target.value)
                  }
                  error={report.Investigation_Status ? false : true}
                >
                  {invStatusDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Status Change"
                value={report.Date_Status_Change}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Status_Change", e.target.value)
                }
                error={report.Date_Status_Change ? false : true}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Person
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="First Name"
                value={report.FirstName}
                onChange={(e) => handleValueChange("firstName", e.target.value)}
                error={report.FirstName ? false : true}
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Middle Name"
                value={report.MiddleName}
                onChange={(e) =>
                  handleValueChange("middleName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Last Name"
                value={report.LastName}
                onChange={(e) => handleValueChange("lastName", e.target.value)}
                error={report.LastName ? false : true}
              />
            </Grid>

            <Grid item xs={3} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="DOB"
                value={report.DOB}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleValueChange("dob", e.target.value)}
                error={report.DOB ? false : true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="SSN"
                value={report.SSN}
                onChange={(e) => handleValueChange("ssn", e.target.value)}
              // error={report.SSN ? false : true}
              />
            </Grid>

            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="PhoneNo"
                value={report.PhoneNo}
                onChange={(e) => handleValueChange("phoneNo", e.target.value)}
              // error={report.PhoneNo ? false : true}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Email"
                value={report.Email}
                onChange={(e) => handleValueChange("email", e.target.value)}
                error={report.Email ? false : true}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Job
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>

            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="jobTitle-Label">Job Title</InputLabel>
                <Select
                  labelId="jobTitle-Label"
                  id="jobTitle-select"
                  value={defaultjobTilte}
                  label="JobTitle"
                  renderValue={() => defaultjobTilte}
                  onChange={(e: any) =>
                    handleValueChange("job_Title", e.target.value)
                  }
                  error={report.Job_Title ? false : true}
                >
                  {jobTitleDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Sponsor Name"
                value={report.SponsorName}
                onChange={(e) =>
                  handleValueChange("sponsorName", e.target.value)
                }
                error={report.SponsorName ? false : true}
              />
            </Grid>
          </Grid>


        </div>
      </div>

      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          PAC
        </div>
        <div className="my-3">

          {/* Temp Card Grid Container Start */}

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Temp Card Number"
                value={report.Temp_Card_Number}
                onChange={(e) =>
                  handleValueChange("temp_Card_Number", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Temp Card Issue Date"
                value={report.Date_Temp_Card_Issued}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_Temp_Card_Issued", e.target.value)
                }
              />
            </Grid>
          </Grid>

          {/* Temp Card Grid Container End */}

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            {report?.PAC_Card_Issued == 'Y' && (
              <Grid item xs={6} sx={{ pr: 2 }}>
                <TextField
                  fullWidth
                  margin="none"
                  label="Pac Card Number"
                  value={report.PAC_Card_Number}
                  onChange={(e) =>
                    handleValueChange("pac_Card_Number", e.target.value)
                  }
                />
              </Grid>
            )}
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Date Pac Card Issued"
                value={report.Date_PAC_Card_Issued}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("date_pac_Card_Issued", e.target.value)
                }
              />
            </Grid>
          </Grid>

          {/* card Isuued yes/no and Notes */}

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="pacCard-Label">PAC Card Issued</InputLabel>
                <Select
                  labelId="pacCard-Label"
                  id="pacCard-select"
                  value={defaultpacCard}
                  label="PAC_Card_Issued"
                  renderValue={() => defaultpacCard}
                  onChange={(e: any) =>
                    handleValueChange("pac_Card_Issued", e.target.value)
                  }
                >
                  {pacCardDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Notes"
                value={report.Notes}
                onChange={(e) => handleValueChange("notes", e.target.value)}
              />
            </Grid>
          </Grid>

          {/* card Issued yes/no and Notes */}


        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TempCardInputForm;

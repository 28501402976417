import { Portal } from "@headlessui/react";
import {
  Alert,
  Box,
  breadcrumbsClasses,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { TerminalHireObject, TerminalHirePoolResponse, TerminalHireQuery } from "../../../interfaces/NJPORT/TerminalHire.interface";
import { getPdoHirePool, InvestigationDropDown } from "../../../services/njport.service";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: TerminalHireObject | null;
}

const TerminalHireInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<TerminalHireObject>({});
  const [shipTerminalDD, setShipTerminalDD] = React.useState<DropDown[]>([]);
  const [portDD, setPortDD] = React.useState<DropDown[]>([]);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [poolbegining, setDefaultPoolBegining] = React.useState<number>(100);


  React.useEffect(() => {
    portLocationDropDown();
    if (editReport) {
      console.log(editReport);
      setReport(editReport);

    };
  }, []);

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "portLocation":
        temp.Port = val;
        terminalDropDown(temp.Port);
        break;
      case "shipTerminal":
        temp.ShipTerminal = val;
        break;
      case "hiringdate":
        temp.HiringDateTime = val;
        startPool(val);
        break;
      case "workersHired":
        temp.WorkersHired = val;
        break;
      case "description":
        temp.TerminalDesc = val;
        break;
    }
    setReport(temp);
  };

  const portLocationDropDown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_LOCATION",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPortDD(response);
  };

  const terminalDropDown = async (port: any) => {
    let data = {
      params: {
        DropDownName: "PORT_SHIPTERMINAL" + "_" + port,
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setShipTerminalDD(response);
  };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const startPool = async (val: any) => {
    let search_query: TerminalHireQuery = {
      queryType: "PDOHIRE_STARTPOOL",
      dateTypeValue: 1,
      dateRange: true,
      fromDate: val + "T00:00:00",
      toDate: val + "T23:59:59",
    };
    let result: TerminalHirePoolResponse = await getPdoHirePool(search_query);
    if (result && result.AnyTable && result.AnyTable.length > 0) {
      if (result.AnyTable[0].StartPool) {
        report.PoolBiginning = result.AnyTable[0].StartPool
        setDefaultPoolBegining(result.AnyTable[0].StartPool)
      }
    }
  };

  const convertDate = (dateInput: string | null | undefined) => {
    const date = dateInput ? new Date(dateInput) : new Date();
    let a = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let b =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let d = date.getFullYear() + "-" + b + "-" + a;
    return d;
  };


  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          TerminalHire
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
          <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="date"
                label="Hiring Date"
                // value={report.HiringDateTime}
                defaultValue={convertDate(report.HiringDateTime)}
                InputLabelProps={{ shrink: true }}
                onBlur={(e) =>
                  handleValueChange("hiringdate", e.target.value)
                }
                // error={report.HiringDateTime ? true : false}
                helperText={report.HiringDateTime ? "" : ""}
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="port">Port</InputLabel>
                <Select
                  labelId="port"
                  id="Port"
                  value={report.Port}
                  label="PORT"
                  onChange={(e: any) =>
                    handleValueChange("portLocation", e.target.value)
                  }
                  error={report.Port ? false : true}>
                  {portDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="shipTerminal-Label">Ship Terminal</InputLabel>
                <Select
                  labelId="shipTerminal-Label"
                  id="Ship Terminal"
                  value={report.ShipTerminal}
                  label="ShipTerminal"
                  onChange={(e: any) =>
                    handleValueChange("shipTerminal", e.target.value)
                  }
                  error={report.ShipTerminal ? false : true}>
                  {shipTerminalDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Workers Hired"
                value={report.WorkersHired}
                onChange={(e) =>
                  handleValueChange("workersHired", e.target.value)
                }
              />
            </Grid> 
            <Grid item xs={6} sx={{ pr: 2 }}>
            <TextField
                fullWidth
                margin="none"
                label="Terminal description"
                value={report.TerminalDesc}
                onChange={(e) =>
                  handleValueChange("description", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid container spacing={1} sx={{ pl: 2, mt: 2, justifyContent: 'flex-end' }}>
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button sx={{ marginLeft: '8px' }}>CLEAR</Button> {/* Add margin to the second button */}
          </ButtonGroup>
        </Grid>
      </Grid>

    </Box>
  );
};

export default TerminalHireInputForm;

import React, { useState, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog, Tooltip, Box, Divider, Grid, CardMedia
} from "@mui/material";
import ALPRDetail from "../../interfaces/Detail/ALPRDetail.interface"
import {RunALPRDetailNo_IDQuery, RunALPRDetailQuery, RunLPRDetailQuery} from "../../services/detail.service";
import './Detail.css'
import DetailMap from "./_DetailMap";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LPRDetail from "../../interfaces/Detail/LPRDetail.interface";

interface DetailProps {
    ID?: string | null
    ALPRObject?: string | null
    department: string | null
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const LPRDetailInfo: React.FC<DetailProps> = ({ ID = null, department }) => {
    const [DataLoaded, setDataLoaded] = React.useState<boolean>(false)
    const [Data, setData] = React.useState<LPRDetail>({})
    const [pin, setPin] = React.useState<any>({})
    const [enlarged, setEnlarged] = React.useState<any>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const [imgPosition, setImgPosition] = React.useState<string>('0% 0%')

    const loadData = async () => {
        
        if (!DataLoaded) {
            setDataLoaded(true)
            if(ID) 
            {
                let res = await RunLPRDetailQuery(ID, department);
                setData(res)
                createPin(res)
            }
            
        }
    }

    React.useEffect(() => {
        if (!DataLoaded) {
            setDataLoaded(true)
            loadData()
        }
    });

    const createPin = async (Data: any) => {
        let tPin: any = {}
        if (Data && Data?.Src_Latitude && Data?.Src_Longitude) {
            tPin = ({
                lat: Data?.Src_Latitude,
                lng: Data?.Src_Longitude,
                date: Data?.Red_TimeStamp,
                name: Data?.Src_Description,
                plate: Data?.Red_Vrm,
                detail: "alpr"
            })
        }
        setPin(tPin)
    }
    
    const handleMouseMove = (e:any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setImgPosition(x+'% ' + y+'%') 
    }

    const formatName = (fName:any, mName:any, lName:any) => {
        let nameLst = [fName, mName, lName].filter(n => n && n !== '').join(' ');
        return nameLst;
    }

    const formatAddress = (address:any, city:any, state:any, zip:any) => {
        let addLst = [address, city, state].filter(n => n && n !== '').join(', ');
        if(zip && zip !== '') addLst += ' ' + zip;
        return addLst;
    }

    const formatDate = (dateString:string | Date | null | undefined) => {
        if(dateString)
        {
            let date:any = new Date(dateString)
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = (date.getDate()).toString().padStart(2, '0');
      
            return month + '/' + day + '/' + year;
        }
        else{
            return ""
        }
    }
    

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5 ">
            {enlarged ?
                <Dialog 
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={()=>{setEnlarged(null)}}
                    open={open}
                    TransitionComponent={Transition}
                
                >
                    <Tooltip title={"Click to Shrink"}><img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/></Tooltip>
                </Dialog>
                :
                <></>
            }
            <Card sx={{ maxWidth: 1200, m: 'auto', mb: 1 }} >
                <CardContent>
                    <div className="border-2 border-gray-700 mb-3 p-2">
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Camera: </span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className="DetailData">{Data?.Src_Name}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Date: </span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className="DetailData">{Data?.Red_TimeStamp}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Description: </span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className="DetailData">{Data?.Src_Description}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Server: </span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className="DetailData">{Data?.Ste_Name}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="border-2 border-gray-700 mb-3">
                        <Grid container>                            
                            <Grid item xs={6} className = "DetailImg p-2">
                                <Tooltip title={"Click to Enlarge"}>
                                    <CardMedia
                                        component="img"
                                        // sx={{height: 350, width: 400}}                               
                                        src={Data?.PlateImage}
                                        onClick={()=>{setEnlarged(Data?.PlateImage); setOpen(true)}}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6} className = "DetailImg p-2">
                                <Tooltip title={"Click to Enlarge"}>
                                    <CardMedia
                                        component="img" 
                                        // sx={{height: 350, width: 400}}  
                                        src={Data?.CarImage}
                                        onClick={()=>{setEnlarged(Data?.CarImage); setOpen(true)}}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="border-2 border-gray-700 mb-3">
                        <h3 className="mb-4 font-bold font text-xl p-2">Vehicle Details</h3>
                        <Grid container spacing={2}>                            
                            <Grid item xs={2}>
                                <span className="DetailLabel">Plate Number: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.Red_Vrm}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Plate Year: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.PlateYear}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">State: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.PlateState}</span>
                            </Grid>                            
                            <Grid item xs={2}>
                                <span className="DetailLabel">Make: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VehMake}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Model: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VehModel}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Year: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VehYear}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">VIN: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VIN}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">VIN Make: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VINVehMake}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">VIN Model: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VINVehModel}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Color: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VehColor}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Body: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.VehBody}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Axel Props: </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailData">{Data?.AxelProps}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="border-2 border-gray-700 mb-3">
                        <h3 className="mb-4 font-bold font text-xl p-2">Registered Owner</h3>
                        <Grid container spacing={2}>                            
                            <Grid item xs={2}>
                                <span className="DetailLabel">Name: </span>
                            </Grid>
                            <Grid item xs={10}>
                                <span className="DetailData">{formatName(Data?.FirstName, Data?.MiddleName, Data?.LastName)}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Gender: </span>
                            </Grid>
                            <Grid item xs={10}>
                                <span className="DetailData">{Data?.Sex}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">DOB: </span>
                            </Grid>
                            <Grid item xs={10}>
                                <span className="DetailData">{formatDate(Data?.DOB)}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <span className="DetailLabel">Address: </span>
                            </Grid>
                            <Grid item xs={10}>
                                <span className="DetailData">{formatAddress(Data?.Address, Data?.City, Data?.State, Data?.Zip)}</span>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="lpr" /> </div> : <></>}
                    </div>
                    
                    
                    
                    {/* <h3 className="mb-4 font-bold font text-xl text-blue-400">Vehicle Details</h3>

                    <table>                        
                        <tbody>
                            <tr>
                                <td className = "img-wrapper">
                                    <Tooltip title={"Click to Enlarge"}><img src={Data?.PlateImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.PlateImage); setOpen(true)}}/></Tooltip>
                                </td>
                                <td className = "img-wrapper">
                                    <Tooltip title={"Click to Enlarge"}><img src={Data?.CarImage} width="200" height="250" onClick={()=>{setEnlarged(Data?.CarImage); setOpen(true)}}/></Tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">ID</span><span className="DetailTableData">{Data?.ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read ID</span><span className="DetailTableData">{Data?.Red_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate #</span><span className="DetailTableData">{Data?.Red_Vrm}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Plate State</span><span className="DetailTableData">{Data?.Red_Vrm_State}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read Date</span><span className="DetailTableData">{Data?.Red_TimeStamp}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Latitude</span><span className="DetailTableData">{Data?.Src_Latitude}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Longitude</span><span className="DetailTableData">{Data?.Src_Longitude}</span></div>
                    {pin && pin.lat && pin.lng ? <div className="DetailTableRow border-2 border-black"> <DetailMap data={pin} detail="alpr" /> </div> : <></>} */}
                </CardContent>
            </Card>

            {/* <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-5 font-bold font text-xl text-blue-400">Camera Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Site ID</span><span className="DetailTableData">{Data?.Ste_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Server</span><span className="DetailTableData">{Data?.Ste_Name}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Camera ID</span><span className="DetailTableData">{Data?.Src_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Camera Name</span><span className="DetailTableData">{Data?.Src_Name}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Description</span><span className="DetailTableData">{Data?.Src_Description}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Fixed Camera</span><span className="DetailTableData">{Data?.Src_Fixed}</span></div>
                </CardContent>
            </Card>
            <Card sx={{ maxWidth: 900, m: 'auto', mb: 1 }}>
                <CardContent>
                    <h3 className="mb-4 font-bold font text-xl text-blue-400">Other Information</h3>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">External Link</span><span className="DetailTableData">{Data?.Src_IPV4Address}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Mapped User ID</span><span className="DetailTableData">{Data?.Msp_User_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Read Source ID</span><span className="DetailTableData">{Data?.Red_Src_ID}</span></div>
                    <div className="DetailTableRow" ><span className="DetailTableLabel">Source</span><span className="DetailTableData">{Data?.ServerDesc}</span></div>
                </CardContent>
            </Card> */}
        </div>
    );
}

export default LPRDetailInfo;
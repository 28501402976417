import React, { useEffect, useState } from "react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface PDOHireStatsProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const PDOHireStats: React.FC<PDOHireStatsProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
    }) => {
    const [checkerRequests, setCheckerRequests] = useState<number>(0);
    const [checkerAvailable, setCheckerAvailable] = useState<number>(0);
    const [checkerRemaining, setCheckerRemaining] = useState<number>(0);
    const [checkerUnfilled, setCheckerUnfilled] = useState<number>(0);
    const [lsAvailable, setLSAvailable] = useState<number>(0);
    const [lsRequests, setLSRequests] = useState<number>(0);
    const [lsRemaining, setLSRemaining] = useState<number>(0);
    const [lsUnfilled, setLSUnfilled] = useState<number>(0);
    const [refresh, setRefresh] = useState<number>(0);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        const payload = {
            queryType: "PDOHIRE_DAY",
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,            
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setCheckerRequests(0)
            setCheckerAvailable(0)
            setCheckerRemaining(0)
            setCheckerUnfilled(0)
            setLSAvailable(0)
            setLSRemaining(0)
            setLSRequests(0)
            setLSUnfilled(0)
            setData(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);

    useEffect(() => {
        let CKRequests: any;
        let CKAvailable: any;
        let CKRemaining: any;
        let CKUnfilled: any;
        let LSRequests: any;
        let LSAvailable: any;
        let LSRemaining: any;
        let LSUnfilled: any;

        CKRequests = 0;
        CKAvailable = 0;
        CKRemaining = 0;
        CKUnfilled = 0;
        LSRequests = 0;
        LSAvailable = 0;
        LSRemaining = 0;
        LSUnfilled = 0;

        data?.forEach((item: any) => {
            if (item?.JobID === "CK") {
                if (item.Requested !== undefined) {
                    CKRequests += item?.Requested
                }
                if (item.Available !== undefined) {
                    CKAvailable += item?.Available;
                }
                if (item.Remaining !== undefined) {
                    CKRemaining += item?.Remaining;
                }
                if (item.Unfilled !== undefined) {
                    CKUnfilled += item?.Unfilled;
                }
            }
            else if (item?.JobID === "LS") {
                if (item.Requested !== undefined) {
                    LSRequests += item?.Requested;
                }
                if (item.Available !== undefined) {
                    LSAvailable += item?.Available;
                }
                if (item.Remaining !== undefined) {
                    LSRemaining += item?.Remaining;
                }
                if (item.Unfilled !== undefined) {
                    LSUnfilled += item?.Unfilled;
                }
            }
        });
        setCheckerRequests(CKRequests);
        setCheckerAvailable(CKAvailable);
        setCheckerRemaining(CKRemaining);
        setCheckerUnfilled(CKUnfilled);
        setLSAvailable(LSRequests);
        setLSRemaining(LSAvailable);
        setLSRequests(LSRemaining);
        setLSUnfilled(LSUnfilled); 
    }, [data]);

    if (!data || data?.length <= 0) {
        return (
            <div
                className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                style={{ color: "red", fontWeight: 500 }}
            >
                No Data
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <div className="flex flex-col w-full">
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Requests</span>
                        <span>{checkerRequests}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Available</span>
                        <span>{checkerAvailable}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Remaining</span>
                        <span>{checkerRemaining}</span>
                    </div>
                    {checkerUnfilled > 0 ? (
                        <div className="flex justify-between p-2 border-b border-gray-200">
                            <span>Checkers Unfilled</span>
                            <span>{checkerUnfilled}</span>
                        </div>) : (
                        <div></div>
                    )
                    }
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Requested</span>
                        <span>{lsRequests}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Available</span>
                        <span>{lsAvailable}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Remaining</span>
                        <span>{lsRemaining}</span>
                    </div>
                    {lsUnfilled > 0 ? (
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Unfilled</span>
                        <span>{lsUnfilled}</span>
                    </div> ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PDOHireStats;
 
